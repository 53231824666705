import { Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import theme from "../../../modules/theme";
import { UserChip } from "../../User";
import { IGrapeComment } from "./gql_interface_grape_comments";

export const GrapeUserComment = (props: { comment: IGrapeComment; condensed?: boolean }) => {
  const now = moment();
  const date = moment(props.comment.created_at);
  const editDate = moment(props.comment.updated_at);

  return (
    <div css={{ margin: 8, marginTop: 0 }}>
      {props.condensed ? (
        <Typography variant="body2" style={{ marginBottom: 8 }} children={props.comment.message} />
      ) : (
        <div css={{ borderTop: "1px solid #eee", paddingTop: 10 }}>
          <div css={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {props.comment.owner ? (
              <React.Fragment>
                <UserChip user={props.comment.owner} collapsed />
                <Typography
                  //? name + surname
                  variant="caption"
                  style={{ marginLeft: 6, lineHeight: 1 }}
                  children={props.comment.owner.name + " " + props.comment.owner.surname}
                />
                <Typography
                  //? creation date
                  variant="caption"
                  style={{ color: "rgba(27, 32, 60, 0.38)", marginLeft: 12, lineHeight: 1 }}
                  children={date.format(now.year !== date.year ? "DD-MM-yyyy, HH.mm" : "DD MMM, HH.mm")}
                />
              </React.Fragment>
            ) : null}

            {editDate.isAfter(date) ? (
              <Typography
                //? edited
                variant="caption"
                style={{ color: "rgba(27, 32, 60, 0.38)", marginLeft: 12, lineHeight: 1 }}
                children="modificato"
              />
            ) : null}
          </div>

          {props.comment.reference_comment ? (
            <GrapeCommentCit
              title={
                props.comment.reference_comment.owner
                  ? props.comment.reference_comment.owner.name + " " + props.comment.reference_comment.owner.surname
                  : "- - -"
              }
              content={props.comment.reference_comment.message}
            />
          ) : null}

          <Typography variant="body2" style={{ marginTop: 6 }}>
            <span dangerouslySetInnerHTML={{ __html: props.comment.message }} />
          </Typography>
        </div>
      )}
    </div>
  );
};

export const GrapeCommentCit = (props: { title: string; content: string }) => (
  <div
    css={{
      display: "flex",
      flexDirection: "column",
      borderLeft: "2px solid " + theme.palette.primary.main,
      margin: 4,
      marginLeft: 0,
      paddingLeft: 4,
    }}
  >
    <Typography
      variant="subtitle2"
      css={{ fontWeight: "bold", color: theme.palette.primary.main }}
      children={props.title}
    />
    <Typography
      variant="body2"
      css={{ maxWidth: 200, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
      children={<span dangerouslySetInnerHTML={{ __html: props.content }} />}
    />
  </div>
);
