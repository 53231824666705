/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { Box, fade, Grid, useTheme } from "@material-ui/core";
import { IBoardGrape } from ".";
import Grapes from "../../../modules/Grapes";
import { isCompletionGrapeByTitle } from "../../../utils";
import AddTextField from "../../AddTextField";
import Grape from "../../Grape";
import { IGrape } from "../../grape_gql_interface";
import { BoardColumn, BoardStructure } from "./BoardBody";
import { boardGrapeSpecs } from "./BoardBody_bk";

interface IBoardBodyColumns {
  structure: BoardStructure;
  attached?: boolean;
  boardProps: IBoardGrape;
  visibleColumns: BoardColumn[];
  filterByProgress?: IGrape;
  columnItems: IGrape[][];
  withHeader?: boolean;
}

export const BoardBodyColumns = (props: IBoardBodyColumns) => {
  const theme = useTheme();
  const client = useApolloClient();

  const targetGrape = props.filterByProgress || props.boardProps.grape;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {props.withHeader && <BoardBodyHeader {...props} />}

      <Grid
        container
        item
        xs
        direction="row"
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {props.visibleColumns.map((column: BoardColumn, index: number) => {
          const droppableId = `${targetGrape.id}_crossOrder_${column.id}`;

          return (
            <Box
              key={column.id + index}
              display="flex"
              flexDirection="column"
              style={{
                paddingTop: 0,
                paddingLeft: boardGrapeSpecs.columnPadding / 2,
                paddingRight: boardGrapeSpecs.columnPadding / 2,
                paddingBottom: boardGrapeSpecs.columnPadding,

                width: boardGrapeSpecs.columnWidth,
                whiteSpace: "initial",
                position: "relative",
              }}
            >
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                css={{
                  paddingLeft: boardGrapeSpecs.columnPadding / 2,
                  paddingRight: boardGrapeSpecs.columnPadding / 2,
                  backgroundColor: column.hidden ? fade(theme.palette.primary.main, 0.1) : "rgba(0, 0, 0,0.026)",
                  borderTopLeftRadius: props.attached ? 0 : boardGrapeSpecs.borderRadius,
                  borderTopRightRadius: props.attached ? 0 : boardGrapeSpecs.borderRadius,
                  borderBottomLeftRadius: boardGrapeSpecs.borderRadius,
                  borderBottomRightRadius: boardGrapeSpecs.borderRadius,
                }}
              >
                <Box flexGrow={1} display="flex" css={{ ".grapes-column-container": { paddingBottom: 8 } }}>
                  {props.boardProps.renderGrapesColumn(targetGrape, props.columnItems[index], {
                    droppableId: droppableId,
                    expandChildrens: column.expandChildrens ?? true,
                    renderBananaDot: !column.noBananaDot,
                    todoModeEnabled: column.todoModeEnabled,
                    anchestors: props.boardProps.anchestors,
                    distance: 8,
                    trailComponent: (
                      <AddTextField
                        parentGrape={targetGrape}
                        labels={props.boardProps.allLabels}
                        addToGrape={(title, edits) => {
                          const variables = { ...edits };
                          variables["type"] = "todo";
                          variables["cross_order"] = column.id;
                          variables["status"] = isCompletionGrapeByTitle(column.name) ? 1 : 0;
                          return Grapes.addGrape(client, targetGrape.id, title, variables);
                        }}
                        withLabelSelector
                      />
                    ),
                    // withAddBtn
                  })}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Grid>
    </div>
  );
};

const BoardBodyHeader = (props: IBoardBodyColumns) => {
  const grape = props.filterByProgress || props.boardProps.grape;
  return (
    <div
      css={{
        position: "sticky",
        zIndex: 1,
        top: 43,
        marginTop: 8,
        padding: "0 4px",
        backgroundColor: "white",
      }}
    >
      <div
        className={props.filterByProgress ? "hoverable" : undefined}
        css={{
          // maxWidth: 400
          marginRight: 15,
        }}
      >
        {props.filterByProgress ? (
          <Grape
            grape={grape}
            flat
            collaborators={props.boardProps.collaborators}
            onRequestOpen={(g, params) => props.boardProps.onRequestOpen?.(g || grape, params)}
            anchestors={[...props.boardProps.anchestors, grape]}
            groupType={grape.type} // {props.boardProps.grape.type}
            style={{ backgroundColor: "transparent", borderBottom: "none" }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: "Kanban" }} css={{ padding: "8px 4px" }} />
        )}
      </div>
    </div>
  );
};
