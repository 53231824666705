import { Button } from "@material-ui/core";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { L } from ".";
import MLDialog from "../components/MLDialog";

let resettingStore = false;
export let messaging: any;

var firebaseConfig = {
  apiKey: "AIzaSyCqRdCMaJ4VaRSSkIsazxN0dfGQW7oGrvw",
  authDomain: "grapes.mabiloft.com",
  projectId: "sviluppo-mabiloft",
  messagingSenderId: "140292590300",
  appId: "1:140292590300:web:9f0d8dc1216faacc370219",
  measurementId: "G-ZX8RBG70BG",
};
// var firebaseConfig = {
//   apiKey: "AIzaSyCqRdCMaJ4VaRSSkIsazxN0dfGQW7oGrvw",
//   authDomain: "sviluppo-mabiloft.firebaseapp.com",
//   databaseURL: "https://sviluppo-mabiloft.firebaseio.com",
//   projectId: "sviluppo-mabiloft",
//   storageBucket: "sviluppo-mabiloft.appspot.com",
//   messagingSenderId: "140292590300",
//   appId: "1:140292590300:web:9f0d8dc1216faacc370219",
//   measurementId: "G-ZX8RBG70BG"
// };
//!FINE PARTE FIREBASE

export const __DEV__ = Boolean(
  typeof window !== "undefined" &&
    (window.location.hostname === "localhost" ||
      window.location.hostname === "localhost.mabiloft.com" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/))
);

//prevent - Droppable: unsupported nested scroll container detected.A Droppable can only have one scroll parent (which can be itself)
//? override warning
let defaultWarn = console.warn;
console.warn = (...objs: any[]) => {
  if (objs && objs.length && (objs[0] + "").includes("creact-beautiful-dnd")) {
    console.log("prevented droppable unsupported nested warning");
    return;
  }
  defaultWarn(...objs);
};

//? override error
// let defaultError = console.error;
// console.error = (...objs: any[]) => {
//   if (objs && objs.length && (objs[0] + "").includes("creact-beautiful-dnd")) {
//     console.log("prevented droppable error");
//     return;
//   }
//   defaultError(...objs);
// };

//? other
// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem('token');
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     }
//   }
// });

// const client = new ApolloClient({
//   uri: 'http://192.168.1.10:4567/graphql/v1',

//   // uri: `http${process.env.NODE_ENV == "production" ? "s" : ""}://grapes-backend.herokuapp.com/graphql/v1`,
//   // headers: {
//   //   authorization:
//   //     'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZDgyYWIwYTA3ZjM2ZDY2MDhmMWI3N2IiLCJlbWFpbCI6ImVkZHlAbWFiaWxvZnQuY29tIiwicGVybV90eXBlIjoic2luZ2xlIiwiaWF0IjoxNTY4ODQ0NTU0LCJleHAiOjE2MDA0MDIxNTR9.lRuQoh4pFeVq4h8SEJZEIbjNLci8U-x7ykcqwyn1FwA',
//   // },
//   request: operation => {
//     const token = localStorage.getItem("app-token");
//     operation.setContext({
//       headers: {
//         // authorization: token ? `Bearer ${token}` : ""
//         authorization: token || ""
//       }
//     });
//   }
// });

const cache = new InMemoryCache();
export const token = localStorage.getItem("app-token");

const uploadLink = createUploadLink({
  headers: {
    authorization: token || "",
  },
  uri: process.env.REACT_APP_URL,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log("giorno", process.env.NODE_ENV);
  if (graphQLErrors) {
    if (graphQLErrors.some(({ message }) => message === "invalidJWT")) {
      // && process.env.NODE_ENV != "production"

      window.location.href = "/logout";
    }

    graphQLErrors.map(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations?.toString()}, Path: ${path}`);
      MLDialog.showSnackbar(L(message), {
        variant: "error",
        persist: true,
        action: (key) => (
          <Button
            variant="contained"
            style={{ background: "white", fontWeight: "bolder" }}
            onClick={() => MLDialog.hideSnackbar(key)}
          >
            {L("iUnderstood")}
          </Button>
        ),
      });
    });
  }
  //if (networkError) MLDialog.showSnackbar(networkError.toString(), {variant: 'warning'});
});

const mergedLink = ApolloLink.from([errorLink, uploadLink]);

//authLink.concat(link).concat(errorLink),
export const apolloClient = new ApolloClient({
  // link, //: ApolloLink.from([authLink, errorLink, uploadLink]),
  link: mergedLink,
  cache,
  resolvers: {},
});

if (process.env.NODE_ENV !== "development" && false) {
  import("firebase/").then((firebaseModule) => {
    const firebase = firebaseModule.default;
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();

      // messaging.usePublicVapidKey(
      //   "BFtI0CKGBZeH__Zmn6Vi6MsiZpIbqdZXR3vK87m2Sxu4iayqllxkH149YhshKQDFzR6FchswxCFvJBViN_9QX0E"
      // );

      messaging
        .getToken()
        // .getToken({vapidKey: "BFtI0CKGBZeH__Zmn6Vi6MsiZpIbqdZXR3vK87m2Sxu4iayqllxkH149YhshKQDFzR6FchswxCFvJBViN_9QX0E"})
        .then((refreshedToken) => {
          console.log("TOKEN", refreshedToken);
        });

      messaging.onMessage((payload) => {
        console.log("Message received. ", payload);

        const notification = payload.notification;
        const title = notification.title ?? "notifica";
        const body = notification.body;

        if (!resettingStore) {
          resettingStore = true;
          apolloClient.resetStore().then((resp) => {
            resettingStore = false;
          });
        }

        MLDialog.showSnackbar(title + (body ? ": " + body : ""));
      });
    }
  });
}

export function clearCurrentFocus() {
  try {
    (document as any)?.activeElement?.blur?.();
  } catch (e) {}
}

export function placeCaretAtEnd(el: any, atStart?: boolean) {
  el.focus();
  if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
    var range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(!atStart);
    var sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
  } else if (typeof (document as any)?.body?.createTextRange != "undefined") {
    var textRange = (document as any).body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(!atStart);
    textRange.select();
  }
}

export function placeCaretAt(el: any, position: number) {
  el.focus();
  if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
    console.log(111111);
    var range = document.createRange();
    range.setStart(el, 5); //position - 1);
    range.collapse(true);
    // range.selectNodeContents(el);
    // range.collapse(!atStart);
    var sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
  } else if (typeof (document as any)?.body?.createTextRange != "undefined") {
    console.log(2222);
    var textRange = (document as any).body.createTextRange();
    textRange.moveToElementText(el);
    // textRange.collapse(!atStart);
    textRange.select();
  }
}

export const getCaretPosition = (elem: any) => {
  var sel = window.getSelection() as any;
  var cum_length = [0, 0];

  const node_walk = (node: any, func: any) => {
    var result = func(node);
    for (node = node.firstChild; result !== false && node; node = node.nextSibling) result = node_walk(node, func);
    return result;
  };

  if (sel.anchorNode == elem) cum_length = [sel.anchorOffset, sel.extentOffset];
  else {
    var nodes_to_find = [sel.anchorNode, sel.extentNode];
    if (!elem.contains(sel.anchorNode) || !elem.contains(sel.extentNode)) return undefined;
    else {
      var found = [0, 0] as any;
      var i;
      node_walk(elem, function (node: any) {
        for (i = 0; i < 2; i++) {
          if (node == nodes_to_find[i]) {
            found[i] = true;
            if (found[i == 0 ? 1 : 0]) return false; // all done
          }
        }

        if (node.textContent && !node.firstChild) {
          for (i = 0; i < 2; i++) {
            if (!found[i]) cum_length[i] += node.textContent.length;
          }
        }
      });
      cum_length[0] += sel.anchorOffset;
      cum_length[1] += sel.extentOffset;
    }
  }
  if (cum_length[0] <= cum_length[1]) return cum_length;
  return [cum_length[1], cum_length[0]];
};
