import { Grid } from "@material-ui/core";

export const UnauthScaffold = (props: { children: any }) => (
  <Grid
    container
    justify="center"
    alignItems="center"
    direction="column"
    style={{
      backgroundColor: "#fbfeff",
      height: "100%",
      //overflow: 'hidden'
    }}
  >
    <img src={require("../assets/grapes-full.png")} width="250" />

    <Grid
      container
      item
      justify="center"
      direction="column"
      css={{
        width: 270,
        maxWidth: "100%",
        padding: 10,
        borderRadius: 3,
        boxShadow: "0 4px 10px rgba(50, 0, 100, 0.15)",
        backgroundColor: "white",
        position: "relative",
      }}
    >
      {props.children}
    </Grid>
  </Grid>
);
