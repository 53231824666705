/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { __DEV__ } from "../../../utils/init";
import { IGrape } from "../../grape_gql_interface";
import DebugIcon from "@material-ui/icons/Widgets";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import { defaultGrapeListType } from "../../GrapeDetail/core/grapeTypes";
import { isArray } from "util";
import { GrapeDetailContextType } from "../../GrapeDetail/GrapeDetail/hooks/GrapeDetailContext";
import { useRootGrapeContextSelector } from "../../App/RootGrapeProvider";
import { cleanHTMLTags } from "../../../utils";

const AppDebugComponent = (props: {}) => {
  const detailGrapes = useRootGrapeContextSelector("detailGrapes");
  const [visible, setVisible] = useState(false);
  const [grapeDetail, setGrapeDetail] = useState<{ key: string; controller: GrapeDetailContextType }>();

  return (
    <React.Fragment>
      {visible ? (
        <div
          css={{
            position: "absolute",
            bottom: 0,
            // top: 0,
            left: 0,
            // right: 0,
            height: "100%",
            maxHeight: 700,
            width: "100vw",
            maxWidth: 600,
            zIndex: 100,
            overflow: "scroll",
            border: "1px solid black",
            backgroundColor: "white",
            padding: 10,
          }}
        >
          {Object.keys(detailGrapes).map((gKey) => {
            const g = detailGrapes[gKey];
            return (
              <GrapeControllerDebugItem
                key={gKey}
                gKey={gKey}
                controller={g}
                depth={0}
                onClick={(g) => setGrapeDetail({ key: gKey, controller: g })}
              />
            );
          })}

          {grapeDetail ? (
            <div
              css={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
              onClick={() => setGrapeDetail(undefined)}
            >
              <div
                css={{
                  position: "relative",
                  border: "1px solid black",
                  backgroundColor: "white",
                  padding: 10,
                  margin: 20,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  css={{ position: "absolute", top: 0, right: 0, fontSize: 20, padding: 4 }}
                  className="hoverable"
                  onClick={() => setGrapeDetail(undefined)}
                  children={<CloseIcon />}
                />
                <PropValue title="key" value={grapeDetail.key} size="small" />
                <PropValue title="title" value={grapeDetail.controller.grape.title} size="big" />
                <PropValue title="description" value={grapeDetail.controller.grape.description} />
                <PropValue title="type" value={grapeDetail.controller.grape.type || defaultGrapeListType} />
                <PropValue title="labels" value={grapeDetail.controller.grape.labels || []} />
                <PropValue
                  title={"childrens: " + (grapeDetail.controller.grape.children?.length || 0)}
                  value={grapeDetail.controller.grape.children?.map((g) => g.title) || 0}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div
        css={{
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 100,
          padding: 4,
          height: "24px !important",
          width: "24px !important",
          background: "red",
          cursor: "pointer",
          borderTopRightRadius: 12,
          transform: visible ? "translate(0, 0)" : "translate(4px, -4px)",
          transition: "all 200ms",
        }}
        onClick={() => setVisible(!visible)}
        children={<DebugIcon style={{ color: "white", fontSize: 20 }} />}
      />
    </React.Fragment>
  );
};

export const GrapeAppDebugEnabled = false;

export const AppControllerDebug = GrapeAppDebugEnabled && __DEV__ ? AppDebugComponent : (x: any) => null;

const GrapeControllerDebugItem = (props: {
  controller: GrapeDetailContextType;
  gKey: string;
  depth: number;
  onClick: (g: GrapeDetailContextType) => void;
}) => {
  const [expanded, setExpanded] = useState(true);
  const canToggleExpand = props.depth > 0;

  // const depthColor="rgba(200, 150, 0, " + (0.05 + props.depth / 10) + ")"
  const depthColor = ["#eee", "#0a0", "#ad0", "#dd0", "#f80", "#f00", "#f00", "#f00", "#f00"][props.depth];

  return (
    <div>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          // borderBottom: "1px solid #eee",
          ":hover": { backgroundColor: "#fafafa", cursor: "pointer" },
        }}
        onClick={() => props.onClick(props.controller)}
        style={
          {
            //   backgroundColor: props.depth % 2 === 0 ? "#f8f8f8" : "none",
          }
        }
      >
        <div
          css={{
            fontSize: 10,
            color: "#888",

            width: 24 * (1 + props.depth),
            borderBottom: "3px solid white",
            borderColor: depthColor,
            position: "relative",
          }}
          className={canToggleExpand ? "hoverable" : ""}
          onClick={() => canToggleExpand && setExpanded(!expanded)}
        >
          <span children={props.depth + ":" + (props.controller.grape?.children?.length || "")} />

          <div css={{ position: "absolute", top: 0, right: 0 }}>
            {canToggleExpand && props.controller?.grape.children?.length ? (
              <ChevronLeftIcon
                style={{
                  fontSize: 16,
                  transition: "transform 200ms",
                  transform: expanded ? "rotate(90deg)" : "rotate(270deg)",
                }}
              />
            ) : null}
          </div>
        </div>

        <div
          children={props.controller?._props?.initialUrlGrapes?.join("_")}
          css={{ whiteSpace: "nowrap", fontSize: 14, color: "blue" }}
        />

        <div css={{ flex: 1, overflow: "hidden" }}>
          <div
            children={cleanHTMLTags(props.controller?.grape.title ?? "") ?? props.gKey}
            css={{ whiteSpace: "nowrap" }}
          />
        </div>
        <MoreHorizIcon className="hoverable" css={{ fontSize: 20, marginLeft: 20, opacity: 0.4 }} />
      </div>
    </div>
  );
};

const PropValue = (props: { title: string; value: any; size?: "big" | "small" }) => {
  return (
    <div css={{ borderBottom: "1px solid #ddd", padding: "10px 0" }}>
      <div css={{ fontSize: 14, fontWeight: "normal", marginBottom: 4 }} children={props.title} />
      <div
        css={{ fontSize: props.size === "big" ? 20 : props.size === "small" ? 10 : 14, fontWeight: "bold" }}
        children={isArray(props.value) ? "[ " + props.value.join(", ") + " ]" : props.value}
      />
    </div>
  );
};
