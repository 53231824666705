/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { LinearProgress, useTheme } from "@material-ui/core";
import { useState } from "react";
import Grapes from "../../modules/Grapes";
import { GET_DELETED_GRAPES } from "../../repositories/queries_mutations";
import { useRootGrapeController } from "../App/RootGrapeProvider";
import Contextable from "../Contextable";
import Grape from "../Grape";
import { IGrape } from "../grape_gql_interface";
import { StickyLabel } from "./StickyLabel";

export const DeletedGrapesList = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const [hasNextPage, setHasNextPage] = useState(true);
  const { getDeletedGrapes } = useRootGrapeController();

  const [mapActivities, setMapActivities] = useState<{ [page: number]: boolean | Object | IGrape[] }>({
    0: getDeletedGrapes || [],
  });

  let loading = false;
  const activities: IGrape[] = [];

  Object.values(mapActivities).forEach((v) => {
    if (v === true) {
      loading = true;
    } else if (v) {
      activities.push(...(v as IGrape[]));
    }
  });

  // activities.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));

  const fetchNextPage = () => {
    let nextPage = 0;
    for (nextPage = 0; nextPage < Object.keys(mapActivities).length; nextPage++) {
      if (!mapActivities[nextPage]) {
        nextPage = nextPage;
        break;
      }
    }

    setMapActivities((mas) => ({ ...mas, [nextPage]: true }));

    client
      .query({
        query: GET_DELETED_GRAPES,
        variables: {
          page: nextPage,
          perPage: 20,
        },
      })
      .then(({ data }) => {
        const pageActivities = data?.getDeletedGrapes || [];
        if (pageActivities.length === 0) {
          setHasNextPage(false);
        }
        setMapActivities((mas) => ({ ...mas, [nextPage]: pageActivities }));
      })
      .catch((err) => {
        console.log("error activities", err);
        setMapActivities((mas) => ({ ...mas, [nextPage]: false }));
      });
  };

  return (
    <div>
      <StickyLabel children="Task eliminati" />

      <div>
        {activities.map((g: any) => (
          <Contextable
            key={g.id}
            menuItems={[
              {
                caption: "Ripristina",
                onClick: () => {
                  Grapes.editGrape(client, g.id, { state: 1 } as any);
                },
              },
            ]}
          >
            <Grape style={{ marginBottom: 8 }} groupType="list" anchestors={[]} grape={g} noContextMenu />
          </Contextable>
        ))}

        {loading && <LinearProgress css={{ width: 200, margin: 10 }} />}
      </div>

      {getDeletedGrapes.length && hasNextPage && (
        <div
          className="hoverable"
          css={{
            flex: 1,
            display: "inline-block",
            fontWeight: "bold",
            padding: "0 8px",
            color: theme.palette.primary.main,
          }}
          children={"Altri task eliminati →"}
          onClick={() => {
            fetchNextPage();
          }}
        />
      )}
    </div>
  );
};
