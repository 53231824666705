import { useApolloClient } from "@apollo/react-hooks";
import moment, { Moment } from "moment";
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import Grapes, { IGrapeEdit } from "../../../../modules/Grapes";
import { getDateTypeValueTitle } from "../../../CalendarZ";
import { GrapeDateFactory } from "../../../GrapeDetailItems/GrapeDateItem";
import { GET_GRAPE, IGrape } from "../../../grape_gql_interface";
import MLDialog from "../../../MLDialog";
import { useSafeGrapeDetailContextSelector } from "../../GrapeDetail/hooks/GrapeDetailContext";
import { GrapesGanttTask, GrapesGanttTaskTitle, useGanttTask } from "./GrapesGanttTask";

export type OnTaskAction = (
  task: IGrape,
  action: "edit" | "add" | "delete",
  payload?: IGrapeEdit & { color?: string }
) => void;

export const GrapesGanttMetrics = {
  headerMonthHeight: 24,
  headerDayHeight: 32,
  rowHeight: 24,
  rowAddHeight: 45,
  rowPadding: 2,
  rowHoverColor: "rgba(0, 0, 0, 0.06)",
  dayWidth: 20,
  leftColumnWidth: 200,
  borderWidth: 1,
  hideDays: [0, 6],
  borderColor: "#ddd",
};

export const uniqueGrapeDateKey = (t: IGrape) => t.id + "_" + t.date + "_" + t.date0 + "_" + t.date1 + "_" + t.date2;

const GrapesGanttContext = createContext(
  {} as {
    isExpanded: (id: string) => boolean;
    onExpandPressed: (id: string, expanded: boolean) => void;
    onTaskAction: OnTaskAction;
    totalDaysWithoutWeeks: number;
    firstDate: Moment;
    onDateChange: (id: string, which: "start" | "end", date: Date) => void;
    containerRef: React.RefObject<HTMLDivElement>;
    horizontalScrollRef: React.RefObject<HTMLDivElement>;
  }
);

export const useGrapesGanttContext = () => useContext(GrapesGanttContext);

// export const GrapesGantt = ({ filters }: { filters: GrapeGanttFilter }) => {
export const GrapesGantt = () => {
  const client = useApolloClient();
  const grape = useSafeGrapeDetailContextSelector("grape");
  const editGrape = useSafeGrapeDetailContextSelector("editGrape");
  const setLabelColor = useSafeGrapeDetailContextSelector("setLabelColor");
  const containerRef = useRef<HTMLDivElement>(null);
  const horizontalScrollRef = useRef<HTMLDivElement>(null);

  const { subTasks: rootSubTasks } = useGanttTask(grape, 0);
  const subTasks = rootSubTasks.filter((t) => t.type !== "deadline");
  const subTasksWithMe = [{ ...grape, children: grape.children.filter((t) => t.type === "deadline") }, ...subTasks];

  const firstDate = _getFirstDate(grape, 3).subtract(2, "days").startOf("week");
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>(() =>
    subTasks.reduce((acc, t) => ({ ...acc, [t.id]: true }), {})
  );

  let lastDate = moment(firstDate).add(500, "days").endOf("month");
  const lastDateFromNow = moment().add(300, "days");
  if (lastDate.isBefore(lastDateFromNow)) lastDate = lastDateFromNow;

  const totalDays = lastDate.diff(firstDate, "days");
  const totalDaysWithoutWeeks = Math.ceil((totalDays / 7) * (7 - GrapesGanttMetrics.hideDays.length)) + 1;

  useEffect(() => {
    const scrollDays = daysDurationWithoutCertainWeekdays({
      start: firstDate,
      end: moment().subtract(20, "days"),
      removeDays: GrapesGanttMetrics.hideDays,
    });

    if (scrollDays > 0) {
      const scrollLeft = scrollDays * GrapesGanttMetrics.dayWidth;
      containerRef.current!.scrollLeft = scrollLeft;
    }

    //   const onScroll = () => {
    //     console.log(containerRef?.current?.scrollTop);
    //   };
    //   containerRef.current?.addEventListener("scroll", onScroll);
    //   return () => containerRef.current?.removeEventListener("scroll", onScroll);
  }, []);

  const onTaskAction: OnTaskAction = (task, action, payload) => {
    const id = task.id;
    const refetchQueries = [{ query: GET_GRAPE, variables: { id: grape.id } }];
    switch (action) {
      case "add":
        const title = (payload as any).title;
        if (payload?.date) {
          // add grape date: deadline | track
          const date = moment(payload!.date);
          const dateTypeTitle: string = (payload as any)!.type;

          GrapeDateFactory.create({
            client,
            grapeId: id,
            dateItem: {
              date,
              selection: { startDate: date.toDate() },
              title: title || dateTypeTitle,
              type: getDateTypeValueTitle(dateTypeTitle),
            },
            refetchQueries,
          });
        } else {
          // add normal grape
          Grapes.addGrape(client, id, title, {}, refetchQueries);
        }
        break;
      case "edit":
        const key = Object.keys(payload!)[0];
        const value = (payload as any)[key];
        if (key === "color") {
          setLabelColor(task.title, value);
        } else {
          editGrape(id, key, value);
        }
        break;
      case "delete":
        Grapes.doDeleteGrape(client, { id, parent: undefined }, refetchQueries);
        break;
    }
  };

  return (
    <GrapesGanttContext.Provider
      value={{
        containerRef,
        horizontalScrollRef,
        isExpanded: (id: string) => expanded[id],
        onExpandPressed: (id, isExpanded) => setExpanded((e) => ({ ...e, [id]: isExpanded })),
        onTaskAction,
        totalDaysWithoutWeeks,
        firstDate,
        onDateChange: (id, which, date) => {
          editGrape(id, which === "start" ? "date0" : "date", date);
        },
      }}
    >
      <div
        ref={containerRef}
        css={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          maxWidth: 1000,
          // maxHeight: 500,
          overflowY: "scroll",
          userSelect: "none",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            minWidth: GrapesGanttMetrics.leftColumnWidth,
            width: GrapesGanttMetrics.leftColumnWidth,
            position: "sticky",
            left: 0,
            zIndex: 2,
            paddingTop: GrapesGanttMetrics.headerMonthHeight + GrapesGanttMetrics.headerDayHeight,
          }}
        >
          <div
            css={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              height: "1000vh",
              backgroundColor: "white",
              zIndex: -1,
              "::after": {
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                width: GrapesGanttMetrics.borderWidth,
                backgroundColor: GrapesGanttMetrics.borderColor,
              },
            }}
          />

          {subTasksWithMe.map((t) => (
            <GrapesGanttTaskTitle
              key={t.id}
              task={t}
              indent={t.id === grape.id ? -1 : 0}
              // disableExpand={t.id === grape.id}
            />
          ))}
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            // width: `calc(100% - ${GrapesGanttMetrics.leftColumnWidth}px)`,
          }}
        >
          <div
            ref={horizontalScrollRef}
            css={{
              // overflowY: "hidden",
              // overflowX: "scroll",
              // flex: 1,
              paddingBottom: 30,
              // boxShadow: "inset 0 0 0 1px " + GrapesGanttMetrics.borderColor,
            }}
          >
            <div css={{ position: "relative" }}>
              <div css={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} />

              <TasksHeader totalDays={totalDays} totalDaysWithoutWeeks={totalDaysWithoutWeeks} firstDate={firstDate} />

              {subTasksWithMe.map((t) => (
                <GrapesGanttTask key={uniqueGrapeDateKey(t)} task={t} indent={t.id === grape.id ? -1 : 0} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </GrapesGanttContext.Provider>
  );

  function _getFirstDate(t: IGrape, depth: number) {
    let firstDate: moment.Moment = moment.min(
      ...([
        ...(depth >= 0 ? (t?.children || []).map((c) => _getFirstDate(c, depth - 1)) : []),
        t.date && moment(t.date),
        t.date0 && moment(t.date0),
        t.date1 && moment(t.date1),
        t.date2 && moment(t.date2),
      ].filter(Boolean) as Moment[])
    );

    return firstDate;
  }
};

const TasksHeader = ({
  totalDays,
  totalDaysWithoutWeeks,
  firstDate,
}: {
  totalDays: number;
  totalDaysWithoutWeeks: number;
  firstDate: Moment;
}) => {
  let lastRenderedDay: Moment | null = null;
  const today = moment().startOf("day");

  return (
    <div
      style={{ display: "flex", flexDirection: "row", position: "sticky", top: 0, zIndex: 1, pointerEvents: "none" }}
    >
      <div
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          width: totalDaysWithoutWeeks * GrapesGanttMetrics.dayWidth,
          backgroundColor: "white",
          zIndex: -1,
          overflow: "hidden",
          // boxShadow: `inset 0 -${GrapesGanttMetrics.borderWidth}px 0 0 ${GrapesGanttMetrics.borderColor}`,
        }}
      />

      {Array.from({ length: totalDays }).map((_, i) => {
        const date = moment(firstDate).add(i, "days");
        const isToday = date.isSame(today, "day");

        if (GrapesGanttMetrics.hideDays.includes(date.day())) {
          if (isToday) {
            return (
              <div css={{ width: 0, position: "relative" }}>
                <div
                  css={{
                    position: "absolute",
                    top: GrapesGanttMetrics.headerMonthHeight + GrapesGanttMetrics.headerDayHeight,
                    left: 0,
                    width: 5,
                    height: "1000vh",
                    background: "rgba(180, 90, 0, 0.3)",
                  }}
                />
              </div>
            );
          }
          return null;
        }

        const isNewWeek = lastRenderedDay && lastRenderedDay.week() !== date.week();
        const isNewMonth = !lastRenderedDay || lastRenderedDay.month() !== date.month();
        lastRenderedDay = date;

        const newMonthWidth = isNewMonth
          ? 1 +
            (daysDurationWithoutCertainWeekdays({
              start: date,
              end: moment(date).endOf("month"),
              removeDays: GrapesGanttMetrics.hideDays,
            }) +
              1) *
              GrapesGanttMetrics.dayWidth
          : 0;

        return (
          <div
            key={i}
            css={{
              minWidth: GrapesGanttMetrics.dayWidth,
              width: GrapesGanttMetrics.dayWidth,
              height: GrapesGanttMetrics.headerDayHeight,
              marginTop: GrapesGanttMetrics.headerMonthHeight,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              // backgroundColor: day.month() % 2 === 0 ? "#eee" : "#fff",
              "::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                height: "1000vh",
                width: 1,
                backgroundColor: `rgba(0, 0, 0, ${isNewWeek ? 0.5 : 0.2})`, // isNewWeek ? "#777" : "#ddd",
              },
            }}
          >
            <span className="text-[10px] font-bold text-gray-500" children={date.format("D")} />
            <span className="text-[10px] text-gray-500 uppercase" children={date.format("dd").substring(0, 1)} />

            {isToday && (
              <div
                css={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  height: "1000vh",
                  backgroundColor: "rgba(50, 100, 150, 0.1)",
                }}
              />
            )}

            {isNewMonth && (
              <div
                css={{
                  position: "absolute",
                  top: -GrapesGanttMetrics.headerMonthHeight,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  boxShadow: "inset 0 0 0 1px " + GrapesGanttMetrics.borderColor,
                  textAlign: "center",
                  height: GrapesGanttMetrics.headerMonthHeight,
                  width: newMonthWidth,
                }}
              >
                <div
                  css={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: "1000vh",
                    width: 1,
                    backgroundColor: "#777",
                  }}
                />

                <span
                  className="text-xs tracking-wider text-center uppercase text-gray-500"
                  children={date.format("MMM" + (newMonthWidth > 50 ? " YY" : ""))}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const daysDurationWithoutCertainWeekdays = ({
  start,
  end,
  removeDays: days,
}: {
  start: Moment;
  end: Moment;
  removeDays: number[];
}) => {
  const totDays = end.diff(start, "days");
  let count = 0;
  let day = start.day();
  for (let c = 0; c < totDays; c++) {
    if (!days.includes(day)) count++;
    day = (day + 1) % 7;
  }
  return count;
};

export const addDaysToDateWithCertainWeekdays = ({
  date,
  daysDelta,
  removeDays,
}: {
  date: Moment;
  daysDelta: number;
  removeDays: number[];
}) => {
  const direction = Math.sign(daysDelta) > 0 ? 1 : -1;
  let dateRef = moment(date);
  for (let c = 0; c < Math.abs(daysDelta); c++) {
    dateRef = dateRef.add(direction, "days");
    while (c < Math.abs(daysDelta) && removeDays.includes(dateRef.day())) {
      dateRef = dateRef.add(direction, "days");
    }
  }
  return dateRef.toDate();
};
