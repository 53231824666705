/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { jsx } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Popover,
  Typography,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import React, { CSSProperties, useRef, useState } from "react";
import { IGrape } from "../grape_gql_interface";
import { showFilesUploadDialog } from "../GrapeDetail/core/file_upload_utils";
import GrapeFile, { GrapeFileThumbnail, IGrapeFile } from "../GrapeFile";
import Grapes from "../../modules/Grapes";
import { showInputDialog } from "../../utils";
import MLDialog from "../MLDialog";

const GrapeFilesItem = (props: {
  iconOnly?: boolean;
  outlined?: boolean;
  grape: IGrape;
  onRequestOpen: (grape: IGrape) => void;
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const client = useApolloClient();
  const theme = useTheme();
  const fileInput = useRef<any>();

  const attachments = [
    ...(props.grape?.files || []),
    ...(props.grape?.children?.filter((g) => ["detail", "canvas"].includes(g.type)) || []),
  ];

  const color = attachments.length ? theme.palette.primary.main : undefined;

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesToUpload: File[] = [];
    if (event.target.files && event.target.files.length) {
      for (var c = 0; c < event.target.files.length; c++) {
        const file: File = event.target.files[c];
        filesToUpload.push(file);
      }
    }
    if (filesToUpload.length) showFilesUploadDialog(client, props.grape, filesToUpload);
  };

  return (
    <React.Fragment>
      <div
        className={"hoverable" + (props.outlined ? " outlined" : "")}
        css={{
          marginLeft: 8,
          display: "flex",
          alignItems: "center",
          // padding: "0 6px",
          paddingLeft: 3,
          paddingRight: 6,
        }}
        onClick={() => setModalVisible(true)}
      >
        <AttachFileIcon
          style={{
            fontSize: "initial",
            opacity: 0.8,
            color,
            // marginLeft: props.iconOnly ? 6 : -3,
            // marginRight: 6,
            marginLeft: 3,
            marginRight: 3,
          }}
        />
        <span
          children={attachments.length || (props.iconOnly ? "" : "FILE")}
          style={{ color, letterSpacing: 1, fontSize: 12 }}
        />
      </div>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        // onDragEnter={(evt) => evt.preventDefault()}
        onDragLeave={(evt) => evt.preventDefault()}
        onDragOver={(evt) => evt.preventDefault()}
        onDrop={(evt) => {
          evt.preventDefault();
          if (evt.dataTransfer.files) {
            onFileChange?.({ target: { files: evt.dataTransfer.files } } as any);
          }
        }}
      >
        <DialogTitle>
          <span children={"📁 "} />
          <span dangerouslySetInnerHTML={{ __html: props.grape.title }} />
        </DialogTitle>

        <DialogContent css={{ marginBottom: 16 }}>
          <Box display="flex" flexDirection="row">
            {attachments.length ? (
              attachments.map((attachment, i) =>
                (attachment as IGrapeFile)?.url ? (
                  <GrapeFile key={attachment.id} grapeFile={attachment as IGrapeFile} />
                ) : (
                  <GrapeFileThumbnail
                    key={attachment.id}
                    mimetype={(attachment as IGrape).type}
                    onClick={() => {
                      setModalVisible(false);
                      props.onRequestOpen(attachment as IGrape);
                    }}
                    title={
                      <div css={{ display: "flex", flexDirection: "column" }}>
                        <span
                          className="material-icons"
                          css={{ fontSize: 14, marginTop: 4 }}
                          children={(attachment as IGrape).type === "detail" ? "insert_drive_file" : "brush"}
                        />
                        <span children={(attachment as IGrape).title} />
                      </div>
                    }
                  />
                )
              )
            ) : (
              <span children="Empty" css={{ opacity: 0.6 }} />
            )}
          </Box>
        </DialogContent>

        <DialogActions css={{ borderTop: "1px solid #DDD" }}>
          <Button children="CHIUDI" onClick={() => setModalVisible(false)} />
          <Box flex={1} />
          {/* <AddFileToGrape
            inputRef={(r) => (fileInput.current = r)}
            button
            onFileChange={onFileChange}
            icon={<AttachFileIcon color="primary" style={{ fontSize: "initial", marginBottom: 2 }} />}
            title="CARICA"
          /> */}
          <AddGrapeAttachment
            grape={props.grape}
            onRequestOpen={(g) => {
              props.onRequestOpen(g);
              setModalVisible(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default GrapeFilesItem;

export const AddFileToGrape = (props: {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: (ref: any) => void;
  children?: any;
  className?: string;
  style?: CSSProperties;
  button?: boolean;
  title?: string;
  icon?: React.ReactNode;
}) => {
  const icon = props.icon || <AddIcon color="primary" style={{ fontSize: "initial", marginBottom: 2 }} />;
  // const icon = <AttachFileIcon color="primary" style={{ fontSize: "initial", opacity: 0.8 }} />;
  const title = props.title || "AGGIUNGI"; // "FILE";

  return (
    <Grid
      container
      direction="column"
      item
      className={(props.button ? "hoverable " : "") + (props.className || "")}
      style={{
        position: "relative",
        width: 50,
        // height: files.length || descendentFiles.length ? 50 : 20,
        height: 50,
        background: "#eee",
        margin: 2,
        overflow: "hidden",
        cursor: "pointer",
        ...(props.button
          ? {
              background: "transparent",
              // display: "flex",
              // flexDirection: "row",
              // alignItems: "center",
              width: "auto",
              height: "auto",
              // padding: "4px 12px",
            }
          : {}),
        ...(props.style || {}),
      }}
      justify="center"
      alignItems="center"
    >
      {props.children ||
        (props.button ? (
          <Button color="primary" endIcon={icon} children={title} />
        ) : (
          <React.Fragment>
            <span children={title} style={{ letterSpacing: 1, fontSize: 12 }} />
            {icon}
          </React.Fragment>
        ))}

      <input
        type="file"
        onChange={props.onFileChange}
        multiple
        ref={props.inputRef}
        style={{
          position: "absolute",
          top: "-100%",
          left: 0,
          width: "100%",
          height: "200%",
          opacity: 0,
          cursor: "pointer",
        }}
      />
    </Grid>
  );
};

const AddGrapeAttachment = ({ grape, onRequestOpen }: { grape: IGrape; onRequestOpen: (grape: IGrape) => void }) => {
  const client = useApolloClient();
  const anchorEl = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const inputRef = useRef<any>();

  const onCreateDocument = (type: "detail" | "canvas", title: string) => {
    showInputDialog(title, "Nome documento", "Nome...", (title) => {
      if ((title || "").trim() !== "") {
        Grapes.addGrape(client, grape.id, title, { type }).then((res) => {
          const newGrapeId = res?.data?.createGrape?.id;
          onRequestOpen({ id: newGrapeId, title, type } as IGrape);
        });

        handleClose();
        MLDialog.hideModal();
      }
    });
  };

  type ModalAction = {
    id: string;
    icon: string;
    title: string;
    onClick: () => void;
  };

  const actions: ModalAction[] = [
    {
      id: "create-detail",
      icon: "insert_drive_file",
      title: "Documento di testo",
      onClick: () => onCreateDocument("detail", "Documento di testo"),
    },
    {
      id: "create-canvas",
      icon: "brush",
      title: "Disegno",
      onClick: () => onCreateDocument("canvas", "Disegno"),
    },
    {
      id: "upload",
      icon: "attach_file",
      title: "Carica file",
      onClick: () => inputRef?.current?.click(),
    },
  ];

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesToUpload: File[] = [];
    if (event.target.files && event.target.files.length) {
      for (var c = 0; c < event.target.files.length; c++) {
        const file: File = event.target.files[c];
        filesToUpload.push(file);
      }
    }
    if (filesToUpload.length) showFilesUploadDialog(client, grape, filesToUpload);
    handleClose();
    MLDialog.hideModal();
  };

  return (
    <div ref={anchorEl}>
      <Button color="primary" variant="text" onClick={() => setOpen(true)}>
        <span children="AGGIUNGI" css={{ marginRight: 4 }} />
        <AddIcon color="primary" style={{ fontSize: "initial", marginBottom: 2 }} />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div css={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
          {actions.map((action, i) => (
            <Button
              key={i}
              onClick={() => {
                // handleClose();
                action.onClick();
              }}
              css={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                padding: "12px 16px",
              }}
            >
              <span className="material-icons" children={action.icon} css={{ fontSize: 20 }} />
              <span children={action.title} css={{ marginLeft: 16, textTransform: "none" }} />
            </Button>
          ))}
        </div>

        <div css={{ width: 0, height: 0, overflow: "hidden" }}>
          <input type="file" ref={inputRef} onChange={onFileChange} multiple />
        </div>
      </Popover>
    </div>
  );
};

const GrapeAttachment = ({ grape }: { grape: IGrape }) => {
  return <div />;
};
