/** @jsxImportSource @emotion/react */

import "moment/locale/it";
import { useCallback, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { IGrapesApp, useGrapesApp } from "../../App";
import UserPreferences from "../../utils/UserPreferences";
import { ClientSortableFolder } from "./ClientSortableFolder";

export interface FolderItem<T> {
  id: string;
  isFolder: boolean;
  name: string;
  list: string[];
}

const debugEnabled = false;

function ClientSortableTree<T extends { id: string }>(props: {
  groupId: string;
  list: T[];
  getItemId: (item: T) => string;
  //---
  selectedItemId?: string;
  className?: string;
  renderItem: (item: T, index: number) => React.ReactNode;
  renderEmptyItem?: any;
  renderTrailElement?: any;
  disabled?: boolean;
  distance?: number;
}): JSX.Element {
  const grapesApp = useGrapesApp();
  const getLocalStorageId = () => getStorageId(props.groupId);
  const [initialItemSelectedId] = useState(() => props.selectedItemId);

  const mapItems = deriveItemsByItemId(props.list);

  //? indexes
  const initIndexes = useCallback(() => {
    try {
      let valuestr = UserPreferences.getPreference(grapesApp, getLocalStorageId());
      if (!valuestr) throw "not stored yet";
      let values = JSON.parse(valuestr);
      return values;
    } catch (ex) {}
    return {};
  }, []);

  const [indexes, setIndexes] = useState<{ [key: string]: number }>(initIndexes);

  //? folders
  const initFolders = useCallback(() => getInitialFolders(grapesApp, props.groupId), []);

  const [folders, setFolders] = useState<FolderItem<T>[]>(initFolders);
  const [expandedFolders, setExpandedFolders] = useState<string[]>(() => {
    if (!initialItemSelectedId) return [];
    for (let i in folders) {
      if (folders[i].isFolder && folders[i].list?.includes(initialItemSelectedId)) {
        return [folders[i].id];
      }
    }
    return [];
  });
  const folderedGrapes = deriveFoldersByGrapesId(folders);

  const foundFolders: { [folderId: string]: boolean } = {};
  const folderedList = props.list
    .map((item, index) => {
      const itemId = props.getItemId(item as T);
      const folder = folderedGrapes[itemId];

      if (folder) {
        if (!foundFolders[folder.id]) {
          foundFolders[folder.id] = true;
        } else {
          // skip other ites of folder
          return null;
        }
      }
      return folder ?? item;
    })
    .filter(Boolean) as (FolderItem<T> | T)[];

  //? add missing folders
  folders.forEach((f) => {
    if (!foundFolders[f.id]) {
      foundFolders[f.id] = true;
      folderedList.push(f);
    }
  });

  //? sort items and folders
  folderedList.sort((a, b) => {
    const ida = getItemId(a);
    const idb = getItemId(b);
    let ia = (indexes[ida] >= 0 ? indexes[ida] : folderedList.indexOf(a) || 0) as number;
    let ib = (indexes[idb] >= 0 ? indexes[idb] : folderedList.indexOf(b) || 0) as number;
    return ia - ib;
  });

  function deriveItemsByItemId(list: T[]) {
    const map: { [id: string]: T } = {};
    for (let c = 0; c < list.length; c++) {
      map[props.getItemId(list[c])] = list[c];
    }
    return map;
  }

  function deriveFoldersByGrapesId(folders: FolderItem<T>[]) {
    const map: { [id: string]: FolderItem<T> } = {};

    for (let c = 0; c < folders.length; c++) {
      for (let n = 0; n < folders[c].list.length; n++) {
        const itemId = folders[c].list[n];
        // const itemId = props.getItemId(item);
        map[itemId] = folders[c];
      }
    }

    return map;
  }

  const onIndexesChanged = (newIndexes: { [key: string]: number }) => {
    setIndexes(newIndexes);
    UserPreferences.savePreference(grapesApp, getLocalStorageId(), JSON.stringify(newIndexes));
  };

  const onFolderChanged = (newFolders: FolderItem<T>[]) => {
    setFolders(newFolders);
    saveFolders(grapesApp, props.groupId, newFolders);
  };

  return (
    <ReactSortable
      key={folderedList.length}
      animation={100}
      delayOnTouchOnly
      delay={100}
      list={folderedList}
      group={{ name: "root", put: ["nested"], pull: true }}
      setList={(values) => {
        const newIndexes: any = {};
        values.forEach((item, index) => {
          const isFolder = (item as any).isFolder;
          newIndexes[isFolder ? item.id : getItemId(item)] = index;
        });

        //? save to localStorage
        const isChanged = Object.keys(newIndexes).some((key) => newIndexes[key] !== indexes[key]);
        if (isChanged) {
          onIndexesChanged(newIndexes);
        }
      }}
    >
      {folderedList.map((item, index) => {
        const isFolder = (item as any).isFolder;
        let folder: FolderItem<T>;
        if (isFolder) {
          folder = item as FolderItem<T>;
          // if (__DEV__) console.log("folderItem", item);
        }
        const draggableId = isFolder ? folder!.id : props.getItemId(item as T);

        return isFolder ? (
          <ClientSortableFolder
            key={folder!.id}
            folder={folder!}
            disabled={props.disabled}
            selectedItemId={props.selectedItemId}
            items={folder!.list.map((id) => mapItems[id]).filter(Boolean)}
            distance={props.distance}
            renderItem={props.renderItem}
            renderEmptyItem={props.renderEmptyItem}
            onDelete={() => onFolderChanged(folders.filter((f) => f.id !== folder.id))}
            onChanged={(newf) => {
              const newfs = folders.map((f) => (f.id === newf.id ? newf : f));
              onFolderChanged(newfs);
            }}
            // is folder expanded
            isExpanded={expandedFolders.includes(folder!.id)}
            setExpanded={(expanded) => {
              setExpandedFolders((folderIds) => {
                const newFolders = folderIds.filter((folderId) => folderId !== folder!.id);
                if (expanded) newFolders.push(folder!.id);
                return newFolders;
              });
            }}
          />
        ) : (
          <div key={props.getItemId(item as T)}>{props.renderItem(item as T, index)}</div>
        );
      })}
    </ReactSortable>
  );

  function getItemId(item: T | FolderItem<T>) {
    return (item as any).isFolder ? (item as FolderItem<T>).id : props.getItemId(item as T);
  }
}

export default ClientSortableTree;

const getStorageId = (groupId: string) => "csl-indexes-" + groupId;

export function saveFolders<T>(app: IGrapesApp, groupId: string, newFolders: FolderItem<T>[]) {
  UserPreferences.savePreference(app, "folders:" + getStorageId(groupId), JSON.stringify(newFolders));
}

export function getInitialFolders<T>(app: IGrapesApp, groupId: string): FolderItem<T>[] {
  try {
    let valuestr = UserPreferences.getPreference(app, "folders:" + getStorageId(groupId));
    if (!valuestr) throw "not stored yet";
    let values = JSON.parse(valuestr);
    return Array.isArray(values) ? values : [];
  } catch (ex) {}
  return [];
}

export function createTempNewFolder<T>(newName: string, list: string[]): FolderItem<T> {
  return {
    isFolder: true,
    id: "fid:" + new Date().getTime(),
    name: newName,
    list: list,
  };
}
