/** @jsxImportSource @emotion/react */
import { Box, Button, getLuminance, LinearProgress } from "@material-ui/core";
import fromCDN from "from-cdn";
import { useEffect, useRef, useState } from "react";
import { hideGrapesFromChildren } from "../GrapeDetail/core/grapeTypes";
import { GrapeDetailOpenParams } from "../GrapeDetail/GrapeDetail";
import { IGrape, IUserGrapePermission } from "../grape_gql_interface";
import { Stage, Layer, Line, Text } from "react-konva";
import { simplifyPoints } from "./simplify_path";
import { showColorPickerModal, showInputDialog } from "../../utils";
import { GrapeDetailToolbar } from "../GrapeDetail/GrapeDetail/GrapeDetailToolbar";
import DetailScaffold from "../GrapeDetail/DetailScaffold";

//! alternative: react-mind-map --> https://www.npmjs.com/package/react-mindmap

interface CanvasGrapeProps {
  grape: IGrape;
  collaborators: IUserGrapePermission[];
  editGrape: (grapeId: string, attr: string, value: any) => void;
  onRequestOpen: (item: IGrape, params?: GrapeDetailOpenParams) => void;
}

const CanvasGrape = (props: CanvasGrapeProps) => {
  const childrens: IGrape[] = props.grape.children?.filter((x) => !hideGrapesFromChildren.includes(x?.type)) || [];

  return <Box css={{ background: "red" }} children="from cdn ok 👍" />;
};

export const GrapeDetailCanvasWIP = (props: CanvasGrapeProps) => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    fromCDN([
      "https://webix.io/dev/dhtmlx/diagram/diagram_4.0/codebase/diagramWithEditor.css",
      "https://webix.io/dev/dhtmlx/diagram/diagram_4.0/codebase/diagramWithEditor.js",
    ]).then(() => setReady(true));
  }, []);

  if (!ready) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return <CanvasGrape {...props} />;
};

/* ---------- */

type CanvasTool = "p" | "e" | "t"; // pen, eraser, text
type CanvasLine = { t: CanvasTool; c: string; p: number[]; v?: string };

export const GrapeDetailCanvas = (props: CanvasGrapeProps) => {
  const [tool, setTool] = useState<CanvasTool>("p");
  const [restore, setRestore] = useState<CanvasLine[]>([]);
  const [lines, setLines] = useState<CanvasLine[]>(() => {
    try {
      const list: CanvasLine[] = JSON.parse(props.grape.description || "[]");
      if (Array.isArray(list) && list.length && !isNaN(list[0].p[0])) {
        return list;
      }
    } catch (e) {}
    return [];
  });
  const isDrawing = useRef(false);
  const [color, setColor] = useState("#000000");

  const saveRef = useRef<any>();

  const dispatchSave = (list: CanvasLine[]) => {
    if (saveRef.current) clearTimeout(saveRef.current);
    saveRef.current = setTimeout(() => {
      props.editGrape(props.grape.id, "description", JSON.stringify(list));
    }, 1000);
  };

  const handleMouseDown = (e: any) => {
    if (e?.target?.textArr) {
      isDrawing.current = false;
      return;
    }

    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { t: tool, c: color, p: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e: any) => {
    const isShiftPress = e?.evt?.shiftKey;

    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];

    if (isShiftPress && lastLine.p.length > 1) {
      // reset end point
      lastLine.p = [lastLine.p[0], lastLine.p[1], point.x, point.y];
    } else {
      // add point
      lastLine.p = lastLine.p.concat([point.x, point.y]);
    }

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;

    setLines((lines) => {
      let allLines = lines.slice(0);
      if (lines.length && lines[lines.length - 1].t !== "t") {
        allLines = lines.length ? lines.slice(0, lines.length - 1) : [];
        allLines.push({
          t: lines[lines.length - 1].t,
          c: lines[lines.length - 1].c,
          p: simplifyPoints(lines[lines.length - 1].p, 1),
        });
      }
      dispatchSave(allLines);
      return allLines;
    });
  };

  return (
    <DetailScaffold grape={props.grape} header={<GrapeDetailToolbar />}>
      <div css={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 8 }}>
        <div
          css={{
            cursor: "pointer",
            padding: 4,
            color: getLuminance(color) > 0.9 ? "#fff" : "#000",
            border: "1px solid #eee",
          }}
          children={"Colore: " + color}
          onClick={() => showColorPickerModal("Colore", color, setColor)}
        />

        <div
          className="hoverable"
          css={{ marginLeft: 12 }}
          children={
            <span css={{}}>
              <span css={{ fontWeight: "bold", textDecoration: "underline" }}>T</span>ext
            </span>
          }
          onClick={() => {
            showInputDialog("Testo", "Inserisci il testo", undefined, (text) => {
              if (text) {
                setLines((lines) => [
                  ...lines,
                  {
                    t: "t",
                    c: color,
                    p: [50, 50],
                    v: text,
                  },
                ]);
              }
            });
          }}
        />

        <div css={{ flex: 1 }} />

        {/* UNDO */}
        <div css={{ width: 24, marginRight: 4 }}>
          {lines.length ? (
            <div
              className="hoverable"
              children={<span className="material-icons" children="undo" />}
              onClick={() => {
                // const remove = lines[lines.length-1]
                setLines((lines) => {
                  if (!lines.length) return lines;
                  const pop = lines[lines.length - 1];
                  setRestore((l) => [...l, pop]);
                  return lines.slice(0, lines.length - 1);
                });
              }}
            />
          ) : undefined}
        </div>

        {/* REDO */}
        <div css={{ width: 24 }}>
          {restore.length ? (
            <div
              className="hoverable"
              children={<span className="material-icons" children="redo" />}
              onClick={() => {
                setRestore((res) => {
                  if (!res.length) return res;
                  const pop = res[res.length - 1];
                  setLines((l) => [...l, pop]);
                  return res.slice(0, res.length - 1);
                });
              }}
            />
          ) : undefined}
        </div>

        {/* {[
           "pen",
           // "eraser"
         ].map((item) => (
           <Button
             key={item}
             color={tool === item ? "primary" : "default"}
             children={item}
             css={tool === item ? { fontWeight: "bold", textDecoration: "underline" } : {}}
             onClick={() => {
               setTool(item);
             }}
           />
         ))} */}
      </div>

      <div css={{ boxShadow: "0 0 1px #888" }}>
        <Stage
          width={340}
          height={500}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
        >
          <Layer>
            {lines.map((line, i) =>
              line.t === "t" ? (
                <Text
                  key={i}
                  text={line.v}
                  x={line.p[0]}
                  y={line.p[1]}
                  fontSize={20}
                  fill={line.c}
                  onClick={(e) => console.log("-->", e)}
                  draggable
                  onDblClick={(e) => {
                    showInputDialog(
                      "Modifica",
                      line.v,
                      line.v,
                      (text) => {
                        if (text) {
                          setLines((lines) =>
                            lines.map((l, index) =>
                              i === index
                                ? {
                                    ...l,
                                    v: text,
                                  }
                                : l
                            )
                          );
                        }
                      },
                      undefined,
                      true
                    );
                  }}
                  onDragEnd={(e) => {
                    const x = e.target.x();
                    const y = e.target.y();
                    setLines((lines) =>
                      lines.map((l, index) =>
                        i === index
                          ? {
                              ...l,
                              p: [x, y],
                            }
                          : l
                      )
                    );
                  }}
                />
              ) : (
                <Line
                  key={i}
                  points={line.p}
                  stroke={line.c}
                  onClick={(e) => console.log("-->", e)}
                  strokeWidth={line.t === "e" ? 10 : 3}
                  tension={0.5}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={line.t === "e" ? "destination-out" : "source-over"}
                />
              )
            )}
          </Layer>
        </Stage>
      </div>
    </DetailScaffold>
  );
};
