import { useCallback, useRef } from "react";
import { GrapeDetailOpenParams, IGrapeDetail } from "..";
import { useGrapesApp } from "../../../../App";
import Grapes from "../../../../modules/Grapes";
import { cleanHTMLTags, clearCurrentInputFocus } from "../../../../utils";
import UserPreferences from "../../../../utils/UserPreferences";
import { IGrape } from "../../../grape_gql_interface";
import MLDialog from "../../../MLDialog";
import { GrapeSocketController } from "../../GrapeDetailPointers/usersSocketController";
import { GrapeModalState } from "./GrapeDetailContext";

export const useGrapeDetailContextActions = ({
  grape,
  props,
  modalGrape,
  selectedGrape,
  setSelectedGrape,
  setSelectedGrapeParams,
  socketController,
  setModalGrape,
}: {
  grape: IGrape;
  props: IGrapeDetail;
  modalGrape: GrapeModalState | undefined;
  selectedGrape?: IGrape;
  socketController: GrapeSocketController;
  setSelectedGrape: (grape?: IGrape, params?: GrapeDetailOpenParams) => void;
  setSelectedGrapeParams: (params?: GrapeDetailOpenParams) => void;
  // setModalGrape: React.Dispatch<React.SetStateAction<GrapeModalState | undefined>>;
  setModalGrape: (state: GrapeModalState) => void;
}) => {
  const grapesApp = useGrapesApp();

  const closeModal = () => {
    setModalGrape({ ...((modalGrape || {}) as any), visible: false });
    // setModalGrape((x) => ({ ...x!, visible: false }));
    socketController.onFocus(undefined);
  };

  const onDeleted = () => {
    if (selectedGrape && selectedGrape.id === grape.id) {
      setSelectedGrape(undefined);
    }
  };

  const onRequestClose = () => {
    if (modalGrape?.visible) {
      closeModal();
    } else {
      setSelectedGrape(undefined);
    }
  };

  const onRequestOpen = (item: IGrape, params?: GrapeDetailOpenParams) => {
    console.log("-->", item);
    clearCurrentInputFocus();

    // if ((item.labels || []).includes("❌ 401") && Grapes.getSetting(item, "mirror-grape")) {
    if (item.type === "mirror" && Grapes.getSetting(item, "mirror-grape")) {
      const ownerDesc = ((item.owner?.name || "") + " " + (item.owner?.surname || "")).trim() || item.owner?.email;
      MLDialog.showModal(
        "Non hai i permessi per visualizzare questo grappolo",
        "Devi essere invitato al grappolo, o in qualsiasi livello superiore per poter vedere il contenuto.\n\n" +
          (ownerDesc.trim() ? "Questo è stato creato da " + ownerDesc : "")
      );
      return;
    }

    //? does crash
    // if (item?.type === 'kanban' || grape?.type === 'kanban') {

    if (["kanban", "board"].includes(grape?.type) && !params?.openAside && !props.onModalExpand) {
      // console.log("modal -->", cleanHTMLTags(item?.title || ""));
      socketController.onFocus(item);
      setModalGrape({ grape: item, params, visible: true });
    } else {
      // console.log("aside -->", cleanHTMLTags(item?.title || ""));
      const scrollToRight = !["kanban", "board"].includes(item?.type);
      onRequestOpenAside(item, params, scrollToRight);
    }
  };

  const onRequestOpenAside = (item: IGrape, params?: GrapeDetailOpenParams, scrollToRight?: boolean) => {
    if (scrollToRight == undefined ? !["kanban", "board"].includes(item?.type) : scrollToRight) postScrollToRight();
    // if (item.id === selectedGrape) return;
    setSelectedGrape(item, params);
  };

  const postScrollTimeout = useRef<NodeJS.Timeout>();
  const postScrollToRight = () => {
    if (postScrollTimeout.current) clearTimeout(postScrollTimeout.current);
    postScrollTimeout.current = setTimeout(() => {
      const modal: any = document.querySelector("#modal-horizontal-scroller .MuiPaper-root");
      const page: any = document.querySelector("#horizontal-scroller");
      const el = modal || page;
      if (el) el.scrollTo({ left: 999999999999999, behavior: "smooth" });
    }, 300);
  };

  const scrollToBottom = useCallback(() => {
    const grapeScroller = document.querySelector(".grape-detail-" + grape.id + " .droppable-container > div");
    if (grapeScroller) grapeScroller.scrollTo({ top: 999999999999999, behavior: "smooth" });
  }, []);

  function setSelectedTabGrapeId(newGrapeId: string) {
    if (newGrapeId != grape.id) {
      setSelectedGrape({ id: newGrapeId } as any);
      UserPreferences.savePreference(grapesApp, "tabs_id_" + grape.id, newGrapeId);
    } else {
      setSelectedGrape(grape);
      setSelectedGrapeParams({ isMainTabsGrape: true });
    }
  }

  return {
    closeModal,
    onDeleted,
    onRequestOpen,
    onRequestOpenAside,
    onRequestClose,
    onModalExpand: props.onModalExpand,
    onGrapeSubpathChange: props.onGrapeSubpathChange,
    onRequestCloseParent: props.onRequestClose,
    scrollToBottom,
    setSelectedTabGrapeId,
    setSelectedGrape,
  };
};
