import { Theme } from "@material-ui/core";
import { ApolloClient, gql } from "apollo-boost";
import React, { ReactElement } from "react";
import { IGrapesApp } from "../App";
import { GRAPE_FILE_FRAGMENT, USER_FRAGMENT } from "../repositories/fragments";
import { GrapeDetailOpenParams } from "./GrapeDetail/GrapeDetail";
import { IGrapeFile } from "./GrapeFile";
import { IUser } from "./User";

export const GRAPE_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment grape on Grape {
    id
    title
    description
    type
    main_order
    cross_order
    status
    parent
    date
    date0
    date1
    date2
    progress
    storypoints
    urgency
    priority
    settings
    theme
    assignees {
      ...user
    }
    epics
    labels
    updated_at
    created_at
    links_to {
      type
      grape {
        id
        title
        description
        status
        parent
        type
        date
        date0
      }
    }
    owner {
      ...user
    }
  }
`;

export const GRAPE_LINKED_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment grapeLinked on Grape {
    id
    title
    description
    type
    main_order
    cross_order
    status
    parent
    date
    date0
    date1
    date2
    progress
    storypoints
    urgency
    priority
    settings
    theme
    epics
    labels
    updated_at
    created_at
  }
`;

export const GET_TODO_COUNT = gql`
  query getTodoCount($grapeId: ID!) {
    getTodoCount(grapeId: $grapeId)
  }
`;

export const GET_CHAT_COUNT = gql`
  query getCountChatNotification($grapeId: ID!) {
    getCountChatNotification(grapeId: $grapeId)
  }
`;

export const GET_GRAPE = gql`
  ${USER_FRAGMENT}
  ${GRAPE_FILE_FRAGMENT}
  ${GRAPE_FRAGMENT}
  ${GRAPE_LINKED_FRAGMENT}

  query getGrape($id: ID) {
    allGrapes(id: $id) {
      ...grape
      users {
        user {
          ...user
        }
        permission
      }
      virtualStorypoints
      files {
        ...grapeFile
      }
      blocked_by {
        ...grapeLinked
      }
      related_to {
        ...grapeLinked
      }
      cloned_by {
        ...grapeLinked
      }
      mirrored_by {
        ...grapeLinked
      }
      children {
        ...grape
        assignees {
          ...user
        }
        virtualStorypoints
        files {
          ...grapeFile
        }
        children {
          ...grape
          assignees {
            ...user
          }
          virtualStorypoints
          files {
            ...grapeFile
          }
          # todo wip to set conditional remove
          children {
            ...grape
          }
          # -> bring children to render subGrapes
          # children {
          #   ...grape
          #   assignees {
          #     ...user
          #   }
          #   virtualStorypoints
          #   files {
          #     ...grapeFile
          #   }
          #   # bring children to render subGrapes
          #   children {
          #     ...grape
          #   }
          # }
        }
      }
    }
  }
`;

export const GET_GRAPE_OLD_KANBAN = gql`
  ${USER_FRAGMENT}
  ${GRAPE_FILE_FRAGMENT}
  ${GRAPE_FRAGMENT}

  query getGrape($id: ID) {
    allGrapes(id: $id) {
      ...grape
      users {
        user {
          ...user
        }
        permission
      }
      virtualStorypoints
      files {
        ...grapeFile
      }
      children {
        ...grape
        assignees {
          ...user
        }
        virtualStorypoints
        files {
          ...grapeFile
        }
        children {
          ...grape
          assignees {
            ...user
          }
          virtualStorypoints
          files {
            ...grapeFile
          }
          # bring children to render subGrapes
          children {
            ...grape
            assignees {
              ...user
            }
            virtualStorypoints
            files {
              ...grapeFile
            }
            # bring children to render subGrapes
            children {
              ...grape
            }
          }
        }
      }
    }
  }
`;

export const GET_ANCESTORS = gql`
  query getGrape($grapeId: ID!) {
    getAncestors(grapeId: $grapeId) {
      id
      title
      type
    }
  }
`;

export const EDIT_GRAPE = gql`
  mutation editGrape($input: InputEditGrape) {
    editGrape(input: $input) {
      id
      title
      description
      type
      main_order
      status
      parent
      date
      date0
      date1
      date2
      progress
      epics
      labels
      settings
      theme
      updated_at
      created_at
    }
  }
`;

export const SET_REMINDER = gql`
  mutation setReminder($input: InputSetReminder) {
    setReminder(input: $input) {
      id
      sent
    }
  }
`;

export const CREATE_MY_GRAPE = gql`
  mutation createMyGrape($input: InputCreateGrape) {
    createMyGrape(input: $input) {
      id
    }
  }
`;

export const CREATE_GRAPE = gql`
  mutation createGrape($input: InputCreateGrape) {
    createGrape(input: $input) {
      id
      parent
      type
      settings
    }
  }
`;

export const APPEND_GRAPE = gql`
  mutation appendGrape($grapeId: ID!, $parentId: ID!, $main_order: Float!, $cross_order: Float, $status: Float) {
    appendGrape(
      id: $grapeId
      parentId: $parentId
      main_order: $main_order
      cross_order: $cross_order
      status: $status
    ) {
      id
      children {
        id
      }
    }
  }
`;

export const CHANGE_OWNER_OF_GRAPE = gql`
  mutation changeOwner($grapeId: ID!, $email: String!) {
    changeOwner(grapeId: $grapeId, email: $email) {
      id
    }
  }
`;

export const ASSIGN_GRAPE_TO_USER = gql`
  mutation assignGrapeToUser($grapeId: ID!, $userId: ID!) {
    assignUser(grapeId: $grapeId, userId: $userId) {
      id
    }
  }
`;

export const DEASSIGN_GRAPE_FROM_USER = gql`
  mutation deassignGrapeToUser($grapeId: ID!, $userId: ID!) {
    deassignUser(grapeId: $grapeId, userId: $userId) {
      id
    }
  }
`;

export const REMOVE_USER_FROM_GRAPE = gql`
  mutation removeUser($grapeId: ID!, $userId: ID!) {
    removeUser(grapeId: $grapeId, userId: $userId) {
      id
    }
  }
`;

export interface IUserGrapePermission {
  user: IUser;
  permission?: string;
  assigned?: boolean;
}

export type GrapeLinkType = "blocks" | "relates" | "clones" | "mirrors";

export interface GrapeLink {
  type: GrapeLinkType;
  grape: IGrape;
}

export interface GrapeLinkInput {
  type: GrapeLinkType;
  grape: string;
}

export type GrapeChildrenFilter = {
  label?: string;
  userId?: string;
  sprintId?: string;
  // gantt grape
  onlyGanttized?: boolean;
  onlyUncompleted?: boolean;
};

export interface IGrape {
  id: string;
  title: string;
  description: string;
  type: string;
  main_order: number;
  cross_order: number;
  status: number;
  parent: string;
  updated_at: string;
  created_at: string;
  children: IGrape[];
  date: string;
  date0: string;
  date1: string;
  date2: string;
  progress: number;
  storypoints?: number;
  virtualStorypoints?: number;
  priority?: number;
  settings?: any;
  theme?: any;
  owner: IUser;
  assignees: IUser[];
  epics?: string[];
  labels?: string[];
  files?: IGrapeFile[];
  users?: IUserGrapePermission[];
  urgency?: number;
  _subSection?: string;
  links_to?: GrapeLink[];
  blocked_by?: IGrape[];
  related_to?: IGrape[];
  cloned_by?: IGrape[];
  mirrored_by?: IGrape[];
}

export interface IGrapeComp {
  grape: IGrape;
  groupType: string;
  renderCaption?: () => ReactElement;
  onRequestOpen?: (grape?: IGrape, params?: GrapeDetailOpenParams) => void;
  onRequestAddGrape?: Function;
  onDeleted?: Function;
  anchestors: IGrape[];
  collaborators?: IUserGrapePermission[];
  myGrape?: string;
  selected?: boolean;
  selectedBy?: any[];
  flat?: boolean;
  priority?: number;
  renderSubGrapes?: boolean;
  renderBananaDot?: boolean;
  noContextMenu?: boolean;
  shouldFetchAnchestors?: boolean;
  listItem?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onLabelClick?: (label: string, index: number, event: any) => void;
}
export interface IGrapeCompHOC extends IGrapeComp {
  theme: Theme;
  client: ApolloClient<any>;
  grapesApp: IGrapesApp;
}
