import { ContentBlock, ContentState } from "draft-js";
import React, { ReactElement } from "react";
import { openFileInPage } from "../../GrapeFile";

interface ImageProps {
  block: ContentBlock;
  className?: string;
  theme?: any;
  contentState: ContentState;
  blockStyleFn: unknown;
  blockProps: unknown;
  customStyleMap: unknown;
  customStyleFn: unknown;
  decorator: unknown;
  forceSelection: unknown;
  offsetKey: unknown;
  selection: unknown;
  tree: unknown;
  preventScroll: unknown;
}

// export const ImageComponentBlock = (props: ImageProps) => {
//   console.log(33, props);
//   console.log(44, props.block);

//   console.log(55, )

//   return <div style={{ backgroundColor: "red", margin: 10 }}>ciao!</div>;
// };

// export const ImageComponentBlock = (props: ImageProps) => {
//   const { block, className, theme = {}, ...otherProps } = props;
//   // leveraging destructuring to omit certain properties from props
//   const {
//     blockProps,
//     customStyleMap,
//     customStyleFn,
//     decorator,
//     forceSelection,
//     offsetKey,
//     selection,
//     tree,
//     blockStyleFn,
//     preventScroll,
//     contentState,
//     ...elementProps
//   } = otherProps;
//   const combinedClassName = (theme?.image || "") + " " + (className || "");
//   console.log("11111111");
//   const { src } = contentState.getEntity(block.getEntityAt(0)).getData();
//   console.log("2222222");
//   return <img {...elementProps} src={src} role="presentation" className={combinedClassName} />;
// };

export const ImageComponentBlock = React.forwardRef<HTMLImageElement, ImageProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function Image(props, ref): ReactElement {
    const { block, className, theme = {}, ...otherProps } = props;
    // leveraging destructuring to omit certain properties from props
    const {
      blockProps,
      customStyleMap,
      customStyleFn,
      decorator,
      forceSelection,
      offsetKey,
      selection,
      tree,
      blockStyleFn,
      preventScroll,
      contentState,
      ...elementProps
    } = otherProps;
    const combinedClassName = (theme?.image || "") + " " + (className || "");
    const { src } = contentState.getEntity(block.getEntityAt(0)).getData();
    return (
      <img
        {...elementProps}
        ref={ref}
        src={src}
        role="presentation"
        className={combinedClassName}
        style={{ cursor: "pointer" }}
        onClick={() => openFileInPage(src, "image/jpeg")}
      />
    );
  }
);
