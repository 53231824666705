/** @jsxImportSource @emotion/react */
import { ApolloClient, gql } from "apollo-boost";
import { DropResult } from "react-beautiful-dnd";
import { APPEND_GRAPE, GET_GRAPE, GRAPE_FRAGMENT, IGrape } from "../components/grape_gql_interface";
import MLDialog from "../components/MLDialog";
import { isCompletionGrapeByTitle } from "../utils";
import Grapes from "./Grapes";
import { __DEV__ } from "../utils/init";
import { hideGrapesFromChildren } from "../components/GrapeDetail/core/grapeTypes";
import { getStatusChangeBoard } from "../components/GrapeDetail/GrapeBoard";

export function onDragEndHandler(client: ApolloClient<any>, result: DropResult) {
  try {
    computDragEnd(client, result);
  } catch (e) {
    console.log("dnd error", e);
  }
}

function computDragEnd(client: ApolloClient<any>, result: DropResult) {
  if (!result) throw "invalid result";
  if (!result.destination && !result.combine) throw "invalid destination or combine";

  const draggableIdTokens = result.draggableId.split("_");
  const grapeId = draggableIdTokens[0];
  const oldParentId = draggableIdTokens[1];
  const grapeStatus = draggableIdTokens[2];
  const isMyGrapeRoot = draggableIdTokens[3]; //undefined if not root grape

  const droppableIdTokens = (result.destination?.droppableId || result.combine!.draggableId).split("_");
  const parentId = droppableIdTokens[0];
  const isMovingHorizontally = droppableIdTokens[1] === "crossOrder";

  const destinationIndex = (result.destination && result.destination.index) || 0;

  //!wip - prevent dragging shared grape inside other grapes
  if (result.combine && isMyGrapeRoot === "false") {
    MLDialog.showSnackbar('Non puoi "annidare" un nodo che ti è stato condiviso');
    throw "cannot drag shared grape inside other grapes";
  }

  //? trying to add grape to himself
  if (!parentId || grapeId === parentId) throw "cannot add grape to himself";

  // if (__DEV__) {
  //   console.log(">> ", result.combine, grapeId, parentId, oldParentId);
  //   console.log(222, parentId, droppableIdTokens[1], droppableIdTokens[2], destinationIndex);
  //   return;
  // }

  const grape = readGrapeFragment(client, grapeId);
  const fromGrape = readGrapeFragment(client, oldParentId);

  const fromGrapeChildren = fromGrape.children.filter((x) => !["progress", ...hideGrapesFromChildren].includes(x.type));

  const toGrape = readGrapeFragment(client, parentId);

  /// PREPARE FOR SORTING
  let grapesList = toGrape.children.filter((x) => !["progress", ...hideGrapesFromChildren].includes(x.type));
  // toGrape.children.sort(sortStrangeGrapeTypes);
  let main_order = 500;
  let cross_order = grape.cross_order;

  let status = undefined; // or start at 0
  let shouldRefetch = false;

  if (!grape || !fromGrape || !toGrape) {
    console.log("❌", grape, fromGrape, toGrape);
    alert("can't do this");
    throw `‹${grapeId}› cannot be moved to ‹${parentId}›, g ${grape}, fg ${fromGrape}, tg ${toGrape}`;
  }

  let toIndex = destinationIndex;
  let insideIndex = fromGrapeChildren.findIndex((x) => x.id === grapeId);

  // ? wants to scroll horizontally
  if (isMovingHorizontally) {
    cross_order = parseInt(droppableIdTokens[2] || "0");

    grapesList = grapesList.filter((g) => g.cross_order === cross_order);
    insideIndex = grapesList.findIndex((x) => x.id === grapeId);

    if (toGrape.type === "board") {
      status = getStatusChangeBoard(toGrape, cross_order);
      // console.log("🟥", "status:", status, "cross_order:", cross_order);
    } else {
      if (toGrape.type === "progress") {
        shouldRefetch = true;
      }
      status = getStatusChangeFromKanbanProgressCache(client, toGrape, cross_order);
    }
  }

  //? grape is already in the right position
  // if (fromGrape.id === toGrape.id && insideIndex === toIndex && !isMovingHorizontally) {
  if (fromGrape.id === toGrape.id && insideIndex === toIndex && grapeStatus === String(status)) {
    throw "grape is already in the right position";
  }

  //? is moving inside the same list, and going downward
  if (parentId === oldParentId && insideIndex >= 0 && toIndex > insideIndex) toIndex++;

  if (toIndex === 0) {
    main_order = grapesList?.[0]?.main_order - 100 ?? 500;
  } else if (toIndex >= grapesList.length || !grapesList[toIndex]) {
    main_order = grapesList[grapesList.length - 1].main_order + 100;
  } else {
    main_order = (grapesList[toIndex - 1].main_order + grapesList[toIndex].main_order) / 2;
  }
  if (isNaN(main_order)) main_order = 500;

  const toGrapeChildren = [
    ...(toGrape.children || []).slice().filter((g) => g.id !== grapeId),
    { ...grape, main_order, cross_order, status, parentId } as IGrape,
  ].sort((g1, g2) => g1.main_order - g2.main_order);

  if (oldParentId !== parentId) {
    writeFragment(client, oldParentId, {
      ...fromGrape,
      children: (fromGrape.children || []).slice().filter((g) => g.id !== grapeId),
    });
  }

  writeFragment(client, parentId, {
    ...toGrape,
    children: toGrapeChildren,
  });

  // console.log("📦", grapeId, parentId, oldParentId, toIndex, insideIndex, isMovingHorizontally);
  // console.table({
  //   grapeId,
  //   parentId,
  //   main_order,
  //   cross_order,
  //   status,
  // });

  client
    .mutate({
      mutation: APPEND_GRAPE,
      variables: {
        grapeId,
        parentId,
        main_order,
        cross_order,
        status,
      },
      //TODO update and refetch only BOARD
      refetchQueries: shouldRefetch ? [{ query: GET_GRAPE, variables: { id: parentId } }] : [],
    })
    .catch((e) => console.error("on-append error", e));
}

export function readGrapeFragment(client: ApolloClient<any>, id: string) {
  return client.readFragment({
    id: "Grape:" + id,
    fragmentName: "grapeList",
    fragment: gql`
      ${GRAPE_FRAGMENT}
      fragment grapeList on Grape {
        ...grape
        children {
          ...grape
        }
      }
    `,
  }) as IGrape;
}

export function writeFragment(client: ApolloClient<any>, parentId: string, data: IGrape) {
  return client.writeFragment({
    id: "Grape:" + parentId,
    fragmentName: "grapeList",
    fragment: gql`
      ${GRAPE_FRAGMENT}
      fragment grapeList on Grape {
        ...grape
        children {
          ...grape
        }
      }
    `,
    data: data,
  });
}

function getStatusChangeFromKanbanProgressCache(client: ApolloClient<any>, toGrape: IGrape, cross_order: number) {
  try {
    /// the following code is VERY optimistic
    const parentColumn = readGrapeFragment(client, toGrape.parent);
    if (parentColumn.type === "board") {
      console.log("not a kanban");
      return;
    }
    const kanban = readGrapeFragment(client, parentColumn.parent);

    const visibleColumns = kanban.children.filter((g) => !Grapes.getSetting(g, "kanban-column-hidden", false));
    const toColumn = visibleColumns[cross_order];

    if (isCompletionGrapeByTitle(toColumn.title)) {
      return 1;
    } else {
      return 0;
    }

    // let grapesList = toGrape.children.filter((x) => !hideGrapesFromChildren.includes(x.type));
  } catch (err) {
    console.log("err retrieving kanban progress item", err);
  }
}
