/** @jsxImportSource @emotion/react */
import { QueryResult } from "@apollo/react-common";
import { Query } from "@apollo/react-components";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { Box, getLuminance, Grid, Tooltip, useTheme } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBoxOutlined";
import moment from "moment";
import React from "react";
import { useGrapesApp } from "../App";
import Grapes from "../modules/Grapes";
import { getUserColor } from "../modules/UserUtils";
import { GET_ALL_GRAPES, GET_MY_GRAPES } from "../repositories/queries_mutations";
import { isEmptyHTMLString, isTodoGrapeByTitle, isWipGrapeByTitle } from "../utils";
import { __DEV__ } from "../utils/init";
import { GRAPE_FILE_INLINE } from "./AddTextField";
import { ContextableGrape } from "./ContextableGrape";
import { hideGrapesFromChildren } from "./GrapeDetail/core/grapeTypes";
import { getKanbanSetting } from "./GrapeDetail/KanbanGrape";
import { openFileInPage } from "./GrapeFile";
import { getKanbanChildrens } from "./GrapeDetail/KanbanGrape";
import { getBoardGrapeStructure } from "./GrapeDetail/GrapeBoard";
import { isCompletionGrapeByTitle } from "../utils";
import GrapeLabels from "./GrapeLabels";
import { GrapePriorityIcon, grapesPriorities, IPriority } from "./GrapePriority";
import {
  CREATE_GRAPE,
  EDIT_GRAPE,
  GET_CHAT_COUNT,
  GET_GRAPE,
  IGrape,
  IGrapeComp,
  IGrapeCompHOC,
} from "./grape_gql_interface";
import OptimisticTristateCheckbox from "./input/OptimisticTristateCheckbox";
import UrgencyStatus from "./UrgencyStatus/UrgencyStatus";
import { IUser, UserChip } from "./User";

const tickAudio = new Audio(require("../assets/audio/tick.mp3"));

export const UPDATE_TIMEOUT_MILLIS = 500;

export const focusGrape = (id: string) => {
  // TODO - remove timeout
  setTimeout(() => {
    const el: any = document.querySelector("#gid-" + id);
    if (el && el.focus) el.focus();
  }, 200);
};

class GrapeComp extends React.Component<IGrapeCompHOC> {
  middleClickStart = { alive: false, x: 0, y: 0 };

  updateAttrs: any = {};
  updateTimeout?: any = undefined;

  shortcutFired: boolean = false;
  grapeTitleFocus: boolean = false;

  shouldComponentUpdate() {
    return !this.grapeTitleFocus;
  }

  onChange(attr: string, value: any, options?: { withDelay?: boolean }) {
    this.updateAttrs[attr] = value;

    // console.log(this.updateAttrs)

    if (this.updateTimeout) clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(
      () => {
        this.props.client
          .mutate({
            mutation: EDIT_GRAPE,
            variables: {
              input: {
                ...this.updateAttrs,
                id: this.props.grape.id,
              },
            },
            refetchQueries: [
              this.props.myGrape
                ? { query: GET_MY_GRAPES }
                : { query: GET_GRAPE, variables: { id: this.props.grape.id } },
            ],
          })
          .then(({ data }: any) => {
            //console.log(data);
          })
          .catch((e) => console.log(2, e));

        //? __________
        /// MIRROR GRAPE REFLECTIONS
        const mirrorSourceId = Grapes.getSetting(this.props.grape, "mirror-grape-source");
        if (mirrorSourceId) {
          const forbiddenReflections = ["parent", "id", "settings"];
          for (let forbidden of forbiddenReflections) {
            delete this.updateAttrs[forbidden];
          }
          if (Object.keys(this.updateAttrs).length) {
            this.props.client.mutate({
              mutation: EDIT_GRAPE,
              variables: {
                input: {
                  ...this.updateAttrs,
                  id: mirrorSourceId,
                },
              },
            });
          }
        }
      },
      options && options.withDelay ? UPDATE_TIMEOUT_MILLIS : 0
    );
  }

  addGrape = async (parentId: string) => {
    const resp = await this.props.client.mutate({
      mutation: CREATE_GRAPE,
      variables: {
        input: { parent: parentId },
      },
      awaitRefetchQueries: true,
      refetchQueries: [parentId ? { query: GET_GRAPE, variables: { id: parentId } } : { query: GET_ALL_GRAPES }],
    });
    return resp.data.createGrape.id;
  };

  onKeyDown = (e: React.KeyboardEvent) => {
    // console.log(e.which);
    if (e.which === 13) {
      // enter
      if (e.ctrlKey) {
        // PRESS CTRL + ENTER
        //! removed add child
        // if (this.shortcutFired) return;
        // this.shortcutFired = true;
        // this.addGrape(this.props.grape.id).then(focusGrape);
      } else if (!e.shiftKey) {
        // PRESS ONLY ENTER
        e.preventDefault();
        // if (this.shortcutFired) return;
        // this.shortcutFired = true;
        // this.addGrape(this.props.grape.parent, this.props.grape.main_order).then(focusGrape);
        if (this.props.onRequestAddGrape) this.props.onRequestAddGrape();
        return true;
      }
    }
  };

  renderAssignees() {
    const assignees: IUser[] = (this.props.grape || {}).assignees || [];
    if (!assignees.length) return null;
    const size = 20;
    const shift = size * 0.7;
    const width = size + shift * (assignees.length - 1);
    return assignees?.length ? (
      <div style={{ display: "inline-block", width, height: size, position: "relative", paddingLeft: 5 }}>
        {assignees.map((user, index) => (
          <UserChip
            key={user.id}
            collapsed
            size={size}
            user={this.props.grape.assignees[index]}
            avatarStyle={{ margin: 5 }}
            style={{
              position: "absolute",
              margin: "auto",
              top: 0,
              left: 0,
              transform: `translateX(${shift * index}px)`,
            }}
          />
        ))}
      </div>
    ) : null;
  }

  renderChatCount() {
    return (
      this.props.grape && (
        <Query query={GET_CHAT_COUNT} variables={{ grapeId: this.props.grape.id }} pollInterval={10000}>
          {({ loading, error, data }: QueryResult) => {
            if (loading || error) return null;

            if (!data.getCountChatNotification || data.getCountChatNotification == 0) return null;

            return (
              <div
                children={data.getCountChatNotification}
                style={{
                  fontSize: 10,
                  transform: "translateX(-2px)",

                  color: "white",
                  background: this.props.theme.palette.primary.main,
                  padding: "0px 4px",
                  borderRadius: 5,
                  marginBottom: 1,
                }}
              />
            );
          }}
        </Query>
      )
    );
  }

  renderChildrenPresence() {
    const isRootGrape = !(this.props.myGrape || this.props.grape.parent);
    const childrenPresences = [];

    const subgrapes = this.props.grape?.children?.filter((sg) => !hideGrapesFromChildren.includes(sg.type));

    if (["todo", "progress", "sprint"].includes(this.props.grape.type) && subgrapes?.length) {
      // const nDone = this.props.grape.children.filter((g) => g.status > 0).length;
      // const nTot = this.props.grape.children.length;

      let doneColumnIndex = 1;
      if (this.props.anchestors.length > 1) {
        const kanban = this.props.anchestors[this.props.anchestors.length - 2];
        // not in backlog
        const kanbanVisibleColumns = (kanban.children || []).filter(
          (g) => !hideGrapesFromChildren.includes(g.type) && !Grapes.getSetting(g, "kanban-column-hidden", false)
        );
        // console.log(999, kanban, kanbanVisibleColumns);
        if (kanbanVisibleColumns.length) doneColumnIndex = kanbanVisibleColumns.length - 1;
      }

      const nDone = subgrapes.filter((g) =>
        this.props.grape.type === "progress" ? g.cross_order === doneColumnIndex : g.status === 1
      ).length;

      const nTot = subgrapes.length;
      const color = nDone === nTot ? "#55aa00" : nDone >= nTot - 1 || nDone / nTot > 0.7 ? "#ffaa00" : "#999";
      childrenPresences.push(
        <span
          key="counter"
          css={{
            whiteSpace: "nowrap",
            fontSize: 10,
            fontWeight: 500,
            color,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingRight: 2,
          }}
        >
          {nDone === nTot ? <CheckBoxIcon css={{ color, fontSize: 14, marginRight: 2 }} /> : null}
          {nDone !== nTot ? nDone + "/" + nTot : nTot}
        </span>
      );
    } else if (!isRootGrape) {
      if (
        !isEmptyHTMLString(this.props.grape.description) ||
        this.props.grape.files?.filter((f) => f.title !== GRAPE_FILE_INLINE)?.length ||
        (subgrapes?.length && !this.props.renderSubGrapes)
      ) {
        childrenPresences.push(
          <div
            key="dot"
            style={{
              width: 4,
              height: 4,
              // background: theme.palette.primary.main,
              background: "#777",
              borderRadius: 2,
              margin: 4,
              marginTop: 12,
            }}
          />
        );
      }
    }

    if (!isRootGrape) {
      const isBananaDotEnabled = this.props.renderBananaDot && !Grapes.getSetting(this.props.grape, "no-banana-dot");
      if (this.props.grape.date) {
        //? task has a deadline
        const deadline = moment(this.props.grape.date).startOf("day");
        const grapeDays = moment().startOf("day").diff(deadline, "days");
        let color =
          grapeDays > 0
            ? "#000000"
            : grapeDays >= -2
            ? "#bb0000"
            : grapeDays > -5
            ? "#fe8800"
            : grapeDays > -15
            ? "#ffe000"
            : "#ffffff";
        if (!isBananaDotEnabled) color = "#ffffff";
        const tooltipMessage = "Deadline: " + deadline.format("D MMMM YYYY");
        childrenPresences.push(
          <Tooltip title={tooltipMessage} key="deadline">
            <div
              children={deadline.format("D MMM")}
              css={{
                position: "absolute",
                top: 2,
                right: 2,
                // width: 9,
                fontSize: 9,
                padding: "0px 2px",
                // color: 'white',
                whiteSpace: "nowrap",
                textTransform: "uppercase",
                height: 12, // 9,
                borderRadius: 2,
                backgroundColor: color,
                color: getLuminance(color) > 0.5 ? "black" : "white",
              }}
            />
          </Tooltip>
        );
      } else if (isBananaDotEnabled) {
        //? prevent children presence DOT on root grapes
        const createdDate = moment(this.props.grape.date || this.props.grape.created_at);
        const grapeAge = moment().diff(createdDate, "weeks");
        if (grapeAge >= 2) {
          let tooltipMessage = grapeAge + " settimane fa";
          if (grapeAge >= 5) {
            const months = moment().diff(createdDate, "months");
            tooltipMessage += " ∼ " + months + " " + (months > 1 ? "mesi" : "mese");
          }

          childrenPresences.push(
            <Tooltip title={tooltipMessage} key="banana-dot">
              <div
                className="banana-dot"
                css={{
                  position: "absolute",
                  top: 2,
                  right: 2,
                  width: 9,
                  height: 9,
                  borderRadius: 2,
                  backgroundColor:
                    grapeAge < 4 ? "#ffe000" : grapeAge < 6 ? "#fe8800" : grapeAge < 50 ? "#bb0000" : "#000000",
                }}
              />
            </Tooltip>
          );
        }
      }
    }

    if (!childrenPresences.length) return null;

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        style={{
          // pointerEvents: "none",
          position: "absolute",
          width: "auto",
          // right: 2,
          // top: 0,
          // bottom: 0,
          top: 0,
          right: 0,
          paddingTop: 12,
        }}
      >
        {childrenPresences}
      </Grid>
    );
  }

  render() {
    const isEditable = false; //this.props.groupType === "todo";

    let shadowColor = "rgb(15 15 15 / 10%)";
    if (this.props.selectedBy?.length) {
      shadowColor = this.props.theme.palette.primary.main;
      for (let c = 0; c < (this.props.collaborators?.length || 0); c++) {
        if (this.props.collaborators![c].user.id === this.props.selectedBy[0]) {
          shadowColor = getUserColor(this.props.collaborators![c].user);
        }
      }
    }

    return (
      <ContextableGrape {...this.props} onChange={this.onChange.bind(this)}>
        <Grid
          container
          direction="column"
          className={this.props.className}
          id={`gid-${this.props.grape.id}`}
          style={{
            position: "relative",
            cursor: "pointer",
            backgroundColor: this.props.selected ? "#eee" : "white",

            ...(this.props.flat
              ? {
                  borderBottom: "1.3px solid #eee",
                }
              : {
                  boxShadow: `${shadowColor} 0px 0px 0px 1px, ${shadowColor} 0px 2px 4px`,
                  borderRadius: 3,
                  margin: "0 4px",
                  width: "calc(100% - 8px)",
                }),

            ...(this.props.style || {}),
          }}
          //! +++ MOUSE WHEEL CLICK +++
          onMouseDown={(e) => {
            if (e?.nativeEvent?.which == 2) {
              this.middleClickStart = { alive: true, x: e.nativeEvent.clientX, y: e.nativeEvent.clientY };
              e.preventDefault();
            }
          }}
          onMouseMove={(e) => {
            if (e?.nativeEvent?.which == 2 && this.middleClickStart.alive) {
              const dx = Math.abs(this.middleClickStart.x - e.nativeEvent.clientX);
              const dy = Math.abs(this.middleClickStart.y - e.nativeEvent.clientY);
              if (Math.sqrt(dx * dx + dy * dy) > 10) {
                this.middleClickStart.alive = false;
              }
            }
          }}
          onMouseUp={(e) => {
            if (e?.nativeEvent?.which == 2) {
              if (this.middleClickStart.alive) {
                this.middleClickStart.alive = false;
                // success click 🔝
                this.props.onRequestOpen && this.props.onRequestOpen(this.props.grape, { openAside: true });
              }
            }
          }}
          //! --- MOUSE WHEEL CLICK ---
          onClick={(e: any) => {
            if (e?._ignore) {
              e._ignore = false;
              return;
            }
            // success click 🔝
            this.props.onRequestOpen && this.props.onRequestOpen(this.props.grape, { openAside: e.altKey });
          }}
          css={{ 'div[id^="gid-"]': { backgroundColor: "transparent !important" } }}
          // onClick={isEditable ? undefined : () => this.props.onRequestOpen && this.props.onRequestOpen()}
          // onDoubleClick={isEditable ? () => this.props.onRequestOpen && this.props.onRequestOpen() : undefined}
        >
          {this.props.renderCaption ? this.props.renderCaption() : null}
          <Box
            display="flex"
            flexGrow={1}
            style={{
              maxWidth: "100%",
              position: "relative",
              // display: 'inline-block',
              // padding: 10,
            }}
          >
            <Box flexGrow={1} overflow="hidden" style={{ padding: 8, paddingRight: 16 }}>
              {["todo", "sprint"].includes(this.props.groupType) ? (
                <OptimisticTristateCheckbox
                  size={this.props.listItem ? "small" : "medium"}
                  value={this.props.grape.status}
                  containerStyle={{ float: "left" }}
                  style={{ padding: 0, transform: "translateY(-1px)" }}
                  onChange={(toCrossOrder: number) => {
                    if (toCrossOrder === 1) {
                      tickAudio.currentTime = 0;
                      if (navigator.userAgent.toLowerCase().indexOf("safari") != -1) {
                        tickAudio.play();
                      }
                    }
                    this.onChange("status", toCrossOrder);
                    return true;
                  }}
                />
              ) : this.props.listItem ? (
                <div
                  css={{
                    display: "inline-block",
                    // verticalAlign: 'middle',
                    marginLeft: -6,
                    marginRight: 8,
                    // width: 6,
                    // height: 6,
                    // borderRadius: 3,
                    // backgroundColor: '#333',
                    color: "#333",
                  }}
                  // children={this.props.groupType === "kanban" ? "▸" : "•"} //▴▸▼▾•
                  children={this.props.groupType === "progress" ? "▣" : "•"} //▴▸▼▾•▢▷◓▣
                />
              ) : null}

              {this.props.grape && <UrgencyStatus grape={this.props.grape} urgency={this.props.grape?.urgency || 0} />}

              {this.renderGrapePriorityIcon()}

              <GrapeLabels
                leading={
                  [
                    this.props?.grape?.storypoints
                      ? {
                          title: "" + this.props?.grape?.storypoints,
                          tooltip: "Story points",
                          color: {
                            background: "#ffffff",
                            foreground: "#333333",
                          },
                        }
                      : undefined,
                    this.props?.grape?.virtualStorypoints
                      ? {
                          title: "" + this.props?.grape?.virtualStorypoints,
                          tooltip: "Children's story points",
                          color: {
                            background: "#ffffff",
                            foreground: "#ee9900",
                            // background: '#333333',
                            // foreground: '#ffffff',
                          },
                        }
                      : undefined,
                    // LINK SPRINTS
                    ...(this.props?.grape?.links_to || []).map((link) => {
                      if (link.grape.type !== "sprint") return null;
                      return {
                        title: link.grape?.title,
                        tooltip: "sprint: " + link.grape?.title,
                        color: { background: "#ffffff", foreground: "#ff0077" },
                      };
                    }),
                  ].filter((x) => !!x) as any
                }
                // labels={this.props?.grape?.epics || []}
                labels={this.props?.grape?.labels}
                onLabelClick={this.props.onLabelClick}
              />

              {this.renderInlineFiles()}

              <span
                id={"gid-" + this.props.grape.id}
                contentEditable={isEditable}
                draggable={isEditable}
                onDragStart={(e) => {
                  if (isEditable) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                data-placeholder="..."
                suppressContentEditableWarning={true}
                // onClick={onTargetClick}
                // children={this.props.grape.title}
                dangerouslySetInnerHTML={{ __html: this.props.grape.title }}
                // children={this.props.main_order + this.props.title}
                // onChange={(e: any) => this.onChange("title", e.target.value)}
                onInput={(e: any) => this.onChange("title", e.nativeEvent.target.innerHTML, { withDelay: true })}
                onFocus={() => (this.grapeTitleFocus = true)}
                onBlur={() => (this.grapeTitleFocus = false)}
                // onKeyPress={this.onKeyPress}
                onKeyDown={this.onKeyDown}
                onKeyUp={() => (this.shortcutFired = false)}
                css={{
                  // background: this.props.groupType === 'todo' ? undefined : '#fafafa',
                  cursor: isEditable ? "text" : undefined,
                  font: "inherit",
                  color: "#333",
                  userSelect: "none",
                  whiteSpace: "pre-line",
                  // fontSize: 17,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              />

              {/* <br />
              <GrapeLabels labels={this.props?.grape?.labels} onLabelClick={this.props.onLabelClick} /> */}

              {this.props.myGrape !== "assignedToMe" ? this.renderAssignees() : null}

              {this.renderChildrenPresence()}
            </Box>
          </Box>

          {this.props.renderSubGrapes && this.renderSubGrapes()}
        </Grid>
      </ContextableGrape>
    );
  }

  renderGrapePriorityIcon() {
    if (!this.props.grape?.priority || this.props.grape?.priority < 0) {
      return null;
    }
    const isRootGrape = this.props.className === "grape-root";
    const priority: IPriority = grapesPriorities.find((x) => x.value === this.props.grape.priority)!;
    return (
      <span css={{ float: isRootGrape ? "right" : undefined }}>
        <GrapePriorityIcon priority={priority} />
      </span>
    );
  }

  renderInlineFiles() {
    return (
      this.props.grape?.files
        ?.filter((gf) => gf.title === GRAPE_FILE_INLINE)
        ?.map((gf) => (
          <div
            key={gf.url}
            css={{
              width: 24,
              height: 24,
              marginRight: 4,
              float: "left",
              borderRadius: 3,
              border: "1px solid #ccc",
              backgroundImage: `url(${gf.thumbnail ?? gf.url})`,
              backgroundSize: "cover",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openFileInPage(gf.url, gf.mimetype);
            }}
          />
        )) ?? null
    );
  }

  renderSubGrapes() {
    const subgrapes: IGrape[] = getHighlightedSubgrapes(this.props.grape);
    if (!subgrapes?.length) {
      return null;
    }

    const maxSubGrapes = 4;
    const diffFromMax = subgrapes.length - maxSubGrapes;

    return (
      <div
        css={{ maxWidth: "100%", paddingLeft: 10 }}
        onClick={(e) => {
          if ((e as any)._ignore === undefined) {
            (e as any)._ignore = true;
          }
        }}
      >
        {subgrapes.slice(0, maxSubGrapes + (diffFromMax === 1 ? 1 : 0)).map((sg, i) => (
          <Grape
            key={sg.id}
            grape={sg}
            listItem
            flat
            collaborators={this.props.collaborators}
            // onRequestOpen={this.props.onRequestOpen}
            onRequestOpen={(g, params) => {
              // this.props.onRequestOpen?.(undefined, params);
              this.props.onRequestOpen?.(this.props.grape, params);
            }}
            anchestors={[...this.props.anchestors, this.props.grape]}
            groupType={this.props.grape.type}
            css={{
              maxHeight: 78,
              overflow: "hidden",
              // 'span[id^="gid-"] ': { fontSize: 15 },
            }}
            style={i === maxSubGrapes - 1 ? { borderBottom: 0 } : undefined}
          />
        ))}
        {diffFromMax > 1 && (
          <div
            css={{
              padding: 4,
              // textAlign: 'center',
              textAlign: "right",
              opacity: 0.65,
              paddingRight: 10,
              // fontWeight: 'bold',
              // letterSpacing: 1,
              // textTransform: 'uppercase',
              ":hover": { opacity: 1 },
            }}
            children={diffFromMax + " more"}
            onClick={(e) => {
              // prevent parent to ignore callback
              (e as any)._ignore = false;
            }}
          />
        )}
      </div>
    );
  }
}

const Grape = (props: IGrapeComp) => {
  const theme = useTheme();
  const client = useApolloClient();
  const grapesApp = useGrapesApp();

  const mirrorGrapeId = Grapes.getSetting(props.grape, "mirror-grape");

  let grape = props.grape;

  const { data: dataMirrorGrape, error: errorMirrorGrape } = useQuery(GET_GRAPE, {
    variables: { id: mirrorGrapeId },
    skip: !mirrorGrapeId,
  });

  if (mirrorGrapeId) {
    grape = {
      ...(dataMirrorGrape?.allGrapes?.[0] || grape),
      id: mirrorGrapeId,
    };
    grape.settings = {
      ...grape.settings,
      "mirror-grape": mirrorGrapeId,
      "mirror-grape-source": props.grape.id,
      "mirror-grape-error": errorMirrorGrape,
    };
    if (errorMirrorGrape || dataMirrorGrape?.allGrapes?.length === 0) {
      // if (__DEV__) console.log("❌ err get mirror grape", mirrorGrapeId, grape.title, grape.id, grape.parent);
      // if (grape.parent) grape.labels = [...(grape.labels || []), "❌ 401"];
    }
    grape.labels = ["mirror", ...(grape.labels || []).filter((m) => m !== "mirror")];

    if (grape.type === "sprint") {
      grape.children = [...(grape.children || []), ...(grape.related_to || [])];
    }
  }

  return <GrapeComp {...props} theme={theme} client={client} grapesApp={grapesApp} grape={grape} />;
};

export default Grape;

// una funzione che prende un oggetto qualsiasi (o array di oggetti) e deve prendere tutte le promise al suo interno e aspettare che tutte le promise siano terminate

const getHighlightedSubgrapes = (grape: IGrape) => {
  /// kanban
  if (grape.type === "kanban") {
    const kanbanColumns = getKanbanChildrens(grape).filter((g: IGrape) => !getKanbanSetting(g, "column-hidden", false));
    const subgrapes: IGrape[] = [];
    kanbanColumns.forEach((column: IGrape) => {
      if (isTodoGrapeByTitle(column.title) || isWipGrapeByTitle(column.title)) {
        subgrapes.push(...(column?.children || []));
      }
    });
    return subgrapes;
  }

  /// board
  if (grape.type === "board") {
    const struct = getBoardGrapeStructure(grape);
    const validColumns = struct.columns
      .filter((col) => isTodoGrapeByTitle(col.name) || col.isWipColumn || isWipGrapeByTitle(col.name))
      .map((col) => col.id);
    return grape.children
      ?.filter((sg) => !hideGrapesFromChildren.includes(sg.type))
      .filter((sg) => validColumns.includes(sg.cross_order));
  }

  /// default
  return grape.children?.filter((sg) => !hideGrapesFromChildren.includes(sg.type) && sg.status !== 1);
};
