import { OperationVariables, QueryResult } from "@apollo/react-common";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { SentryError } from "@sentry/utils";
import { DocumentNode } from "graphql";
import { useCallback, useEffect, useState } from "react";
const createActivityDetector = require("activity-detector").default;

export function useSafeWindowPollingQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const res = useQuery(query, {
    ...options,
    pollInterval: options.pollInterval,
    // pollInterval: undefined,
  });

  if (res.error) {
    console.error("❌", query, options?.query, res.error);
  }

  return res;

  /*
  const [visibilityState, setVisibilityState] = useState(true);
  const [isIdle, setIsIdle] = useState(false);

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState === "visible");
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const activityDetector = createActivityDetector(options);
    activityDetector.on("idle", () => setIsIdle(true));
    activityDetector.on("active", () => setIsIdle(false));
    return () => activityDetector.stop();
  }, []);

  const isUserActive = visibilityState && !isIdle;

  return useQuery(query, {
    ...options,
    pollInterval: isUserActive ? options.pollInterval : 60 * 60 * 24 * 1000,
  });
  */
}
