/** @jsxImportSource @emotion/react */
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { jsx } from "@emotion/react";
import { Box, Dialog, Grid, useTheme } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ApolloClient from "apollo-client";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { grapesPollInterval } from "./GrapeDetail";
import { GRAPE_DESCENDENT_CHATS, GRAPE_DESCENDENT_CHAT_NOTIFICATIONS } from "./core/gql_interface";
import { useGrapesApp } from "../../App";
import Grapes from "../../modules/Grapes";
import { retrieveGrapeAncestorsTryCache } from "../../utils";
import { useSafeWindowPollingQuery } from "../../utils/safeWindowQuery";
import { GET_GRAPE, IGrape } from "../grape_gql_interface";
import GrapeCollaboratorsThumbs from "../GrapeCollaboratorsThumbs";
import { AddFileToGrape } from "../GrapeDetailItems/GrapeFilesItem";
import ChatGrape, { getMessagesAndFiles, IChatNotification } from "./ChatGrape";
import DetailScaffold from "./DetailScaffold";
import { showFilesUploadDialog } from "./core/file_upload_utils";
import { IMyGrape } from "../MyGrapeDetail";

export interface IGrapesChatApp {
  openChatOnGrape: (grape: IGrape, params?: IGrapesChatAppParams) => void;
}

export interface IGrapesChatAppParams {
  // nothing atm
}

//? grapes chat context
const GrapesChatAppContext = React.createContext<IGrapesChatApp>({ openChatOnGrape: (_) => {} });
const { Provider: GrapesChatAppProviderMain, Consumer: GrapesChatAppConsumer } = GrapesChatAppContext;
export const useGrapesChatApp = () => useContext(GrapesChatAppContext);
export { GrapesChatAppConsumer };

export const GrapesChatAppProvider = (props: { children: JSX.Element }) => {
  const client = useApolloClient();
  const [rootGrape, setRootGrape] = useState<IGrape>();
  const [grape, setGrape] = useState<IGrape>();
  const [selectedChatGrape, setSelectedChatGrape] = useState<IGrape>();
  const [chatParams, setChatParams] = useState<IGrapesChatAppParams>();

  const [isMacOS] = useState(() => (navigator?.platform || "").indexOf("Mac") > -1);
  const [frame, setFrame] = useState({ left: 30, top: 30, width: 600, height: 600, visible: false });
  const [loading, setLoading] = useState(false);

  const onChatPanelClose = () => setFrame((f) => ({ ...f, visible: false }));

  return (
    <GrapesChatAppProviderMain
      value={{
        openChatOnGrape: (grape, params) => {
          retrieveGrapeAncestorsTryCache(client, grape).then((ancestors) => {
            const rootGrape = ancestors[0] || grape;
            setSelectedChatGrape(undefined);
            setRootGrape(rootGrape);
            setGrape(grape);
            setChatParams(params);
            setFrame((f) => ({ ...f, visible: true }));
          });
        },
      }}
    >
      {props.children}

      {rootGrape && grape ? (
        <Dialog
          // keepMounted
          open={frame.visible}
          onClose={onChatPanelClose}
          // css={{
          //   position: "fixed",
          //   top: 0,
          //   left: 0,
          //   right: 0,
          //   bottom: 0,
          //   zIndex: 1500,
          //   pointerEvents: frame.visible ? "all" : "none",
          //   opacity: frame.visible ? 1 : 0,
          // }}
        >
          {/* BACKGROUND CLOSING PANEL */}
          {/* <div
            css={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.3)" }}
            onClick={onChatPanelClose}
          /> */}

          {/* TOP BAR */}
          <div
            css={{
              background: "white",
              // position: "absolute",
              ...frame,
              display: "flex",
              flexDirection: "column",
              boxShadow: "0 4px 16px rgb(0 0 0 / 10%)",
              borderRadius: 3,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: 24,
                background: "#eee",
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
                justifyContent: "flex-end", // isMacOS ? "flex-start" : "flex-end",
              }}
            >
              <div
                className="material-icons"
                children="close"
                css={{
                  fontSize: 18,
                  padding: "0 8px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  ":hover": { background: "rgba(0, 0, 0, 0.12)" },
                }}
                onClick={onChatPanelClose}
              />
            </div>

            {/* BODY */}
            <div css={{ display: "flex", flex: 1 }}>
              <GrapeChatBody
                rootGrape={rootGrape}
                grape={grape!}
                selectedChatGrape={selectedChatGrape}
                isBackground={!frame.visible}
                onGrapeChatSelected={(chatGrape) => {
                  setSelectedChatGrape(chatGrape);
                }}
              />
            </div>
          </div>
        </Dialog>
      ) : undefined}
    </GrapesChatAppProviderMain>
  );
};

const GrapeChatBody = (props: {
  rootGrape: IGrape;
  grape: IGrape;
  selectedChatGrape?: IGrape;
  onGrapeChatSelected: (grapeChat: IGrape) => void;
  isBackground?: boolean;
}) => {
  const grapesApp = useGrapesApp();
  const theme = useTheme();
  const client = useApolloClient();
  const { rootGrape, isBackground } = props;
  const backgroundPollInterval = grapesPollInterval * 100;

  const { data: dataGrape, refetch: refetchGrape } = useSafeWindowPollingQuery(GET_GRAPE, {
    variables: { id: props.grape.id },
    pollInterval: isBackground ? backgroundPollInterval : grapesPollInterval,
  });
  const grape: IGrape = dataGrape?.allGrapes?.[0] || props.grape;

  const { data: dataDescendentChats, refetch: refetchDescent } = useSafeWindowPollingQuery(GRAPE_DESCENDENT_CHATS, {
    variables: { grapeId: rootGrape.id },
    pollInterval: isBackground ? backgroundPollInterval : grapesPollInterval,
  });

  // const { data: dataDescentChatNotifications } = useSafeWindowPollingQuery(GRAPE_DESCENDENT_CHAT_NOTIFICATIONS, {
  //   variables: { grapeId: rootGrape.id },
  //   pollInterval: isBackground ? backgroundPollInterval : grapesPollInterval,
  // });

  // useEffect(() => {
  //   if (grape.type === "chat") {
  //     setTimeout(() => client.reFetchObservableQueries(), 500);
  //   }
  // }, [grape.type]);

  // const allChatNotifications: IChatNotification[] = dataDescentChatNotifications?.allDescentChatNotifications || [];
  const allDescentChats: IMyGrape[] = dataDescendentChats?.allDescentChats || [];
  const chats: IGrape[] = grape.children?.filter((x) => x?.type === "chat") || [];
  const subChats: IMyGrape[] = allDescentChats.filter((x) => !chats.filter((c) => c?.id === x?.id).length);
  // const chatNotifications = allChatNotifications.filter((x) => chats.filter((c) => c?.id === x?.id).length);
  // const subChatNotifications = allChatNotifications.filter((x) => subChats.filter((c) => c?.id === x?.id).length);
  // const allChats = [...chats, ...subChats].sort((a, b) => (a.parent === rootGrape.id ? -1 : b.parent === rootGrape.id ? 1 : 0));
  const allChats = [...chats, ...subChats].sort((a, b) => {
    if (!a?.children?.length) return -1;
    if (!b?.children?.length) return 1;
    const msgsa = getMessagesAndFiles(a.children, a.files);
    const msgsb = getMessagesAndFiles(b.children, b.files);
    const msga = msgsa[msgsa.length - 1];
    const msgb = msgsb[msgsb.length - 1];
    // const msga = a.children[0];
    // const msgb = b.children[0];
    const da = moment(msga.created_at);
    const db = moment(msgb.created_at);
    // console.log(9999, 2, da, db);
    return -da.diff(db);
  });

  /// --------------------------
  //? find the correct grape
  let selectedChatGrape: IGrape | undefined;
  if (props.selectedChatGrape) {
    selectedChatGrape = props.selectedChatGrape;
  } else {
    selectedChatGrape = allChats.find((c) => c.parent === grape?.id);
  }

  // read notification
  useEffect(() => {
    if (grape && hasNewChatMessagesForUser(grape, grapesApp.user.id)) {
      setAccessToChatByUser(client, grape, grapesApp.user.id, moment().toISOString());
    }
  }, [grape]);

  return (
    <Grid container direction="row" css={{ height: "100%" }}>
      <Grid item css={{ width: 200, borderRight: "1px solid rgba(0, 0, 0, 0.2)" }}>
        <Grid container direction="column">
          {allChats.map((chat, index) => {
            const newMessages = hasNewChatMessagesForUser(chat, grapesApp.user.id);
            const isChatSelected = chat.id === selectedChatGrape?.id;
            // const chatMessages = getMessagesAndFiles(chat.children || [], chat.files);
            return (
              <Box
                display="flex"
                className="hoverable"
                key={index}
                style={{
                  cursor: "pointer",
                  padding: 10,
                  borderTop: "1px solid #eee",
                  backgroundColor: isChatSelected ? "rgba(0, 0, 0, 0.06)" : undefined,
                  fontSize: 12,
                  maxHeight: 42,
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={() => props.onGrapeChatSelected(chat)}
              >
                <span
                  css={{ flex: 1 }}
                  dangerouslySetInnerHTML={{
                    __html: ((chat as any)?.ancestors || []).slice(-1)[0]?.title || grape.title,
                  }}
                />

                <div
                  style={{
                    width: 8,
                    height: 8,
                    marginLeft: 8,
                    textAlign: "center",
                    lineHeight: "24px",
                    borderRadius: 12,
                    backgroundColor: newMessages ? theme.palette.primary.main : undefined,
                  }}
                />
              </Box>
            );
          })}
        </Grid>
      </Grid>

      <Grid item xs css={{ overflow: "hidden", display: "flex", flexDirection: "column" }}>
        <DetailScaffold grape={selectedChatGrape || ({ type: "chat" } as any)} header={renderTopbarChat()}>
          {props.isBackground ? null : (
            <ChatGrape
              key={(selectedChatGrape || grape).id}
              parentGrape={grape}
              grape={selectedChatGrape}
              editGrape={(grapeId: string, prop: string, value: any, params: any) => {
                const changes: any = {};
                changes[prop] = value;
                Grapes.editGrape(client, grapeId, changes);
              }}
              onChatGrapeCreated={(chatGrape) => props.onGrapeChatSelected(chatGrape)}
            />
          )}
        </DetailScaffold>
      </Grid>
    </Grid>
  );

  function renderTopbarChat() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexShrink={1}
        style={{ borderBottom: "1px solid #eee", paddingLeft: 5, position: "relative", maxWidth: "300" }}
      >
        <Box
          display="flex"
          flexDirection="row"
          style={{
            minHeight: 21,
            position: "relative",
          }}
        >
          <Box
            flexGrow={1} // overflow="hidden"
            style={{ maxWidth: "100%" }}
            children={
              <GrapeCollaboratorsThumbs
                collaborators={[]}
                grape={grape}
                shouldFetchAnchestors
                onCollaboratorClick={(c) => {}}
                onInviteCollaboratorClick={() => Grapes.requestInviteToGrape(client, grape)}
                maxWidth="none"
              />
            }
          />

          <div style={{ paddingTop: 4 }}>
            {/* {renderShareGrapeButton()} */}

            <AddFileToGrape
              onFileChange={onFileChange}
              className="hoverable"
              style={{
                padding: "0 5px",
                margin: 0,
                marginLeft: 3,
                display: "inline-block",
                background: "transparent",
                width: 32,
                height: 25,
                cursor: "pointer",
                // opacity: 0.8,
              }}
              children={<AttachFileIcon style={{ width: 20 }} />}
            />
          </div>
        </Box>
      </Box>
    );
  }

  function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const filesToUpload: File[] = [];
    if (event.target.files && event.target.files.length) {
      for (var c = 0; c < event.target.files.length; c++) {
        const file: File = event.target.files[c];
        filesToUpload.push(file);
      }
    }
    if (filesToUpload.length) showFilesUploadDialog(client, selectedChatGrape!, filesToUpload);
  }
};

export const GrapeChatButton = (props: { grape: IGrape; className?: string; disableFetches?: boolean }) => {
  const { grape } = props;
  const grapesChatApp = useGrapesChatApp();
  const theme = useTheme();

  const { data: dataDescendentChats } = useSafeWindowPollingQuery(GRAPE_DESCENDENT_CHATS, {
    variables: { grapeId: props.grape.id },
    // pollInterval: grapesPollInterval,
    skip: props.disableFetches,
  });

  // const { data: dataDescentChatNotifications } = useSafeWindowPollingQuery(GRAPE_DESCENDENT_CHAT_NOTIFICATIONS, {
  //   variables: { grapeId: grape.id },
  //   pollInterval: grapesPollInterval,
  //   skip: props.disableFetches,
  // });

  // useEffect(() => {
  //   if (grape.type === "chat") {
  //     setTimeout(() => client.reFetchObservableQueries(), 500);
  //   }
  // }, [grape.type]);

  // const allChatNotifications: IChatNotification[] = dataDescentChatNotifications?.allDescentChatNotifications || [];
  const allDescentChats: IMyGrape[] = dataDescendentChats?.allDescentChats || [];
  //todo if [disableFetches] retrieve allDescentChats from graphql cache of anchestor <GrapeChatButton />
  const chats: IGrape[] = grape.children?.filter((x) => x?.type === "chat") || [];
  const subChats: IMyGrape[] = allDescentChats.filter((x) => !chats.filter((c) => c?.id === x?.id).length);
  // const chatNotifications = allChatNotifications.filter((x) => chats.filter((c) => c?.id === x?.id).length);
  // const subChatNotifications = allChatNotifications.filter((x) => subChats.filter((c) => c?.id === x?.id).length);
  const allChats = [...chats, ...subChats];

  return (
    <div
      css={{
        marginLeft: 8,
        display: "flex",
        alignItems: "center",
        padding: 6,
      }}
      className={(props.className || "") + " hoverable"}
      style={{ marginLeft: 5 }}
      children={
        // allChatNotifications.length ? (
        //   <MessagesCountIcon children={allChatNotifications.length} />
        // ) :
        <i
          className="material-icons"
          children="textsms"
          style={{
            marginRight: 5,
            fontSize: 16,
            color: allChats.length ? theme.palette.primary.main : "#444",
            transform: "scale(-1.1, 1.1)",
          }}
        />
      }
      onClick={() => {
        grapesChatApp.openChatOnGrape(grape);
      }}
    />
  );
};

export const setAccessToChatByUser = (client: ApolloClient<any>, chatGrape: IGrape, userId: string, date: string) => {
  const lastAccess = getAccessToChatForUser(chatGrape, userId);
  if (date && lastAccess !== date) {
    Grapes.setSetting(client, chatGrape, `read_${userId}`, date);
  }
};

export const getAccessToChatForUser = (chatGrape: IGrape, userId: string): string => {
  return Grapes.getSetting(chatGrape, `read_${userId}`);
};

export const hasNewChatMessagesForUser = (chatGrape: IGrape, userId: string) => {
  const lastAccessToChat = getAccessToChatForUser(chatGrape, userId);
  if (!lastAccessToChat) return true;

  const messages = getMessagesAndFiles(chatGrape.children || [], chatGrape.files);
  if (messages.length === 0) return false;

  const lastAccessDate = new Date(lastAccessToChat);
  const lastChatMessageDate = new Date(messages[0].created_at);
  return lastAccessDate < lastChatMessageDate;
};
