import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#8764b8",
      contrastText: "#ffffff",
    },
    text: {
      primary: "rgba(0,0,0,0.87)",
      secondary: "rgba(0,0,0,0.6)",
    },
  },
  typography: {
    fontFamily: "Nunito Sans",
    allVariants: {
      fontFamily: "Nunito Sans",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 20,
      },
    },
  },
});
