/** @jsxImportSource @emotion/react */

import { useApolloClient } from "@apollo/react-hooks";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import React from "react";
import Grapes from "../../../modules/Grapes";
import { showInputDialog } from "../../../utils";
import { IGrape } from "../../grape_gql_interface";
import { ItemSelector } from "../../input/ItemSelector";
import MLDialog from "../../MLDialog";
import { useGrapeDetailContextSelector } from "../GrapeDetail/hooks/GrapeDetailContext";
import { SprintEditorModal } from "./components/SprintModalEditor";

export const BoardSprintManager = (props: { grape: IGrape }) => {
  const client = useApolloClient();
  const childrenFilter = useGrapeDetailContextSelector("childrenFilter");
  const setChildrenFilter = useGrapeDetailContextSelector("setChildrenFilter");

  const [editModal, setEditModal] = React.useState<IGrape>();

  const allSprints = (props.grape.children || []).filter((g) => g.type === "sprint");
  const selectedSprintId = childrenFilter?.sprintId;
  const setSelectedSprint = (sprintId: string | undefined) => setChildrenFilter({ ...childrenFilter, sprintId });

  return (
    <React.Fragment>
      <ItemSelector
        list={allSprints}
        getId={(sprint) => sprint.id}
        placeholder="Sprint "
        renderItem={(sprint, isOpen) => (
          <SprintItem
            sprint={sprint}
            isOpen={isOpen}
            onEditPressed={() => {
              // setEditModal({ ...sprint, shown: new Date().getTime() } as any);
              setEditModal(sprint);
            }}
          />
        )}
        selectedId={selectedSprintId}
        css={{ marginLeft: 4, marginTop: 2, padding: 2 }}
        onChanged={(sprintId, sprint) => setSelectedSprint(sprintId)}
        onAddPressed={() => {
          showInputDialog("Nome dello sprint", "Che nome vuoi dare a questo sprint?", "Scrivi qui...", (title) => {
            if ((title || "").trim() !== "") {
              const exists = allSprints.find((sprint) => (sprint?.title || "").toLowerCase() === title);
              if (exists) {
                MLDialog.showSnackbar("Esiste già uno sprint con questo nome", { variant: "error" });
                return;
              }

              Grapes.addGrape(client, props.grape.id, title, {
                type: "sprint",
              }).then((resp) => {
                const newGrape = resp.data.createGrape;
                // setSelectedSprint(newGrape.id);
              });
            }
          });
          return false;
        }}
      />

      <SprintEditorModal
        sprint={editModal}
        onClose={() => setEditModal(undefined)}
        onEditSave={(changes) => {
          return Grapes.editGrape(client, editModal!.id, changes).then(() => {
            setEditModal(undefined);
          });
        }}
      />
    </React.Fragment>
  );
};

const SprintItem = (props: { sprint: IGrape; isOpen: boolean; onEditPressed: () => void }) => {
  const start = props.sprint?.date0 && moment(props.sprint.date0).format("DD/MM");
  const end = props.sprint?.date && moment(props.sprint.date).format("DD/MM");

  return (
    <div
      css={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 4,
      }}
    >
      <div css={{ flex: 1 }} children={props.sprint.title} />

      {props.isOpen ? (
        <React.Fragment>
          <div
            css={{
              marginLeft: 12,
              lineHeight: 1,
              padding: 4,
              border: start || end ? "1px solid #ccc" : undefined,
              borderRadius: 3,
            }}
          >
            <div css={{ fontWeight: "bold", color: "green" }} children={start || ""} />
            {/* {start && end ? " → " : ""} */}
            <div css={{ fontWeight: "bold", color: "red" }} children={end || ""} />
          </div>
          <MoreHorizIcon
            css={{ marginLeft: 12, padding: 4, fontSize: 20 }}
            className="hoverable"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onEditPressed();
            }}
          />
        </React.Fragment>
      ) : null}
    </div>
  );
};
