/** @jsxImportSource @emotion/react */
import { Box } from "@material-ui/core";
import React from "react";
import { isValidURL, openAppUrlIfExists, stripTags } from "../utils";

var showdown = require("showdown");
var TurndownService = require("turndown").default;
var tldParser = require("tld-extract");

export default class DescriptionInput extends React.Component<{
  id: string;
  text: string;
  placeholder?: string;
  itemRef?: any;
  style?: React.CSSProperties;
  onChange: (text: string) => any;
  onFocus?: () => void;
  onBlur?: () => void;
  onPaste?: (event: React.ClipboardEvent) => boolean | void;
  className?: string;
}> {
  grapeTitleFocus: boolean = false;

  shouldComponentUpdate() {
    return !this.grapeTitleFocus;
  }

  render() {
    const { text, id, placeholder, style, onChange, ...otherProps } = this.props;
    const cleanText = text === "<br>" ? "" : text;
    return (
      <Box
        itemRef="input"
        {...otherProps}
        flexGrow={1}
        id={this.props.id}
        contentEditable={true}
        alignSelf="center"
        data-placeholder={this.props.placeholder || "..."}
        suppressContentEditableWarning={true}
        css={{ a: { cursor: "pointer" } }}
        style={{
          font: "inherit",
          color: "#333",
          whiteSpace: "pre-line",
          ...(this.props.style || {}),
        }}
        dangerouslySetInnerHTML={{ __html: cleanText }}
        // children={cleanText}
        onInput={(e: any) => {
          // console.log("before", cleanText);
          // console.log("after", e.nativeEvent.target.innerHTML);
          this.props.onChange(e.nativeEvent.target.innerHTML);
        }}
        onKeyPress={(e: React.KeyboardEvent) => {
          //? disable Enter+Shift
          if (e.code === "Enter" && e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            // const pos = getCaretPosition(e.target);
            document.execCommand("insertText", false, "\n");
            return false;
          }
        }}
        onFocus={() => {
          this.grapeTitleFocus = true;
          try {
            otherProps?.onFocus?.();
          } catch (e) {}
        }}
        onBlur={() => {
          this.grapeTitleFocus = false;
          try {
            otherProps?.onBlur?.();
          } catch (e) {}
        }}
        // onPaste={(event: React.ClipboardEvent) => onPasteHandler(event)}
        onPaste={(e) => {
          if (this.props.onPaste?.(e)) {
            return;
          }

          onPasteEventParseFormattedText(e);
          // onPasteHandlerURL(e);

          // const newText = onPasteHandlerURL(e);
          // if (newText) this.props.onChange?.(newText);
        }}
        onClick={(e) => {
          const element = (e.target as any) ?? {};
          // if (element.nodeName?.toLowerCase() === "a" && element.href?.length && e.ctrlKey) {
          if (element.nodeName?.toLowerCase() === "a" && element.href?.length) {
            const url = (element.href as string).trim();
            let appUrl: string | undefined = undefined;

            console.log("🔗", url);

            const prefixFigma = "https://www.figma.com/";

            if (url.startsWith(prefixFigma)) {
              appUrl = "figma://" + url.substring(prefixFigma.length);
            }

            //? no appUrl to try open ?
            if (appUrl == undefined || appUrl.trim() == "") {
              (window as any)?.open(url, "_blank")?.focus();
              return;
            }

            //? try opening appUrl
            openAppUrlIfExists(appUrl, url);
          }
        }}
      />
    );
  }
}

export const onPasteEventParseFormattedText = (event: React.ClipboardEvent) => {
  let data = event.clipboardData.getData("text/html") || event.clipboardData.getData("text/plain");
  event.preventDefault();

  //? parse strange text
  data = data.replace(/\<(\?xml|(\!DOCTYPE[^\>\[]+(\[[^\]]+)?))+[^>]+\>/g, "");
  data = data.replace(/(<style[\w\W]+style>)/g, ""); //<-------
  data = stripTags(data, "<b><b/><i></i><ul></ul><ol></ol><li></li><br><br/>");
  data = data.replace("<br>", "");
  data = data.replace(/style="[^"]*"/g, "");

  //? shorten urls
  // onPasteHandlerURL(event);
  let match = data.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);
  let final = data.trim();

  if (match) {
    match.map((url: string) => {
      let shortUrl = url;
      let maxLength = 20;
      if (url.length > maxLength) {
        // shortUrl = url.replace(/^https?:\/\//, "").substring(0, 8) + "…" + url.substring(url.length - 8);
        try {
          const domain = tldParser(url).domain;
          const index = url.length - (maxLength - domain.length);
          shortUrl = domain + "/…" + url.substring(index);
        } catch (e) {
          shortUrl = url.length > maxLength ? url.substring(0, maxLength) + "…" : url;
        }
      }

      final = final.replace(url, '<a href="' + url + '" target="_BLANK">' + shortUrl + "</a>");
    });
    data = final;
  }

  //? insert data to input
  document.execCommand("insertHTML", false, data);
};

export const onPasteHandlerURL = (e: React.ClipboardEvent) => {
  const srcText = (e.clipboardData.getData("text/plain") || "").trim();

  if (!isValidURL(srcText)) {
    return false;
  }

  const maxLength = 20;
  const prefix = "link: …";

  try {
    e.preventDefault();

    let url = srcText;
    let anchor = srcText;
    if (srcText.length > maxLength + prefix.length) {
      // anchor = srcText.substring(0, 5) + '…' + srcText.substring(srcText.length - 5);
      anchor = prefix + srcText.substring(srcText.length - maxLength - prefix.length);
      try {
        const domain = tldParser(url).domain;
        // const index = Math.max(url.length - maxLength - domain.length, url.length - maxLength);
        const index = url.length - (maxLength - domain.length);
        anchor = domain + "/…" + url.substring(index);
      } catch (tld_err) {}
    }
    const anchorHTML = `<a href="${url}" target="_blank">${anchor}</a>`;

    document.execCommand("insertHTML", false, anchorHTML);
    return url;
  } catch (ex) {
    console.log("🖍 err parsing paste ", ex);
  }
  return false;
};
