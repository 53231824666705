import { animated, useSpring } from "@react-spring/web";
import React from "react";
import { useNotifierValue } from "react-use-notifier";
import { getUserColor } from "../../../modules/UserUtils";
import { getContrastYIQ } from "../../GrapeLabels";
import { IUser } from "../../User";
import { useSafeGrapeDetailContextSelector } from "../GrapeDetail/hooks/GrapeDetailContext";
import { IGrapesMouseMoveSocketEvent } from "./usersSocketController";

export const GrapeUserPointers = (props: {}) => {
  const subPath = useSafeGrapeDetailContextSelector("subPath");
  const socketController = useSafeGrapeDetailContextSelector("socketController");

  const pointers = useNotifierValue(socketController.pointers);
  const showPointers = useNotifierValue(socketController.showPointers);

  return (
    <React.Fragment>
      {/* <div
        css={{
          // position: "fixed",
          // top: props.rect.top,
          // left: props.rect.left,
          // width: "100vw",
          // height: props.rect.height,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          // position: "absolute",
          // top: 0,
          // left: 0,
          // right: 0,
          // height: props.rect.height,
          pointerEvents: "none",
          zIndex: 100,
          backgroundColor: "rgba(255, 100, 0, 0.5)",
          overflow: "hidden",
        }}
      > */}
      {showPointers
        ? pointers?.map((user, index) => <GrapeUserPointer key={user.user.id} subPath={subPath} state={user} />)
        : null}
      {/* </div> */}
    </React.Fragment>
  );
};

export const GrapeUserPointer = (props: { subPath: string; state: IGrapesMouseMoveSocketEvent }) => {
  const { subPath, state } = props;
  const pointerWidth = 27;
  const differentSubPath = state.subPath !== subPath;

  const position = useSpring({
    x: props.state.x,
    y: props.state.y,
    config: {
      mass: 1,
      tension: 700,
      friction: 50,
    },
  });

  return (
    <animated.div
      style={{
        position: "absolute",
        pointerEvents: "none",
        zIndex: 100,
        width: pointerWidth,
        height: pointerWidth,
        top: -pointerWidth * 0.18,
        left: -pointerWidth * 0.35,
        opacity: differentSubPath ? 0 : state.state === "afk" ? 0.15 : 1,
        // x: state.x,
        // y: state.y,
        ...position,
      }}
    >
      <FloatingPointer user={state.user} width={pointerWidth} />
    </animated.div>
  );
};

export const getUserNickname = (user: IUser) => {
  let nickname = ((user.name || "") + (user.surname || "") + (user.email || ""))
    .substring(0, 4)
    .trim()
    .toLocaleLowerCase();

  if (user.email === "tiziano@mabiloft.com") nickname = "tiz";
  else if (user.email === "martina@mabiloft.com") nickname = "mem";
  else if (nickname === "beat") nickname = "bea";
  else if (nickname === "giul") nickname = "giulio";
  else if (nickname === "fran") nickname = "fra";
  else if (nickname === "mich") nickname = "mike";
  return nickname;
};

const FloatingPointer = (props: { user: IUser; width: number }) => {
  const userColor = getUserColor(props.user);
  const textColor = getContrastYIQ(userColor);

  const svgWidth = 42;
  const svgHeight = 39;

  const nickname = getUserNickname(props.user);

  return (
    <div css={{ position: "relative" }}>
      <div
        css={{
          position: "absolute",
          color: textColor,
          top: 15,
          left: 35,
          padding: "3px 6px",
          fontFamily: "monospace",
          fontWeight: "bold",
          backgroundColor: userColor,
          textTransform: "capitalize",
        }}
        children={nickname}
      />

      <svg
        width={svgWidth}
        height={svgHeight}
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        css={{
          width: props.width,
          height: (svgHeight / svgWidth) * props.width * 1.2,
          transform: "rotate(-45deg)",
        }}
      >
        <path
          d="M15.1059 20.3658C14.0796 20.3471 13.6124 19.0763 14.3823 18.3973L30.3779 4.2904C30.7412 3.97006 31.3125 4.22796 31.3125 4.71228V26.0399C31.3125 27.0665 30.0504 27.5567 29.3575 26.7993L25.8421 22.957C25.5694 22.6588 25.0806 22.737 24.9144 23.1053L22.9171 27.5324C22.4061 28.6649 21.0738 29.1687 19.9413 28.6577C18.8091 28.1467 18.3054 26.8146 18.8162 25.6823L20.8139 21.2544C20.9801 20.8861 20.7154 20.468 20.3114 20.4606L15.1059 20.3658Z"
          fill={userColor}
        />
      </svg>
    </div>
  );
};
