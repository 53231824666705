import ApolloClient from "apollo-client";
import moment from "moment";
import { IGrape } from "../grape_gql_interface";
import { IUser } from "../User";
import { GrapesPlugin } from "./types";

export default class PresenzePlugin extends GrapesPlugin {
  static title = "Presenze";

  onInvoked(grape: IGrape, user: IUser, client: ApolloClient<any>): void {
    const monthsIT = [
      "gennaio",
      "febbraio",
      "marzo",
      "aprile",
      "maggio",
      "giugno",
      "luglio",
      "agosto",
      "settembre",
      "ottobre",
      "novembre",
      "dicembre",
    ];

    let month: number | undefined;
    for (let m = 0; m < monthsIT.length; m++) {
      if (grape.title.toLowerCase().includes(monthsIT[m])) {
        month = m;
        break;
      }
    }
    if (month == null) {
      alert("il titolo\n\n[ " + grape.title + " ]\n\n" + "non contiene un mese valido");
      return;
    }

    const yearString = this.extractNumbers(grape.title);
    if (!yearString) {
      alert("il titolo\n\n[ " + grape.title + " ]\n\n" + "non contiene un anno valido (es. 2018)");
      return;
    }
    const year: number = parseInt(yearString);

    function downloadAsFile(filename: string, data: string) {
      const blob = new Blob([data], { type: "text/csv" });
      if ((window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveBlob(blob, filename);
      } else {
        const elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    }

    let result = "";
    let date = moment({ year, month });
    do {
      result += date.weekday() == 5 || date.weekday() == 6 ? "" : "8";
      result += "\t";
      date = date.add(1, "days");
    } while (date.month() == month);

    downloadAsFile(monthsIT[month] + "_" + year, result);
  }

  extractNumbers(str: string) {
    var m = /(^|\s)(\d{4})(\s|$)/.exec(str);
    if (m) {
      return m[2];
    }
  }
}
