/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { Box, Button, fade, Grid, LinearProgress, Tooltip, useTheme } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment, { Moment } from "moment";
import { useRef, useState } from "react";
import { GrapeDetailOpenParams } from "./GrapeDetail";
import { useGrapesApp } from "../../App";
import Grapes, { DELETE_GRAPE } from "../../modules/Grapes";
import { cleanHTMLTags, showConfirmDialog, showInputDialog } from "../../utils";
import { __DEV__ } from "../../utils/init";
import AddTextField from "../AddTextField";
import Contextable from "../Contextable";
import CustomTooltip from "../CustomTooltip";
import DescriptionInput from "../DescriptionInput";
import Grape from "../Grape";
import GrapeLabels from "../GrapeLabels";
import { GET_GRAPE, IGrape, IUserGrapePermission } from "../grape_gql_interface";
import MLDialog from "../MLDialog";
import { PromiseItem, PromisesDispatcherTool } from "../../modules/utilities/PromisesDispatcherTool";
import { boardGrapeSpecs, BoardStructure } from "./GrapeBoard/BoardBody";
import { serializeBoardStructure } from "./GrapeBoard/BoardBody_bk";
import DetailScaffold from "./DetailScaffold";
import { HIDE_COMPLETED_GRAPES, RESTORE_COMPLETED_GRAPES } from "./core/gql_interface";
import { GrapeComments } from "./GrapeComments/GrapeComments";
import { GrapeDetailToolbar } from "./GrapeDetail/GrapeDetailToolbar";
import { BoardSprintManager } from "./GrapeBoard/BoardSprintManager";
import { IRenderGrapesColumnParams } from "./GrapeDetail/hooks/GrapeDetailRenderColumn";
import { hideGrapesFromChildren } from "./core/grapeTypes";
import ApolloClient from "apollo-client";

interface IKanbanGrape {
  editGrape: (grapeId: string, prop: string, value: any, props?: any) => void;
  renderGrapesColumn: (child: IGrape, children: IGrape[], params?: IRenderGrapesColumnParams) => any;
  subPath?: string;
  allLabels?: string[];
  onSubPathChange?: (subpath: string) => void;
  grape: IGrape;
  onRequestOpen?: (item: IGrape, params?: GrapeDetailOpenParams) => void;
  collaborators?: IUserGrapePermission[];
  childrens: IGrape[];
  anchestors: IGrape[];
}

const { columnWidth, columnPadding, horizontalPadding, borderRadius } = boardGrapeSpecs;

const KanbanGrape = (props: IKanbanGrape) => {
  const { editGrape } = props;
  const theme = useTheme();
  const grapesApp = useGrapesApp();

  const [loading, enqueueLoading] = useLoading();

  const client = useApolloClient();
  const contextables = useRef<any>({});
  const showBacklog = props.subPath === "backlog";
  const setShowBacklog = (backlog: boolean) => props.onSubPathChange?.(backlog ? "backlog" : "");

  // const allLabels = props.childrens.reduce((g, c, acc) => [...g, ...getAllFoundLabels(c)]);
  // const kanbanColumns = getKanbanChildrens(props.grape).filter(
  //   (g) => !getKanbanSetting(g, "column-hidden", false) || showBacklog
  // );
  const kanbanColumns = getKanbanChildrens(props.grape).filter(
    (g) => !getKanbanSetting(g, "column-hidden", false) || showBacklog
  );

  const shiftProgressGrapes = (params: { fromIndex: number; delta: number }) => {
    const shiftingPromises = [];
    for (let colIndex = 0; colIndex < kanbanColumns.length; colIndex++) {
      for (let index = 0; index < kanbanColumns[colIndex].children.length; index++) {
        if (kanbanColumns[colIndex].children[index].type === "progress") {
          const progressChildren = kanbanColumns[colIndex].children[index].children;
          for (let i = 0; i < progressChildren.length; i++) {
            if (progressChildren[i].cross_order >= params.fromIndex) {
              const cross_order = Math.round(progressChildren[i].cross_order + params.delta);
              shiftingPromises.push(Grapes.editGrape(client, progressChildren[i].id, { cross_order }));
            }
          }
        }
      }
    }
    enqueueLoading(Promise.all(shiftingPromises));
  };

  //cross_order
  const addKanbanColumn = (index: number, main_order: number) => {
    showInputDialog("Nuova colonna", "Nome della nuova colonna", undefined, (title) => {
      if (!title) return;
      shiftProgressGrapes({ fromIndex: index, delta: 1 });
      enqueueLoading(Grapes.addGrape(client, props.grape.id, title, { main_order }));
    });
  };

  const openKanbanColumnSettings = (e: any, index: number) => {
    contextables.current[index]?.open(e);
  };

  return (
    <DetailScaffold
      grape={props.grape}
      header={
        <GrapeDetailToolbar
          actions={
            showBacklog || kanbanColumns?.length !== props.childrens?.length
              ? [
                  {
                    color: theme.palette.primary.main,
                    // color: showBacklog ? undefined : theme.palette.primary.main,
                    tooltip: showBacklog ? "Hide backlog" : "Backlog",
                    icon: showBacklog ? "grid_off" : "grid_on",
                    onClick: () => setShowBacklog(!showBacklog),
                  },
                ]
              : undefined
          }
          trailing={<BoardSprintManager grape={props.grape} />}
        />
      }
    >
      {loading ? <LinearProgress css={{ position: "absolute", top: 0, left: 0, right: 0 }} /> : null}

      {/* {__DEV__ || ["tiziano@mabiloft.com", "eddy@mabiloft.com"].includes(grapesApp.user.email)
        ? renderMigrationTool()
        : null} */}

      {kanbanColumns.length > 0 ? (
        <KanbanGridBody
          params={props}
          backlog={showBacklog}
          kanbanColumns={kanbanColumns}
          renderHeaders={renderHeaders}
        />
      ) : (
        renderEmptyColumns()
      )}
    </DetailScaffold>
  );

  function renderEmptyColumns() {
    return (
      <Button
        css={{ margin: 8 }}
        variant="outlined"
        children="Create the first column"
        onClick={() => {
          enqueueLoading(Grapes.addGrape(client, props.grape.id, "TODO", { main_order: 0 }));
        }}
      />
    );
  }

  function moveColumnBy(index: number, delta: number) {
    const grapeSource = kanbanColumns[index];
    const grapeTarget = kanbanColumns[index + delta];
    if (!grapeSource || !grapeTarget) {
      MLDialog.showSnackbar("cannot move column: ${grapeSource} to ${grapeTarget}");
      return;
    }

    let mainToOrder = grapeTarget.main_order;
    let targetToOrder = grapeSource.main_order;
    if (mainToOrder === targetToOrder) mainToOrder += delta;

    editGrape(grapeSource.id, "main_order", mainToOrder);
    editGrape(grapeTarget.id, "main_order", targetToOrder, [{ query: GET_GRAPE, variables: { id: props.grape.id } }]); //!wip parent id
  }

  function setKanbanSetting(grape: IGrape, setting: string, value: string | boolean) {
    Grapes.setSetting(client, grape, "kanban-" + setting, value);
    // const settings: any = grape.settings || {};
    // settings["kanban-" + setting] = value;
    // props.editGrape(grape.id, "settings", settings);
  }

  function restoreCompletedTasks(grape: IGrape, fromDate?: Moment) {
    client
      .mutate({
        mutation: RESTORE_COMPLETED_GRAPES,
        variables: { parentId: grape.id, fromDate },
        refetchQueries: [{ query: GET_GRAPE, variables: { id: grape.id } }],
        awaitRefetchQueries: true,
      })
      .then(() => console.log("done"));
  }

  function renderHeaders() {
    if (!kanbanColumns?.length) return null;
    return (
      <div
        css={{
          left: 0,
          right: 0,
          top: 0,
          position: "sticky",
          paddingRight: horizontalPadding,
          // backgroundColor: "white",
          zIndex: 2,
        }}
      >
        <div css={{ display: "flex" }}>
          {/* <div css={{ position: "relative", flex: 1, display: "flex", flexDirection: "row" }}> */}
          {kanbanColumns.map((child: IGrape, index: number) => {
            const prevIndex: number = ((index > 0 ? kanbanColumns[index - 1].main_order : 0) + child.main_order) / 2;
            const nextIndex: number =
              ((index < kanbanColumns.length - 1 ? kanbanColumns[index + 1].main_order : child.main_order + 40) +
                child.main_order) /
              2;

            const expandChildrens = shouldExpandChildrens(child);
            const isHidden = getKanbanSetting(child, "column-hidden", false);
            const todoModeEnabled = child.type === "todo";

            const totStorypoints = child.virtualStorypoints || 0; // || child.children?.reduce((n, { storypoints }) => n + (storypoints || 0), 0);

            return (
              <Box
                flex={1}
                key={child.id}
                display="flex"
                flexDirection="column"
                style={{
                  padding: columnPadding / 2,
                  paddingTop: columnPadding + columnPadding / 2,
                  paddingBottom: 0,
                  backgroundColor: "white",
                  width: columnWidth,
                  whiteSpace: "initial",
                  position: "relative",
                }}
              >
                <div
                  css={{
                    flex: 1,
                    // boxShadow: "0 0 1px blue",
                    backgroundColor: isHidden ? fade(theme.palette.primary.main, 0.1) : "rgba(0, 0, 0,0.026)",
                    padding: "4px 0",
                    borderTopLeftRadius: borderRadius,
                    borderTopRightRadius: borderRadius,

                    ".kanban-add-column-btn": {
                      top: 13,
                      display: "inline-block",
                      width: 24,
                      height: 24,
                      // borderRadius: 12,
                      borderRadius: 5,
                      opacity: 0,
                      transition: "opacity 250ms",
                      pointerEvents: "none",
                      textAlign: "center",
                      lineHeight: "20px",
                      fontSize: "20px",
                      cursor: "pointer",
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      position: "absolute",
                      zIndex: 1,
                      "&.to-left": { left: -13 },
                      "&.to-right": { right: -13 },
                      "::before": { content: "'+'" },
                      ":hover": { backgroundColor: "#eee" },
                    },
                    ".kanban-column-settings, .kanban-column-storypoints": {
                      top: 13,
                      display: "flex",
                      position: "absolute",
                      right: 20,
                      width: 24,
                      height: 24,
                      borderRadius: 16,
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid transparent",
                      margin: "auto",
                      cursor: "pointer",
                      // backgroundColor: "white",
                      // border: "1px solid #ccc",
                      pointerEvents: "none",
                      opacity: 0,
                      transition: "opacity 250ms",
                      ":hover": { backgroundColor: "#eee" },
                    },
                    ".kanban-column-storypoints": {
                      fontSize: 11,
                      fontWeight: "bold",
                      right: "auto",
                      left: 20,
                      backgroundColor: "white",
                      borderColor: "#ccc",
                      opacity: 1,
                      pointerEvents: "all",
                      color: "#ee9900",
                      // color: 'white',
                      // border: 'none',
                      // backgroundColor: '#ee9900',
                    },

                    ":hover": {
                      ".kanban-add-column-btn, .kanban-column-settings": {
                        opacity: "1 !important" as any,
                        pointerEvents: "initial !important" as any,
                      },
                    },
                  }}
                >
                  <div className="kanban-add-column-btn to-left" onClick={() => addKanbanColumn(index, prevIndex)} />
                  <div className="kanban-add-column-btn to-right" onClick={() => addKanbanColumn(index, nextIndex)} />

                  {totStorypoints > 0 ? (
                    <Tooltip title="Column story points">
                      <div className="kanban-column-storypoints" children={totStorypoints} />
                    </Tooltip>
                  ) : null}

                  <div className="kanban-column-settings" onClick={(e) => openKanbanColumnSettings(e, index)}>
                    <MoreVertIcon fontSize="small" css={{ opacity: 0.8 }} />
                  </div>

                  <Contextable
                    innerRef={(r) => (contextables.current[index] = r)}
                    menuItems={[
                      {
                        key: "move",
                        hoverable: false,
                        caption: (
                          <div
                            css={{ flex: 1, display: "flex", flexDirection: "column", borderBottom: "1px solid #ccc" }}
                          >
                            <h6 children="Move" style={{ textAlign: "center", margin: 0, marginBottom: 4 }} />
                            <div
                              css={{
                                display: "flex",
                                flex: 1,
                                height: 30,
                                flexDirection: "row",
                                "> div": { paddingTop: 4 },
                              }}
                            >
                              <div
                                css={{ flex: 0.5, opacity: index > 0 ? 1 : 0 }}
                                className="hoverable"
                                children="← left"
                                onClick={() => index > 0 && moveColumnBy(index, -1)}
                              />
                              <div
                                css={{
                                  flex: 0.5,
                                  textAlign: "right",
                                  opacity: index < kanbanColumns.length - 1 ? 1 : 0,
                                }}
                                className="hoverable"
                                children="right →"
                                onClick={() => index < kanbanColumns.length - 1 && moveColumnBy(index, 1)}
                              />
                            </div>
                          </div>
                        ),
                      },
                      // { key: 'add-before', caption: 'Enter 1 on the left', onClick: () => addKanbanColumn(prevIndex) },
                      // { key: 'add-after', caption: 'Enter 1 on the right', onClick: () => addKanbanColumn(nextIndex) },
                      {
                        key: "todo-mode",
                        checked: todoModeEnabled,
                        caption: "Abilita todo list", //"Todo mode",
                        onClick: (params) => props.editGrape(child.id, "type", params?.checked ? "todo" : "list"),
                      },
                      {
                        key: "expand-childrens",
                        checked: expandChildrens,
                        caption: "Mostra anteprima lista",
                        onClick: (params) => setKanbanSetting(child, "column-collapse", !params?.checked),
                      },
                      {
                        key: "description",
                        caption: (child.description?.trim()?.length ? "Modifica" : "Aggiungi") + " descrizione",
                        onClick: () => {
                          const newDescription = prompt("Descrizione", child.description);
                          if (newDescription !== undefined && newDescription !== null) {
                            props.editGrape(child.id, "description", newDescription);
                          }
                        },
                      },
                      child.type === "todo" && {
                        key: "hide-tasks",
                        caption: "Nascondi task completati",
                        onClick: () => {
                          client.mutate({
                            mutation: HIDE_COMPLETED_GRAPES,
                            variables: { parentId: child.id },
                          });
                        },
                      },
                      child.type === "todo" && {
                        key: "resume-tasks",
                        caption: "Ripristina task completati",
                        subMenuItems: [
                          {
                            key: "resume-tasks-today",
                            caption: "Oggi",
                            onClick: () => restoreCompletedTasks(child, moment().subtract(1, "days").endOf("day")),
                          },
                          {
                            key: "resume-tasks-week",
                            caption: "Ultima settimana",
                            onClick: () => restoreCompletedTasks(child, moment().subtract(4, "days").startOf("week")),
                          },
                          {
                            key: "resume-tasks-all",
                            caption: "Tutti",
                            onClick: () => {
                              showConfirmDialog(
                                "Vuoi ripristinare tutti i task completati?",
                                "",
                                //calback
                                () => restoreCompletedTasks(child)
                              );
                            },
                          },
                        ],
                      },
                      {
                        key: "banana-dot",
                        caption:
                          (Grapes.getSetting(child, "no-banana-dot") ? "Abilita" : "Disabilita") + " invecchiamento 🍌",
                        onClick: () =>
                          Grapes.setSetting(client, child, "no-banana-dot", !Grapes.getSetting(child, "no-banana-dot")),
                      },
                      {
                        key: "hide-show",
                        caption: isHidden ? "Mostra sempre questa colonna" : "Nascondi colonna nel backlog",
                        onClick: () => {
                          shiftProgressGrapes({ fromIndex: index, delta: !isHidden ? -1 : 1 });
                          setKanbanSetting(child, "column-hidden", !isHidden);
                        },
                      },
                      {
                        key: "open-in-new-tab",
                        caption: "Apri grappolo colonna",
                        onClick: () => props.onRequestOpen?.(child),
                      },
                      {
                        key: "delete",
                        caption: "Elimina colonna",
                        color: "#de2000",
                        onClick: () => {
                          showConfirmDialog(`Vuoi eliminare la colonna "${child.title || " "}"?`, undefined, () => {
                            if (!isHidden) shiftProgressGrapes({ fromIndex: index, delta: -1 });
                            enqueueLoading(
                              client.mutate({
                                mutation: DELETE_GRAPE,
                                variables: { id: child.id },
                                refetchQueries: [{ query: GET_GRAPE, variables: { id: props.grape.id } }], //!wip parent id
                              })
                            );
                          });
                        },
                      },
                    ]}
                  >
                    <CustomTooltip
                      arrow
                      title={child.description || " "}
                      disableFocusListener={!child.description}
                      disableHoverListener={!child.description}
                      disableTouchListener={!child.description}
                      style={{ backgroundColor: "red" }}
                      css={
                        child.description
                          ? {
                              "::before": {
                                content: '""',
                                width: 6,
                                height: 6,
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 3,
                                display: "inline-block",
                                marginRight: 5,
                                marginBottom: 2,
                              },
                            }
                          : undefined
                      }
                    >
                      <DescriptionInput
                        id={"gid-title-" + child.id}
                        text={child.title}
                        placeholder="titolo..."
                        onChange={(text: string) => editGrape(child.id, "title", text, { withDelay: true })}
                        style={{
                          whiteSpace: "nowrap",
                          maxWidth: "100%",
                          overflow: "hidden",
                          // fontSize: 18,
                          fontSize: 15,
                          marginTop: 5,
                          marginBottom: 5,
                          textAlign: "center",
                          fontWeight: "bold",
                          color: isHidden ? theme.palette.primary.main : undefined,
                        }}
                      />
                    </CustomTooltip>
                  </Contextable>
                </div>
              </Box>
            );
          })}
          {/* </div> */}
        </div>
      </div>
    );
  }

  function renderMigrationTool() {
    return (
      <div
        css={{
          margin: "5px 15px",
          padding: 5,
          cursor: "pointer",
          background: "#ffaa22",
          width: 100,
          fontWeight: "bold",
          letterSpacing: 1,
          textAlign: "center",
          ":hover": { opacity: 0.7 },
        }}
        children="MIGRAPES"
        onClick={() => {
          for (let c = 0; c < props.childrens.length; c++) {
            for (let i = 0; i < props.childrens[c].children.length; i++) {
              if (props.childrens[c].children[i].type === "progress") {
                alert(
                  'Impossibile migrare alla nuova kanban board. Per favore cancella tutti i "progress" grapes prima.'
                );
                return;
              }
            }
          }

          showConfirmDialog(
            "Vuoi migrare alla nuova kanban?",
            "L'operazione non è facile da invertire, confermi?",
            () => migrateToNewKanbanBoard(client, props.grape)
          );
        }}
      />
    );
  }
};

export default KanbanGrape;

const KanbanGridBody = (props: {
  backlog?: boolean;
  params: IKanbanGrape;
  kanbanColumns: IGrape[];
  renderHeaders: () => any;
}) => {
  const { kanbanColumns } = props;

  const complexGrapes: IGrape[] = [];

  const structuralColumns = kanbanColumns.map((col) => ({
    ...col,
    children: props.backlog
      ? col.children
      : col.children?.filter((subGrape) => {
          // if (subGrape?.children?.length && subGrape.type === "todo") {
          if (subGrape?.children?.length >= 0 && subGrape.type === "progress") {
            complexGrapes.push(subGrape);
            return false;
          }
          return true;
        }),
  }));

  return (
    <Grid
      container
      direction="column"
      style={{
        // marginTop: columnPadding,
        margin: `0 ${horizontalPadding}px`,
        // width: Math.max(1, kanbanColumns.length) * (columnWidth + columnPadding * 2) + 32, // 30 + margin fix
        width: Math.max(1, kanbanColumns.length) * (columnWidth + columnPadding) + 2, // margin fix
      }}
    >
      {props.renderHeaders()}

      {complexGrapes.map((node, columnIndex) => {
        const shadowGrapes = structuralColumns.map((col, i) => ({
          ...node,
          type: col.type,
          //? refer to columnRef so we can get banana-dot param
          columnRef: col,
          children: node.children?.filter(
            //? use math-min to always show a column
            (nodeChild) => Math.min(nodeChild.cross_order, structuralColumns.length - 1) === i
          ),
        }));

        return (
          <Grid key={node.id} container direction="column">
            {renderHeader(
              <Grape
                grape={node}
                flat
                // listItem
                collaborators={props.params.collaborators}
                onRequestOpen={(g, params) => props.params.onRequestOpen?.(g || node, params)}
                anchestors={[...props.params.anchestors, node]}
                groupType={props.params.grape.type}
                style={{ backgroundColor: "transparent", borderBottom: "none" }}
              />
            )}
            {/* {renderHeader(node.title, node.labels)} */}
            <KanbanGridColumns attached={columnIndex === 0} group={node} columns={shadowGrapes} params={props.params} />
          </Grid>
        );
      })}

      {complexGrapes?.length ? renderHeader("Kanban") : null}
      <KanbanGridColumns attached={complexGrapes.length === 0} columns={structuralColumns} params={props.params} />

      <GrapeComments grape={props.params.grape} />
    </Grid>
  );

  function renderHeader(title: any, labels?: string[]) {
    const isString = typeof title === "string";
    return (
      <div
        css={{
          position: "sticky",
          zIndex: 1,
          top: 45,
          marginTop: 8,
          padding: "0 4px",
          backgroundColor: "white",
        }}
      >
        <div
          className={isString ? undefined : "hoverable"}
          //css={{ maxWidth: 400 }}
        >
          {labels?.length ? <GrapeLabels labels={labels} /> : null}
          {isString ? <div dangerouslySetInnerHTML={{ __html: title }} css={{ padding: "8px 4px" }} /> : title}
        </div>
      </div>
    );
  }
};

const KanbanGridColumns = (props: { params: IKanbanGrape; columns: IGrape[]; group?: IGrape; attached?: boolean }) => {
  const theme = useTheme();
  const client = useApolloClient();

  return (
    <Grid
      container
      item
      xs
      direction="row"
      style={{
        whiteSpace: "nowrap",
        // flex: 1,
        // width: Math.max(1, kanbanColumns.length) * (columnWidth + columnPadding * 2) + 32, // 30 + margin fix
      }}
    >
      {props.columns.map((child: IGrape, index: number) => {
        const isHidden = getKanbanSetting(child, "column-hidden", false);
        const paramsGrape: IGrape = props.group ? (child as any).columnRef : child;
        return (
          <Box
            key={props.group?.id + "_" + "_" + child.id + index}
            display="flex"
            flexDirection="column"
            style={{
              // padding: columnPadding,
              paddingTop: 0,
              // paddingTop: columnPadding,
              paddingLeft: columnPadding / 2,
              paddingRight: columnPadding / 2,
              paddingBottom: columnPadding,

              width: columnWidth,
              whiteSpace: "initial",
              // display: 'inline-block',
              position: "relative",
              // border: '3px solid red',

              // height: '100%',
              // overflow: 'hidden',
            }}
          >
            {/* <div css={{ height: columnHeadHeight, minWidth: 50, backgroundColor: "red" }} /> */}

            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              css={{
                paddingLeft: columnPadding / 2,
                paddingRight: columnPadding / 2,
                backgroundColor: isHidden ? fade(theme.palette.primary.main, 0.1) : "rgba(0, 0, 0,0.026)",
                borderTopLeftRadius: props.attached ? 0 : borderRadius,
                borderTopRightRadius: props.attached ? 0 : borderRadius,
                borderBottomLeftRadius: borderRadius,
                borderBottomRightRadius: borderRadius,
              }}
            >
              <Box flexGrow={1} display="flex" css={{ ".grapes-column-container": { paddingBottom: 8 } }}>
                {renderColumnChildrens(child, index, {
                  expandChildrens: shouldExpandChildrens(paramsGrape),
                  renderBananaDot: !Grapes.getSetting(paramsGrape, "no-banana-dot"),
                })}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Grid>
  );

  function renderColumnChildrens(
    child: IGrape,
    columnIndex: number,
    params: {
      expandChildrens: boolean;
      renderBananaDot: boolean;
    }
  ) {
    const { renderGrapesColumn, grape } = props.params;

    const droppableId = props.group ? `${props.group.id}_crossOrder_${columnIndex}` : undefined;

    return renderGrapesColumn(child, child.children, {
      droppableId: droppableId,
      expandChildrens: params.expandChildrens,
      renderBananaDot: params.renderBananaDot,
      anchestors: props.params.anchestors,
      distance: 8,
      trailComponent:
        !props.group || columnIndex === 0 ? (
          <AddTextField
            parentGrape={child}
            labels={props.params.allLabels}
            addToGrape={(title, variables) => Grapes.addGrape(client, child.id, title, { ...variables, type: "todo" })}
            withLabelSelector
          />
        ) : undefined,
      // withAddBtn
    });
  }
};

export function getKanbanSetting<T extends Object>(grape: IGrape, setting: string, defaultValue?: T) {
  return Grapes.getSetting(grape, "kanban-" + setting, defaultValue);
}

const shouldExpandChildrens = (col: IGrape) => !getKanbanSetting(col, "column-collapse");

const useLoading: () => [boolean, (thread: Promise<any>) => void] = () => {
  const [threads, setThreads] = useState(0);
  const enqueueLoading = (thread: Promise<any>) => {
    setThreads((t) => t + 1);
    const onDone = () => setThreads((t) => t - 1);
    thread.then(onDone).catch(onDone);
  };
  const loading = threads > 0;
  return [loading, enqueueLoading];
};

export const getKanbanChildrens = (grape: IGrape) => {
  return grape.children.filter((g) => {
    if (g?.type !== "column" && hideGrapesFromChildren.includes(g.type)) {
      return false;
    }
    return true;
  });
};

//!_________

export function migrateToNewKanbanBoard(client: ApolloClient<any>, grape: IGrape) {
  const promises: PromiseItem[] = [];
  const childrens = getKanbanChildrens(grape);

  if (false) {
    MLDialog.showModal(
      "MIGRAPING",
      <PromisesDispatcherTool
        promises={[
          {
            name: "tool1",
            promise: () =>
              new Promise((resolve, reject) => {
                setTimeout(() => resolve(123), 500);
                // throw "e";
              }),
          },
          {
            name: "tool2",
            promise: () =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  reject("er99");
                }, 500);
              }),
          },
          {
            name: "tool3",
            promise: () =>
              new Promise((resolve, reject) => {
                setTimeout(() => resolve("x3x2x45xab"), 500);
              }),
          },
        ]}
      />
    );
    return;
  }

  Grapes.editGrape(client, grape.id, { type: "board" }).then(() => {
    const structIds: { [x: string]: number } = {};
    const struct: BoardStructure = {
      columns: childrens.map((g, i) => {
        const newColId: number = i;
        structIds[g.id] = newColId;

        // g.children.map((child) => {
        //   Grapes.editGrape(client, child.id, { cross_order: newColId, parent: props.grape.id });
        // });
        for (let i = 0; i < g.children.length; i++) {
          const child = g.children[i];
          promises.push({
            name: "edit: " + cleanHTMLTags(child.title),
            promise: async () => {
              await Grapes.editGrape(client, child.id, { cross_order: newColId, parent: grape.id });
              return true;
            },
          });
        }

        return {
          id: newColId,
          name: g.title,
          description: g.description,
          order: g.main_order,
          hidden: getKanbanSetting(g, "column-hidden", false),
          expandChildrens: shouldExpandChildrens(g),
          noBananaDot: getKanbanSetting(g, "no-banana-dot", false),
          todoModeEnabled: g.type === "todo",
        };
      }),
    };

    // props.childrens.map((g) => Grapes.doDeleteGrape(client, g));
    for (let i = 0; i < (childrens || []).length; i++) {
      const g = childrens[i];
      promises.push({
        name: "delete: " + cleanHTMLTags(g.title),
        promise: async () => {
          await Grapes.doDeleteGrape(client, g);
          return true;
        },
      });
    }

    if (false) MLDialog.showModal("MIGRAPING", <PromisesDispatcherTool promises={promises} />);

    // Grapes.setSetting(client, props.grape, "board-structure", serializeBoardStructure(struct));
    promises.push({
      name: "settings: " + cleanHTMLTags(grape.title),
      promise: async () => {
        await Grapes.setSetting(client, grape, "board-structure", serializeBoardStructure(struct));
        return true;
      },
    });

    MLDialog.showSnackbar("Attendi qualche secondo..");
    setTimeout(() => {
      MLDialog.showSnackbar("A breve dovrebbe essere tutto ok!");
    }, 4000);
  });
}
