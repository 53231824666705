import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from "@material-ui/core";
import { OptionsObject, SnackbarProvider, SnackbarProviderProps, withSnackbar, WithSnackbarProps } from "notistack";
import * as React from "react";

export class MLDialogProvider extends React.Component<SnackbarProviderProps> {
  render() {
    return (
      <SnackbarProvider {...this.props}>
        <__MLDialog />
        {this.props.children}
      </SnackbarProvider>
    );
  }
}

export interface MLDialogParams {
  title?: string;
  message?: string | any;
  onPositiveClick?: () => boolean | void; // return true for not closing modal
  onNegativeClick?: () => boolean | void; // return true for not closing modal
  onOppositeClick?: () => boolean | void; // return true for not closing modal
  positiveText?: string;
  negativeText?: string;
  oppositeText?: string;
  fullscreen?: boolean;
}

export default class MLDialog extends React.Component<WithSnackbarProps> {
  state = MLDialog.defaultState;

  static __uniqueRef: MLDialog;

  static defaultState = {
    open: false,
    title: "",
    message: "",
    onPositiveClick: () => false,
    onNegativeClick: null,
    onOppositeClick: null,
    positiveText: "Ok",
    negativeText: "Annulla",
    oppositeText: "Elimina",
    fullscreen: false,
  };

  static showModal(title: String, message: string | any, params?: MLDialogParams) {
    MLDialog.__uniqueRef.showModal(title, message, params);
  }

  static hideModal() {
    MLDialog.__uniqueRef.hideModal();
  }

  static showSnackbar(message: String, options?: OptionsObject) {
    MLDialog.__uniqueRef.showSnackbar(message, options);
  }

  static hideSnackbar(key: string | number | undefined) {
    MLDialog.__uniqueRef.hideSnackbar(key);
  }

  constructor(props: WithSnackbarProps) {
    super(props);
    MLDialog.__uniqueRef = this;
  }

  showModal(title: String, message: string | any, params?: MLDialogParams) {
    const self = this;
    setTimeout(() => {
      self.setState(
        Object.assign({}, MLDialog.defaultState, params, {
          title: title,
          message: message,
          open: true,
        })
      );
    });
  }

  hideModal = () => {
    this.setState({ open: false });
  };

  showSnackbar(message: String, options?: OptionsObject) {
    this.props.enqueueSnackbar(message, options);
  }

  hideSnackbar(key: string | number | undefined) {
    this.props.closeSnackbar(key);
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.hideModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={this.state.fullscreen}
      >
        <DialogTitle id="alert-dialog-title" children={this.state.title} />
        <DialogContent>
          {typeof this.state.message === "string" ? (
            <DialogContentText
              id="alert-dialog-description"
              children={this.state.message}
              style={{ whiteSpace: "pre-line" }}
            />
          ) : (
            this.state.message
          )}
        </DialogContent>
        <DialogActions>
          <Box display="flex" flexDirection="row" flex={1}>
            {this.state.onOppositeClick && (
              <Button
                style={{ marginLeft: 8 }}
                color="secondary"
                children={this.state.oppositeText}
                onClick={() => {
                  if (this.state.onOppositeClick && (this.state.onOppositeClick! as Function)()) {
                    console.log(this.state.onOppositeClick);
                    return;
                  }
                  this.hideModal();
                }}
              />
            )}

            <Box flex={1} />

            {this.state.onNegativeClick && (
              <Button
                color="primary"
                children={this.state.negativeText}
                onClick={() => {
                  if (this.state.onNegativeClick && (this.state.onNegativeClick! as Function)()) {
                    console.log(this.state.onNegativeClick);
                    return;
                  }
                  this.hideModal();
                }}
              />
            )}

            {this.state.positiveText != "" ? (
              <Button
                autoFocus
                color="primary"
                children={this.state.positiveText}
                onClick={() => {
                  if (this.state.onPositiveClick && this.state.onPositiveClick()) {
                    return;
                  }
                  this.hideModal();
                }}
              />
            ) : null}
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
}

const __MLDialog = withSnackbar(MLDialog);
