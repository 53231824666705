import React from "react";
import { Grid, Tooltip, Box } from "@material-ui/core";
import { getUserColor } from "../modules/UserUtils";

export interface IUser {
  id: string;
  name: string;
  surname: string;
  email: string;
  thumb_profile?: string;
  user_preferences?: any;
  user_public_preferences?: any;
}

interface IUserChip {
  collapsed?: boolean;
  deletable?: boolean;
  assigned?: boolean;
  active?: boolean;
  user: IUser;
  onClick?: any;
  className?: string;
  tooltip?: string;
  style?: React.CSSProperties;
  avatarStyle?: React.CSSProperties;
  size?: number;
  margin?: number;
  colored?: boolean;
}

export const UserChip = (props: IUserChip) => {
  if (!props.user) return null;

  const { assigned, deletable, active, collapsed } = props;

  const username = props.user.name + " " + props.user.surname;
  const initials =
    (props.user.name &&
      props.user.surname &&
      (props.user.name.charAt(0) + props.user.surname.charAt(0)).substring(0, 2)) ||
    "?";

  const profileImage = props.user.thumb_profile;
  const size = props.size || 22;

  return (
    <Box
      display="flex"
      alignSelf="self-start"
      className={props.className}
      onClick={props.onClick}
      style={props.style}
      //@ts-ignore
      css={{
        position: "relative",

        ...(props.colored
          ? {
              ".avatar::before": {
                content: "''",
                position: "absolute",
                left: -2,
                top: -2,
                right: -2,
                bottom: -2,
                borderRadius: "50%",
                transition: "opacity 200ms",
                border: "2.5px solid " + getUserColor(props.user),
                opacity: 1,
              },
              ".avatar::after": {
                content: "''",
                position: "absolute",
                left: 0,
                top: 0,
                right: -0,
                bottom: -0,
                borderRadius: "50%",
                border: "2.5px solid white",
              },
            }
          : {}),

        ...(assigned
          ? {
              "::after": {
                content: '""',
                width: 10,
                height: 10,
                position: "absolute",
                top: 2,
                left: 2,
                borderRadius: 5,
                background: "#8764b8",
              },
            }
          : {}),

        ".owner .avatar::after": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          width: 20,
          height: 20,
          borderRadius: 10,
          border: "1px solid #888",
          pointerEvents: "none",
        },
      }}
    >
      <Tooltip title={`${props.tooltip || ""} ${username}`} disableHoverListener={!props.tooltip && !props.collapsed}>
        <Grid
          item
          className="avatar"
          style={{
            width: size,
            height: size,
            fontSize: size * 0.4 + "px",
            lineHeight: size + "px",
            borderRadius: size / 2,
            color: "#333",
            background: profileImage ? "none" : "#eee",
            textAlign: "center",
            textTransform: "uppercase",
            position: "relative",
            backgroundImage: `url('${profileImage}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            ...(props.avatarStyle || {}),
          }}
          children={profileImage ? "" : initials}
        />
      </Tooltip>

      {!props.collapsed ? <Grid item children={username} style={{ marginLeft: 5, marginTop: 2 }} /> : null}

      {/* {deletable ? (
        <div
          className="user-deletable"
          css={{
            position: "absolute",
            opacity: 0,
            left: 0,
            top: 0,
            right: 5,
            width: 2,
            bottom: 4,
            zIndex: 1,
            transform: "rotate(45deg)",
            background: "red",
            margin: "auto",
            pointerEvents: "none",
          }}
        />
      ) : null} */}
    </Box>
  );
};
