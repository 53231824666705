import { useQuery } from "@apollo/react-hooks";
import { Box, Grid, InputBase, LinearProgress } from "@material-ui/core";
import { useSetAtom } from "jotai";
import { useRef, useState } from "react";
import { SEARCH } from "../../repositories/queries_mutations";
import { MAGIC_ATOM } from "../../stores/magic";
import { IGrape } from "../grape_gql_interface";

export const SearchBox = (props: { onSelected?: (grape: IGrape) => void }) => {
  const [search, setSearch] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [focus, setFocus] = useState(false);
  const debounce = useRef<any>();

  const isSearching = searchWord.trim().length > 2;

  const { data, loading } = useQuery(SEARCH, { variables: { word: searchWord }, skip: !isSearching });
  const results = data?.search || [];

  const setMagicEnabled = useSetAtom(MAGIC_ATOM);

  return (
    <div>
      <Box display="flex" flexDirection="row" style={{ borderBottom: "3px solid #eee", position: "relative" }}>
        <InputBase
          placeholder="Cerca"
          fullWidth
          value={search}
          css={{ flexGrow: 1, padding: 4, paddingLeft: 36 }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e: any) => {
            const str = e.target.value as string;

            if (str.toLowerCase() === "enchantix") {
              setMagicEnabled((value) => !value);
              setSearch("");
              if (debounce.current) clearTimeout(debounce.current);
              return;
            }

            if (str.toLowerCase() === "abracadabra") {
              window.location.href = "/my-activities";
              return;
            }
            if (str.toLowerCase() === "mannager") {
              // get last occurency of "/" from window location href
              const lastSlash = window.location.href.lastIndexOf("/");
              // get the last part of the url
              const lastPart = window.location.href.substring(lastSlash + 1);

              window.location.href = `/my-activities?id=${lastPart}`;
              return;
            }
            setSearch(str);
            // debounce
            if (debounce.current) clearTimeout(debounce.current);
            debounce.current = setTimeout(() => setSearchWord(str), 1000);
          }}
        />

        <i
          children="search"
          className="material-icons search"
          style={{
            color: "#444",
            opacity: focus ? 1 : 0.5,
            transition: "opacity 200ms",
            padding: 8,
            pointerEvents: "none",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
          }}
        />

        {search.trim() !== "" && (
          <i
            className="material-icons"
            // onClick={() => createNewGrape().then(id => setTimeout(() => focusGrape(id), 250))}
            onClick={() => {
              setSearch("");
              setSearchWord("");
            }}
            style={{ color: "#444", padding: 8, cursor: "pointer" }}
            children="close"
          />
        )}
      </Box>

      <div
        css={{
          position: "fixed",
          // top: 0,
          // left: 250 + 1,
          // right: 0,
          top: 43,
          width: 250,
          bottom: 0,
          backgroundColor: "white",
          zIndex: 100,
          pointerEvents: isSearching ? "inherit" : "none",
          // transition: 'opacity 300ms, transform 300ms',
          opacity: isSearching ? 1 : 0,
          transform: `scale(${isSearching ? 1 : 0.8})`,
        }}
      >
        {loading ? (
          <LinearProgress />
        ) : !results.length ? (
          <i style={{ padding: 24, opacity: 0.7 }} children="Nessun risultato" />
        ) : (
          <Grid container direction="column">
            {results.map((item: any, index: number) => (
              <Grid
                item
                className={"gid-" + item.id}
                key={item.id}
                css={{
                  cursor: "pointer",
                  position: "relative",
                  padding: 4,
                  maxWidth: 250,
                  height: 50,
                  borderBottom: "1px solid #eee",
                  // borderRadius: 8,
                  // margin: 5,
                  overflow: "hidden",
                }}
                onClick={() => props.onSelected?.(item)}
              >
                {/* <div dangerouslySetInnerHTML={{ __html: item.title }} /> */}
                <div dangerouslySetInnerHTML={{ __html: item.title.replace(/<\/?[^>]+(>|$)/g, "") }} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
};
