/** @jsxImportSource @emotion/react */
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import moment from "moment";
import "moment/locale/it";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useHistory } from "react-router-dom";
import { createContext, useContextSelector } from "use-context-selector";
import { useGrapesApp } from "../../App";
import useSocket from "../../hooks/useSocket";
import { LIST_ROOT_GROUP_ID } from "../../pages/Main/ListGrapes";
import { GET_ALL_GRAPES, GET_DELETED_GRAPES, GET_MY_GRAPES } from "../../repositories/queries_mutations";
import { showInputDialog } from "../../utils";
import { __DEV__ } from "../../utils/init";
import { useSafeWindowPollingQuery } from "../../utils/safeWindowQuery";
import { createTempNewFolder, getInitialFolders, saveFolders } from "../ClientSortable/ClientSortableTree";
import { hideGrapesFromChildren } from "../GrapeDetail/core/grapeTypes";
import { GrapeDetailContextType } from "../GrapeDetail/GrapeDetail/hooks/GrapeDetailContext";
import { CREATE_GRAPE, IGrape } from "../grape_gql_interface";
import MLDialog from "../MLDialog";
import { GetMyGrapes, IMyGrape, MyGrapeType } from "../MyGrapeDetail";

moment.locale("it");

export const myGrapeConfigs: any = {
  myDay: { withCheckboxes: true },
  myWeek: { withCheckboxes: false },
  expiring: { withCheckboxes: true },
  assignedToMe: { withCheckboxes: true },
  archived: {},
};

export const useRootGrapeController = () => {
  const history = useHistory();
  const client = useApolloClient();
  const grapesApp = useGrapesApp();

  const { data: dataDeletedGrapes } = useQuery(GET_DELETED_GRAPES, { variables: { page: 0, perPage: 20 } });
  const getDeletedGrapes: IGrape[] = dataDeletedGrapes?.getDeletedGrapes || [];

  const {
    loading: loadingRootGrapes,
    data: rootGrapes,
    refetch: refetchRootGrapes,
    error: appError,
  } = useSafeWindowPollingQuery(GET_ALL_GRAPES, { pollInterval: 15 * 1000 });

  useSocket("rootGrapesUpdated", () => {
    refetchRootGrapes();
  });

  const {
    data: mygData,
    loading: myGrapesLoading,
    refetch: myGrapesRefetch,
    error: mygError,
  } = useSafeWindowPollingQuery(GET_MY_GRAPES, { pollInterval: 15 * 1000 });

  useSocket("myGrapesUpdated", () => {
    myGrapesRefetch();
  });

  if (loadingRootGrapes || appError || mygError) {
    console.log("🟥 root:", loadingRootGrapes, "error:", appError, "mygError:", mygError);
  }

  const [urlTokens] = useState(history?.location?.pathname?.split("/").filter((x) => !!x));
  const [urlGrapes, _setUrlGrapes] = useState(urlTokens?.filter((x) => x?.split("_")[0].length === 24));
  const grapeDetailOnly = window?.location?.href?.includes("grapeDetailOnly=1");

  let initialSelectedMyGrape = urlTokens?.length && urlTokens[0].split("_")[0].length !== 24 ? urlTokens[0] : undefined;
  const initialSelectedGrape = urlGrapes?.length ? { id: urlGrapes[0].split("_")[0] } : undefined;
  //! force myDay as fallback
  if (!initialSelectedGrape && !initialSelectedMyGrape) initialSelectedMyGrape = "myDay";

  const [selectedGrape, _setSelectedGrape] = useState<IGrape | undefined>(initialSelectedGrape as any);
  const [selectedMyGrape, _setSelectedMyGrape] = useState(initialSelectedMyGrape as MyGrapeType | undefined);
  const myGrapes: GetMyGrapes = mygData?.myGrapes;
  const [detailGrapes, _setDetailGrapes] = useState<{ [grapeId: string]: GrapeDetailContextType }>({});

  //! SECRET DON'T TOUCH PLS
  useEffect(() => {
    window.addEventListener("keypress", (event) => {
      if (event.key === "▬" || event.key === "¹") {
        const pathname = history.location.pathname;
        const grapesIds = pathname.split("/").filter((s) => s.length > 0);
        if (grapesIds.length > 0) {
          history.push("/" + grapesIds[grapesIds.length - 1]);
          window.location.reload();
        }
      }
    });
  }, []);

  //? middlewhare --> url dispatch
  const setRootSelectedGrape = (
    myGrape: MyGrapeType | undefined,
    grape: IGrape | undefined,
    nextSelectedMyGrape?: string | false
  ) => {
    if (grape) {
      history.push("/" + (myGrape && nextSelectedMyGrape !== false ? myGrape + "/" : "") + grape.id);
    } else {
      history.push("/" + (myGrape ? myGrape + "/" : ""));
    }

    _setSelectedMyGrape(myGrape);
    _setSelectedGrape(grape);
    _setUrlGrapes(grape?.id ? [grape.id] : []);
  };

  const createNewFolder = () => {
    showInputDialog("Inserisci nome della cartella", undefined, "Nome della cartella", (title) => {
      if (!title) return;
      const folders = getInitialFolders(grapesApp, LIST_ROOT_GROUP_ID);
      const newFolder = createTempNewFolder(title, []);
      const newFolders = [...folders, newFolder];
      saveFolders(grapesApp, LIST_ROOT_GROUP_ID, newFolders);
      window.location.reload();
    });
  };

  const createNewGrape = () => {
    showInputDialog("Inserisci il titolo del nuovo Grappolo", undefined, "Nome del grappolo", (title) => {
      if (!title) return;
      client
        .mutate({
          mutation: CREATE_GRAPE,
          awaitRefetchQueries: true,
          variables: { input: { title, type: "list" } },
          // variables: { input: { title, type: __DEV__ ? "root" : "list" } },
          refetchQueries: [{ query: GET_ALL_GRAPES }],
        })
        .then((resp) => {
          // return resp.data.createGrape.id;
          setRootSelectedGrape(selectedMyGrape, resp.data.createGrape);
        });
    });
  };

  const poke = (email: String) => {
    // TODO experimental demo-only function
    client
      .mutate({
        mutation: gql`
          mutation poke($email: String!) {
            pokeAPerson(email: $email)
          }
        `,
        variables: { email },
      })
      .then((a) => MLDialog.showSnackbar("🔔 Poke inviato a " + email))
      .catch((e) => MLDialog.showSnackbar("❌ Errore nel Poke a " + email));
  };

  const _registerDetailGrape = (grapeId: string, detailGrape: GrapeDetailContextType | undefined) => {
    _setDetailGrapes((details) => {
      if (detailGrape) {
        if (__DEV__) console.log("🟢 registerDetailGrape", grapeId);
        return { ...details, [grapeId]: detailGrape };
      } else {
        if (__DEV__) console.log("🔴 unregisterDetailGrape", grapeId);
        const { [grapeId]: _, ...rest } = details;
        return rest;
      }
    });
  };

  return {
    appError,
    myGrapes,
    myGrapesLoading,
    getDeletedGrapes,
    urlGrapes,
    setRootSelectedGrape,
    grapeDetailOnly,
    rootGrapes,
    loadingRootGrapes,
    selectedGrape,
    selectedMyGrape,
    // actions
    createNewFolder,
    createNewGrape,
    poke,
    // internals
    detailGrapes,
    _registerDetailGrape,
  };
};

//!-----------

export type RootGrapeContextType = ReturnType<typeof useRootGrapeController>;

export const RootGrapeContext = createContext<RootGrapeContextType | undefined>(undefined);

export function useRootGrapeContextSelector<T extends keyof RootGrapeContextType>(field: T): RootGrapeContextType[T] {
  return useContextSelector(RootGrapeContext, (v) => v![field]);
}
