import { Tooltip } from "@material-ui/core";
import { useRef } from "react";
import { useGrapesApp } from "../App";
import GrapeCollaboratorsThumbs from "./GrapeCollaboratorsThumbs";
import { GrapeChatButton } from "./GrapeDetail/ChatGrapesApp";
import { chatDisabled } from "./GrapeDetail/GrapeDetail";
import { IGrape, IUserGrapePermission } from "./grape_gql_interface";
import { IUser } from "./User";

export const ContextableUserAssign = ({
  grape,
  collaborators,
  shouldFetchAnchestors,
  fromAdvancedMenu,
  onAssignedTo,
}: {
  grape: IGrape;
  collaborators?: IUserGrapePermission[];
  shouldFetchAnchestors?: boolean;
  fromAdvancedMenu?: boolean;
  onAssignedTo?: (userId: string, deassign?: boolean) => void;
}) => {
  const allCollaborators = useGrapeCollaborators(grape, collaborators);

  return (
    <div style={{ display: "block", whiteSpace: "initial", width: "100%" }}>
      <div css={{ display: "flex", flexDirection: "row", alignItems: "flex-end", marginBottom: 4, marginTop: -8 }}>
        <h6 children="Assegna" style={{ margin: 0, marginTop: 4 }} />
        <div css={{ flex: 1 }} />
        {!fromAdvancedMenu && !chatDisabled ? (
          <Tooltip title="Messaggi">
            <div>
              <GrapeChatButton grape={grape!} disableFetches />
            </div>
          </Tooltip>
        ) : null}
      </div>

      <GrapeCollaboratorsThumbs
        grape={grape}
        collaborators={allCollaborators}
        shouldFetchAnchestors={shouldFetchAnchestors}
        // onUpdatedCollaborators={(newList) => (collaboratorsRef.current = newList)} //todo assicurarsi che funzioni anche senza (era un setState dall'interno verso fuori, da evitare)
        onAssignedTo={onAssignedTo}
      />
    </div>
  );
};

export const useGrapeCollaborators = (grape: IGrape, collaborators?: IUserGrapePermission[]) => {
  const grapesApp = useGrapesApp();
  return [
    ...(collaborators || []),
    ...(grapesApp?.user ? [{ user: grapesApp.user }] : []),
    ...(grape?.owner ? [{ user: grape.owner }] : []),
    ...(grape?.assignees?.map((user: IUser) => ({ user, assigned: true })) || []),
    ...(grape?.users?.map((user: IUserGrapePermission) => user) || []),
  ];
};
