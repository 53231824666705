import { copyToClipboard } from "../../utils";
import { IGrape } from "../grape_gql_interface";
import MLDialog from "../MLDialog";

export const getGrapeUniqueId = (grape: IGrape) => {
  function hex2scribable(hexStr: string) {
    if (!hexStr) return "";
    let scribable = parseInt(hexStr, 16).toString(32).split("");
    // let scribable = '0123456789abcdefghijklmnopqrstuvwxyz'.split('');
    for (var i = 0; i < scribable.length; i++) {
      switch (scribable[i]) {
        case "0":
          scribable[i] = "w";
          break;
        case "1":
          scribable[i] = "x";
          break;
        case "o":
          scribable[i] = "y";
          break;
        case "l":
          scribable[i] = "z";
          break;
      }
    }
    return scribable.join("").toUpperCase();
  }

  function scribable2hex(scribable: string) {
    if (!scribable) return "";
    const tokens = scribable.toLocaleLowerCase().split("");
    for (var i = 0; i < tokens.length; i++) {
      switch (tokens[i]) {
        case "w":
          tokens[i] = "0";
          break;
        case "x":
          tokens[i] = "1";
          break;
        case "y":
          tokens[i] = "o";
          break;
        case "z":
          tokens[i] = "l";
          break;
      }
    }
    return parseInt(tokens.join(""), 32).toString(16);
  }
  let gid = grape.id.substr(24 - 6);
  const bgid = "" + gid;
  gid = hex2scribable(gid);
  // console.log(bgid === scribable2hex(gid) ? '💚' : '💙');
  return gid;
};

export const GrapeUniqueId = (props: { grape: IGrape; className?: string }) => {
  const gid = getGrapeUniqueId(props.grape);

  return (
    <div
      children={gid}
      className={(props.className || "") + " hoverable outlined"}
      css={{
        alignSelf: "center",
        fontSize: 13,
        cursor: "pointer",
        height: 18,
        display: "inline-block",

        fontFamily: "monospace",
        letterSpacing: 1,
        // padding: '0 3px',
        paddingLeft: 4,
        paddingRight: 3,
        marginLeft: 5,
      }}
      onClick={() => {
        copyToClipboard(gid);
        MLDialog.showSnackbar("Copied to clipboard");
      }}
    />
  );
};
