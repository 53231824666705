import ApolloClient from "apollo-client";
import React from "react";
import { IGrapesApp } from "../App";
import { IUser } from "../components/User";
import { GET_ME_SINGLE, UPDATE_USER } from "../repositories/queries_mutations";

const UserPreferences = {
  //? user preferences
  getPreference: (app: IGrapesApp, uniqueId: string, defaultValue?: string): string | undefined => {
    try {
      // const offline = localStorage.getItem(uniqueId);
      const online: any = app.user.user_preferences?.[uniqueId];
      return online || localStorage.getItem(uniqueId) || defaultValue;
    } catch (error) {
      console.log("error getting preference", error);
    }
  },

  savePreference: (app: IGrapesApp, uniqueId: string, value: string) => {
    try {
      localStorage.setItem(uniqueId, value);

      const userPrefs = app.user.user_preferences || {};
      userPrefs[uniqueId] = value;
      app.client.mutate({
        mutation: UPDATE_USER,
        variables: { input: { user_preferences: userPrefs } },
        refetchQueries: [{ query: GET_ME_SINGLE }],
      });
    } catch (e) {
      console.log("error saving user preference", e);
    }
  },

  //? user settings
  getPublicPreference: (user: IUser, uniqueId: string, defaultValue?: string): string | undefined => {
    // const offline = localStorage.getItem(uniqueId);
    const online: any = user.user_public_preferences?.[uniqueId];
    return online || localStorage.getItem(uniqueId) || defaultValue;
  },

  savePublicPreference: (app: IGrapesApp, uniqueId: string, value: string) => {
    localStorage.setItem(uniqueId, value);

    const userPrefs = app.user.user_public_preferences || {};
    userPrefs[uniqueId] = value;
    app.client.mutate({
      mutation: UPDATE_USER,
      variables: { input: { user_public_preferences: userPrefs } },
      refetchQueries: [{ query: GET_ME_SINGLE }],
    });
  },
};

export default UserPreferences;
