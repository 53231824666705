import { gql } from "apollo-boost";
import { GRAPE_FRAGMENT } from "../components/grape_gql_interface";
import { GRAPE_FILE_FRAGMENT, MY_GRAPE_FRAGMENT, USER_FRAGMENT } from "./fragments";

export const SEARCH = gql`
  query search($word: String) {
    search(word: $word) {
      __typename
      ... on Grape {
        id
        title
        parent
      }
      ... on GrapeFile {
        id
        title
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: InputUpdateUser) {
    updateUser(input: $input) {
      id
      name
      surname
    }
  }
`;

export const GET_DELETED_GRAPES = gql`
  query getDeletedGrapes($page: Int, $perPage: Int) {
    getDeletedGrapes(page: $page, perPage: $perPage) {
      id
      title
      type
      labels
      main_order
      children {
        id
        title
        type
        labels
      }
    }
  }
`;

export const GET_ALL_GRAPES = gql`
  ${USER_FRAGMENT}
  ${GRAPE_FILE_FRAGMENT}
  ${GRAPE_FRAGMENT}

  query getAllGrapes {
    allGrapes {
      ...grape
      users {
        user {
          ...user
        }
        permission
      }
      files {
        ...grapeFile
      }
    }
  }
`;

export const GET_ME_SINGLE = gql`
  query meSingle {
    meSingle {
      id
      email
      name
      surname
      thumb_profile
      user_preferences
      user_public_preferences
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const GET_MY_GRAPES = gql`
  ${MY_GRAPE_FRAGMENT}
  ${GRAPE_FRAGMENT}
  query myGrapes {
    myGrapes {
      myDay {
        ...myGrape
        headless_my_day
      }
      assignedToMe {
        ...myGrape
      }
      # expiring {
      #   ...myGrape
      # }
      archived {
        ...grape
      }
    }
  }
`;
