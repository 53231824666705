/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { Box, Button, getLuminance, Grid, Grow, LinearProgress, Tooltip } from "@material-ui/core";
import { gql } from "apollo-boost";
import ApolloClient from "apollo-client";
import { isSameDay } from "date-fns";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { myGrapeConfigs } from "../../App";
import Grapes, { IGrapeEdit } from "../../modules/Grapes";
import { MY_GRAPE_FRAGMENT } from "../../repositories/fragments";
import { GET_MY_GRAPES } from "../../repositories/queries_mutations";
import AddTextField from "../AddTextField";
import { AppGrapeDetail } from "../App/AppGrapeDetail";
import { useRootGrapeContextSelector } from "../App/RootGrapeProvider";
import ClientSortableList from "../ClientSortable/ClientSortableList";
import Grape from "../Grape";
import { hideGrapesFromChildren } from "../GrapeDetail/core/grapeTypes";
import { CREATE_MY_GRAPE, IGrape } from "../grape_gql_interface";
import { DeletedGrapesList } from "./DeletedGrapesList";
import ignoreErrorMutation from "./ignoreErrorMutation";
import MyGrapeList from "./MyGrapeList";
import PlannerMyGrape from "./PlannerMyGrape";
import { WeekGrapesView } from "./WeekGrapesView";

export type MyGrapeType = "myDay" | "myWeek" | "assignedToMe" | "planner" | "expiring" | "archived";

export interface IMyGrape extends IGrape {
  rootParent?: string;
  ancestors: {
    id: string;
    title: string;
    type: string;
  }[];
}

export type GetMyGrapes = {
  [key in MyGrapeType]: IMyGrape[];
};

export const MyGrapeDetail = (props: {}) => {
  const client = useApolloClient();

  const myGrapes = useRootGrapeContextSelector("myGrapes");
  const selectedGrape = useRootGrapeContextSelector("selectedGrape");
  const selectedMyGrape = useRootGrapeContextSelector("selectedMyGrape");
  const setRootSelectedGrape = useRootGrapeContextSelector("setRootSelectedGrape");
  const myGrapesLoading = useRootGrapeContextSelector("myGrapesLoading");

  const today = moment().startOf("day");
  const [segmentedButtons, setSegmentedButtons] = useState<{ title: string; onClick: any }[]>();
  const [selectedDate, setSelectedDate] = useState(today);
  const weekViewEnabled = selectedMyGrape === "myWeek";
  const selectedMyGrapeKey = selectedMyGrape === "myWeek" ? "myDay" : selectedMyGrape;
  const mergePastDaysOnDate = moment().subtract(1, "day");

  const myGrapeSourceList: IMyGrape[] = myGrapes?.[selectedMyGrapeKey!] || [];
  const myGrapeList = parseMyGrapesData(myGrapeSourceList).map((x: IMyGrape) => ({
    ...x,
    rootParent: x && ((x.ancestors || [])[0] || {}).id,
  }));
  // sort
  myGrapeList.sort((a: any, b: any) => (a.rootParent || "").localeCompare(b.rootParent || ""));

  const myTodoList = myGrapeList.filter((x) => x.status === 1);
  const isToday = selectedDate.isSame(moment(), "day");

  // MyDay Expiring AssignedToMe
  const clearMyTodoList = (action: "restore" | "hide", fromDate?: Moment) => {
    const capitalized = selectedMyGrape && selectedMyGrape?.charAt(0).toUpperCase() + selectedMyGrape.slice(1);

    const mutation = ignoreErrorMutation(action, capitalized);
    client
      .mutate({
        mutation: mutation,
        variables: { fromDate },
        refetchQueries: [{ query: GET_MY_GRAPES }],
        awaitRefetchQueries: true,
      })
      .then(() => console.log("done"));
  };

  const addMyGrape = async (title: string, date?: Date, variables?: IGrapeEdit) => {
    console.log("add my grape", title);

    const inputVariables = { ...(variables || {}), title, date1: date };

    const resp = await client.mutate({
      mutation: CREATE_MY_GRAPE,
      variables: {
        input: inputVariables,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_MY_GRAPES }],
    });

    console.log("success", resp);
    // return ql(resp.data, "createMyGrape.id") || "non trovato";

    return resp.data.createMyGrape.id;
  };

  if (selectedMyGrape === "planner") {
    return <PlannerMyGrape />;
  }

  if (!(selectedMyGrape === "myDay" || selectedMyGrape === "myWeek")) {
    // assignedToMe + archived
    return (
      <MyGrapeList
        myGrapeList={myGrapeList}
        selectedGrape={selectedGrape}
        setSelectedGrape={(grape) => setRootSelectedGrape(selectedMyGrape, grape)}
        selectedMyGrape={selectedMyGrape}
        loading={myGrapesLoading}
        trailing={selectedMyGrape === "archived" && <DeletedGrapesList />}
      />
    );
  }

  return (
    <Box display="flex" flexDirection="row">
      <Box
        flexGrow="1"
        display="flex"
        flexDirection="column"
        style={{
          borderRight: "1px solid #ccc",
          // width: weekViewEnabled ? undefined : 300,
        }}
      >
        {renderMyDayHeader()}

        {/* HEADER */}
        {myGrapeConfigs[selectedMyGrape!]?.withCheckboxes && (
          <Grid
            container
            direction="row"
            style={{ maxWidth: 400, borderBottom: "1px solid #eee", padding: 4, marginBottom: 4 }}
            spacing={1}
            onMouseLeave={() => setTimeout(() => setSegmentedButtons(undefined), 300)}
          >
            {segmentedButtons?.length ? (
              segmentedButtons.map((b, i) => (
                <Grid
                  key={b.title}
                  item
                  xs={Math.max(2, Math.floor(12 / segmentedButtons.length)) as any}
                  css={{ display: "flex" }}
                >
                  <Grow in appear timeout={1000 - i * 250}>
                    <Button
                      style={{ flex: 1, fontSize: 10, padding: "5px 10px" }}
                      variant="outlined"
                      children={b.title}
                      onClick={() => {
                        setSegmentedButtons(undefined);
                        b.onClick();
                      }}
                    />
                  </Grow>
                </Grid>
              ))
            ) : (
              <React.Fragment>
                <Grid item xs={6}>
                  <Grow in timeout={750}>
                    <Button
                      disabled={!myTodoList.length}
                      style={{ width: "100%", fontSize: 10, padding: "5px 10px" }}
                      variant="outlined"
                      // children={`nascondi ${myTodoList.length || ""} task completat${
                      //   myTodoList.length === 1 ? "o" : "i"
                      // }`}
                      onClick={() => clearMyTodoList("hide")}
                      children={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `nascondi <b>${myTodoList.length || 0}</b><br/>task completat${
                              myTodoList.length === 1 ? "o" : "i"
                            }`,
                            // __html: `nascondi task completat${myTodoList.length === 1 ? "o" : "i"}: <b>${
                            //   myTodoList.length || 0
                            // }</b>/${
                            //   myGrapeList.length
                            //   myGrapeList.filter((g) => moment(g.date || g.created_at).isSame(moment(), "day")).length
                            // }`,
                          }}
                        />
                      }
                    />
                  </Grow>
                </Grid>
                <Grid item xs={6}>
                  <Grow in timeout={500}>
                    <Button
                      style={{ width: "100%", fontSize: 10, padding: "5px 10px" }}
                      variant="outlined"
                      // onClick={() => clearMyTodoList('restore')}
                      onClick={() =>
                        setSegmentedButtons([
                          {
                            title: "tutti",
                            onClick: () => clearMyTodoList("restore"),
                          },
                          {
                            title: "ultima settimana",
                            onClick: () => clearMyTodoList("restore", moment().subtract(4, "days").startOf("week")),
                          },
                          {
                            title: "oggi",
                            onClick: () => clearMyTodoList("restore", moment().startOf("day")),
                          },
                        ])
                      }
                    >
                      ripristina task
                      <br />
                      nascosti
                    </Button>
                  </Grow>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        )}

        {/* LIST */}
        <Box
          flexGrow={1}
          className="no-sb"
          style={{
            overflowY: "scroll",
            padding: "7.5px 16px",
            // padding: "3px 8px",
          }}
        >
          {weekViewEnabled
            ? renderMyWeekBody(
                moment(selectedDate).startOf("week"),
                moment(selectedDate).startOf("week").add(5, "days")
              )
            : renderMyWeekBody(moment(selectedDate).subtract(1, "day"), moment(selectedDate).add(2, "day"), (date) =>
                date.isBefore(moment(), "day")
              )}
        </Box>
      </Box>

      <AppGrapeDetail />
    </Box>
  );

  function renderMyDayHeader() {
    return (
      <div css={{ display: "flex", flexDirection: "row", maxWidth: 400, position: "sticky", left: 0 }}>
        {/* previous day */}
        <div
          css={{ padding: 6, userSelect: "none" }}
          className="hoverable"
          children={<i className="material-icons" children="arrow_left" />}
          onClick={() => setSelectedDate((d) => moment(d).subtract(1, weekViewEnabled ? "weeks" : "days"))}
        />

        {/* back to current day */}
        <Tooltip title="back to current day">
          <div
            css={{
              padding: 6,
              userSelect: "none",
              // opacity: 0.65,
              visibility: !isToday || weekViewEnabled ? "visible" : "hidden",
            }}
            onClick={() => {
              setSelectedDate(moment());
              setRootSelectedGrape("myDay", undefined);
            }}
            className="hoverable"
            // children={<i className="material-icons" children="restore" />}
            children="🌞"
          />
        </Tooltip>

        {/* current day label day */}
        <div
          css={{ flexGrow: 1, padding: 6, textAlign: "center" }}
          children={
            weekViewEnabled
              ? moment(selectedDate).startOf("week").format("D MMM") +
                " → " +
                moment(selectedDate).endOf("week").format("D MMM")
              : isToday
              ? "🌞 Today"
              : selectedDate.format("ddd D MMM")
          }
        />

        {/* weekly view Switch */}
        <Tooltip title={(weekViewEnabled ? "close" : "switch to") + " weekly view"}>
          <div
            css={{
              padding: 6,
              userSelect: "none",
              // opacity: 0.65,
            }}
            className="hoverable"
            // children={<i className="material-icons" children="date_range" />}
            children={weekViewEnabled ? "📆" : "📅"}
            onClick={() => {
              if (weekViewEnabled) setSelectedDate(moment());
              setRootSelectedGrape(weekViewEnabled ? "myDay" : "myWeek", undefined);
            }}
          />
        </Tooltip>

        {/* next day */}
        <div
          css={{ padding: 6, userSelect: "none" }}
          className="hoverable"
          children={<i className="material-icons" children="arrow_right" />}
          onClick={() => setSelectedDate((d) => moment(d).add(1, weekViewEnabled ? "weeks" : "days"))}
        />
      </div>
    );
  }

  function filterGrapesByDateRange(start: Moment, end: Moment) {
    return myGrapeList.filter((g) => {
      const dEnd = moment(g.date1 || g.date || g.created_at);
      const dStart = moment(g.date2 || dEnd);

      return !(dStart.isAfter(end) || dEnd.isBefore(start));
    });
  }

  function renderMyWeekBody(start: Moment, end: Moment, isPastTasksColumn?: (day: Moment) => boolean) {
    const onDragEndColumns: any = {};
    return (
      <DragDropContext
        onDragEnd={(result, provided) => {
          const prefix = "my-grape-myDay|";
          if (!(result?.destination?.droppableId || "").includes(prefix)) {
            console.log("not valid dnd");
            return;
          }
          const tokens = result.destination!.droppableId.split("|");
          const date = moment(tokens[1]);
          const draggedGrape = myGrapeList.filter((g) => g.id === result.draggableId)[0];
          const draggedGrapeDate = moment(draggedGrape.date1 || draggedGrape.date || draggedGrape.created_at);
          const draggedGrapeDateStart = moment(draggedGrape.date2 || draggedGrapeDate);
          const shouldChangeStartDate = !draggedGrapeDate.isSame(draggedGrapeDateStart, "day");
          const grapeChanges: { date1?: string; date0?: string; date2?: string } = {};

          if (shouldChangeStartDate) {
            if (date.isAfter(draggedGrapeDate) || date.isSame(draggedGrapeDate, "day")) {
              grapeChanges.date1 = date.toISOString();
              grapeChanges.date2 = "";
            } else {
              grapeChanges.date2 = date.toISOString();
            }
          } else {
            // const isSameDay = date.isSame(draggedGrapeDate, "day");
            // if (tokens[2] === "past") {
            //   let prevDate = moment(draggedGrape.date || draggedGrape.created_at || draggedGrape.date1);
            //   if (prevDate.isSameOrAfter(moment(), "day")) prevDate = moment().subtract(1, "day");
            //   grapeChanges.date1 = date.toISOString();
            // } else {
            grapeChanges.date1 = date.toISOString();
            if (draggedGrape.title.includes("intro fra")) console.log("edit --->", grapeChanges.date1);
            // }
          }

          const reference = onDragEndColumns[date.weekday()];
          if (reference) {
            reference.onDragEnd(result);
          }

          concludeOptimisticDateChange(client, myGrapeList, result.draggableId, grapeChanges);
          // Grapes.editGrape(client, result.draggableId, { date1: date.toISOString() });
          Grapes.editGrape(client, result.draggableId, grapeChanges);
          console.log("EDIT", result.draggableId, grapeChanges);
        }}
      >
        {false ? (
          <WeekGrapesView
            startDate={start.toDate()}
            // grapes={filterGrapesByDateRange(moment().startOf("week"), moment().endOf("week"))}
            grapes={filterGrapesByDateRange(start, end)}
            // startDate={start.startOf("week").toDate()}
            // grapes={filterGrapesByDateRange(start.startOf("week"), start.endOf("week"))}
            renderItem={(g, i) => (
              <div css={{ border: "1px solid red", height: "100%" }} dangerouslySetInnerHTML={{ __html: g.title }} />
            )}
            // renderItem={(g, i) => renderItem(g, i, true)}
          />
        ) : (
          <div css={{ display: "flex", flexDirection: "row" }}>
            {/* {[...new Array(7)].map((_, dayOfWeek) => { */}
            {[...new Array(end.diff(start, "days"))].map((_, index) => {
              const day = moment(start).add(index, "days").startOf("day");
              const dayOfWeek = day.weekday();
              const dayToday = day.isSame(today, "day");
              const isPastColumn = isPastTasksColumn?.(day);
              return (
                <div
                  key={day.toISOString()}
                  style={{
                    width: 235, //200,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 4,
                    margin: "0px 2.5px",
                    backgroundColor: "rgba(0,0,0,0.026)",
                  }}
                >
                  <div
                    children={isPastColumn ? "Precedenti" : (dayToday ? "🌞 " : "") + day.format("dddd D MMM")}
                    css={{
                      paddingTop: 10,
                      paddingBottom: 2,
                      textAlign: "center",
                      fontFamily: "monospace",
                      opacity: dayToday ? 1 : 0.6,
                      fontWeight: dayToday ? "bold" : undefined,
                    }}
                  />
                  {renderSingleDayBody({
                    day,
                    isPastColumn,
                    customDragDrop: (onDragEnd, children) => {
                      onDragEndColumns[dayOfWeek] = { day, onDragEnd };
                      return children;
                    },
                  })}
                </div>
              );
            })}
          </div>
        )}
      </DragDropContext>
    );
  }

  function renderSingleDayBody(params: {
    day: Moment;
    customDragDrop?: (onDragEnd: (res: DropResult) => void, children: any) => any;
    oldTasksOnly?: boolean;
    isPastColumn?: boolean;
  }) {
    const { day, customDragDrop, oldTasksOnly, isPastColumn } = params;

    const isToday = day.isSame(moment(), "day");
    const isPastDate = !isToday && day.isBefore(moment(), "day");
    const currentDayList = myGrapeList.filter((g) => {
      const gDate = moment(g.date1 || g.date || g.created_at);
      const gDateStart = moment(g.date2 || gDate);
      if (!!mergePastDaysOnDate && mergePastDaysOnDate.isSame(day, "day") && !weekViewEnabled) {
        return gDate.isSameOrBefore(day, "day");
      }
      const isEndDate = gDate.isSame(day, "day");
      const isStartDate = gDateStart.isSame(day, "day");
      if (oldTasksOnly) return !isEndDate && gDate.isSameOrBefore(day, "day");

      // return isEndDate;
      if (isEndDate || !(gDateStart.isAfter(day.startOf("day")) || gDate.isBefore(day.endOf("day")))) {
        if (weekViewEnabled) {
          if (isStartDate) {
            return true;
          }

          // started in a previous week
          if (day.weekday() == 0 && gDateStart.isBefore(day)) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    });

    return (
      <React.Fragment>
        {oldTasksOnly && currentDayList.length > 0 ? (
          <div children="Non completati" css={{ fontWeight: "bold", fontSize: 18, marginLeft: 8, marginTop: 16 }} />
        ) : null}

        <ClientSortableList
          css={{ flex: 1 }}
          customDragDrop={customDragDrop}
          key={selectedMyGrapeKey}
          groupId={`my-grape-${selectedMyGrapeKey}|${day.startOf("day").toISOString()}|` + (isPastColumn ? "past" : "")}
          distance={8}
          list={currentDayList || []}
          getItemId={(item) => item.id}
          renderTrailElement={oldTasksOnly ? null : renderTrailElement()}
          renderItem={(item, index) => renderItem(item, index, day)}
        />
      </React.Fragment>
    );

    function renderTrailElement() {
      return myGrapesLoading ? (
        <LinearProgress css={{ margin: 4 }} />
      ) : (
        <AddTextField
          addToGrape={(title, vars) => addMyGrape(title, day.toDate(), vars)}
          // addToGrape={(title, vars) => addMyGrape(title, weekViewEnabled || !isToday ? day.toDate() : undefined)}
          renderEmptyItem={
            !weekViewEnabled && currentDayList.length == 0 ? (
              <div
                style={{
                  padding: 10,
                  textAlign: "left",
                  width: "100%",
                  color: "#aaa",
                  boxShadow: "border-box",
                  WebkitBoxSizing: "border-box",
                }}
                children={isPastDate ? "Non è stato segnato nulla..." : "Niente in programma..."}
              />
            ) : null
          }
        />
      );
    }
  }

  function renderItem(item: IMyGrape, index: number, day: Moment, collapsed?: boolean) {
    const list: any[] = item?.ancestors || [];
    // const list: any[] = ((item && (item as any).genealogicalTree) || {}).ancestors || [];
    const grapeDate = moment(item.date1 || item.date || item.created_at);
    const grapeDateStart = moment(item.date2 || grapeDate);
    const grapeDays = day.diff(item.date1 || item.date || item.created_at, "days");
    const noBananaDot = Grapes.getSetting(item, "no-banana-dot");
    const oldGrapeColor =
      // grapeDays < 2 ? "#ffe000" : grapeDays < 5 ? "#fe8800" : grapeDays < 31 ? "#bb0000" : "#000000";
      grapeDays < 2 ? "#ffe000" : grapeDays < 5 ? "#fe8800" : "#bb0000";

    return (
      <Grape
        key={item.id}
        myGrape={selectedMyGrapeKey}
        grape={item}
        // style={{ margin: 4 }}
        anchestors={list || []}
        shouldFetchAnchestors
        className="grape-list-item"
        groupType={myGrapeConfigs[selectedMyGrapeKey!]?.withCheckboxes ? "todo" : "list"}
        selected={selectedGrape && selectedGrape.id === item.id}
        renderBananaDot={false} // item.type !== "kanban"}
        renderSubGrapes={!collapsed && !["kanban", "board"].includes(item.type)}
        renderCaption={() => (
          <React.Fragment>
            {
              weekViewEnabled ? (
                !grapeDateStart.isSame(grapeDate, "day") ? (
                  <CaptionLabel color="#1aa0ff" children={"fino a " + grapeDate.format("dddd D MMM")} />
                ) : null
              ) : item.status !== 1 && !day.isSameOrBefore(grapeDate, "day") ? (
                <CaptionLabel
                  children={
                    noBananaDot
                      ? ""
                      : grapeDays < 5
                      ? grapeDate.format("dddd")
                      : Math.max(1, grapeDays) + " " + (grapeDays < 2 ? "giorno" : "giorni")
                  }
                  withoutMargin={noBananaDot}
                  color={oldGrapeColor}
                />
              ) : null // <CaptionLabel children="Da fare oggi" color="#2196f3" />
            }

            {/* {list?.[0]?.title && (index === 0 || item.rootParent !== myGrapeList[index - 1]?.rootParent) ? ( */}
            {list?.[0]?.title ? (
              <div
                style={{
                  cursor: "pointer",
                  padding: 8,
                  fontWeight: "bold",
                  // borderTop: "3px solid #eee",
                }}
                dangerouslySetInnerHTML={{ __html: list[0]?.title }}
                // children={list[0] && list[0].title}
                onClick={(e: any) => {
                  if (e.stopPropagation) e.stopPropagation();
                  setRootSelectedGrape(selectedMyGrape, list[0]);
                }}
              />
            ) : null}
          </React.Fragment>
        )}
        onRequestOpen={(g) => {
          // if (item.id === selectedGrape) return;
          let itemToSelect = g || item;
          if (["kanban", "board"].includes(list[list.length - 2]?.type)) {
            //! prevent to open single grape as detail - kanban item
            // itemToSelect = list[list.length - 2]; // TODO - prevent opening kanban
          }
          setRootSelectedGrape(selectedMyGrape, itemToSelect);
        }}
      />
    );
  }
};

function concludeOptimisticDateChange(
  client: ApolloClient<any>,
  myGrapeList: IMyGrape[],
  grapeId: string,
  changes: { date1?: string; date0?: string; date2?: string }
) {
  const warnCache = console.warn;
  console.warn = () => {};
  try {
    const myGrape = client.readFragment({
      id: "MyGrape:" + grapeId,
      fragmentName: "grapeList",
      fragment: gql`
        ${MY_GRAPE_FRAGMENT}
        fragment grapeList on MyGrape {
          ...myGrape
        }
      `,
    }) as IMyGrape;

    if (myGrape) {
      client.writeFragment({
        id: "MyGrape:" + grapeId,
        fragmentName: "grapeList",
        fragment: gql`
          ${MY_GRAPE_FRAGMENT}
          fragment grapeList on MyGrape {
            ...myGrape
          }
        `,
        data: {
          ...myGrape,
          ...changes,
        },
      });
      console.log("✅ optimistic update:", changes, myGrape);
    } else {
      for (let i = 0; i < myGrapeList.length; i++) {
        // for (let j = 0; j < myGrapeList[i].children.length; j++) {
        // if (myGrapeList[i].children[j].id === grapeId) {
        if (myGrapeList[i].id === grapeId) {
          console.log("FOUND");
          //todo wip
          break;
        }
        // }
      }
    }
  } catch (e) {
    console.log("❌ error:", e);
  }
  console.warn = warnCache;
}

const CaptionLabel = (props: { children: string; color: string; withoutMargin?: boolean }) => (
  <div
    children={props.children}
    css={{
      display: "inline-block",
      margin: props.withoutMargin ? 0 : 5,
      marginBottom: 0,
      borderRadius: 4,
      padding: "2px 8px",
      fontSize: 12,
      // textTransform: "capitalize",
      letterSpacing: 1,
      textAlign: "center",
      color: getLuminance(props.color) > 0.33 ? "black" : "white",
      backgroundColor: props.color,
    }}
  />
);

function parseMyGrapesData(data: IMyGrape[]) {
  return data;

  //todo wip tiz - per qualche motivo, continua a dare "date1" con il vecchio valore (probabilmente cache problem)
  const myDayGrapesUnique: { [grapeId: string]: IMyGrape } = {};

  for (let c = 0; c < data.length; c++) {
    const myGrape = data[c];
    if (myGrape?.ancestors?.[0]?.type === "kanban") {
      for (let i = 0; i < myGrape.children?.length; i++) {
        const grape = myGrape.children[i];
        if (hideGrapesFromChildren.includes(grape.type)) continue;
        if (grape.title.includes("intro fra")) console.log(321, c, i, grape.title, grape.date1);
        myDayGrapesUnique[grape.id] = { ...grape, ancestors: [...(myGrape.ancestors || []), myGrape] };
      }
    } else {
      myDayGrapesUnique[myGrape.id] = myGrape;
    }
  }

  return Object.values(myDayGrapesUnique);
}
