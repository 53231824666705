import { gql } from "apollo-boost";
import ApolloClient from "apollo-client";
import { IGrape } from "../grape_gql_interface";
import { IUser } from "../User";
import { GrapesPlugin } from "./types";

export default class ExportCSVPlugin extends GrapesPlugin {
  static title = "Export CSV";

  onInvoked(grape: IGrape, user: IUser, client: ApolloClient<any>): void {
    client
      .query({
        query: gql`
      query MyQuery {
        allGrapes(id: "${grape.id}") {
          title
          description
          children {
            title
            description
            children {
              title
              description
              children {
                title
                description
                children {
                  title
                  description
                }
              }
            }
          }
        }
      }
    `,
      })
      .then((response: any) => {
        function downloadAsFile(filename: string, data: string) {
          const blob = new Blob([data], { type: "text/csv" });
          if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(blob, filename);
          } else {
            const elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }
        }

        interface GrapeLevel {
          title: string;
          description: string;
          children: GrapeLevel[];
        }

        function clearString(s: string) {
          return s.replace(/<\/?[^>]+(>|$)/g, "");
        }

        function grapeLevelize(grape: GrapeLevel, level: number) {
          const tabs = [...new Array(level)].map((_) => "\t").join("");
          let str = "";
          str += "\n" + tabs + clearString(grape.title || "");
          if (grape.description) str += "\n" + tabs + clearString(grape.description);
          for (var c = 0; c < (grape.children || []).length; c++) {
            str += grapeLevelize(grape.children[c], level + 1);
          }
          return str;
        }

        downloadAsFile(clearString(grape.title), grapeLevelize(response.data.allGrapes[0], 0));
      });
  }
}
