/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { Grid, Tooltip } from "@material-ui/core";
import moment from "moment";
import { useCallback, useRef } from "react";
import { useGrapesApp } from "../App";
import Grapes from "../modules/Grapes";
import { showMoveGrapeDialog, showPickerGrapeDialog } from "../modules/moveGrape";
import { GET_ALL_GRAPES, GET_MY_GRAPES } from "../repositories/queries_mutations";
import { cleanHTMLTags, copyToClipboard, openNewWindowGrape, showConfirmDialog } from "../utils";
import { TMP_GRAPE_PREFIX } from "./AddTextField";
import Contextable, { IContextMenuItem } from "./Contextable";
import { ContextableUserAssign, useGrapeCollaborators } from "./ContextableUserAssign";
import GrapeCollaboratorsThumbs from "./GrapeCollaboratorsThumbs";
import { GrapeChatButton } from "./GrapeDetail/ChatGrapesApp";
import { chatDisabled } from "./GrapeDetail/GrapeDetail";
import { useSafeGrapeDetailContextSelector } from "./GrapeDetail/GrapeDetail/hooks/GrapeDetailContext";
import { getGrapeUniqueId } from "./GrapeDetailItems/GrapeUniqueId";
import GrapeLabels from "./GrapeLabels";
import { GrapePriorityIcon, grapesPriorities } from "./GrapePriority";
import {
  GET_GRAPE,
  GrapeLinkInput,
  IGrapeComp,
  IUserGrapePermission,
  REMOVE_USER_FROM_GRAPE,
} from "./grape_gql_interface";
import MLDialog from "./MLDialog";
import { getUserPlugins } from "./plugins";
import { GrapesPlugin } from "./plugins/types";
import { IUser } from "./User";

interface IContextableGrape extends Omit<IGrapeComp, "groupType"> {
  onChange: (attr: string, value: any, options?: { withDelay?: boolean }) => void;
  disableOnSelectCapture?: boolean;
  bothMouseButtons?: boolean;
  children: any;
  fromAdvancedMenu?: boolean;
}

export const ContextableGrape = (props: IContextableGrape) => {
  const client = useApolloClient();
  const grapesApp = useGrapesApp();
  const grapeDetail = useSafeGrapeDetailContextSelector("grape");
  const labelColors = useSafeGrapeDetailContextSelector("labelColors") || {};
  const setLabelColor = useSafeGrapeDetailContextSelector("setLabelColor");
  const collaborators = useGrapeCollaborators(props.grape, props.collaborators);

  const getMenuItems = useCallback(() => {
    const isRootGrape = props.className === "grape-root";
    const isOwner = props.grape?.owner?.id === grapesApp?.user?.id;
    const mirrorGrapeId = Grapes.getSetting(props.grape, "mirror-grape");
    const toLeaveInsteadOfDelete = isRootGrape && !isOwner; // && !props.grape.parent
    const toRemoveFromMyDayInsteadOfDelete = props.myGrape; // && props.grape.parent;

    // menu items
    const menuItems: IContextMenuItem[] = [];
    const menuItemsMore: IContextMenuItem[] = [];

    if (!props.fromAdvancedMenu) {
      menuItems.push({
        key: "assign",
        hoverable: false,
        caption: (
          <ContextableUserAssign
            grape={props.grape}
            collaborators={props.collaborators}
            shouldFetchAnchestors={props.shouldFetchAnchestors}
            fromAdvancedMenu={props.fromAdvancedMenu}
            onAssignedTo={() => props.onChange("_", new Date().getTime())}
          />
        ),
      });
    }

    // priority
    menuItems.push({
      key: "priority",
      hoverable: false,
      caption: (
        <Grid container direction="row">
          <div children="Priorità" css={{ display: "inline-block", marginRight: 8 }} />
          <Grid item xs />
          {grapesPriorities
            .sort((a, b) => a.value - b.value)
            .map((priority, index) => {
              const grapePriority = props.grape.priority || -1;
              const isSelected = grapePriority === priority.value;
              return (
                <Tooltip key={priority.id} title={priority.id} css={{ height: "auto", margin: 0 }}>
                  <div
                    children={<GrapePriorityIcon priority={priority} />}
                    className="hoverable"
                    css={{
                      position: "relative",
                      display: "inline-block",
                      padding: "0 2px",
                      // opacity: grapePriority >= 0 ? (isSelected ? 1 : 0.2) : 1,
                      // ":hover": { opacity: 1 },
                      ":before": isSelected
                        ? {
                            content: '""',
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: 2,
                            borderRadius: 3,
                            border: "1px solid black",
                            borderTop: "none",
                          }
                        : undefined,
                    }}
                    onClick={() => {
                      if (grapePriority == priority.value) {
                        props.onChange("priority", -1);
                      } else {
                        props.onChange("priority", priority.value);
                      }
                    }}
                  />
                </Tooltip>
              );
            })}
        </Grid>
      ),
      // onClick: () => Grapes.openLabelEditor(props.grape, props.onChange),
    });

    // edit label

    menuItems.push({
      key: "editLabel",
      // caption: 'Aggiungi o rimuovi etichetta',
      // caption: props.grape?.labels?.length ? "Modifica etichetta" : "Aggiungi etichetta",
      caption: props.grape?.labels?.length ? (
        <Grid container direction="row">
          <div
            children={(props.grape.labels.length === 1 ? "Etichetta" : "Etichette") + ":"}
            css={{ display: "inline-block", marginBottom: 2, marginRight: 8 }}
          />
          <GrapeLabels labels={props.grape.labels.filter((l) => l !== "mirror")} />
        </Grid>
      ) : (
        "Aggiungi etichetta"
      ),
      onClick: () =>
        Grapes.openLabelEditor(
          props.grape,
          props.onChange,
          labelColors,
          setLabelColor ? (label, color) => setLabelColor(label, color) : undefined
        ),
    });

    // story points
    menuItems.push({
      key: "storyPoints",
      // caption: ((props.grape.storypoints || "") + " Story points").trim(),
      caption: "Story points" + (props.grape.storypoints ? ": " + props.grape.storypoints : ""),
      color: props.grape.storypoints ? "#ee9900" : undefined,
      onClick: () => Grapes.openStoryPointsEditor(props.grape, props.onChange),
    });

    // deadline
    menuItems.push({
      key: "deadline",
      caption: (props.grape.date ? "Modifica" : "Imposta") + " data di scadenza 📅",
      onClick: () => Grapes.openModalPlanner(client, props.grape, { myGrape: props.myGrape, editMode: "deadline" }),
    });

    // sprint
    if (["board", "kanban"].includes(grapeDetail?.type)) {
      const allSprints = (grapeDetail?.children || []).filter((g) => g.type === "sprint");
      if (allSprints.length > 0) {
        const linksTo = [...(props.grape.links_to || [])];
        const allowMultipleSprints = false;
        menuItems.push({
          key: "sprint",
          caption: "Sprint",
          subMenuItems: allSprints.map((sprint) => {
            let isSelected = false;
            for (let i in linksTo) {
              if (linksTo[i].grape.id === sprint.id) {
                isSelected = true;
                break;
              }
            }

            const text = cleanHTMLTags(sprint.title);

            return {
              key: sprint.id,
              caption: text,
              // color: isSelected ? getStringColors(text).background : undefined,
              checked: !!isSelected,
              onClick: () => {
                const newLinksTo: GrapeLinkInput[] = allowMultipleSprints
                  ? linksTo.filter((l) => l.grape.id !== sprint.id).map((l) => ({ type: l.type, grape: l.grape.id }))
                  : [];

                if (!isSelected) {
                  newLinksTo.push({ type: "relates", grape: sprint.id });
                }
                // Grapes.editGrape(client, props.grape.id, { links_to: newLinksTo });
                props.onChange("links_to", newLinksTo);
              },
            };
          }),
        });
      }
    }

    // myDay
    // if (!props.myGrape) {
    menuItems.push({
      key: "deadline",
      caption: (props.grape.date1 ? "Modifica nelle mie giornate" : "Aggiungi alle mie giornate") + " 🌞",
      // hueRotate={props.grape.date1 ? 190 : undefined}
      onClick: () => Grapes.openModalPlanner(client, props.grape, { myGrape: props.myGrape, editMode: "myDay" }),
    });

    menuItems.push({ key: "divider", hoverable: false });

    // banana dot
    if (!["kanban", "board"].includes(props.grape.type)) {
      const noBananaDot = Grapes.getSetting(props.grape, "no-banana-dot");
      // const noBananaDot = Grapes.getSetting(props.grape, "no-banana-dot") || !!grapeListTypes[props.grape.type]?.disableBananaDot;
      menuItemsMore.push({
        key: "bananaDot",
        caption: (noBananaDot ? "Abilita" : "Disabilita") + " invecchiamento 🍌",
        onClick: () => Grapes.setSetting(client, props.grape, "no-banana-dot", !noBananaDot),
      });
    }

    // service class
    // menuItems.push({
    //   key: "serviceClass",
    //   caption: "Classe di servizio",
    //   color: props.grape.storypoints ? "#ee9900" : undefined,
    //   onClick: () => Grapes.openStoryPointsEditor(props.grape, props.onChange),
    // });

    // open link
    if (props.grape.title && (props.grape.title.includes("http://") || props.grape.title.includes("https://"))) {
      menuItems.push({
        key: "openLink",
        caption: "Apri link in un'altra scheda",
        color: "blue",
        onClick: () => {
          const url = props.grape.title.substr(props.grape.title.indexOf("http"));
          window.open(url);
        },
      });
    }

    // other tools
    menuItemsMore.push(
      // { key: '20', caption: 'Inserisci', onClick: () => this.onInsert(0).then(focusGrape) },
      {
        key: "invite",
        caption: "Invita un collaboratore",
        onClick: () => Grapes.requestInviteToGrape(client, props.grape, collaborators),
      }
    );

    menuItemsMore.push({
      key: "newWindow",
      caption: "Apri su nuova pagina",
      onClick: () => openNewWindowGrape(props.grape),
    });

    menuItems.push({
      key: "copyLink",
      color: "blue",
      caption: "Copia indirizzo link",
      onClick: (e) => {
        let url = window.location.origin;
        let subpath = "";
        for (var c = 0; c < props.anchestors?.length; c++) {
          subpath += "/" + props.anchestors[c].id;
          if (props.anchestors[c]._subSection) subpath += "_" + props.anchestors[c]._subSection;
          if (props.anchestors[c].type === "kanban") {
            c++;
            console.log("skip", c, props.anchestors[c]);
            continue;
          }
        }

        url += subpath + "/" + props.grape.id;

        copyToClipboard(url);

        MLDialog.showSnackbar("Link copied to clipboard");
      },
    });

    // { key: '33', caption: 'Inserisci sopra', onClick: () => this.onInsert(-1).then(focusGrape) },
    // { key: '37', caption: 'Inserisci sotto', onClick: () => this.onInsert(0).then(focusGrape) },
    // { key: '40', caption: 'Aggiungi figlio', onClick: () => this.onAddChild() },

    // if (!toLeaveInsteadOfDelete)
    menuItemsMore.push({
      key: "move",
      caption: "Sposta su un altro grappolo",
      onClick: () => showMoveGrapeDialog(client, props.grape),
    });

    menuItemsMore.push({
      key: "move",
      caption: "Invia mirror su un altro grappolo",
      onClick: () => {
        showPickerGrapeDialog(
          "Su quale grappolo vuoi inviare il mirror?",
          (toGrape) => {
            if (!toGrape) return;
            Grapes.mirrorGrape({ client, parent: toGrape!, grape: props.grape });
          },
          {
            client: client,
            fromGrape: props.grape,
          }
        );
      },
    });

    menuItemsMore.push({
      key: "videocall",
      caption: "Partecipa a video chiamata 📹",
      onClick: () => {
        const uid = getGrapeUniqueId(props.grape);
        const url = `https://meet.jit.si/grapes/${uid}`;
        window.open(url, "_blank");
      },
    });

    const userPlugins = getUserPlugins(grapesApp?.user);
    if (userPlugins != null) {
      menuItemsMore.push({
        key: "plugins",
        caption: "Plugins",
        subMenuItems: userPlugins.map((plugin) => {
          return {
            key: plugin.title,
            caption: plugin.title,
            onClick: () => {
              const instance: GrapesPlugin = new (plugin as any).plugin();
              instance.onInvoked(props.grape, grapesApp?.user, client);
            },
          };
        }),
      });
    }

    if (menuItemsMore.length) {
      menuItems.push({
        key: "moreItems",
        caption: "Avanzate",
        color: "blue",
        subMenuItems: menuItemsMore,
      });
    }

    if (isRootGrape || props.myGrape === "archived") {
      menuItems.push(
        //! ARCHIVE - UNARCHIVE
        props.myGrape === "archived"
          ? {
              key: "unarchive",
              color: "#888",
              caption: "Togli da Archiviati",
              onClick: () => Grapes.unarchiveGrape(client, props.grape),
            }
          : {
              key: "archive",
              color: "#888",
              caption: "Archivia",
              onClick: () => Grapes.archiveGrape(client, props.grape),
            }
      );
    }

    if (props.grape.owner) {
      menuItems.push({
        key: "created_by",
        color: "#ccc",
        hoverable: false,
        caption:
          "Creata " + moment(props.grape.created_at).fromNow() + " da " + (props.grape.owner && props.grape.owner.name),
      });
    }

    menuItems.push({
      key: "delete",
      caption: toRemoveFromMyDayInsteadOfDelete
        ? "Rimuovi dalla mia giornata"
        : mirrorGrapeId
        ? "Rimuovi mirror"
        : toLeaveInsteadOfDelete
        ? "Abbandona"
        : "Elimina",
      color: "#de2000",
      onClick: () => {
        showConfirmDialog(
          (
            <div
              dangerouslySetInnerHTML={{
                __html: `Vuoi ${
                  toRemoveFromMyDayInsteadOfDelete
                    ? "rimuovere"
                    : mirrorGrapeId
                    ? "annullare il mirror di"
                    : toLeaveInsteadOfDelete
                    ? "abbandonare"
                    : "eliminare"
                } "${props.grape.title || " "}"?`,
              }}
            />
          ) as any,
          undefined,
          () => {
            let refetchQueries = [];
            if (props.myGrape) {
              refetchQueries.push({ query: GET_MY_GRAPES });
            } else if (!props.grape.parent) {
              refetchQueries.push({ query: GET_ALL_GRAPES });
            } else if (props.grape.parent) {
              refetchQueries.push({ query: GET_GRAPE, variables: { id: props.grape.parent } });
            }

            if (toRemoveFromMyDayInsteadOfDelete) {
              Grapes.setMyDay(client, props.grape, false);
            } else if (mirrorGrapeId) {
              const mirrorGrapeSourceId = Grapes.getSetting(props.grape, "mirror-grape-source");
              if (mirrorGrapeSourceId) Grapes.doDeleteGrape(client, { id: mirrorGrapeSourceId } as any, refetchQueries);
            } else if (toLeaveInsteadOfDelete) {
              // console.log("deassign", props.grape.id, grapesApp.user.id);
              client
                .mutate({
                  // mutation: DEASSIGN_GRAPE_FROM_USER,
                  mutation: REMOVE_USER_FROM_GRAPE,
                  variables: { grapeId: props.grape.id, userId: grapesApp.user.id },
                  refetchQueries: [{ query: GET_MY_GRAPES }, { query: GET_ALL_GRAPES }],
                })
                .catch((e) => console.log("🖤 err", e));
            } else {
              Grapes.doDeleteGrape(client, props.grape, refetchQueries);
            }
            if (props.onDeleted) props.onDeleted();
          }
        );
      },
    });

    return menuItems;
  }, [
    // dependencies
    props,
    client,
    grapesApp,
    grapeDetail,
    labelColors,
  ]);

  return (
    <Contextable
      disabled={props.noContextMenu}
      menuItems={props.grape.id.startsWith(TMP_GRAPE_PREFIX) ? [] : getMenuItems()}
      children={props.children}
      bothMouseButtons={props.bothMouseButtons}
      disableOnSelectCapture={props.disableOnSelectCapture}
    />
  );
};

const MergedIcons = (props: {
  icon: string;
  hueRotate?: number;
  tooltip: string;
  onClick: () => void;
  overIcon?: string;
}) => (
  <Tooltip title={props.tooltip}>
    <div className="hoverable" css={{ padding: 4, position: "relative" }} onClick={props.onClick}>
      <span
        children={props.icon}
        css={{ filter: props.hueRotate ? "hue-rotate(" + props.hueRotate + "deg)" : undefined }}
      />
      {props.overIcon && (
        <div css={{ position: "absolute", fontSize: 12, bottom: 0, right: 0 }} children={props.overIcon} />
      )}
    </div>
  </Tooltip>
);
