import { Draggable, Droppable } from "react-beautiful-dnd";
import { useNotifierValue } from "react-use-notifier";
import { getGrapeDraggableId } from "..";
import { recursiveFilterGrapeChildrenMatch } from "../../../../utils";
import { TMP_GRAPE_PREFIX } from "../../../AddTextField";
import Grape from "../../../Grape";
import { IGrape } from "../../../grape_gql_interface";
import { defaultGrapeListType, hideGrapesFromChildren } from "../../core/grapeTypes";
import { useSafeGrapeDetailContextSelector } from "./GrapeDetailContext";

export interface IRenderGrapesColumnParams {
  isCombineEnabled?: boolean;
  expandChildrens?: boolean;
  renderBananaDot?: boolean;
  todoModeEnabled?: boolean;
  disableDragAndDrop?: boolean;
  style?: any;
  distance?: number;
  trailComponent?: any;
  anchestors?: IGrape[];
  droppableId?: string;
  onFilterChildrensChanged?: (val: { label?: string; userId?: string }) => void;
}

export const useGrapeDetailRenderColumn = () => {
  const childrenFilter = useSafeGrapeDetailContextSelector("childrenFilter");
  const collaborators = useSafeGrapeDetailContextSelector("collaborators");
  const allAnchestors = useSafeGrapeDetailContextSelector("allAnchestors");
  const selectedGrape = useSafeGrapeDetailContextSelector("selectedGrape");
  const socketController = useSafeGrapeDetailContextSelector("socketController");
  // actions
  const onDeleted = useSafeGrapeDetailContextSelector("onDeleted");
  const onRequestOpen = useSafeGrapeDetailContextSelector("onRequestOpen");

  const selectedGrapeIds = useNotifierValue(socketController.selectedGrapeIds);

  const renderGrapesColumn = (grape: IGrape, grapesList: IGrape[], params?: IRenderGrapesColumnParams) => (
    <Droppable
      isDropDisabled={params?.disableDragAndDrop}
      droppableId={params?.droppableId || grape.id || "tmp"}
      isCombineEnabled={params?.isCombineEnabled}
    >
      {(provided, snapshot) => {
        const anchestors = [...(params?.anchestors || allAnchestors), grape];

        return (
          <div
            ref={provided.innerRef}
            className="grapes-column-container"
            style={{ minHeight: 21, flexGrow: 1, maxWidth: "100%" }}
          >
            {(grapesList || [])
              .filter((x) => {
                //!wip - to remove - server only
                if (hideGrapesFromChildren.includes(x?.type)) {
                  return false;
                }
                if (childrenFilter) {
                  return recursiveFilterGrapeChildrenMatch(x, childrenFilter, 4);
                }
                return true;
              })
              .map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={getGrapeDraggableId({ item: item, parent: grape })}
                  index={index}
                  shouldRespectForcePress
                  isDragDisabled={params?.disableDragAndDrop || grape.id.startsWith(TMP_GRAPE_PREFIX)}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        className={`draggable-grape ${snapshot.isDragging ? "dragging" : ""} ${
                          snapshot.combineTargetFor ? "combine-for" : ""
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          style={{
                            position: "relative",
                            paddingTop: params?.distance,
                            pointerEvents: grape.id.startsWith(TMP_GRAPE_PREFIX) ? "none" : undefined,
                          }}
                        >
                          <Grape
                            key={item.id}
                            grape={item}
                            anchestors={anchestors}
                            className="grape-list-item"
                            groupType={params?.todoModeEnabled ? "todo" : grape.type || defaultGrapeListType}
                            collaborators={collaborators}
                            selected={!!(selectedGrape && selectedGrape.id === item.id)}
                            selectedBy={selectedGrapeIds?.[item.id]}
                            onRequestAddGrape={() => {}}
                            onRequestOpen={(g, params) => onRequestOpen(g || item, { ...(params || {}), anchestors })}
                            onDeleted={onDeleted}
                            renderSubGrapes={params?.expandChildrens}
                            renderBananaDot={params?.renderBananaDot}
                            style={params?.style}
                            // onLabelClick={(label, i, e) => {
                            //   setChildrenFilter({ ...(childrenFilter || {}), label });
                            //   e.preventDefault();
                            //   e.stopPropagation();
                            // }}
                          />
                        </div>
                      </div>
                    );
                  }}
                </Draggable>
              ))}
            {provided.placeholder}
            {params?.trailComponent}
          </div>
        );
      }}
    </Droppable>
  );

  return renderGrapesColumn;
};
