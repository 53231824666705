/** @jsxImportSource @emotion/react */
import { Button, Grid } from "@material-ui/core";
import { ApolloError } from "apollo-client";

const ApplicationError = (props: { error?: ApolloError }) => {
  console.log("🟥 error", props.error);

  return (
    <div css={{ margin: 32 }}>
      <Grid container direction="column" justify="center" alignItems="center">
        <h3>Application Error</h3>
        <br />
        <p>Problem contacting server, please try again later</p>
        <br />
        <br />
        <p>If the problem persists, try logging in again</p>
        <br />
        <Button
          variant="contained"
          children="logout"
          onClick={() => {
            if (window.confirm("Vuoi uscire?")) {
              localStorage.removeItem("app-token");
              window.location.href = "";
            }
          }}
        />
      </Grid>
    </div>
  );
};

export default ApplicationError;
