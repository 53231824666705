import { IGrape } from "../../grape_gql_interface";
import { MyGrapeType } from "../../MyGrapeDetail";
import { useRootGrapeContextSelector } from "../RootGrapeProvider";

export const MyGrapeButton = (props: { id: MyGrapeType; title: string; countCriteria?: (item: IGrape) => boolean }) => {
  const setRootSelectedGrape = useRootGrapeContextSelector("setRootSelectedGrape");
  const selectedMyGrape = useRootGrapeContextSelector("selectedMyGrape");
  const myGrapes = useRootGrapeContextSelector("myGrapes");

  const list: IGrape[] = myGrapes?.[props.id] || [];
  const isSelected = selectedMyGrape === props.id;

  return (
    <div
      style={{
        padding: 8,
        paddingRight: 20,
        borderBottom: "1px solid #eee",
        fontSize: 14,
        fontWeight: 500,
        cursor: "pointer",
        position: "relative",
        backgroundColor: isSelected ? "#eee" : "white",
      }}
      onClick={() => {
        setRootSelectedGrape(props.id as MyGrapeType, undefined);
      }}
    >
      {props.title}

      <div
        children={props.countCriteria != null ? list.filter(props.countCriteria).length : "" || ""}
        style={{
          fontSize: 10,
          position: "absolute",
          right: 2,
          margin: "auto",
          top: 0,
          bottom: 0,
          height: 10,
        }}
      />
    </div>
  );
};
