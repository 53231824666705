/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { Box, Button, Card, CardContent, Container, Grid, TextField, Typography } from "@material-ui/core";
import { gql } from "apollo-boost";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MLDialog from "../../components/MLDialog";
import { UnauthScaffold } from "../../components/UnauthScaffold";

export const ResetPassword = (props: {}) => {
  const client = useApolloClient();
  const history = useHistory();
  const [psw1, setPsw1] = useState("");
  const [psw2, setPsw2] = useState("");
  const [error, setError] = useState<string>();
  const [token, setToken] = useState("");

  useEffect(() => {
    if (window?.location?.href?.includes("?token=")) {
      const urlToken = window.location.href.split("?token=")[1];
      setToken(urlToken);
    } else {
      history.push("/");
    }
  }, []);

  const onConfirm = () => {
    if ((psw1 || "").trim().length < 8) {
      setError("Inserisci una password da almeno 8 caratteri");
      return;
    }
    if (psw1 !== psw2) {
      setError("Le password non corrispondono");
      return;
    }
    client
      .mutate({
        mutation: gql`
          mutation changePassword($password: String!, $token: String!) {
            changePassword(password: $password, token: $token)
          }
        `,
        variables: { password: psw1, token },
      })
      .then((a) => {
        MLDialog.showSnackbar("La password è stata resettata!", { variant: "success" });
        setTimeout(() => history.replace("/"));
      })
      .catch((e) => MLDialog.showSnackbar("C'è stato un problema, riprova tra poco", { variant: "error" }));
  };

  return (
    <UnauthScaffold>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <h3 children="Reimposta password" css={{ margin: "10px 0" }} />
        </Grid>
        <Grid item>
          <h4 children="Inserisci la nuova password!" css={{ fontWeight: "normal", margin: "10px 0" }} />
        </Grid>
        <Grid item xs>
          <TextField
            fullWidth
            placeholder="Password"
            variant="outlined"
            type="password"
            onChange={(e) => setPsw1(e.target.value)}
          />
        </Grid>
        <Grid item xs>
          <TextField
            fullWidth
            placeholder="Ripeti password"
            variant="outlined"
            type="password"
            onChange={(e) => setPsw2(e.target.value)}
          />
        </Grid>
        <Grid css={{ margin: 8, marginTop: 16 }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            children="reimposta password"
            onClick={onConfirm}
          />
        </Grid>
      </Grid>
    </UnauthScaffold>
  );
};
