import { useApolloClient } from "@apollo/react-hooks";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { IGrapeEdit } from "../../../../modules/Grapes";
import { mergeObjects } from "../../../../utils";
import { UPDATE_TIMEOUT_MILLIS } from "../../../Grape";
import { EDIT_GRAPE, GET_GRAPE, IGrape } from "../../../grape_gql_interface";

export type GrapeEditFun = (grapeId: string, attr: string, value: any, options?: { withDelay?: boolean }) => void;

export type GrapeOptimisticFun = (grape: IGrape) => void;

export const useEditGrape = (grape: IGrape): [IGrape, GrapeEditFun, GrapeOptimisticFun] => {
  let optimisticGrape = grape;

  const [updates, setUpdates] = useState<IGrape>(optimisticGrape);

  if (
    updates?.updated_at &&
    (!grape.updated_at || moment(updates.updated_at).isAfter(moment(optimisticGrape.updated_at)))
  ) {
    optimisticGrape = updates;
  }

  // const [optimisticGrape, setOptimisticGrape] = useState<IGrape>(grape);
  // useEffect(() => {
  //   console.log(1111);
  //   if (!grape?.updated_at) return;
  //   if (!optimisticGrape?.updated_at) setOptimisticGrape(grape);
  //   const datePrev = moment(optimisticGrape.updated_at);
  //   const dateNew = moment(grape.updated_at);
  //   console.log(2222, dateNew.isAfter(datePrev), datePrev, dateNew);
  //   if (dateNew.isAfter(datePrev)) {
  //     console.log(3333);
  //     setOptimisticGrape(grape);
  //   }
  // }, [grape]);

  const client = useApolloClient();

  //! edit grapes
  let updateAttributes = useRef<any>({});
  let updateTimeouts = useRef<any>({});

  const editGrape: GrapeEditFun = (grapeId, attr, value, options) => {
    updateAttributes.current[grapeId] = updateAttributes.current[grapeId] || {};
    updateAttributes.current[grapeId][attr] = value;

    if (updateTimeouts.current[grapeId]) clearTimeout(updateTimeouts.current[grapeId]);
    updateTimeouts.current[grapeId] = setTimeout(
      () => {
        // if (__DEV__) console.log("edited:", grapeId, new Date().getTime());
        client.mutate({
          mutation: EDIT_GRAPE,
          variables: {
            input: {
              ...updateAttributes.current[grapeId],
              // ...(options?.withDelay ? updateAttrs : syncUpdateAttrs),
              id: grapeId,
            },
          },
          refetchQueries: [{ query: GET_GRAPE, variables: { id: grapeId } }],
        });
      },
      options && options.withDelay ? UPDATE_TIMEOUT_MILLIS : 0
    );
  };

  const optimistic = (data: IGrape) => {
    const newGrape = {
      id: data.id,
      title: data.title,
      description: data.description,
      labels: data.labels,
      status: data.status,
      main_order: data.main_order,
      cross_order: data.cross_order,
      updated_at: data.updated_at,
      epics: data.epics,
      date: data.date,
      date0: data.date0,
      date1: data.date1,
      date2: data.date2,
      progress: data.progress,
      priority: data.priority,
      type: data.type,
      urgency: data.urgency,
      storypoints: data.storypoints,
    };

    if (grape.id === newGrape.id) {
      setUpdates({
        ...grape,
        ...newGrape,
        children: grape.children,
      });
    } else {
      let found: IGrape | undefined;
      for (let i = 0; i < grape.children?.length; i++) {
        if (grape.children[i].id === newGrape.id) {
          found = grape.children[i];
          break;
        }
      }
      if (found) {
        setUpdates({
          ...grape,
          updated_at: newGrape.updated_at ?? grape.updated_at,
          children: grape.children.map((child) => {
            if (child.id === newGrape.id) {
              return { ...child, ...newGrape, children: child.children };
            }
            return child;
          }),
        });
      } else {
        console.log("not found", grape.id, newGrape.id);
      }
    }
  };

  return [optimisticGrape, editGrape, optimistic];
};
