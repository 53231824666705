/** @jsxImportSource @emotion/react */
import { Grid } from "@material-ui/core";
import { IBoardGrape } from ".";
import { IGrape } from "../../grape_gql_interface";
import { BoardBodyColumns } from "./BoardBodyColumns";
import { BoardHeaders } from "./BoardHeaders";

export const boardGrapeSpecs = {
  columnWidth: 280, // 240,
  columnPadding: 5,
  horizontalPadding: 16,
  borderRadius: 4,
};

export const BoardBody = (props: {
  structure: BoardStructure;
  onStructureChanged: (struct: BoardStructure) => void;
  attached?: boolean;
  showBacklog?: boolean;
  boardProps: IBoardGrape;
}) => {
  const visibleColumns = props.structure.columns.filter((c) => props.showBacklog || !c.hidden);
  const progressesByIds: { [id: string]: IGrape } = {};
  // const progresses = props.boardProps.grape.children.filter((g: IGrape) => {
  //   if (g.type === "progress") {
  //     progressesIds[g.id] = g;
  //     return true;
  //   }
  //   return false;
  // });
  props.boardProps.grape.children.forEach((g: IGrape) => {
    if (g.type === "progress") {
      progressesByIds[g.id] = g;
    }
  });
  const progresses = Object.values(progressesByIds);

  const availableColumnIds = props.structure.columns.map((c) => c.id);
  const defaultColumn = props.structure.columns.find((c) => !c.hidden) ?? props.structure.columns[0];
  const columnItems = visibleColumns.map((column, index) => {
    column.storypoints = 0;
    return props.boardProps.grape.children.filter((g: IGrape) => {
      if (
        (column.id === defaultColumn?.id && !availableColumnIds.includes(g.cross_order)) ||
        g.cross_order == column.id
      ) {
        column.storypoints! += g.storypoints || g.virtualStorypoints || 0;
        return true;
      }
      return false;
    });
  });

  return (
    <Grid
      container
      direction="column"
      css={{
        margin: `0 ${boardGrapeSpecs.horizontalPadding}px`,
        width: Math.max(1, visibleColumns.length) * (boardGrapeSpecs.columnWidth + boardGrapeSpecs.columnPadding) + 2, // margin fix
      }}
    >
      <BoardHeaders
        columns={visibleColumns}
        boardProps={props.boardProps}
        structure={props.structure}
        onStructureChanged={props.onStructureChanged}
      />

      {progresses.map((progress) => {
        const progressColumnItems = visibleColumns.map((column, index) => {
          return progress.children.filter((g: IGrape) => {
            if (progress.id !== g.parent) {
              return false;
            }

            if (
              (column.id === defaultColumn?.id && !availableColumnIds.includes(g.cross_order)) ||
              g.cross_order == column.id
            ) {
              return true;
            }
            return false;
          });
        });
        return (
          <BoardBodyColumns
            key={progress.id}
            {...props}
            withHeader
            visibleColumns={visibleColumns}
            columnItems={progressColumnItems}
            filterByProgress={progress}
            boardProps={props.boardProps}
          />
        );
      })}

      <BoardBodyColumns
        {...props}
        withHeader={!!progresses.length}
        visibleColumns={visibleColumns}
        columnItems={columnItems}
        //? remove progresses from main kanban
        // boardProps={{
        //   ...props.boardProps,
        //   grape: {
        //     ...props.boardProps.grape,
        //     children: props.boardProps.grape.children.filter((g) => !progressesByIds[g.id]),
        //   },
        // }}
      />
    </Grid>
  );
};

export const serializeBoardStructure = (structure: BoardStructure) => {
  const struct = {
    ...structure,
    columns: (structure.columns ?? []).map((column) => {
      const col = { ...column };
      delete col.storypoints; //? remove storypoints before saving (it's a calculated value)
      return col;
    }),
  };
  return JSON.stringify(struct);
};

export const deserializeBoardStructure = (structure?: string): BoardStructure => {
  try {
    if (!structure) throw `invalid structure: ${structure}`;
    const struct: BoardStructure = JSON.parse(structure!);
    if (!(struct.columns.length > 0)) throw `invalid structure object: ${structure} --> ${struct}`;
    return struct;
  } catch (e) {
    return {
      columns: [
        { id: 0, order: 0, name: "TODO" },
        { id: 100, order: 100, name: "WIP" },
        { id: 200, order: 200, name: "DONE" }, // isCompletionColumn: true,
      ],
    };
  }
};

export interface BoardStructure {
  columns: BoardColumn[];
}
export interface BoardColumn {
  id: number;
  name: string;
  description?: string;
  order: number;
  storypoints?: number;
  hidden?: boolean;
  isWipColumn?: boolean;
  isCompletionColumn?: boolean;
  expandChildrens?: boolean;
  noBananaDot?: boolean;
  todoModeEnabled?: boolean;
}
