import { useApolloClient, useQuery } from "@apollo/react-hooks";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGrapesApp } from "../App";
import MLDialog from "./MLDialog";

const commit: { count?: number; date?: string } = require("../_git_commit");

const AppBarSmall = (props: { actions: IAction[] }) => {
  const theme = useTheme();
  const client = useApolloClient();
  const history = useHistory();
  const drawerAnchor = useRef(null);
  const { user } = useGrapesApp();

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ zIndex: 2, borderTop: "1px solid #eee", background: theme.palette.primary.main }}
    >
      <Tooltip title="Open menu">
        <MenuIcon
          ref={drawerAnchor}
          onClick={() => setDrawerOpen(true)}
          style={{ padding: 8, cursor: "pointer", fill: "white" }}
        />
      </Tooltip>

      <Box flexGrow={1} children="Grapes" style={{ fontSize: 17, color: "white" }} />

      {props?.actions?.map((action, index) => (
        <Action action={action} key={index} />
      ))}

      {/* <Tooltip title="Chat threads">
        <ChatIcon
          // onClick={() => createNewGrape().then(id => setTimeout(() => focusGrape(id), 250))}
          onClick={() => (document.location.pathname = '/chat')}
          style={{ padding: 8, cursor: 'pointer', fill: 'white' }}
        />
      </Tooltip> */}

      <Popover
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchorEl={drawerAnchor?.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container direction="column" style={{ width: 240 }}>
          <Grid
            container
            item
            style={{ height: 140, background: theme.palette.primary.main, padding: 24 }}
            justify="center"
            alignItems="center"
          >
            {user ? (
              <React.Fragment>
                <h3 style={{ color: "white", margin: 0 }} children={user.name + " " + user.surname} />
                <h5 style={{ color: "white", margin: 0 }} children={user.email} />
              </React.Fragment>
            ) : null}
          </Grid>

          <List>
            {/* <ListItem button>
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary="Albero" onClick={() => history.push('/tree')} />
            </ListItem> */}

            <a
              href="/changelog.html"
              target="_blank"
              rel="noopener noreferrer"
              css={{ color: theme.palette.primary.main, textDecorationColor: theme.palette.primary.main }}
            >
              <Grid
                container
                style={{ background: "white", height: 100 }}
                justify="center"
                alignItems="center"
                children={
                  "v0.1." + ("" + commit.count).trim()
                  // commit.date ? moment(new Date(commit.date)).format('Do MMMM YYYY, HH:mm') : JSON.stringify(commit)
                }
              />
            </a>

            <Divider />

            <ListItem button>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                onClick={() => {
                  if (window.confirm("Vuoi uscire?")) {
                    localStorage.removeItem("app-token");
                    window.location.href = "";
                  }
                }}
              />
            </ListItem>
          </List>
        </Grid>
      </Popover>
    </Box>
  );
};

export default AppBarSmall;

interface IAction {
  title: string;
  onClick?: () => void;
  muicon?: string;
  subactions?: IAction[];
}

const Action = (props: { action: IAction; color?: string }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef<any>();

  const onClick = () => {
    props.action.onClick?.();
    if (props.action.subactions?.length) {
      setMenuOpen(true);
    }
  };

  return (
    <React.Fragment>
      {props.action.subactions?.length ? (
        <Menu anchorEl={ref.current} open={menuOpen} onClose={() => setMenuOpen(false)}>
          {props.action.subactions.map((a, i) => (
            <MenuItem
              key={i}
              children={<Action action={a} color="#333333" />}
              onClick={() => setMenuOpen(false)}
              css={{
                backgroundColor: "transparent !important",
                ":hover": {
                  textShadow: "-0.06ex 0 currentColor, 0.06ex 0 currentColor",
                },
              }}
            />
          ))}
        </Menu>
      ) : null}

      {props.action.muicon ? (
        <Tooltip title={props.action.title}>
          <i
            ref={(r) => (ref.current = r)}
            className={"material-icons"}
            // onClick={() => createNewGrape().then(id => setTimeout(() => focusGrape(id), 250))}
            onClick={onClick}
            style={{ color: props.color || "white", padding: 8, cursor: "pointer" }}
            children={props.action.muicon || props.action.title}
          />
        </Tooltip>
      ) : (
        <div
          ref={(r) => (ref.current = r)}
          // className="hoverable"
          children={props.action.title}
          onClick={onClick}
          css={{ color: props.color || "white" }}
        />
      )}
    </React.Fragment>
  );
};
