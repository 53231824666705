import { gql } from "apollo-boost";
import { USER_FRAGMENT } from "../../../repositories/fragments";
import { IGrape } from "../../grape_gql_interface";
import { IUser } from "../../User";

export interface IGrapeComment {
  id: string;
  grape_id: string;
  grape?: IGrape;
  owner_id: string;
  owner?: IUser;
  message: string;
  media_url: string;
  media_thumbnail: string;
  reference_comment_id: string;
  reference_comment?: IGrapeComment;
  user_read_ids: string[];
  users_read?: IUser[];
  created_at: string;
  updated_at: string;
}

export const COMMENT_FRAGMENT = gql`
  ${USER_FRAGMENT}

  fragment comment on Comment {
    id
    # grape_id
    # grape
    owner_id
    owner {
      ...user
    }
    message
    media_url
    media_thumbnail
    reference_comment_id
    reference_comment {
      id
      owner_id
      owner {
        ...user
      }
      message
      media_url
      media_thumbnail
    }
    user_read_ids
    # users_read
    created_at
    updated_at
  }
`;

export const GET_COUNT_COMMENTS = gql`
  query getCountComments($grapeId: ID!) {
    getCountOfCommentsToReadOfGrape(grape_id: $grapeId) {
      total
      unread
    }
  }
`;

export const GET_COMMENTS = gql`
  ${COMMENT_FRAGMENT}

  query getGrapeComments($grapeId: ID!) {
    getCommentsOfGrape(grape_id: $grapeId) {
      ...comment
    }
  }
`;

export const CREATE_GRAPE_COMMENT = gql`
  ${COMMENT_FRAGMENT}

  mutation createGrapeComment($input: InputCreateComment) {
    createComment(input: $input) {
      ...comment
    }
  }
`;

export const EDIT_GRAPE_COMMENT = gql`
  ${COMMENT_FRAGMENT}

  mutation editGrapeComment($input: InputEditComment) {
    editComment(input: $input) {
      ...comment
    }
  }
`;

export const READ_GRAPE_COMMENTS = gql`
  mutation readCommentsOfGrape($grapeId: ID!) {
    readCommentsOfGrape(grape_id: $grapeId)
  }
`;

export const DELETE_GRAPE_COMMENT = gql`
  mutation deleteGrapeComment($id: ID!) {
    deleteComment(id: $id)
  }
`;
