import { gql } from "apollo-boost";
import ApolloClient from "apollo-client";
import { GRAPE_FRAGMENT, IGrape } from "../components/grape_gql_interface";

export const retrieveAnchestorsFromCache = (
  client: ApolloClient<any>,
  grape: { id: string; title?: string; parent?: string }
) => {
  const anchestors: IGrape[] = [];

  const readSimpleGrapeFragment = (client: ApolloClient<any>, id: string) => {
    return client.readFragment({
      id: "Grape:" + id,
      fragmentName: "grapeList",
      fragment: gql`
        ${GRAPE_FRAGMENT}
        fragment grapeList on Grape {
          ...grape
        }
      `,
    }) as IGrape;
  };

  const pushSafeParent = (pid: string | undefined) => {
    if (!pid) return;
    // anchestors.push({ id: pid });

    try {
      const g = readSimpleGrapeFragment(client, pid);
      anchestors.unshift(g);
      pushSafeParent(g?.parent);
    } catch (e) {
      anchestors.unshift({ id: pid } as any);
      console.log("❌ --> ", e);
    }
  };

  let parentId = grape.parent;
  if (!parentId) {
    try {
      parentId = readSimpleGrapeFragment(client, grape.id).parent;
    } catch (e) {
      console.log("❌ none --> ", e);
    }
  }
  anchestors.push(grape as any);
  pushSafeParent(parentId);

  return anchestors;
};
