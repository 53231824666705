import { useApolloClient } from "@apollo/react-hooks";
import { DragDropContext } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import { onDragEndHandler } from "../../modules/dndHandler";
import { GrapeDetailProvider } from "../GrapeDetail/GrapeDetail";
import { useRootGrapeContextSelector } from "./RootGrapeProvider";

export const AppGrapeDetail = (props: {}) => {
  const client = useApolloClient();
  const history = useHistory();

  const selectedGrape = useRootGrapeContextSelector("selectedGrape");
  const setRootSelectedGrape = useRootGrapeContextSelector("setRootSelectedGrape");
  const selectedMyGrape = useRootGrapeContextSelector("selectedMyGrape");
  const urlGrapes = useRootGrapeContextSelector("urlGrapes");

  if (!selectedGrape) return null;
  return (
    <DragDropContext onDragEnd={(res) => onDragEndHandler(client, res)}>
      <GrapeDetailProvider
        key={selectedGrape.id}
        anchestors={[]}
        myGrape={selectedMyGrape}
        onRequestClose={selectedMyGrape ? () => setRootSelectedGrape(selectedMyGrape, undefined) : undefined}
        initialUrlGrapes={urlGrapes || []}
        // onGrapeSubpathChange={(subpath: string) => history.push("/" + selectedGrape.id + "/" + subpath)}
        onGrapeSubpathChange={(subpath: string) => {
          history.push("/" + (selectedMyGrape ? selectedMyGrape + "/" : "") + subpath);
        }}
        onDeleted={() => setRootSelectedGrape(selectedMyGrape, undefined)}
        grape={
          selectedGrape
            ? ({
                id: selectedGrape.id,
                title: selectedGrape.title,
                type: selectedGrape.type,
                children: selectedGrape.children,
              } as any)
            : undefined
        }
      />
    </DragDropContext>
  );
};
