import React, { ReactElement, useRef, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export interface IPriority {
  id: "expedited" | "high" | "medium" | "low";
  value: number;
  color: string;
}

export const grapesPriorities = [
  { id: "expedited", value: 100, color: "#dd0000" },
  { id: "high", value: 80, color: "#ff7d14" },
  { id: "medium", value: 60, color: "#fbb71d" },
  // default --> value: 40
  { id: "low", value: 20, color: "#0099ff" },
] as IPriority[];

export function getCloseGrapePriority(value: number): number {
  const priority = grapesPriorities.find((p) => p.value === value);
  if (priority) {
    return priority.value;
  }
  const closest = grapesPriorities.reduce((prev, curr) =>
    Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev
  );
  return closest.value;
}

const iconSize = 20;
const fontSize = 24;

export const GrapePriorityIcon = (props: { priority: IPriority }) => {
  if (!props.priority) {
    return null;
  }
  if (props.priority.id === "expedited") {
    return <MultipleIconsBuilder count={3} upward color={props.priority.color} />;
  }
  if (props.priority.id === "high") {
    return <MultipleIconsBuilder count={2} upward color={props.priority.color} />;
  }
  if (props.priority.id === "medium") {
    return <MultipleIconsBuilder count={1} upward color={props.priority.color} />;
  }
  if (props.priority.id === "low") {
    return <MultipleIconsBuilder count={2} color={props.priority.color} />;
  }
  return null;
};

const MultipleIconsBuilder = (props: { count: number; color: string; upward?: boolean }) => {
  const outboundShift = (iconSize - fontSize) / 2;
  const totHeight = props.count * iconSize * 0.25;
  const yFix = -(props.count - 1) * iconSize * 0.1;
  return (
    <div
      style={{
        position: "relative",
        width: iconSize,
        height: iconSize,
        display: "inline-block",
      }}
    >
      {[...new Array(props.count)].map((_, index) => {
        const shift = (index / props.count) * totHeight;
        return (
          <ExpandMoreIcon
            key={index}
            style={{
              fontSize,
              transform: props.upward ? "rotate(180deg)" : undefined,
              position: "absolute",
              left: outboundShift,
              top: outboundShift + shift + yFix,
              color: props.color,
            }}
          />
        );
      })}
    </div>
  );
};
