import { GrapeDetailCanvas } from "../../GrapeDetailCanvas/GrapeDetailCanvas";
import { GrapeDetailDocument } from "../../GrapeDetailDocument/GrapeDetailDocument";
import { GrapeDetailList } from "../../GrapeDetailList/GrapeDetailList";
import { GrapeDetailSprint } from "../../GrapeDetailSprint/GrapeDetailSprint";
import { PageGrapeDetail } from "../../GrapePage/PageGrapeDetail";
import { IGrape } from "../../grape_gql_interface";
import { hideGrapesFromChildren } from "../core/grapeTypes";
import { BoardGrape } from "../GrapeBoard";
import { GrapeDetailGantt } from "../GrapeDetailGantt";
import KanbanGrape from "../KanbanGrape";
import RichTextGrape from "../RichTextGrape";
import { useSafeGrapeDetailContextSelector } from "./hooks/GrapeDetailContext";
import { useGrapeDetailRenderColumn } from "./hooks/GrapeDetailRenderColumn";

export const GrapeDetailBody = () => {
  const grape = useSafeGrapeDetailContextSelector("grape");
  const isGrapeReady = useSafeGrapeDetailContextSelector("isGrapeReady");
  const allFoundLabels = useSafeGrapeDetailContextSelector("allFoundLabels");
  const collaborators = useSafeGrapeDetailContextSelector("collaborators");
  const allAnchestors = useSafeGrapeDetailContextSelector("allAnchestors");
  const subPath = useSafeGrapeDetailContextSelector("subPath");
  // actions
  const editGrape = useSafeGrapeDetailContextSelector("editGrape");
  const onRequestOpen = useSafeGrapeDetailContextSelector("onRequestOpen");
  const getGrapeSemiPath = useSafeGrapeDetailContextSelector("getGrapeSemiPath");
  const setSubPath = useSafeGrapeDetailContextSelector("setSubPath");
  const onGrapeSubpathChange = useSafeGrapeDetailContextSelector("onGrapeSubpathChange");
  const renderGrapesColumn = useGrapeDetailRenderColumn();

  if (!isGrapeReady) return null;

  const noChatChildrens: IGrape[] = grape.children.filter((x) => !hideGrapesFromChildren.includes(x?.type)) || [];

  if (subPath === "gantt") {
    return <GrapeDetailGantt />;
  }

  switch (grape.type) {
    case "root":
      return (
        <RichTextGrape
          editGrape={editGrape}
          childrens={noChatChildrens}
          grape={grape}
          renderGrapesColumn={renderGrapesColumn}
        />
      );
      return <GrapeDetailDocument />;
      return (
        <PageGrapeDetail
          editGrape={editGrape}
          childrens={noChatChildrens}
          grape={grape}
          renderGrapesColumn={renderGrapesColumn}
        />
      );
    case "detail":
      return (
        <RichTextGrape
          editGrape={editGrape}
          childrens={noChatChildrens}
          grape={grape}
          renderGrapesColumn={renderGrapesColumn}
        />
      );
    case "board":
      return (
        <BoardGrape
          editGrape={editGrape}
          onRequestOpen={(item, params) => onRequestOpen(item, params)}
          // onDeleted={() => onDeleted(item)} // TODO wip
          collaborators={collaborators}
          childrens={noChatChildrens}
          anchestors={[...allAnchestors, grape]}
          grape={grape}
          allLabels={allFoundLabels}
          renderGrapesColumn={renderGrapesColumn}
          subPath={subPath}
          onSubPathChange={(subpath) => {
            setSubPath(subpath);
            onGrapeSubpathChange?.(getGrapeSemiPath(subpath));
          }}
        />
      );
    case "kanban":
      return (
        <KanbanGrape
          editGrape={editGrape}
          onRequestOpen={(item, params) => onRequestOpen(item, params)}
          // onDeleted={() => onDeleted(item)} // TODO wip
          collaborators={collaborators}
          childrens={noChatChildrens}
          anchestors={[...allAnchestors, grape]}
          grape={grape}
          allLabels={allFoundLabels}
          renderGrapesColumn={renderGrapesColumn}
          subPath={subPath}
          onSubPathChange={(subpath) => {
            setSubPath(subpath);
            onGrapeSubpathChange?.(getGrapeSemiPath(subpath));
          }}
        />
      );
    case "canvas":
      return (
        <GrapeDetailCanvas
          grape={grape}
          collaborators={collaborators}
          editGrape={editGrape}
          onRequestOpen={(item, params) => onRequestOpen(item, params)}
        />
      );
    case "sprint":
      return <GrapeDetailSprint />;
    //? bad states
    case "chat":
      console.log("bad state element (chat)");
      return null;
  }

  return <GrapeDetailList />;
};
