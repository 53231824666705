/** @jsxImportSource @emotion/react */

import { useTheme } from "@material-ui/core";
import CheckBoxChecked from "@material-ui/icons/CheckBox";
import CheckBoxEmpty from "@material-ui/icons/CheckBoxOutlineBlank";
import { useEffect, useRef, useState } from "react";

const OptimisticTristateCheckbox = (props: {
  style?: any;
  containerStyle?: any;
  value: number;
  onChange: (val: number) => void;
  size?: "medium" | "small";
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(props.value || 0);
  const [updated, setUpdated] = useState(true);

  const clickRef = useRef<{ delayValue?: number; timeout?: NodeJS.Timeout; consumed?: boolean }>({});


  // const Icona = value === 1 ? CheckBoxChecked : value === 0 ? CheckBoxEmpty : CheckBoxIndeterminate;
  const Icona = value === 1 ? CheckBoxChecked : CheckBoxEmpty;

  useEffect(() => {
    setUpdated(true);
    setValue(props.value);
  }, [props.value]);

  const dispatchOnChange = (v: number) => {
    setValue(v);
    props.onChange(v);
  };

  const onPointerCancel = (e: any) => {
    clickRef.current.consumed = true;
    if (clickRef.current.timeout) clearTimeout(clickRef.current.timeout);
  };

  const onPointerUp = (e: any) => {
    if (!clickRef.current.consumed) {
      clickRef.current.consumed = true;
      dispatchOnChange(clickRef.current.delayValue || 0);
      if (clickRef.current.timeout) clearTimeout(clickRef.current.timeout);
    }
  };

  const onPointerDown = (e: any) => {
    if (e.nativeEvent.which === 3) {
      //? right click
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    const value = props.value;

    // wheel click
    if (e.nativeEvent.which === 2) {
      clickRef.current.consumed = true;
      const newValue = value !== 2 ? 2 : 0;
      dispatchOnChange(newValue);
      return true;
    }

    // click
    clickRef.current.delayValue = value !== 1 ? 1 : 0;
    clickRef.current.consumed = false;

    if (clickRef.current.timeout) clearTimeout(clickRef.current.timeout);
    clickRef.current.timeout = setTimeout(() => {
      if (!clickRef.current.consumed) {
        clickRef.current.consumed = true;
        dispatchOnChange(value !== 2 ? 2 : 0);
      }
    }, 500);

    return true;
  };

  const size = props.size === "small" ? 18 : 24;
  const hitboxSize = props.size === "small" ? 24 : 32;

  return (
    <div
      style={props.containerStyle}
      css={{
        position: "relative",
        marginRight: 4,
        width: size,
        height: size,
        opacity: updated ? 1 : 0.4,
        "::before": {
          content: '""',
          position: "absolute",
          top: -10, //-13
          bottom: -10,
          right: -10,
          left: -10,
          margin: "auto",
          width: hitboxSize,
          height: hitboxSize,
          opacity: 0.01,
          borderRadius: "50%",
          backgroundColor: "transparent",
        },
        "::after": {
          content: '""',
          position: "absolute",
          opacity: props.value === 2 ? 1 : 0,
          backgroundColor: theme.palette.secondary.main,
          top: 0, // -3,
          bottom: 0,
          right: 0,
          left: 0,
          margin: "auto",
          ...(props.size === "small" ? { width: 6, height: 6 } : { width: 8, height: 8 }),
        },
        ":hover": {
          "&:active": { "::before": { opacity: 0.15 } },
          "::before": {
            backgroundColor: theme.palette.secondary.main,
            opacity: 0.06,
          },
        },
      }}
      onClick={(e) => e.stopPropagation()}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerCancel={onPointerCancel}
    >
      <Icona
        style={{
          fontSize: size,
          color: value === 1 ? theme.palette.secondary.main : undefined,
        }}
      />
    </div>
  );
};

export default OptimisticTristateCheckbox;
