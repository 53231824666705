import { useQuery } from "@apollo/react-hooks";
import { keyframes } from "@emotion/react";
import { fade, Tooltip, useTheme } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import moment, { Moment } from "moment";
import React, { useCallback, useRef, useState } from "react";
import Grapes from "../../../../modules/Grapes";
import {
  cleanHTMLTags,
  firstValidColor,
  recursiveFilterGrapeChildrenMatch,
  showColorPickerModal,
  showConfirmDialog,
  showInputDialog,
} from "../../../../utils";
import Contextable from "../../../Contextable";
import { ContextableUserAssign } from "../../../ContextableUserAssign";
import GrapeLabels from "../../../GrapeLabels";
import { GET_GRAPE, GrapeChildrenFilter, IGrape } from "../../../grape_gql_interface";
import MLDialog from "../../../MLDialog";
import { hideGrapesFromChildren } from "../../core/grapeTypes";
import { useSafeGrapeDetailContextSelector } from "../../GrapeDetail/hooks/GrapeDetailContext";
import { getUserNickname } from "../../GrapeDetailPointers/GrapeUserPointer";
import {
  addDaysToDateWithCertainWeekdays,
  daysDurationWithoutCertainWeekdays,
  GrapesGanttMetrics,
  uniqueGrapeDateKey,
  useGrapesGanttContext,
} from "./GrapesGantt";

const parseTaskToDateRange = ({ task, firstDate }: { task: IGrape; firstDate: Moment }) => {
  const start = moment(task.date0 || task.date).startOf("day");
  const end = moment.max(start, moment(task.date)).endOf("day");

  const startIndex = daysDurationWithoutCertainWeekdays({
    start: firstDate,
    end: start,
    removeDays: GrapesGanttMetrics.hideDays,
  });
  const duration = Math.max(
    daysDurationWithoutCertainWeekdays({
      start: start,
      end: end,
      removeDays: GrapesGanttMetrics.hideDays,
    }) + 1,
    1
  );
  return { start, end, startIndex, duration };
};

const getTaskAssigneesNames = ({
  task,
  subTasks,
  expanded,
}: {
  task: IGrape;
  subTasks: IGrape[];
  expanded: boolean;
}) => {
  const assigned: string[] = [];
  const assignees = (task.assignees || []).map((user) => {
    assigned.push(user.id);
    return user;
  });
  if (expanded) {
    for (let c = 0; c < subTasks.length; c++) {
      const subTask = subTasks[c];
      for (let u = 0; u < subTask?.assignees?.length; u++) {
        const user = subTask.assignees[u];
        if (!assigned.includes(user.id)) {
          assigned.push(user.id);
          assignees.push(user);
        }
      }
    }
  }
  return assignees;
};

export const useGanttTask = (ganttTask: IGrape, indent: number) => {
  let task: IGrape = ganttTask;

  /// get grape if MIRRROR or SPRINT related
  const mirrorGrapeId = Grapes.getSetting(task, "mirror-grape");
  const { data: dataGrape } = useQuery(GET_GRAPE, {
    variables: { id: mirrorGrapeId || task.id },
    skip: !(mirrorGrapeId || ["mirror", "sprint"].includes(task.type)),
  });
  if (dataGrape?.allGrapes?.[0]?.id) {
    task = { ...task, ...dataGrape?.allGrapes?.[0] };
    if (task.related_to) task.children = [...(task.children || []), ...(task.related_to || [])];
  }

  /// derive task data
  const isDeadline = task.type === "deadline";
  const isDeadlineTrack = isDeadline && task.status === 3;
  const isInvalidDate = !(task.date0 || task.date);
  const singleDateIcon = isDeadline && !isDeadlineTrack ? "flag" : undefined;
  const childrenFilter = useSafeGrapeDetailContextSelector("childrenFilter");
  const subTasks = getGanttSubtasks(task, indent >= 0 ? undefined : { childrenFilter });

  /// grapeLabel system for task colors
  const labelColors = useSafeGrapeDetailContextSelector("labelColors") || {};
  const getDefaultGanttColor = () => {
    let color = indent ? (isDeadline ? (isDeadlineTrack ? "#2196f3" : "#ffaa00") : "#77cc44") : "#cccccc";
    return color;
  };

  return {
    subTasks,
    isDeadline,
    singleDateIcon,
    isDeadlineTrack,
    isInvalidDate,
    color: firstValidColor(labelColors[task.title]) || getDefaultGanttColor(),
    labelColors,
  };
};

export const GrapesGanttTask = ({ task, indent }: { task: IGrape; indent: number }) => {
  const { horizontalScrollRef, isExpanded, firstDate, onDateChange, totalDaysWithoutWeeks } = useGrapesGanttContext();
  const { subTasks, isInvalidDate, isDeadlineTrack, isDeadline } = useGanttTask(task, indent);

  const expanded = isExpanded?.(task.id) ?? false;
  const taskTracings = task.children?.filter((t) => t.type === "deadline") || [];
  const teoric = parseTaskToDateRange({ task, firstDate });

  const assignees = getTaskAssigneesNames({ task, subTasks, expanded });

  const [hintAnimation, setHintAnimation] = useState<{ x: number; direction: "to-left" | "to-right" }>();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        background: !expanded
          ? undefined
          : `linear-gradient(rgba(0, 0, 0, 0.03),
                             rgba(0, 0, 0, 0),
                             rgba(0, 0, 0, 0),
                             rgba(0, 0, 0, 0),
                             rgba(0, 0, 0, 0.03))`,
      }}
    >
      <div
        onPointerDown={(e) => {
          // left click only
          if (e.button !== 0) return;

          const scrollContainer = horizontalScrollRef.current;
          const scrollLeft = scrollContainer?.scrollLeft || 0;
          const xContainer = scrollContainer?.getBoundingClientRect().x ?? 0;
          const x = e.pageX + scrollLeft - xContainer;
          const day = Math.floor(x / GrapesGanttMetrics.dayWidth);

          const date = addDaysToDateWithCertainWeekdays({
            date: firstDate,
            daysDelta: day,
            removeDays: GrapesGanttMetrics.hideDays,
          });

          if (isInvalidDate) {
            onDateChange(task.id, "start", date);
            onDateChange(task.id, "end", date);
          } else {
            const delta = 30;
            if (moment(task.date).diff(date, "days") > delta) {
              setHintAnimation({ x, direction: "to-right" });
            } else if (moment(task.date0 || task.date).diff(date, "days") < -delta) {
              setHintAnimation({ x, direction: "to-left" });
            }
          }
        }}
        css={{
          width: totalDaysWithoutWeeks * GrapesGanttMetrics.dayWidth,
          // backgroundColor: getStringColors(task.title).background,
          position: "relative",
          cursor: isInvalidDate ? "crosshair" : undefined,
          ":hover": {
            "::before": {
              content: "''",
              position: "absolute",
              left: 0,
              right: 0,
              top: GrapesGanttMetrics.rowPadding,
              bottom: GrapesGanttMetrics.rowPadding,
              backgroundColor: GrapesGanttMetrics.rowHoverColor,
            },
          },
        }}
      >
        <div
          css={{
            height: GrapesGanttMetrics.rowHeight,
            transform: `translateY(${GrapesGanttMetrics.rowPadding}px)`,
          }}
        >
          {hintAnimation && <HintAnimation key={hintAnimation.direction + hintAnimation.x} {...hintAnimation} />}

          {/* TEORIC */}
          {isInvalidDate ? null : (
            <div
              css={{
                marginLeft: GrapesGanttMetrics.dayWidth * teoric.startIndex + 1,
                width: GrapesGanttMetrics.dayWidth * teoric.duration - 1,
                height:
                  (GrapesGanttMetrics.rowHeight - GrapesGanttMetrics.rowPadding * 2) /
                  (expanded || isDeadline ? 1 : subTasks?.length ? 2 : 1),
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <TaskDraggables
                task={task}
                resizable={!isDeadline || isDeadlineTrack}
                indent={indent}
                value={teoric.start}
                duration={teoric.duration}
                onChangeComplete={(value) => {
                  if (value.start) onDateChange(task.id, "start", value.start);
                  if (value.end) onDateChange(task.id, "end", value.end);
                }}
              />

              <div
                css={{
                  position: "absolute",
                  top: 0,
                  lineHeight: GrapesGanttMetrics.rowHeight - GrapesGanttMetrics.rowPadding * 2 + "px",
                  left: "100%",
                  fontSize: 12,
                  marginLeft: 10,
                  zIndex: 1,
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  textShadow: "0px 0px 3px #fff",
                }}
                children={assignees.map((u) => getUserNickname(u)).join(", ")}
              />
            </div>
          )}

          {/* TRACK */}
          {!expanded &&
            // taskTracings.map((taskTrack) => {
            subTasks.map((taskTrack) => <SubTaskTrack indent={indent} taskTrack={taskTrack} key={taskTrack.id} />)}
        </div>
      </div>

      {expanded && (
        <React.Fragment>
          {subTasks.map((t) => (
            <GrapesGanttTask key={uniqueGrapeDateKey(t)} task={t} indent={indent ? indent + 1 : 1} />
          ))}

          {/* <div css={{ height: GrapesGanttMetrics.rowAddHeight }} /> */}
        </React.Fragment>
      )}
    </div>
  );
};

const SubTaskTrack = ({ taskTrack, indent }: { taskTrack: IGrape; indent: number }) => {
  const { firstDate, onDateChange } = useGrapesGanttContext();
  const { isDeadline, isDeadlineTrack, isInvalidDate, singleDateIcon } = useGanttTask(taskTrack, indent);
  const track = parseTaskToDateRange({ task: taskTrack, firstDate });

  if (isInvalidDate) return null;

  return (
    <div
      key={taskTrack.id}
      css={{
        width: GrapesGanttMetrics.dayWidth * track!.duration - 1,
        height: (GrapesGanttMetrics.rowHeight - GrapesGanttMetrics.rowPadding * 2) / 2,
        zIndex: singleDateIcon ? 1 : undefined,

        position: "absolute",
        bottom: GrapesGanttMetrics.rowPadding * 2,
        left: GrapesGanttMetrics.dayWidth * track!.startIndex + 1,
      }}
    >
      <TaskDraggables
        key={uniqueGrapeDateKey(taskTrack)}
        task={taskTrack}
        resizable={!isDeadline || isDeadlineTrack}
        indent={indent || 1}
        //opacity: singleDateIcon ? 1 : 0.4,
        value={track!.start}
        duration={track!.duration}
        onChangeComplete={(value) => {
          if (value.start) onDateChange(taskTrack.id, "start", value.start);
          if (value.end) onDateChange(taskTrack.id, "end", value.end);
        }}
      />
    </div>
  );
};

const TaskDraggables = ({
  task,
  value,
  indent,
  duration,
  onChangeComplete,
  resizable,
}: {
  task: IGrape;
  value: Moment;
  indent: number;
  duration: number;
  onChangeComplete: (value: { start?: Date; end?: Date }) => void;
  resizable: boolean;
}) => {
  const [moveSteps, setMoveSteps] = useState<number | null>(null);
  const { isDeadline, color, singleDateIcon } = useGanttTask(task, indent);
  const { onTaskAction } = useGrapesGanttContext();

  const drag = useRef(0);
  const steps = useRef(0);
  const delta = useRef({ duration });
  const direction = useRef<"left" | "right" | "both">("both");

  delta.current.duration = duration;

  const handlePointerMove = useCallback((e: PointerEvent) => {
    let curSteps = Math.round((e.clientX - drag.current) / GrapesGanttMetrics.dayWidth);
    if (direction.current === "left") {
      if (curSteps >= delta.current.duration) {
        curSteps = delta.current.duration - 1;
      }
    } else if (direction.current === "right") {
      if (curSteps <= -delta.current.duration) {
        curSteps = -delta.current.duration + 1;
      }
    }

    if (curSteps !== steps.current) {
      setMoveSteps(curSteps);
    }
    steps.current = curSteps;
  }, []);

  const handleMouseUpToCancel = useCallback(() => {
    const changes: { start?: Date; end?: Date } = {};

    if (direction.current !== "right") {
      changes.start = addDaysToDateWithCertainWeekdays({
        date: value,
        daysDelta: steps.current,
        removeDays: GrapesGanttMetrics.hideDays,
      });
    }
    if (direction.current !== "left") {
      changes.end = addDaysToDateWithCertainWeekdays({
        date: value,
        daysDelta: steps.current + duration - 1,
        removeDays: GrapesGanttMetrics.hideDays,
      });
    }

    // dispatch change event
    if (steps.current !== 0) onChangeComplete(changes);

    // reset state
    setMoveSteps(null);
    window.removeEventListener("pointermove", handlePointerMove);
    window.removeEventListener("pointerup", handleMouseUpToCancel);
    window.removeEventListener("keydown", handleKeyPress);
  }, []);

  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") {
      steps.current = 0;
      handleMouseUpToCancel();
    }
  }, []);

  return (
    <GanttTaskContextables task={task} indent={indent}>
      <div
        css={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right:
            direction.current === "both"
              ? 0
              : (moveSteps || 0) * GrapesGanttMetrics.dayWidth * (direction.current === "left" ? 1 : -1),
          borderRadius: 4,
          // background: singleDateIcon ? undefined : color,
          background: singleDateIcon
            ? undefined
            : "linear-gradient(90deg, " +
              color +
              ", " +
              color +
              " " +
              (task.progress || 0) * 100 +
              "%, " +
              fade(color, 0.7) +
              " " +
              (task.progress || 0) * 100 +
              "%, " +
              fade(color, 0.7) +
              ")",

          // background: "white",
          // boxShadow: "inset 0 0 0 1px " + color, // "inset 0 0 0 1px #ccc",
          cursor: "ew-resize",
          transform:
            direction.current === "right"
              ? undefined
              : `translateX(${(moveSteps || 0) * GrapesGanttMetrics.dayWidth}px)`,
        }}
        onPointerDown={(e) => {
          // left click only
          if (e.button !== 0) return;

          const className: string = (e.target as any)?.className;
          direction.current = className.includes("w-resize")
            ? "left"
            : className.includes("e-resize")
            ? "right"
            : "both";

          drag.current = e.clientX;
          window.addEventListener("pointermove", handlePointerMove);
          window.addEventListener("pointerup", handleMouseUpToCancel);
          window.addEventListener("keydown", handleKeyPress);
        }}
        onPointerCancel={() => {
          handleMouseUpToCancel();
        }}
        onPointerUp={() => {
          handleMouseUpToCancel();
        }}
      >
        {singleDateIcon && (
          <div
            className="material-icons"
            css={{
              position: "absolute",
              top: 0,
              bottom: 0,
              pointerEvents: "none",
              display: "flex",
              lineHeight: "16px",
              fontSize: 16,
              color: color,
            }}
            children={singleDateIcon}
          />
        )}

        {resizable && (
          <div
            className="w-resize"
            css={{
              position: "absolute",
              cursor: "w-resize",
              top: 0,
              bottom: 0,
              left: -GrapesGanttMetrics.dayWidth / 4,
              width: GrapesGanttMetrics.dayWidth / 2,
            }}
          />
        )}
        {resizable && (
          <div
            className="e-resize"
            css={{
              position: "absolute",
              cursor: "e-resize",
              top: 0,
              bottom: 0,
              right: -GrapesGanttMetrics.dayWidth / 4,
              width: GrapesGanttMetrics.dayWidth / 2,
            }}
          />
        )}
      </div>
    </GanttTaskContextables>
  );
};

export const GrapesGanttTaskTitle = ({ task, indent }: { task: IGrape; indent: number }) => {
  const { isExpanded, onExpandPressed, onTaskAction } = useGrapesGanttContext();
  const theme = useTheme();
  const expanded = isExpanded?.(task.id) ?? false;
  const { subTasks, color, isDeadline, singleDateIcon, isDeadlineTrack } = useGanttTask(task, indent);

  const isExpandable = subTasks.length > 0 && onExpandPressed;
  const bgColor = indent ? "#ffffff" : "#f0f0f0";
  const isTaskCompleted = task.progress || task.status;

  return (
    <div css={{ display: "flex", position: "relative", flexDirection: "column", paddingLeft: indent > 0 ? 16 : 0 }}>
      {indent < 0 && (
        <div
          css={{
            position: "absolute",
            bottom: 0,
            left: 1,
            right: 0,
            top: 0,
            border: "2px solid " + theme.palette.primary.main,
            borderRight: "none",
            borderTop: "none",
            pointerEvents: "none",
            zIndex: 1,
          }}
        />
      )}

      <div
        css={{
          height: GrapesGanttMetrics.rowHeight,
          ":hover": {
            ".task-more-icon": { opacity: 1, transitionDelay: "0ms !important" },
            ".task-percentage": { opacity: 0, transitionDelay: "0ms !important" },
          },
        }}
      >
        <div
          css={{
            marginTop: GrapesGanttMetrics.rowPadding,
            height: GrapesGanttMetrics.rowHeight - GrapesGanttMetrics.rowPadding * 2,
            backgroundColor: bgColor,
            display: "flex",

            alignItems: "center",
            padding: "0 4px",
          }}
        >
          {isExpandable ? (
            <ExpandMoreIcon
              onClick={() => onExpandPressed?.(task.id, !expanded)}
              style={{
                width: 16,
                opacity: 1,
                cursor: "pointer",
                transform: expanded ? "rotate(180deg)" : undefined,
              }}
            />
          ) : isDeadline ? (
            <div
              className="material-icons"
              children={isDeadlineTrack ? "keyboard_double_arrow_right" : "flag"} // alarm
              css={{ fontSize: 14, opacity: 0.6 }}
            />
          ) : null}

          <div
            // className="line-clamp-1 text-sm leading-none pl-1 flex-1"
            className="whitespace-nowrap relative overflow-clip text-sm leading-none pl-1 flex-1"
            css={{ fontWeight: indent < 0 ? "bold" : undefined }}
            // dangerouslySetInnerHTML={{ __html: cleanHTMLTags(task.title) }}
          >
            <span dangerouslySetInnerHTML={{ __html: cleanHTMLTags(task.title) }} />
            <div
              css={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                width: 10,
                background: `linear-gradient(90deg, ${fade(bgColor, 0)}, ${bgColor})`,
              }}
            />
          </div>

          <GrapeLabelsPreview grape={task} />

          <div css={{ width: 20, height: 20, position: "relative" }}>
            <GanttTaskContextables task={task} indent={indent} bothMouseButtons>
              <React.Fragment>
                <MoreIcon
                  className="task-more-icon"
                  css={{ fontSize: 20, opacity: 0, transition: "opacity 300ms", transitionDelay: "50ms" }}
                />
                <div
                  className="task-percentage"
                  css={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    fontSize: 12,
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    opacity: isTaskCompleted ? 1 : 0.4,
                    transition: "opacity 300ms",
                    transitionDelay: "50ms",
                    letterSpacing: "-1px",
                  }}
                >
                  {isTaskCompleted ? (
                    <span children="✓" css={{ color: "#0a3", marginRight: 2 }} />
                  ) : (
                    <React.Fragment>
                      <span children={(task.progress || 0) * 100} />
                      <span css={{ fontSize: 8 }} children="%" />
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            </GanttTaskContextables>
          </div>
        </div>
      </div>

      {expanded && (
        <React.Fragment>
          {subTasks.map((t) => (
            <GrapesGanttTaskTitle key={t.id} indent={indent ? indent + 1 : 1} task={t} />
          ))}

          {/* <div css={{ height: GrapesGanttMetrics.rowAddHeight }}>
            <AddTextField
              css={{ ".add-btn-hoverable": { fontSize: "14px !important" } }}
              parentGrape={task}
              addToGrape={async (title, variables) => {
                onTaskAction(task, "add", { title });
              }}
            />
          </div> */}
        </React.Fragment>
      )}

      {/* {expanded && (
          <div css={{ height: GrapesGanttMetrics.rowHeight }}>
            <div
              css={{
                marginTop: GrapesGanttMetrics.rowPadding,
                height: GrapesGanttMetrics.rowHeight - GrapesGanttMetrics.rowPadding * 2,
                background: GrapesGanttMetrics.rowHoverColor,
                display: "flex",
                alignItems: "center",
                padding: "0 4px",
              }}
            >
              <div className="line-clamp-1 text-sm leading-none pl-1" children={task.title} />
            </div>
          </div>
        )} */}
    </div>
  );
};

const getGanttSubtasks = (task: IGrape, props?: { childrenFilter: GrapeChildrenFilter | undefined }) => {
  const hideGrapesWithDeadlinesSprintsColumns = hideGrapesFromChildren.filter(
    (t) => !["deadline", "sprint", "column", "mirror"].includes(t)
  );

  let list = (task.children || []).filter((sg) => {
    if (hideGrapesWithDeadlinesSprintsColumns.includes(sg.type)) {
      return false;
    }

    if (props?.childrenFilter) {
      return recursiveFilterGrapeChildrenMatch(sg, props?.childrenFilter || {}, 4);
    }

    return true;
  });

  return list.sort((a, b) => {
    if (a.type === "deadline") {
      if (b.type === "deadline") {
        return a.status - b.status;
      }
      return -1;
    }
    if (b.type === "deadline") return 1;
    return 0;
  });
};

const GrapeLabelsPreview = ({ grape }: { grape: IGrape }) => {
  // const childrenFilter = useSafeGrapeDetailContextSelector("childrenFilter");

  /* {childrenFilter?.label && task.labels?.includes(childrenFilter.label) && (
            <div css={{ maxWidth: 100, whiteSpace: "nowrap", borderRadius: 6, overflow: "hidden" }}>
              <GrapeLabels labels={[childrenFilter.label]} />
            </div>
          )} */

  /* {childrenFilter?.label && task.labels?.includes(childrenFilter.label) && ( */

  const title = (grape.labels || []).join(", ").trim();
  if (title === "") return null;

  return (
    <Tooltip title={title}>
      <div
        css={{
          maxWidth: 60,
          whiteSpace: "nowrap",
          borderRadius: 6,
          overflow: "hidden",
          ".grape-label": { fontSize: "10px !important" },
        }}
      >
        <GrapeLabels labels={grape.labels} disableTooltip />
      </div>
    </Tooltip>
  );
};

const GanttTaskContextables = ({
  task,
  children,
  indent,
  bothMouseButtons,
}: {
  task: IGrape;
  children: any;
  indent: number;
  bothMouseButtons?: boolean;
}) => {
  const { color, isDeadline, isInvalidDate, singleDateIcon } = useGanttTask(task, indent);
  const { onTaskAction } = useGrapesGanttContext();

  const collaborators = useSafeGrapeDetailContextSelector("collaborators");

  return (
    <Contextable
      children={children}
      bothMouseButtons={bothMouseButtons}
      menuItems={[
        //? ASSEGNA TASK
        !isDeadline && {
          key: "assign",
          caption: <ContextableUserAssign grape={task} collaborators={collaborators} fromAdvancedMenu />,
          hoverable: false,
        },

        //? AGGIUNGI TRACK
        !isDeadline && {
          key: "add-track",
          caption: "Aggiungi traccia",
          onClick: () => {
            showInputDialog("Nome della nuova traccia", null, "Traccia", (title) => {
              onTaskAction?.(task, "add", {
                type: "track",
                date: task.date0 || task.date || moment().toISOString(),
                title,
              });
            });
          },
        },

        //? AGGIUNGI DEADLINE
        !isDeadline && {
          key: "add-deadline",
          caption: "Aggiungi deadline",
          onClick: () => {
            showInputDialog("Nome della deadline", null, "Deadline", (title) => {
              onTaskAction?.(task, "add", { type: "deadline", date: task.date, title });
            });
          },
        },

        //? MODIFICA PERCENTUALE
        !isDeadline &&
          !singleDateIcon && {
            key: "edit-progress",
            caption: "Percentuale: " + (task.progress || 0) * 100 + "%",
            onClick: () => {
              showInputDialog("Percentuale", (task.progress || 0) * 100 + "%", "da 0 a 100", (newPercentage) => {
                const value = newPercentage.replace("%", "");
                const percentage = parseInt(value);
                if (isNaN(percentage) || percentage < 0 || percentage > 100) {
                  MLDialog.showSnackbar("Percentuale non valida");
                  return;
                }
                const progress = percentage / 100;
                onTaskAction?.(task, "edit", { progress });
              });
            },
          },

        //? MODIFICA COLORE
        (indent > 0 || isDeadline) && {
          key: "edit-color",
          caption: "Modifica colore",
          onClick: () => {
            showColorPickerModal('"' + task.title + '" colore', color, (newColor) => {
              onTaskAction?.(task, "edit", { color: newColor });
            });
          },
        },

        //? RINOMINA
        {
          key: "rename",
          caption: "Rinomina",
          onClick: () => {
            showInputDialog("Rinominare la attività", null, cleanHTMLTags(task.title), (newTitle) => {
              if ((newTitle || "").trim() !== "") {
                onTaskAction?.(task, "edit", { title: newTitle });
              }
            });
          },
        },

        //? AGGIUNGI TASK FIGLIO
        {
          key: "add-child",
          caption: "Inserisci task figlio",
          onClick: () => {
            showInputDialog("Nome del nuovo task", null, cleanHTMLTags(task.title), (title) => {
              onTaskAction?.(task, "add", { type: "task", title });
            });
          },
        },

        //? TASK INFO
        {
          key: "info",
          color: "#ccc",
          hoverable: false,
          caption:
            task.date || task.date0
              ? (task.date0 ? moment(task.date0).format("D MMM YY") : "") +
                " --> " +
                (task.date ? moment(task.date).format("D MMM YY") : "")
              : "Nessuna data",
          onClick: () => console.log(task),
        },

        //? ELIMINA TRACCIA
        (isDeadline || !isInvalidDate) && {
          key: "delete-track",
          caption: "Elimina traccia",
          color: "#de2000",
          onClick: () => {
            showConfirmDialog(
              "Eliminare la traccia?",
              undefined,
              // isDeadline ? undefined : "L'eliminazione di questo task non è reversibile",
              () => {
                if (isDeadline) {
                  onTaskAction?.(task, "delete");
                } else {
                  onTaskAction?.(task, "edit", { date: "" });
                  onTaskAction?.(task, "edit", { date0: "" });
                }
              }
            );
          },
        },
      ]}
    />
  );
};

const HintAnimation = ({ x, direction }: { x: number; direction: "to-left" | "to-right" }) => {
  const color = "#ff0066";
  const width = 200;
  const o = 0.4;

  const run = keyframes`
    0% {
      opacity: 0;
      transform: translate3d(0,0,0);
    }
  
    10% {
      opacity: 1;
    }
  
    90% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      transform: translate3d(${2000 * (direction === "to-left" ? -1 : 1)}px,0,0);
    }
  `;

  return (
    <div
      css={{
        position: "absolute",
        left: x - width / 2,
        top: 0,
        bottom: GrapesGanttMetrics.rowPadding * 2,
        width: width,
        opacity: 0,
        animation: `${run} 1s ease-in`,
      }}
    >
      {[...new Array(2)].map((_, i) => (
        <div
          key={i}
          css={{
            position: "absolute",
            top: i % 2 === 0 ? 0 : "50%",
            left: 0,
            right: 0,
            bottom: i % 2 === 0 ? "50%" : 0,
            transform: `skewX(${60 * (i % 2 === 0 ? 1 : -1) * (direction === "to-left" ? -1 : 1)}deg)`,
            background: `linear-gradient(90deg, ${fade(color, 0)},  ${fade(color, o)} 20%,  ${fade(
              color,
              o
            )} 80%, ${fade(color, 0)})`,
          }}
        />
      ))}
    </div>
  );
};
