/** @jsxImportSource @emotion/react */

import "moment/locale/it";
import { useCallback, useEffect, useRef, useState } from "react";
import { IGrapesApp, useGrapesApp } from "../../App";
import { showConfirmDialog, showInputDialog } from "../../utils";
import UserPreferences from "../../utils/UserPreferences";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { __DEV__ } from "../../utils/init";
import Contextable, { InnerContextableRef } from "../Contextable";
import { ReactSortable } from "react-sortablejs";
import { FolderItem } from "./ClientSortableTree";

export function ClientSortableFolder<T extends { id: string }>(props: {
  folder: FolderItem<T>;
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  renderEmptyItem?: () => any;
  distance?: number;
  selectedItemId?: string;
  onChanged: (value: FolderItem<T>) => void;
  onDelete: () => void;
  disabled?: boolean;
  isExpanded: boolean;
  setExpanded: (expanded: boolean) => void;
}) {
  const isClosedAndContainsSelectedItem =
    !open && !!props.selectedItemId && props.folder.list.includes(props.selectedItemId);
  const contextableRef = useRef<InnerContextableRef>();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: isClosedAndContainsSelectedItem ? "#eee" : "white",
      }}
    >
      <Contextable
        disabled={props.disabled}
        innerRef={(r) => (contextableRef.current = r)}
        menuItems={[
          {
            key: "edit",
            caption: "Rinomina cartella",
            onClick: () => {
              showInputDialog("Modifica nome cartella", null, props.folder.name, (newName) => {
                if ((newName || "").trim() !== "") {
                  props.onChanged({ ...props.folder, name: newName });
                }
              });
            },
          },
          {
            key: "delete",
            caption: "Elimina cartella",
            color: "#de2000",
            onClick: () => {
              showConfirmDialog("Vuoi eliminare la cartella?", props.folder.name, props.onDelete);
            },
          },
        ]}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            ":hover .client-dots": { opacity: 1 },
          }}
          className="hoverable"
        >
          <div
            css={{
              flex: 1,
              padding: 6,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => props.setExpanded(!props.isExpanded)}
          >
            <div
              css={{
                border: "4px solid transparent",
                borderTop: "6px solid rgba(0, 0, 0, 0.6)",
                marginTop: 8,
                marginLeft: 6,
                marginRight: 8,
                transition: "transform 300ms",
                transformOrigin: "50% 20%",
                transform: props.isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
              }}
            />
            <div
              css={{ flex: 1, fontFamily: "Nunito Sans", fontWeight: "bold", fontSize: 15 }}
              children={props.folder.name}
            />
          </div>

          <div
            className="client-dots hoverable"
            css={{
              padding: 4,
              margin: 4,
              borderRadius: 3,
              opacity: 0.2,
              transition: "opacity 200ms",
            }}
            children={!props.disabled && <MoreVertIcon style={{ fontSize: 20 }} />}
            onClick={(e) => contextableRef.current?.open(e)}
          />
        </div>
      </Contextable>

      <div
        css={{
          paddingLeft: 10,
          marginLeft: 10,
        }}
      >
        {props.isExpanded ? (
          <ReactSortable
            animation={100}
            delayOnTouchOnly
            delay={100}
            list={props.items}
            // group={props.folder.id}
            // group={{ name: props.folder.name, pull: true, put: ["root","nested"] }}
            group={{ name: "nested", pull: true, put: ["root", "nested"] }}
            setList={(newList) => {
              //! remove folders from list (not supported yet)
              const list = newList.filter((i) => !(i as any).isFolder);

              const isChanged = list.map((i) => i.id).join(",") !== props.folder.list.join(",");
              if (isChanged) {
                props.onChanged({ ...props.folder, list: list.map((i) => i.id) });
              }
            }}
          >
            {props.items.length == 0
              ? props.renderEmptyItem
              : props.items.map((item, index) => <span key={item.id}>{props.renderItem(item, index)}</span>)}
          </ReactSortable>
        ) : null}
      </div>
    </div>
  );
}
