/** @jsxImportSource @emotion/react */
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import ArrowDropDownOutlined from "@material-ui/icons/ArrowDropDownOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";

export const itemSelectorAddItem = "add-item";

export function ItemSelector<T>(props: {
  list: T[];
  selectedId?: string;
  getId: (item: T) => string;
  renderItem: (item: T, isOpen: boolean) => React.ReactNode;
  placeholder?: string;
  onChanged: (id?: string, item?: T) => void;
  onAddPressed?: () => boolean;
  className?: string;
}) {
  const [open, setOpen] = useState(false);

  const padx = 6;
  const pady = 0;

  const IconComp = (p: any) => {
    return (
      <div
        css={{
          position: "relative",
          cursor: "pointer",
          ":hover": { background: "white", "svg:last-of-type": { fill: "#aa0000 !important" } },
        }}
        tabIndex={0}
      >
        <ArrowDropDownOutlined
          css={{ pointerEvents: "none", position: "absolute", left: -20, top: 0, bottom: 0, margin: "auto" }}
        />
        <CloseIcon css={{ fontSize: 18, margin: "2px 4px 0 4px" }} onClick={() => props.onChanged("")} />
      </div>
    );
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      css={{
        ":hover": {
          opacity: 0.6,
        },
        ".MuiSelect-root": {
          backgroundColor: "transparent !important",
        },
        ".MuiInputLabel-outlined": {
          transform: `translate(${padx + 1}px, ${pady + 1}px) scale(1) !important`,
        },
        ".MuiSelect-outlined": {
          padding: `${pady}px ${padx}px`,
          paddingLeft: props.selectedId ? undefined : 50,
          paddingRight: props.selectedId ? 24 : undefined,
        },
        ".MuiSelect-iconOutlined": {
          right: 0,
          color: "black",
        },
        // card border
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#dddddd !important",
          borderWidth: "1px !important",
        },
        // text label
        ".MuiFormLabel-root, .MuiInputBase-root": {
          color: "black !important", // rgba(0, 0, 0, 0.6)
          height: 20,
          background: "none !important",
          fontSize: 12,
          lineHeight: "16px",
          fontFamily: "monospace",
          fontWeight: "bold",
        },
        ".MuiFormLabel-root": {
          height: 16,
          top: 0,
          bottom: 0,
          margin: "auto",
        },
        // ".MuiInputBase-root": {
        //   padding: 2,
        // },
      }}
    >
      {props.selectedId ? null : <InputLabel children={props.placeholder} disableAnimation />}

      <Select
        className={props.className}
        // disableUnderline
        value={props.selectedId ?? ""}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        IconComponent={props.selectedId ? IconComp : undefined}
        onChange={(e) => {
          if (e.target?.value !== undefined && typeof e.target?.value === "string") {
            const itemId = e.target.value;
            if (itemId === itemSelectorAddItem) {
              if (props.onAddPressed!()) {
                props.onChanged(itemId);
              }
            } else {
              const item = props.list.find((item) => props.getId(item) === itemId);
              props.onChanged(itemId, item);
            }
          }
        }}
      >
        <MenuItem value={""} style={{ opacity: 0.5 }} children={props.placeholder} />
        {props.list.map((item, index) => {
          const itemId = props.getId(item);
          return (
            <MenuItem
              key={itemId}
              value={itemId}
              children={props.renderItem(item, open)}
              selected={props.selectedId == itemId}
            />
          );
        })}
        {props.onAddPressed ? (
          <MenuItem value={itemSelectorAddItem} style={{ opacity: 0.6 }} children="Aggiungi +" />
        ) : null}
      </Select>
    </FormControl>
  );
}
