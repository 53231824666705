import { useTheme } from "@material-ui/core";
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import { placeCaretAtEnd } from "../utils/init";
import { onPasteEventParseFormattedText } from "./DescriptionInput";

export interface SmartInputFieldRef {
  getElement: (() => HTMLInputElement) | null;
  setText: (text: string) => void;
  clearText: () => void;
  requestFocus: (caretAtEnd?: boolean) => void;
}

export const SmartInputField = forwardRef(
  (
    props: {
      value?: string;
      placeholder?: string;
      onPaste?: (event: React.ClipboardEvent) => boolean | void;
      className?: string;
      onChange?: (text: string) => void;
      onSubmit?: (text: string) => boolean | void;
      onKeyPressed?: (e: React.KeyboardEvent, text: string) => boolean | void;
      onFocus?: (focus: boolean) => void;
      style?: React.CSSProperties;
    },
    forwardRef: ForwardedRef<SmartInputFieldRef>
  ) => {
    const theme = useTheme();
    const ref = useRef<any>();
    const isFocus = useRef(false);
    const [text, setText] = useState(props.value);
    const focusMillis = useRef(0);

    useEffect(() => {
      if (ref.current && props.value !== ref.current?.innerHTML) {
        if (Date.now() - focusMillis.current < 100 || !isFocus.current) {
          setText(props.value);
        }
      }
    }, [props.value]);

    const referencer: SmartInputFieldRef = {
      getElement: () => ref.current,
      setText: (t) => setText(t),
      clearText: () => setText(""),
      requestFocus: (caretAtEnd?: boolean) => {
        if (ref.current) {
          ref.current.focus?.();
          // setTimeout(() => placeCaretAtEnd(ref.current, caretAtEnd), 1); // 10
          placeCaretAtEnd(ref.current, caretAtEnd);
        }
      },
    };

    if (typeof forwardRef === "function") {
      forwardRef(referencer);
    } else if (forwardRef) {
      (forwardRef as any).current = referencer;
    }

    return (
      <div
        dangerouslySetInnerHTML={{ __html: text || "" }}
        // children={text}
        ref={ref}
        className={props.className}
        contentEditable
        suppressContentEditableWarning={true}
        data-placeholder={props.placeholder}
        onInput={(e: any) => {
          let text: string = e?.nativeEvent?.target?.innerHTML || "";
          if (text === "<br>") {
            text = "";
            e.currentTarget.innerHTML = text;
          }
          props.onChange?.(text);
        }}
        css={{
          minHeight: 26,
          minWidth: 80,
          height: "auto",
          whiteSpace: "pre-line",
          padding: "0 12px",
          fontFamily: theme.typography.fontFamily,
          ...(props.style || {}),
        }}
        style={props.style}
        onFocus={() => {
          focusMillis.current = Date.now();
          isFocus.current = true;
          props.onFocus?.(true);
        }}
        onBlur={() => {
          isFocus.current = false;
          props.onFocus?.(false);
        }}
        onPaste={(e) => {
          if (props.onPaste?.(e)) {
            return;
          }

          onPasteEventParseFormattedText(e);
        }}
        onKeyDown={(e: React.KeyboardEvent) => {
          let text: string = e.currentTarget.innerHTML || "";
          if (props.onKeyPressed) {
            if (props.onKeyPressed!(e, text) === true) {
              e.preventDefault();
              return;
            }
          }

          //? disable Enter+Shift
          if (e.code === "Enter" && e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            // const pos = getCaretPosition(e.target);
            document.execCommand("insertText", false, "\n");
            return false;
          }

          //? dispatch Submit Completed
          if (props.onSubmit && e.code === "Enter" && !e.shiftKey) {
            // PRESS ONLY ENTER
            e.preventDefault();
            // const title: string | null = e.currentTarget.textContent;
            if (!text || !text.length) return;
            // return props.onSubmit(title) ?? true;
            if (props.onSubmit(text) !== true) {
              e.currentTarget.innerHTML = "";
            }
          }
        }}
      />
    );
  }
);
