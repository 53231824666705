import { gql } from "apollo-boost";
import { GRAPE_FILE_FRAGMENT, MY_GRAPE_FRAGMENT } from "../../../repositories/fragments";

export const GRAPE_DESCENDENT_FILES = gql`
  ${GRAPE_FILE_FRAGMENT}
  query getGrapeDescendentFiles($grapeId: ID!) {
    allDescentFiles(grapeId: $grapeId) {
      ...grapeFile
    }
  }
`;

export const GRAPE_DESCENDENT_DEADLINES = gql`
  ${MY_GRAPE_FRAGMENT}
  query getGrapeDescendentDeadlines($grapeId: ID!) {
    allDescentDeadlines(grapeId: $grapeId) {
      ...myGrape
    }
  }
`;

export const GRAPE_DESCENDENT_CHATS = gql`
  ${MY_GRAPE_FRAGMENT}
  query getGrapeDescendentChats($grapeId: ID!) {
    allDescentChats(grapeId: $grapeId) {
      ...myGrape
    }
  }
`;

export const GRAPE_DESCENDENT_CHAT_NOTIFICATIONS = gql`
  query allDescentChatNotifications($grapeId: ID!) {
    allDescentChatNotifications(grapeId: $grapeId) {
      id
      updated_at
    }
  }
`;

export const QUERY_EDIT_FILE = gql`
  mutation editFile($fileId: ID!, $title: String!) {
    editFileTitle(fileId: $fileId, title: $title) {
      id
      title
    }
  }
`;

export const QUERY_REMOVE_FILE = gql`
  mutation removeFile($fileId: ID!) {
    removeFile(fileId: $fileId) {
      id
      files {
        id
      }
    }
  }
`;

export const RESTORE_COMPLETED_GRAPES = gql`
  mutation restoreCompletedGrapes($parentId: ID!, $fromDate: Date) {
    restoreCompleted(parentId: $parentId, fromDate: $fromDate) {
      id
      children {
        id
      }
    }
  }
`;

export const HIDE_COMPLETED_GRAPES = gql`
  mutation hideCompleted($parentId: ID!, $cross_order: Float) {
    hideCompleted(parentId: $parentId, cross_order: $cross_order) {
      id
      children {
        id
      }
    }
  }
`;
