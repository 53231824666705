/** @jsxImportSource @emotion/react */
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { useState } from "react";
import { IGrape } from "../../grape_gql_interface";
import { GET_GRAPE_ACTIVITIES } from "./gql_activities";
import ReactMarkdown from "react-markdown";
import { LinearProgress, useTheme } from "@material-ui/core";
import { cleanHTMLTags } from "../../../utils";

interface GrapeActivity {
  id: string;
  text: string;
  created_at: string;
}

export const GrapeActivities = (props: { grape: IGrape }) => {
  const client = useApolloClient();
  const theme = useTheme();
  const [activitiesVisibile, setActivitiesVisible] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [mapActivities, setMapActivities] = useState<{ [page: number]: boolean | Object | GrapeActivity[] }>({});

  let loading = false;
  const activities: GrapeActivity[] = [];

  Object.values(mapActivities).forEach((v) => {
    if (v === true) {
      loading = true;
    } else if (v) {
      activities.push(...(v as GrapeActivity[]));
    }
  });

  // activities.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));

  const fetchNextPage = () => {
    let nextPage = 0;
    for (nextPage = 0; nextPage < Object.keys(mapActivities).length; nextPage++) {
      if (!mapActivities[nextPage]) {
        nextPage = nextPage;
        break;
      }
    }

    setMapActivities((mas) => ({ ...mas, [nextPage]: true }));

    client
      .query({
        query: GET_GRAPE_ACTIVITIES,
        variables: {
          grapeId: props.grape.id,
          options: {
            page: nextPage,
            perPage: 20,
          },
        },
      })
      .then(({ data }) => {
        const pageActivities = data?.activitiesForGrapeId || [];
        if (pageActivities.length === 0) {
          setHasNextPage(false);
        }
        setMapActivities((mas) => ({ ...mas, [nextPage]: pageActivities }));
      })
      .catch((err) => {
        console.log("error activities", err);
        setMapActivities((mas) => ({ ...mas, [nextPage]: false }));
      });
  };

  //   const { data, loading, error } = useQuery(GET_GRAPE_ACTIVITIES, {
  //     skip: !activitiesVisibile,
  //     variables: {
  //       grapeId: props.grape.id,
  //       options: {
  //         page: 0,
  //         perPage: 5,
  //       },
  //     },
  //   });
  //   const activities = (data?.activitiesForGrapeId || []) as GrapeActivity[];

  return (
    <div css={{ padding: 8 }}>
      {activitiesVisibile && (
        <div>
          {activities.map((activity: any) => (
            <GrapeActivity key={activity.id} activity={activity} />
          ))}

          {loading && <LinearProgress css={{ width: 200, margin: 10 }} />}
        </div>
      )}

      <div
        className="hoverable"
        css={{ display: "inline-block", fontWeight: "bold", padding: "0 8px" }}
        children={activitiesVisibile ? "← Nascondi attività" : "Mostra attività"}
        onClick={() => {
          if (!activitiesVisibile) {
            fetchNextPage();
          }
          setActivitiesVisible(!activitiesVisibile);
        }}
      />

      {activitiesVisibile && hasNextPage && (
        <div
          className="hoverable"
          css={{ display: "inline-block", fontWeight: "bold", padding: "0 8px", color: theme.palette.primary.main }}
          children={"Mostra altre attività →"}
          onClick={() => {
            fetchNextPage();
          }}
        />
      )}
    </div>
  );
};

const GrapeActivity = (props: { activity: GrapeActivity }) => {
  return (
    <div css={{ p: { margin: 0, fontFamily: "Nunito Sans" }, margin: "8px 0" }}>
      {props.activity.created_at ? (
        <div
          css={{
            color: "#bbb",
            margin: 0,
            fontSize: "0.8em",
            fontFamily: "Nunito Sans",
          }}
          children={moment(props.activity.created_at).format("DD MMMM yyyy, HH:mm")}
        />
      ) : null}

      <ReactMarkdown children={cleanHTMLTags(props.activity.text)} />
    </div>
  );
};
