import { useApolloClient } from "@apollo/react-hooks";
import Grapes from "../../../modules/Grapes";
import AddTextField from "../../AddTextField";
import { defaultGrapeListType, grapeListTypes } from "../core/grapeTypes";
import { useSafeGrapeDetailContextSelector } from "../GrapeDetail/hooks/GrapeDetailContext";
import { IGrape } from "../../grape_gql_interface";

export const AddNewRow = (props: { grape: IGrape }) => {
  const { grape } = props;

  const client = useApolloClient();
  const allFoundLabels = useSafeGrapeDetailContextSelector("allFoundLabels");
  const socketController = useSafeGrapeDetailContextSelector("socketController");

  if (!grapeListTypes[grape.type || defaultGrapeListType]?.renderInput) {
    return null;
  }

  return (
    <AddTextField
      parentGrape={grape}
      labels={allFoundLabels}
      addToGrape={(title, variables) => Grapes.addGrape(client, grape.id, title, variables)}
      withLabelSelector
    />
  );
};
