/** @jsxImportSource @emotion/react */
import { Box } from "@material-ui/core";
import React from "react";
import { useNotifierValue } from "react-use-notifier";
import { showColorPickerModal } from "../../../utils";
import { GrapeTypeSelector } from "../../GrapeDetailItems/GrapeTypeSelector";
import { IUserGrapePermission } from "../../grape_gql_interface";
import { ItemSelector } from "../../input/ItemSelector";
import { LabelSelector } from "../../LabelSelector";
import { IUser, UserChip } from "../../User";
import { grapeListTypes } from "../core/grapeTypes";
import { useGrapeDetailContextSelector } from "../GrapeDetail/hooks/GrapeDetailContext";
import { GrapeUserPointersToggle } from "./GrapeUserPointersToggle";

export const GrapeDetailFilterBar = (props: {
  //
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
}) => {
  const grape = useGrapeDetailContextSelector("grape");
  const allFoundLabels = useGrapeDetailContextSelector("allFoundLabels");
  const childrenFilter = useGrapeDetailContextSelector("childrenFilter");
  const collaborators = useGrapeDetailContextSelector("collaborators");
  const socketController = useGrapeDetailContextSelector("socketController");
  // actions
  const setChildrenFilter = useGrapeDetailContextSelector("setChildrenFilter");
  const setLabelColor = useGrapeDetailContextSelector("setLabelColor");

  const activeUserIds = useNotifierValue(socketController.activeUserIds);
  const showPointers = useNotifierValue(socketController.showPointers);

  return (
    <Box display="flex" flexDirection="row" css={{ padding: "4px 10px", alignItems: "center" }}>
      {props.leading ?? null}

      {/* {grapeListTypes[grape?.type]?.disableTypeSelector ? null : <GrapeTypeSelector outlined />} */}

      {allFoundLabels.length > 0 && (
        <div css={{ margin: 3 }}>
          <LabelSelector
            placeholder="Etichette "
            selected={childrenFilter?.label}
            labels={allFoundLabels}
            onChanged={(label) => setChildrenFilter({ ...(childrenFilter || {}), label })}
            onEditPressed={(label, color) => {
              showColorPickerModal(label, color, (newColor) => {
                setLabelColor(label, newColor);
              });
            }}
          />
        </div>
      )}

      {collaborators.length > 0 && (
        <ItemSelector
          list={collaborators}
          getId={(c) => c.user.id}
          placeholder="Persone "
          selectedId={childrenFilter?.userId}
          css={{ marginTop: 2, padding: 2 }}
          onChanged={(userId) => setChildrenFilter({ ...(childrenFilter || {}), userId: userId || undefined })}
          renderItem={(user) => <ActiveUserId user={user} />}
        />
      )}

      {props.trailing ?? null}

      {!showPointers || (activeUserIds?.length ?? 0 > 0) ? (
        <GrapeUserPointersToggle
          enabled={!!showPointers}
          onChanged={(value) => (socketController.showPointers.value = value)}
        />
      ) : null}
    </Box>
  );
};

const ActiveUserId = ({ user }: { user: IUserGrapePermission }) => {
  const socketController = useGrapeDetailContextSelector("socketController");
  const activeUserIds = useNotifierValue(socketController.activeUserIds);
  const isActive = activeUserIds?.includes(user.user.id);
  return (
    <UserChip
      colored={isActive}
      // colored based on user
      user={user.user}
      active={isActive}
    />
  );
};
