import { useGrapesApp } from "../../App";
import ClientSortableTree from "../../components/ClientSortable/ClientSortableTree";
import Grape from "../../components/Grape";
import { GrapeDetailOpenParams } from "../../components/GrapeDetail/GrapeDetail";
import { IGrape } from "../../components/grape_gql_interface";

export const LIST_ROOT_GROUP_ID = "root-grapes";

export const RootGrapesList = (props: {
  onDeleted: (grape: IGrape) => void;
  onRequestOpen: (grape: IGrape, params?: GrapeDetailOpenParams) => void;
  rootGrapes: IGrape[];
  selectedGrape?: IGrape;
  readOnly?: boolean;
}) => {
  const grapesApp = useGrapesApp();
  const { rootGrapes, selectedGrape, onRequestOpen } = props;
  // const rootPointers = usersSocketController("root");

  // const socket = useSocket("mouseMove", (data: IGrapesMouseMoveSocketEvent) => {
  //   const milliseconds = new Date().getTime();
  //   console.log("-->", data.user.name, data.grapeId);
  // });

  return (
    <ClientSortableTree
      groupId={LIST_ROOT_GROUP_ID}
      list={rootGrapes as IGrape[]}
      getItemId={(item) => item.id}
      selectedItemId={selectedGrape?.id}
      disabled={props.readOnly}
      renderItem={(item) => {
        const isMyGrapeRoot = !(!item.owner || !grapesApp.user || item.owner.id !== grapesApp.user.id);
        return (
          <div style={{ position: "relative", overflow: "hidden" }}>
            <Grape
              flat
              noContextMenu={props.readOnly}
              key={item.id}
              anchestors={[]}
              grape={{ ...item, labels: [] }}
              groupType="list"
              className="grape-root"
              style={{ userSelect: "none" }}
              selected={selectedGrape && selectedGrape.id === item.id}
              onRequestOpen={(g) => onRequestOpen(g || item)}
              onDeleted={() => props.onDeleted(item)}
            />
          </div>
        );
      }}
    />
  );
};
