import gql from "graphql-tag";

export default (action: string, capitalized: string | undefined) => {
  if (action !== "restore") {
    return gql`mutation ${action + capitalized}_ { ${action + capitalized + "Completed"} }`;
  }

  return gql`mutation ${action + capitalized}_($fromDate: Date) { ${
    action + capitalized + "Completed"
  }(fromDate: $fromDate) }`;
};
