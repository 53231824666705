import React, { Reducer, ReducerAction, ReducerState, useReducer } from "react";
import MLDialog from "../components/MLDialog";
import { traduction } from "../trad";
import { useState } from "react";
import { fade, getLuminance, TextField } from "@material-ui/core";
import { GET_ANCESTORS, GrapeChildrenFilter, GRAPE_FRAGMENT, IGrape } from "../components/grape_gql_interface";
import ApolloClient from "apollo-client";
import { gql } from "apollo-boost";
import moment from "moment";
import ColorPicker from "material-ui-color-picker";
const Compress = require("compress.js");

// function getGrapeDates(grape: IGrape) {
//   const end = moment(grape.date || grape.created_at);
//   const start = moment(grape.date0 || end);
//   return { start, end };
// }
// function getMyGrapeDates(grape: IGrape) {
//   // const end = moment(grape.date1 || grape.date || grape.created_at);
//   const end = moment(grape.date1 || grape.created_at);
//   const start = moment(grape.date2 || end);
//   return { start, end };
// }

export async function resizeImageFn(file: File) {
  const compress = new Compress();

  const resizedImage = await compress.compress([file], {
    size: 2, // the max size in MB, defaults to 2MB
    quality: 0.9, // the quality of the image, max is 1,
    maxWidth: 1800, // the max width of the output image, defaults to 1920px
    maxHeight: 1800, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });
  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
  return resizedFile;
}

export const isPrimitive = (x: any) => {
  if (!x) return true;
  return !["object", "array", "function"].includes(typeof x);
};

//dangerouslySetInnerHTML={{ __html: urlify(message.content) }}
export const urlify = (text: string, makeShort?: boolean) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    const text = makeShort && url.length > 20 ? url.substr(0, 10) + "…" + url.substr(url.length - 10) : url;
    return '<a href="' + url + '" target="_blank">' + text + "</a>";
  });
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
};

export const escapeHTML = (text: string) => text.replace(/<\/?[^>]+(>|$)/g, "").replaceAll("&nbsp;", " ");

export const groupBy = (xs: any[], key: string) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const isEmptyHTMLString = (str: string) => {
  return !(str?.replace(/<\/?[^>]+(>|$)/g, "").trim().length > 0);
};

export const clearCurrentInputFocus = () => {
  try {
    if (document.activeElement != document.body) (document as any).activeElement.blur();
  } catch (e) {}
};

export const cleanHTMLTags = (str: string) => str.replace(/<\/?[^>]+(>|$)/g, "");

export const objDiffs: any = (obj1: any, obj2: any) => {
  if (!obj1 || !obj2) return obj1 !== obj2;
  let p1: any = Object.keys(obj1);
  let p2: any = Object.keys(obj2);
  if (p2.length > p1.length) {
    let tmp = p1;
    p1 = p2;
    p2 = tmp;
  }
  const diffs: string[] = [];
  for (var c = 0; c < p1.length; c++) {
    const o1 = obj1[p1[c]];
    const o2 = obj2[p1[c]];
    if (!isPrimitive(o1) && !isPrimitive(o2)) {
      if (o1.length !== o2.length) diffs.push(p1[c] as string);
    } else if (o1 !== o2) diffs.push(p1[c] as string);
  }
  return diffs;
};

export const showConfirmDialog = (
  title?: string,
  description?: any,
  callback?: () => boolean | void,
  confirm?: string,
  cancel?: string
) => {
  MLDialog.showModal(title || "Confermi?", description || "", {
    onPositiveClick: callback,
    onNegativeClick: () => {},
    positiveText: confirm || "Conferma",
    negativeText: cancel || "Annulla",
  });
};

export const showInputDialog = (
  title?: string,
  description?: any,
  hint?: string,
  callback?: (input: string) => boolean | void,
  customAction?: { title: string; onClick: () => void },
  multiline?: boolean
) => {
  const inputId: string = "input-dialog-" + new Date().getTime();

  setTimeout(() => (document.getElementById(inputId) as any)?.focus?.(), 100);

  const onPositiveClick = () => {
    const value = (document.getElementById(inputId) as any)?.value;
    return callback?.(value);
  };

  MLDialog.showModal(
    title || "Input",
    <div>
      {description || ""}
      <div style={{ height: 10 }} />
      <TextField
        id={inputId}
        fullWidth
        placeholder={hint || "Scrivi qui"}
        autoFocus
        multiline={multiline}
        onKeyPress={(ev) => {
          if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            if (onPositiveClick() !== false) {
              MLDialog.hideModal();
            }
          }
        }}
      />
    </div>,
    {
      positiveText: "Ok",
      onPositiveClick: onPositiveClick,
      negativeText: customAction?.title,
      onNegativeClick: customAction?.onClick,
    }
  );
};

export const copyToClipboard = (str: string) => {
  navigator.clipboard.writeText(str);
  // const el = document.createElement("textarea");
  // el.value = str;
  // document.body.appendChild(el);
  // el.select();
  // document.execCommand("copy");
  // document.body.removeChild(el);
};

const parseLang = () => {
  // const langBrowser = detectBrowserLanguage();
  const langBrowser = "it-IT";

  const allowedLanguages = ["it", "en"];

  const firstPart = langBrowser.split("-")[0];

  //? if Null
  let response = firstPart ? firstPart : "it";

  if (!allowedLanguages.includes(response)) response = "en";

  return response;
};
//! LANG +++
const lang = parseLang();

export const getLang = () => {
  return lang;
};

export const L = (trad: string) => {
  //! get language

  const getTrad = ql(traduction, `${lang}.${trad}`);
  if (getTrad) return getTrad;
  if (process.env.NODE_ENV != "production" && process.env.REACT_APP_SHOW_MISSING_TRAD == "true") {
    if (trad != null) console.warn("Traduzione mancante per", { trad }, "in lingua " + lang);
  }
  // if(process.env.REACT_APP_SHOW_MISSING_TRAD == "true") return "##############";
  return trad;
};

export const ql = (data: any, path: string | undefined) => {
  if (path == null || path == undefined) return null;
  let tokens: any[] = path.split(".");
  let tmp = data;
  let index = 0;
  while (tmp != null && tmp != undefined) {
    if (index == tokens.length) break;
    try {
      tmp = tmp[tokens[index]];
    } catch (err) {
      console.log("######### $err");
      break;
    }
    index++;
  }
  return tmp;
};

export const openNewWindowGrape = (item: IGrape) => {
  const url = window.location.origin + "/" + item.id + "?grapeDetailOnly=1";
  openPreferredSizeWindow(url, window.innerWidth * 0.8, window.innerHeight * 0.8);
};

export const openPreferredSizeWindow = (url: string, prefWidth: number, prefHeight: number) => {
  // window.open("https://www.google.com", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
  const w = Math.min(window.screen.width * 0.85, prefWidth);
  const h = Math.min(window.screen.height * 0.85, prefHeight);

  const x = window.screen.width / 2 - w / 2;
  const y = (window.screen.height / 2 - h / 2) * 0.8;

  window.open(
    url as string,
    "_blank",
    `toolbar=yes,scrollbars=0,resizable=yes,top=${y},left=${x},width=${w},height=${h}`
  );
};

export const focusContentEditable = (target: any) => {
  let p = typeof target === "string" ? document.querySelector(target) : target;
  console.log(55, typeof target, p);
  if (!p) return;
  let s = window.getSelection();
  let r = document.createRange();
  r?.setStart(p, p.childElementCount);
  r?.setEnd(p, p.childElementCount);
  s?.removeAllRanges();
  s?.addRange(r);
};

export function isValidURL(url: string) {
  if (!url?.length) return false;
  const str = url.toLowerCase().trim();
  if (str.startsWith("http://") || str.startsWith("https://")) return true;

  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export function showColorPickerModal(title: string, initialValue: string, callback: (color: string) => void) {
  const palette = { color: initialValue };
  MLDialog.showModal(
    title,
    <div css={{ width: 250, height: 300, fontWeight: "bold" }}>
      <ColorPicker
        defaultValue={initialValue}
        onChange={(c) => {
          if (c) {
            palette.color = c;
          }
        }}
      />
    </div>,
    {
      onPositiveClick: () => {
        let color = palette.color.startsWith("#") ? palette.color : `#${palette.color}`;
        if (/^#[0-9A-F]{6}$/i.test(color)) {
          callback(color);
        } else {
          MLDialog.showSnackbar("Il codice colore non è valido, prova con: #FFAA00", { variant: "error" });
          return true;
        }
      },
    }
  );
}

export function getAllFoundLabels(grape: IGrape) {
  const labels: any = {};
  //! simple
  // for (var c = 0; c < grape?.children?.length || 0; c++) {
  //   const item = grape.children[c] as IGrape;
  //   for (var l = 0; l < (item?.labels?.length || 0); l++) {
  //     labels[item.labels![l]] = 1;
  //   }
  // }
  //! recursive
  const maxDepth = 4;
  function recursivePopulateLabels(grape: IGrape, labels: any, maxDepth: number) {
    for (let i = 0; i < (grape?.labels || []).length; i++) {
      labels[grape.labels![i].toUpperCase()] = 1;
    }
    if (maxDepth < 0) return;
    for (let i = 0; i < (grape.children || []).length; i++) {
      recursivePopulateLabels(grape.children[i], labels, maxDepth - 1);
    }
  }
  recursivePopulateLabels(grape, labels, maxDepth);
  //! tailored
  // for (var c = 0; c < grape?.children?.length || 0; c++) {
  //   const item = grape.children[c] as IGrape;
  //   if (grape.type === "kanban") {
  //     for (var c1 = 0; c1 < item?.children?.length || 0; c1++) {
  //       const item1 = item.children?.[c1] as IGrape;
  //       for (var l = 0; l < (item1?.labels?.length || 0); l++) {
  //         labels[item1.labels![l]] = 1;
  //       }
  //     }
  //   } else {
  //     for (var l = 0; l < (item?.labels?.length || 0); l++) {
  //       labels[item.labels![l]] = 1;
  //     }
  //   }
  // }
  return Object.keys(labels).sort();
}

export function openAppUrlIfExists(appUrl: string, fallbackUrl: string) {
  var appStarted = false;

  const blurCallback = () => {
    appStarted = true;
    // console.log("🟩 Started", new Date().getTime());
  };

  window.addEventListener("blur", blurCallback);
  setTimeout(() => {
    // console.log("🔷 callback", new Date().getTime());
    if (appStarted) {
      // console.log("🧡");
    } else {
      // console.log("❌");
      (window as any).open(fallbackUrl, "_blank");
    }
    window.removeEventListener("blur", blurCallback);
  }, 500);

  (window as any).location.href = appUrl;
}

export function isTodoGrapeByTitle(title: string) {
  const validKanBanCompleted = [
    "todo",
    "da fare",
    "backlog",
    "blocked",
    "bloccato",
    //more
  ];
  const tokens = (title || "").toLowerCase().split(" ");
  for (const i in validKanBanCompleted) {
    const key = validKanBanCompleted[i];
    for (const j in tokens) {
      const token = tokens[j];
      if (key === token && key !== "0") {
        return true;
      }
    }
  }
  return false;
}

export function isCompletionGrapeByTitle(title: string) {
  const validKanBanCompleted = [
    "done",
    "completed",
    "fatto",
    "fatti",
    "completato",
    "completati",
    "finito",
    "finiti",
    //more
  ];
  const tokens = (title || "").toLowerCase().split(" ");
  for (const i in validKanBanCompleted) {
    const key = validKanBanCompleted[i];
    for (const j in tokens) {
      const token = tokens[j];
      if (key === token && key !== "0") {
        return true;
      }
    }
  }
  return false;
}

export function isWipGrapeByTitle(title: string) {
  const validKanBanWip = [
    "wip",
    //more
  ];
  const tokens = (title || "").toLowerCase().split(" ");
  for (const i in validKanBanWip) {
    const key = validKanBanWip[i];
    for (const j in tokens) {
      const token = tokens[j];
      if (key === token && key !== "0") {
        return true;
      }
    }
  }
  return false;
}

export async function retrieveGrapeAncestorsTryCache(client: ApolloClient<any>, grape: IGrape): Promise<IGrape[]> {
  let parentGrape = grape;
  let errorReadingFromCache;

  let retrievedAncestors: IGrape[] = [];

  while (parentGrape.parent) {
    try {
      parentGrape = client.readFragment({
        id: "Grape:" + parentGrape.parent,
        // fragmentName: 'grape',
        // fragment: GRAPE_FRAGMENT,
        fragmentName: "grapeList",
        fragment: gql`
          ${GRAPE_FRAGMENT}
          fragment grapeList on Grape {
            ...grape
            children {
              ...grape
            }
          }
        `,
      }) as IGrape;
      retrievedAncestors = [parentGrape, ...retrievedAncestors];
    } catch (e) {
      errorReadingFromCache = e;
      break;
    }
  }

  if (errorReadingFromCache) {
    const result = await client.query({
      query: GET_ANCESTORS,
      variables: { grapeId: grape.id },
    });
    const ancestors = result.data.getAncestors as IGrape[];
    return ancestors;
  }

  return retrievedAncestors;
}

export function recursiveFilterGrapeChildrenMatch(grape: IGrape, filter: GrapeChildrenFilter, maxDepth: number) {
  if (maxDepth < 0) {
    return false;
  }

  if (
    filter.userId === undefined &&
    !filter.label &&
    !filter.sprintId &&
    !filter.onlyGanttized &&
    !filter.onlyUncompleted
  ) {
    return true;
  }

  //? filter label
  // if (params?.filterChildrens?.label && !grape.labels?.includes(params.filterChildrens.label)) {
  if (filter.label) {
    // if (grape.labels?.includes(filter.label)) {
    if ((grape.labels || []).filter((l) => l.toLowerCase() === filter.label!.toLowerCase()).length > 0) {
      return true;
    }
  }

  //? filter userId
  // if ( params?.filterChildrens?.userId && (grape.assignees || []).filter((a) => a.id === params.filterChildrens!.userId).length === 0 ) {
  if (filter.userId !== undefined) {
    if ((grape.assignees || []).filter((a) => a.id === filter.userId).length > 0) {
      return true;
    }
  }

  //? filter sprintId
  if (filter.sprintId !== undefined) {
    if ((grape.links_to || []).filter((l) => l.grape?.id === filter.sprintId).length > 0) {
      return true;
    }
  }

  //? filter gantt onlyGanttized
  if (filter.onlyGanttized) {
    if (!!(grape.date0 || grape.date)) {
      return true;
    }
  }

  //? filter gantt onlyUncompleted
  if (filter.onlyUncompleted) {
    if ((grape.progress || 0) < 1) {
      return true;
    }
  }

  //? recursion
  for (let i = 0; i < (grape.children || []).length; i++) {
    if (recursiveFilterGrapeChildrenMatch(grape.children[i], filter, maxDepth - 1)) {
      return true;
    }
  }
  return false;
}

export const firstValidColor = (...colors: (string | undefined)[]) => {
  for (let c = 0; c < (colors || []).length; c++) {
    if (!colors[c]) continue;
    const item = colors[c]!.trim();
    try {
      const col = item.startsWith("#") ? colors[c]! : `#${item}`;
      fade(col, 0.5);
      getLuminance(col);
      return col;
    } catch (e) {}
  }
};

export function stripTags(source: string, allowed: string) {
  //example: data = stripTags(data, "<b><b/><i></i><ul></ul><ol></ol><li></li><br><br/>");

  if (source == undefined) {
    return "";
  }

  const input = replaceAnchorsTagsWithHrefs(source);

  allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join(""); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
  return input.replace(commentsAndPhpTags, "").replace(tags, function (v0: string, v1: string) {
    const toReturn = allowed.indexOf("<" + v1.toLowerCase() + ">") > -1 ? v0 : "";
    return toReturn;
  });
}

function replaceAnchorsTagsWithHrefs(text: string) {
  const tags = /(?:<a(?: href=[^>]+>([^<]+)<\/a>(?!<a)?))+/gi;
  const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
  var updated = text.replace(commentsAndPhpTags, "").replace(tags, function (v0: string, v1: string) {
    let foundUrl = "";

    v0.replace(/<a[^>]+?href=(?:3D)?('|")([^\1]+?)\1/gi, function (match, a1, url) {
      foundUrl = url;
      return url;
    });

    if ((foundUrl || "").trim() == "") {
      const quoteChar = v0.includes('"') ? '"' : "'";
      foundUrl = v0.split("href=" + quoteChar)[1].split(quoteChar)[0];
    }

    return foundUrl;
  });

  return updated;
}

export function mergeObjects<T>(...sources: (T | Object | undefined)[]) {
  const target = {} as any;
  for (let i = 0; i < sources.length; i++) {
    const source = sources[i] as any;
    if (source == undefined) continue;
    const keys = Object.keys(source);
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      if (source[key] !== undefined) {
        target[key] = source[key];
      }
    }
  }
  return target as T;
}

export function getGrapeNestedChildrenCount(grape: IGrape, max: number = 999) {
  let total = Math.min(max, grape.children?.length || 0);
  for (let i = 0; i < grape.children?.length; i++) {
    total += getGrapeNestedChildrenCount(grape.children[i]);
    if (total > max) return max;
  }
  return total;
}
