/** @jsxImportSource @emotion/react */
import { useQuery } from "@apollo/react-hooks";
import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { gql } from "apollo-boost";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IGrape } from "../grape_gql_interface";

interface VelocityDialogProps {
  grapeId?: string;
  grape?: IGrape;
  onClose: () => void;
}

const GET_VELOCITY_GRAPE_ID = gql`
  query getVelocityFromGrape($grapeId: ID!) {
    getVelocityFromGrape(id: $grapeId) {
      completedStoryPoints
      totalStoryPoints
      velocities {
        date
        storyPoints
        taskCompleted
      }
    }
  }
`;

function VelocityDialog(props: VelocityDialogProps) {
  const [open, setOpen] = useState(Boolean(props?.grapeId));

  const { data } = useQuery(GET_VELOCITY_GRAPE_ID, {
    variables: { grapeId: props.grapeId },
    skip: !props.grapeId,
  });

  useEffect(() => {
    setOpen(Boolean(props?.grapeId));
  }, [props?.grapeId]);

  const response = data?.getVelocityFromGrape;
  const totalStoryPoints = response?.totalStoryPoints || 0;
  const completedStoryPoints = response?.completedStoryPoints || 0;
  const remainingStoryPoints = totalStoryPoints - completedStoryPoints;

  const velocities = response?.velocities || [];

  const todayStoryPoints = velocities?.[0]?.storyPoints || 0;
  let last7Days = 0;
  let last14Days = 0;
  let fromBeginning = 0;

  const today = moment();

  velocities.forEach((x: any) => {
    const current = moment(x.date);
    if (today.diff(current, "day") <= 7) last7Days += x.storyPoints;
    if (today.diff(current, "day") <= 14) last14Days += x.storyPoints;
    fromBeginning += x.storyPoints || 0;
  });

  last7Days = last7Days / 7;
  last14Days = last14Days / 14;
  fromBeginning = fromBeginning / (velocities.length || 1);

  return (
    <Dialog fullWidth open={open} onClose={props.onClose}>
      <div dangerouslySetInnerHTML={{ __html: props.grape?.title || "" }} css={{ fontSize: 20, margin: 16 }} />
      <DialogContent>
        <Grid container css={{ marginTop: 24, marginBottom: 24 }}>
          <Grid item xs>
            <Grid container direction="column" alignItems="center">
              <Typography variant="h6" children="SP completati (stimati)" />
              <Typography variant="h3" children={completedStoryPoints} css={{ color: "#81c784" }} />
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container direction="column" alignItems="center">
              <Typography variant="h6" children="SP totali" />
              <Typography variant="h3" children={totalStoryPoints} css={{ color: "#e57373" }} />
            </Grid>
          </Grid>
        </Grid>
        <Divider css={{ marginBottom: 24 }} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell css={{ fontWeight: "bold" }}>Oggi</TableCell>
              <TableCell css={{ fontWeight: "bold" }}>7 giorni</TableCell>
              <TableCell css={{ fontWeight: "bold" }}>14 giorni</TableCell>
              <TableCell css={{ fontWeight: "bold" }}>Dall'inizio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell css={{ fontWeight: "bold" }}>
                Storypoints fatti
                <br />
                (SP/gg)
              </TableCell>
              <TableCell>{todayStoryPoints}</TableCell>
              <TableCell>{last7Days.toFixed(1)}</TableCell>
              <TableCell>{last14Days.toFixed(1)}</TableCell>
              <TableCell>{fromBeginning.toFixed(1)}</TableCell>
            </TableRow>
            <TableRow css={{ verticalAlign: "top" }}>
              <TableCell css={{ fontWeight: "bold" }}>
                Data chiusura grape
                <br />
                (stimata)
              </TableCell>
              <TableCell>
                {todayStoryPoints == 0 ? (
                  "MAI"
                ) : (
                  <div>
                    <p css={{ textAlign: "center" }}>
                      {today
                        .clone()
                        .add(remainingStoryPoints / todayStoryPoints, "days")
                        .format("DD/MM/YYYY")}
                    </p>
                    <p css={{ textAlign: "center" }}>
                      {today
                        .clone()
                        .add(remainingStoryPoints / todayStoryPoints, "days")
                        .fromNow()}
                    </p>
                  </div>
                )}
              </TableCell>
              <TableCell>
                {last7Days == 0 ? (
                  "MAI"
                ) : (
                  <div>
                    <p css={{ textAlign: "center" }}>
                      {today
                        .clone()
                        .add(remainingStoryPoints / last7Days, "days")
                        .format("DD/MM/YYYY")}
                    </p>
                    <p css={{ textAlign: "center" }}>
                      {today
                        .clone()
                        .add(remainingStoryPoints / last7Days, "days")
                        .fromNow()}
                    </p>
                  </div>
                )}
              </TableCell>
              <TableCell>
                {last14Days == 0 ? (
                  "MAI"
                ) : (
                  <div>
                    <p css={{ textAlign: "center" }}>
                      {today
                        .clone()
                        .add(remainingStoryPoints / last14Days, "days")
                        .format("DD/MM/YYYY")}
                    </p>
                    <p css={{ textAlign: "center" }}>
                      {today
                        .clone()
                        .add(remainingStoryPoints / last14Days, "days")
                        .fromNow()}
                    </p>
                  </div>
                )}
              </TableCell>
              <TableCell>
                {fromBeginning == 0 ? (
                  "MAI"
                ) : (
                  <div>
                    <p css={{ textAlign: "center" }}>
                      {today
                        .clone()
                        .add(remainingStoryPoints / fromBeginning, "days")
                        .format("DD/MM/YYYY")}
                    </p>
                    <p css={{ textAlign: "center" }}>
                      {today
                        .clone()
                        .add(remainingStoryPoints / fromBeginning, "days")
                        .fromNow()}
                    </p>
                  </div>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

export default VelocityDialog;
