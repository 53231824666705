/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { Box, fade, Grid, useTheme } from "@material-ui/core";
import { IBoardGrape } from ".";
import Grapes from "../../../modules/Grapes";
import AddTextField from "../../AddTextField";
import { IGrape } from "../../grape_gql_interface";
import { BoardHeaders } from "./BoardHeaders";

export const boardGrapeSpecs = {
  columnWidth: 280, // 240,
  columnPadding: 5,
  horizontalPadding: 16,
  borderRadius: 4,
};

export const BoardBody = (props: {
  structure: BoardStructure;
  onStructureChanged: (struct: BoardStructure) => void;
  attached?: boolean;
  showBacklog?: boolean;
  boardProps: IBoardGrape;
}) => {
  const theme = useTheme();
  const client = useApolloClient();

  const availableColumnIds = props.structure.columns.map((c) => c.id);

  const visibleColumns = props.structure.columns.filter((c) => props.showBacklog || !c.hidden);

  const defaultColumn = props.structure.columns.find((c) => !c.hidden) ?? props.structure.columns[0];

  const columnItems = visibleColumns.map((column, index) => {
    column.storypoints = 0;
    return props.boardProps.grape.children.filter((g: IGrape) => {
      if (
        (column.id === defaultColumn?.id && !availableColumnIds.includes(g.cross_order)) ||
        g.cross_order == column.id
      ) {
        column.storypoints! += g.storypoints || 0;
        return true;
      }
      return false;
    });
  });

  return (
    <Grid
      container
      direction="column"
      css={{
        margin: `0 ${boardGrapeSpecs.horizontalPadding}px`,
        width: Math.max(1, visibleColumns.length) * (boardGrapeSpecs.columnWidth + boardGrapeSpecs.columnPadding) + 2, // margin fix
      }}
    >
      <BoardHeaders
        columns={visibleColumns}
        boardProps={props.boardProps}
        structure={props.structure}
        onStructureChanged={props.onStructureChanged}
      />

      <Grid
        container
        item
        xs
        direction="row"
        style={{
          whiteSpace: "nowrap",
          // flex: 1,
          // width: Math.max(1, kanbanColumns.length) * (columnWidth + columnPadding * 2) + 32, // 30 + margin fix
        }}
      >
        {visibleColumns.map((column: BoardColumn, index: number) => {
          return (
            <Box
              key={column.id + index}
              display="flex"
              flexDirection="column"
              style={{
                // padding: columnPadding,
                paddingTop: 0,
                // paddingTop: columnPadding,
                paddingLeft: boardGrapeSpecs.columnPadding / 2,
                paddingRight: boardGrapeSpecs.columnPadding / 2,
                paddingBottom: boardGrapeSpecs.columnPadding,

                width: boardGrapeSpecs.columnWidth,
                whiteSpace: "initial",
                // display: 'inline-block',
                position: "relative",
                // border: '3px solid red',

                // height: '100%',
                // overflow: 'hidden',
              }}
            >
              {/* <div css={{ height: columnHeadHeight, minWidth: 50, backgroundColor: "red" }} /> */}

              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                css={{
                  paddingLeft: boardGrapeSpecs.columnPadding / 2,
                  paddingRight: boardGrapeSpecs.columnPadding / 2,
                  backgroundColor: column.hidden ? fade(theme.palette.primary.main, 0.1) : "rgba(0, 0, 0,0.026)",
                  borderTopLeftRadius: props.attached ? 0 : boardGrapeSpecs.borderRadius,
                  borderTopRightRadius: props.attached ? 0 : boardGrapeSpecs.borderRadius,
                  borderBottomLeftRadius: boardGrapeSpecs.borderRadius,
                  borderBottomRightRadius: boardGrapeSpecs.borderRadius,
                }}
              >
                <Box flexGrow={1} display="flex" css={{ ".grapes-column-container": { paddingBottom: 8 } }}>
                  {/* <BoardBodyColumn column={column} index={index} /> */}
                  {props.boardProps.renderGrapesColumn(props.boardProps.grape, columnItems[index], {
                    droppableId: `${props.boardProps.grape.id}_crossOrder_${column.id}`,
                    expandChildrens: column.expandChildrens ?? true,
                    renderBananaDot: !column.noBananaDot,
                    todoModeEnabled: column.todoModeEnabled,
                    anchestors: props.boardProps.anchestors,
                    distance: 8,
                    trailComponent: (
                      <AddTextField
                        parentGrape={props.boardProps.grape}
                        labels={props.boardProps.allLabels}
                        addToGrape={(title, edits) => {
                          const variables = { ...edits };
                          variables["type"] = "todo";
                          variables["cross_order"] = column.id;
                          return Grapes.addGrape(client, props.boardProps.grape.id, title, variables);
                        }}
                        withLabelSelector
                      />
                    ),
                    // withAddBtn
                  })}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};

export const serializeBoardStructure = (structure: BoardStructure) => JSON.stringify(structure);

export const deserializeBoardStructure = (structure?: string): BoardStructure => {
  try {
    if (!structure) throw `invalid structure: ${structure}`;
    const struct: BoardStructure = JSON.parse(structure!);
    if (!(struct.columns.length > 0)) throw `invalid structure object: ${structure} --> ${struct}`;
    return struct;
  } catch (e) {
    return {
      columns: [{ id: 0, order: 0, name: "TODO" }],
    };
  }
};

export interface BoardStructure {
  columns: BoardColumn[];
}
export interface BoardColumn {
  id: number;
  name: string;
  description?: string;
  order: number;
  storypoints?: number;
  hidden?: boolean;
  isCompletionColumn?: boolean;
  expandChildrens?: boolean;
  noBananaDot?: boolean;
  todoModeEnabled?: boolean;
}
