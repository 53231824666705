import { useApolloClient } from "@apollo/react-hooks";
import { useTheme } from "@material-ui/core";
import React from "react";
import DescriptionInput from "../../DescriptionInput";
import { IGrape } from "../../grape_gql_interface";

export const tabsLayoutHeight = 30;

export const GrapeTabsLayout = (props: {
  grape: IGrape;
  advancedMenu?: React.ReactNode;
  tabs: IGrape[];
  selectedGrape?: { id: string };
  setSelectedTabGrapeId: (id: string) => void;
  onAddPressed: () => void;
}) => {
  const client = useApolloClient();
  const theme = useTheme();

  return (
    <React.Fragment>
      <div css={{ height: tabsLayoutHeight }} />

      <div
        css={{
          borderTop: "1px solid #eee",
          position: "absolute",
          zIndex: 11,
          bottom: 0,
          left: 0,
          right: 0,
          height: tabsLayoutHeight,
          backgroundColor: "#ffffff",
          overflow: "hidden",
        }}
      >
        <div css={{ width: "100%", overflowX: "scroll", whiteSpace: "nowrap" }}>
          {/* CHILDREN: TABS */}
          {[props.grape, ...props.tabs].map((g) => {
            const isSelected = g.id == props.selectedGrape?.id;
            const isMainGrape = g.id === props.grape.id;
            return (
              <div
                key={g.id}
                onClick={() => props.setSelectedTabGrapeId(g.id)}
                // className={isMainGrape ? undefined : "hoverable"}
                className="hoverable"
                css={{
                  display: "inline-block",
                  alignItems: "center",
                  position: "relative",
                  cursor: "pointer",
                  verticalAlign: "top",
                  borderRight: "1px solid #eee",
                  // border: "1px solid #eee",
                  // borderLeft: "none",
                  // borderBottom: "none",
                  zIndex: isSelected ? 1 : undefined,
                  overflow: "hidden",
                  "::after": isSelected
                    ? {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: 2,
                        background: theme.palette.primary.main,
                      }
                    : undefined,
                }}
              >
                {/* {(isMainGrape && props.advancedMenu) || null} */}
                {isMainGrape && props.advancedMenu ? (
                  <div css={{ position: "absolute", left: 8, top: 2, width: 24 }} children={props.advancedMenu} />
                ) : null}

                <div
                  css={{
                    whiteSpace: "nowrap",
                    maxWidth: 140,
                    padding: "4px 8px",
                    fontSize: "12",
                    paddingLeft: isMainGrape && props.advancedMenu ? 24 : undefined,
                  }}
                  dangerouslySetInnerHTML={{ __html: g.title.replace(/<\/?[^>]+(>|$)/g, "") }}
                  // dangerouslySetInnerHTML={{ __html: g.title }}
                />
              </div>
            );
          })}

          {/* ADD BUTTON */}
          <div
            className="hoverable"
            css={{
              display: "inline-block",
              padding: "0 4px",
              paddingTop: "2px",
              alignItems: "center",
              // border: "1px solid #eee",
              borderRight: "1px solid #eee",
              borderLeft: "none",
              borderBottom: "none",
            }}
            onClick={() => props.onAddPressed()}
            children={<i className="material-icons" children="add" />}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
