import Grapes from "../../modules/Grapes";
import { AddNewRow } from "../GrapeDetail/components/AddNewRow";
import { grapeListTypes } from "../GrapeDetail/core/grapeTypes";
import DetailScaffold from "../GrapeDetail/DetailScaffold";
import { GrapeComments } from "../GrapeDetail/GrapeComments/GrapeComments";
import { GrapeDetailToolbar } from "../GrapeDetail/GrapeDetail/GrapeDetailToolbar";
import { useSafeGrapeDetailContextSelector } from "../GrapeDetail/GrapeDetail/hooks/GrapeDetailContext";
import { useGrapeDetailRenderColumn } from "../GrapeDetail/GrapeDetail/hooks/GrapeDetailRenderColumn";

export const GrapeDetailSprint = (props: {}) => {
  const grape = useSafeGrapeDetailContextSelector("grape");
  const renderGrapesColumn = useGrapeDetailRenderColumn();

  return (
    <DetailScaffold grape={grape} header={<GrapeDetailToolbar />}>
      <div
        css={{ padding: 8, paddingTop: 3, paddingBottom: 0, flex: 1, display: "flex" }}
        children={renderGrapesColumn(grape, grape.children, {
          expandChildrens: true,
          disableDragAndDrop: true,
          renderBananaDot: !Grapes.getSetting(
            grape,
            "no-banana-dot",
            grapeListTypes[grape.type || "list"]?.noBananaDotsChildrens //todo - not easy editable (edit 2 times on contextableGrape)
          ),
          distance: 8,
          trailComponent: <AddNewRow grape={grape} />,
        })}
      />

      <GrapeComments grape={grape} />
    </DetailScaffold>
  );
};
