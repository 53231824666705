/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { jsx } from "@emotion/react";
import { Box, useTheme } from "@material-ui/core";
import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useGrapesApp } from "../../App";
import Grapes from "../../modules/Grapes";
import { showConfirmDialog, urlify } from "../../utils";
import Contextable from "../Contextable";
import { GET_GRAPE, IGrape } from "../grape_gql_interface";
import { QUERY_EDIT_FILE, QUERY_REMOVE_FILE } from "./core/gql_interface";
import GrapeFile, { IGrapeFile, openFileInPage } from "../GrapeFile";
import MLDialog from "../MLDialog";
import { UserChip } from "../User";
import {
  getAccessToChatForUser,
  hasNewChatMessagesForUser,
  IGrapesChatAppParams,
  setAccessToChatByUser,
} from "./ChatGrapesApp";
const ScrollView = require("react-inverted-scrollview").default;

export interface IChatNotification {
  id: string;
  updated_at: string;
}

export interface IChatGrape {
  editGrape: (grapeId: string, prop: string, value: any, props?: any) => void;
  grape?: IGrape;
  parentGrape: IGrape;
  chatNotification?: IChatNotification;
  params?: IGrapesChatAppParams;
  onChatGrapeCreated: (chatGrape: IGrape) => void;
}

interface IGrapeMessage extends IGrape {
  sendingState?: "sending" | "sent" | "error" | undefined;
}

export function getMessagesAndFiles(messages: IGrape[], files?: IGrapeFile[]): (IGrape | IGrapeFile)[] {
  if (!files?.length) {
    return messages;
  }

  const mix: (IGrape | IGrapeFile)[] = [...messages, ...files!];

  mix.sort((a, b) => {
    if (new Date(a.created_at) > new Date(b.created_at)) return 1;
    return -1;
  });

  return mix;
}

const ChatGrape = (props: IChatGrape) => {
  const [_upd, _setUpd] = useState(0);
  const forceUpdate = () => _setUpd(_upd + 1);
  const creatingFirstMissage = useRef(false);

  const client = useApolloClient();
  const grapesApp = useGrapesApp();

  const [sendingMessages, setSendingMessages] = useState<IGrapeMessage[]>([]);
  const messages: IGrapeMessage[] = [...(props.grape?.children || [])];

  const scrollRef = useRef<any>();
  // const [lastUpdate, setLastUpdate] = useState<moment.Moment>();
  // useEffect(() => {
  //   if (!lastUpdate && props.chatNotification?.updated_at) {
  //     const time = moment(props.chatNotification?.updated_at);
  //     console.log(333333333333, time.format("HH:mm"), time);
  //     setLastUpdate(time);
  //   }
  // }, [props.params]);
  // console.log(33333333333, lastUpdate); // wip lastUpdate

  sendingMessages.forEach((msg: any) => {
    if (msg.toSkip) return;

    if (!msg.id) {
      messages.push(msg);
    } else {
      let alreadyInside = false;
      //! check if alreadyInside
      for (var c = messages.length - 1; c >= 0; c--) {
        if (messages[c].id === msg.id) {
          alreadyInside = true;
          break;
        }
      }

      if (alreadyInside) {
        msg.toSkip = true;
      } else {
        messages.push(msg);
      }
    }
  });

  const messagesAndFiles = getMessagesAndFiles(messages, props.grape?.files);
  const lastUpdate = props.grape && getAccessToChatForUser(props.grape, grapesApp.user.id);

  return (
    <Box flex="1" display="flex" flexDirection="column">
      <Box
        flex="1"
        css={{
          //backgroundColor: "#f8f8f8",
          height: 0,
        }}
      >
        {props.grape?.id !== "create" && (
          <ScrollView
            ref={(r: any) => (scrollRef.current = r)}
            className="sm-sb"
            style={{
              overscrollBehavior: "initial",
              width: "100%",
              height: "100%",
              background: "#b4c98b",
            }}
          >
            {messagesAndFiles?.map((msg, i) => {
              const date = moment(msg.created_at);

              const toReadMessage = lastUpdate && date.isAfter(lastUpdate);
              const followingMessageSameDay =
                i === messagesAndFiles.length - 1 ||
                date.startOf("day").diff(moment(messagesAndFiles[i + 1].created_at).startOf("day")) === 0;

              const shouldShowPicture = msg?.owner?.id !== messagesAndFiles[i + 1]?.owner?.id;

              return (
                <React.Fragment key={msg?.id || i}>
                  {toReadMessage ? (
                    <div
                      style={{
                        padding: 8,
                        margin: "8px 0",
                        fontWeight: "bold",
                        textAlign: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      }}
                      children="Da leggere"
                    />
                  ) : null}

                  <ThreadMessage
                    message={msg}
                    showPicture={shouldShowPicture || !followingMessageSameDay}
                    isMine={msg?.owner?.id === grapesApp?.user?.id}
                    strDate={msg.created_at ? moment(msg.created_at).format("HH:mm") : " "}
                    grape={props.grape!}
                  />

                  {!followingMessageSameDay ? renderFollowingMessageDayDivider(moment(msg.created_at)) : null}
                </React.Fragment>
              );
            })}
          </ScrollView>
        )}
      </Box>

      {/* <div children={lastUpdate?.format('HH:mm')} /> */}

      <ChatBox
        onSend={async (msg) => {
          let chatGrapeId = props.grape?.id;
          if (!chatGrapeId) {
            // onFirstMessageCreate={(firstMessage: string) => {
            if (creatingFirstMissage.current) return;
            creatingFirstMissage.current = true;

            const createResp = await Grapes.addGrape(client, props.parentGrape.id, undefined, { type: "chat" });

            chatGrapeId = createResp?.data?.createGrape?.id;
            props.onChatGrapeCreated?.({
              id: chatGrapeId!,
              type: "chat",
              parent: props.parentGrape.id,
              title: "",
              description: "",
              children: [],
            } as any);
          }

          const datetime = new Date().toISOString();

          const sendingMessageGrape: any = {
            sendingState: "sending",
            title: msg,
            updated_at: datetime,
            created_at: datetime,
            owner: grapesApp.user,
          };
          setSendingMessages((msgs) => [...msgs, sendingMessageGrape]);

          Grapes.addGrape(client, chatGrapeId!, msg)
            .then((resp) => {
              const newGrapeId: string = resp?.data?.createGrape?.id;
              if (newGrapeId) {
                sendingMessageGrape.id = newGrapeId;
                sendingMessageGrape.sendingState = "sent";
              } else {
                sendingMessageGrape.sendingState = "error";
              }
              forceUpdate();
            })
            .catch((e) => {
              console.log("err send", e);
              sendingMessageGrape.sendingState = "error";
              forceUpdate();
            });
        }}
      />
    </Box>
  );

  function renderFollowingMessageDayDivider(day: Moment) {
    return (
      <div css={{ display: "flex", justifyContent: "center" }}>
        <div
          css={{
            background: "rgba(255, 255, 255, 0.5)",
            color: "#777",
            padding: "3px 12px",
            fontSize: 14,
            borderRadius: 40,
            margin: "12px 0",
          }}
          children={day.format("D MMMM")}
        />
      </div>
    );
  }
};

export default ChatGrape;

const ThreadMessage = (props: {
  grape: IGrape;
  isMine: boolean;
  showPicture: boolean;
  message: IGrapeMessage | IGrapeFile;
  strDate: string;
}) => {
  const { message, isMine, showPicture, strDate } = props;
  const theme = useTheme();
  const client = useApolloClient();
  const isMessageFile = !!(message as IGrapeFile).url;
  const boxShadow = "0 1px 2px rgba(0, 100, 0, 0.1)";

  const isMineAndGoesRight = isMine && false;

  const bgColor =
    (message as IGrapeMessage).sendingState === "error"
      ? "#af1200"
      : isMine
      ? "#effdde" // theme.palette.primary.main
      : "#ffffff";

  return (
    <Box
      display="flex"
      css={{
        margin: "3px 15px",
        alignItems: "flex-end",
        justifyContent: isMineAndGoesRight ? "flex-end" : "flex-start",

        // '@media only screen and (min-width: 1000px)': {
        "@media only screen and (min-width: 100000000px)": {
          justifyContent: "flex-start",
          ".msg-user-img": {
            display: "inline-block !important",
          },
        },
      }}
    >
      <div style={{ display: !isMineAndGoesRight ? undefined : "none" }} className="msg-user-img">
        {showPicture ? (
          <UserChip user={message.owner} collapsed size={msgAvatarSize} avatarStyle={{ backgroundColor: "#ddd" }} />
        ) : (
          <div css={{ width: msgAvatarSize, height: msgAvatarSize }} />
        )}
      </div>

      <Contextable
        menuItems={[
          { caption: "Modifica", onClick: onRequestEdit },
          { caption: "Elimina", onClick: onRequestDelete, color: "#de2000" },
        ]}
      >
        <div
          css={{
            position: "relative",
            // overflow: 'hidden',
            marginLeft: 8,
            display: "inline-block",
            backgroundColor: bgColor,
            // color: isMine ? theme.palette.primary.contrastText : undefined,
            boxShadow,
            padding: "5px 10px",
            borderRadius: 5,
            "@supports (filter: drop-shadow(0 0 10px black))": {
              filter: `drop-shadow(${boxShadow})`,
              boxShadow: "none",
              ":after": showPicture
                ? {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    border: "none",
                    borderTop: "12px solid transparent",
                    borderRight: !isMineAndGoesRight ? "12px solid " + bgColor : undefined,
                    borderLeft: isMineAndGoesRight ? "12px solid " + bgColor : undefined,
                    left: !isMineAndGoesRight ? -8 : undefined,
                    right: isMineAndGoesRight ? -8 : undefined,
                  }
                : undefined,
            },
          }}
        >
          {isMessageFile ? <FileThumbnail file={message as IGrapeFile} grape={props.grape} /> : null}

          <span dangerouslySetInnerHTML={{ __html: urlify(message?.title || "", true) }} />

          <span
            css={{
              marginLeft: 10,
              marginTop: 10,
              fontSize: "0.7em",
              opacity: 0.5,
              float: "right",
            }}
          >
            {(props.message.created_at != props.message.updated_at ? "edited " : "") + strDate}
            <i
              className="material-icons"
              children={
                (message as IGrapeMessage).sendingState === "sending"
                  ? "access_time"
                  : (message as IGrapeMessage).sendingState === "error"
                  ? "error_outline"
                  : "check"
              }
              style={{
                marginLeft: 5,
                fontSize: "1em",
                transform: "scale(1.5)",
              }}
            />
          </span>
        </div>
      </Contextable>
    </Box>
  );

  function onRequestEdit() {
    if (isMessageFile) {
      const file = message as IGrapeFile;
      const newTitle = window.prompt("Edit image title", file.title);
      if (newTitle !== null) {
        client
          .mutate({
            mutation: QUERY_EDIT_FILE,
            refetchQueries: [{ query: GET_GRAPE, variables: { id: props.grape.id } }],
            variables: { fileId: file.id, title: newTitle },
          })
          .catch((error: any) => MLDialog.showSnackbar(`Error ${error}`, { variant: "error" }));
      }
    } else {
      const newTitle = window.prompt("Modifica", props.message.title);
      if (newTitle !== null) {
        Grapes.editGrape(
          client,
          props.message.id,
          { title: newTitle }
          // [{ query: GET_GRAPE, variables: { id: props.grape.id } }]
        );
      }
    }
  }

  function onRequestDelete() {
    if (isMessageFile) {
      const file = message as IGrapeFile;
      showConfirmDialog(`Vuoi rimuovere questo file?`, "Una volta rimosso non potrai più recuperarlo", () => {
        // todo - file deletion not working ATM
        client
          .mutate({
            mutation: QUERY_REMOVE_FILE,
            refetchQueries: [{ query: GET_GRAPE, variables: { id: props.grape.id } }],
            variables: { fileId: file.id },
          })
          .catch((error: any) => MLDialog.showSnackbar(`Error ${error}`, { variant: "error" }));
      });
    } else {
      showConfirmDialog(`Vuoi rimuovere questo messaggio?`, "Una volta rimosso non potrai più recuperarlo", () => {
        Grapes.doDeleteGrape(
          client,
          props.message as IGrapeMessage,
          // relaod
          [{ query: GET_GRAPE, variables: { id: props.grape.id } }]
        );
      });
    }
  }
};

const ChatBox = (props: { onSend: (message: string) => void }) => {
  const inputEl: any = useRef(null);
  const [text, setText] = useState("");

  function getCaretPosition(element: any) {
    const win: any = window;
    const doc: any = document;

    var caretOffset = 0;

    if (win.getSelection) {
      var range = win.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    } else if (doc.selection && doc.selection.type != "Control") {
      var textRange = doc.selection.createRange();
      var preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint("EndToEnd", textRange);
      caretOffset = preCaretTextRange.text.length;
    }

    return caretOffset;
  }

  const onRequestSend = () => {
    const el: any = inputEl.current;
    if (!el?.innerHTML?.trim()?.length) {
      console.log(inputEl);
      return;
    }
    props.onSend(el.innerHTML.trim());
    el.innerHTML = "";
    return true;
  };

  return (
    <Box display="flex" flexDirection="row" style={{ borderTop: "1px solid #eee" }}>
      <div
        ref={inputEl}
        contentEditable={true}
        className="no-sb"
        style={{ flexGrow: 1, padding: "8px 16px", maxHeight: 84, overflowY: "scroll", fontSize: 18 }}
        data-placeholder="write a message…"
        // onKeyUp={(e: any) => console.log(e.target?.innerHTML, inputEl?.current?.innerHTML?.length)}
        onKeyUp={(e: any) => {
          setText(e.target?.innerHTML || "");
          const pos = getCaretPosition(e.nativeEvent.target);
          // console.log(text, pos);
        }}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.which === 13 && !e.shiftKey) {
            // PRESS ONLY ENTER

            e.preventDefault();
            // const el: any = inputEl.current;
            // if (!el || !el.innerHTML || !el.innerHTML.length) {
            //   console.log(inputEl);
            //   return;
            // }
            // props.onSend(el.innerHTML);
            // el.innerHTML = '';
            // return true;
            return onRequestSend();
          }
        }}
      />

      <div onClick={(e: any) => onRequestSend()} style={{ cursor: "pointer", position: "relative" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            height: "100%",
            opacity: text.trim().length ? 1 : 0.2,
            pointerEvents: text.trim().length ? "none" : "initial",
            width: 48,
            transition: "opacity 300ms",
            userSelect: "none",
          }}
        >
          <i className="material-icons" children="send" />
        </Box>
      </div>
    </Box>
  );
};

const msgAvatarSize = 32;

const FileThumbnail = (props: { file: IGrapeFile; grape: IGrapeMessage }) => {
  const { file } = props;
  const isImage = file.mimetype?.startsWith("image");

  // const imgSizeRef = useRef({ width: 200, height: 200 });
  // useEffect(() => {
  //   if (isImage) {
  //     const imgLoad = new Image();
  //     imgLoad.onload = () => {
  //       imgSizeRef.current = { width: imgLoad.width, height: imgLoad.height };
  //     };
  //     imgLoad.src = file.url;
  //   }
  // }, []);

  return isImage ? (
    <div
      style={{
        textAlign: "center",
        cursor: "pointer",
        margin: " -5px -10px",
        marginBottom: 5,
        maxWidth: 240,
        height: 100, //180
        borderRadius: "5px 5px 0 0",
        backgroundImage: `url(${file.url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
      onClick={() => openFileInPage(file.url, file.mimetype)}
    >
      <img
        //? render image to define aspect ratio
        src={file.url}
        style={{ maxWidth: 228, height: "100%", opacity: 0 }}
        // style={{ maxWidth: 240, maxHeight: 300, borderRadius: "5px 5px 0 0" }}
        // onClick={() => openFileInPage(file.url, file.mimetype)}
      />
    </div>
  ) : (
    <GrapeFile grapeFile={file} style={{ border: "1px solid #ccc", pointerEvents: "none" }} />
  );
};
