import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./swSrc/service-worker";
import { getEnvName } from "./utils/getEnvName";

const envName = getEnvName();

Sentry.init({
  release: `grapes-frontend@${process.env.REACT_APP_VERSION}`,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing(), new CaptureConsole({ levels: ["error"] })],
  tracesSampleRate: 1.0,
  environment: envName,
  enabled: envName !== "local",
  autoSessionTracking: envName !== "local",
  ignoreErrors: [],
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
