/** @jsxImportSource @emotion/react */
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
//@ts-ignore
import { useApolloClient } from "@apollo/react-hooks";
import {
  Button,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";
import Grapes from "../../modules/Grapes";
import { showConfirmDialog } from "../../utils";
import { useSafeWindowPollingQuery } from "../../utils/safeWindowQuery";
import { DateSelection, dateTypes, getDateTypeTitle, InfiniteCalendar } from "../CalendarZ";
import { GRAPE_DESCENDENT_DEADLINES } from "../GrapeDetail/core/gql_interface";
import { IGrape } from "../grape_gql_interface";
import MLDialog from "../MLDialog";
import { parseEventsGroupingAncestors } from "../MyGrapeDetail/PlannerMyGrape";
import { useSafeGrapeDetailContextSelector } from "../GrapeDetail/GrapeDetail/hooks/GrapeDetailContext";
import ApolloClient from "apollo-client";

export const GrapeDateFactory = {
  // create a new date item
  create: (props: {
    client: ApolloClient<any>;
    grapeId: string;
    dateItem: {
      title: string;
      type: number;
      date: Moment;
      selection: DateSelection;
    };
    refetchQueries?: any;
  }) => {
    Grapes.addGrape(
      props.client,
      props.grapeId,
      props.dateItem.title,
      {
        type: "deadline",
        status: props.dateItem.type,
        // labels: [getDateTypeTitle(props.dateItem.type)],
        date: props.dateItem.date.toISOString(),
        // date2: selection.endDate ? selection.startDate.toISOString() : "",
        date0: props.dateItem.selection.endDate ? props.dateItem.selection.startDate.toISOString() : "",
      },
      props.refetchQueries || [{ query: GRAPE_DESCENDENT_DEADLINES, variables: { grapeId: props.grapeId } }]
    );
  },

  // edit a date item
  edit: (props: {
    client: ApolloClient<any>;
    grapeId: string;
    dateItem: {
      title: string;
      type: number;
      date: Moment;
      selection: DateSelection;
    };
    refetchQueries?: any;
  }) => {
    Grapes.editGrape(
      props.client,
      props.grapeId,
      {
        title: props.dateItem.title,
        status: props.dateItem.type,
        // labels: [getDateTypeTitle(props.dateItem.type)],
        date: props.dateItem.date.toISOString(),
        // date2: selection.endDate ? selection.startDate.toISOString() : "",
        date0: props.dateItem.selection.endDate ? props.dateItem.selection.startDate.toISOString() : "",
      },
      props.refetchQueries || [{ query: GRAPE_DESCENDENT_DEADLINES, variables: { grapeId: props.grapeId } }]
    )
      .then((x) => console.log(1, x))
      .catch((x) => console.log(2, x));
  },
};

const GrapeDateItem = (props: {
  iconOnly?: boolean;
  outlined?: boolean;
  onChange?: (value: { startDate?: Date; endDate?: Date }) => void;
  grape: IGrape;
}) => {
  const deadlines = useSafeGrapeDetailContextSelector("deadlines") || [];
  const client = useApolloClient();
  const [dateVisible, setDateVisible] = useState(false);
  const [monthDate, setMonthDate] = useState(() => new Date());
  const theme = useTheme();
  const [selection, setSelection] = useState<DateSelection>();
  const anchorRef = useRef<any>();

  const titleRef = useRef<any>();
  const titleTextRef = useRef("");
  const [time, setTime] = useState<Date | null>(null);
  const [type, setType] = useState<number>(0);
  const [rekey, setRekey] = useState(1);
  const [dialogStep, setDialogStep] = useState(0);
  const [dialogEditId, setDialogEditId] = useState<number | string>();

  const onSecondStepCancel = () => {
    titleTextRef.current = "";
    setRekey((r) => r + 1);
    setTime(null);
    setType(0);
  };

  const onDialogCancel = () => {
    if (dialogStep === 1) {
      setDialogStep(0);
      if (dialogEditId) {
        setSelection(undefined);
        setDialogEditId(undefined);
      }
      return;
    }

    if (selection) {
      setSelection(undefined);
      return;
    }

    setDateVisible(false);
  };

  const onDialogConfirm = () => {
    if (!selection) {
      MLDialog.showSnackbar("Please choose date");
      return;
    }

    if (dialogStep === 0) {
      setDialogStep(1);
      const input = titleRef.current?.querySelector("input");
      if (input?.focus) setTimeout(() => input.focus(), 100);
      return;
    }

    if (!titleTextRef.current.trim().length) {
      MLDialog.showSnackbar("Please insert title");
      // TODO - focus title field
      // console.log(11, textRef.current.input);
      // textRef.current.input?.focus?.();
      return;
    }

    const title = titleTextRef.current;
    const date = moment(selection.endDate || selection.startDate);

    if (time !== null) {
      date.set({ hour: time.getHours(), minute: time.getMinutes() });
    }

    if (dialogEditId) {
      GrapeDateFactory.edit({ client, grapeId: dialogEditId as string, dateItem: { title, type, date, selection } });
      setDialogEditId(undefined);
    } else {
      GrapeDateFactory.create({ client, grapeId: props.grape.id, dateItem: { title, type, date, selection } });
    }

    props.onChange?.(selection);
    // setDateVisible(false);
    setSelection(undefined);
    onSecondStepCancel();
    setDialogStep(0);
  };

  const color = deadlines?.length ? theme.palette.primary.main : undefined;

  return (
    <React.Fragment>
      <div
        ref={(ref) => (anchorRef.current = ref)}
        className={"hoverable" + (props.outlined ? " outlined" : "")}
        css={{
          marginLeft: 8,
          display: "flex",
          alignItems: "center",
          padding: "0 6px",
        }}
        onClick={() => setDateVisible(true)}
      >
        <EventIcon
          style={{
            fontSize: "initial",
            opacity: 0.8,
            color,
            // marginLeft: props.iconOnly ? 6 : -3,
            // marginRight: 6,
            marginLeft: 3,
            marginRight: 3,
          }}
        />
        <span
          // children={props.deadlines?.length || (props.iconOnly ? "" : "DATE")}
          children={props.iconOnly ? "" : "DATE"}
          style={{ color, letterSpacing: 1, fontSize: 12 }}
        />
      </div>

      <Popover
        keepMounted
        anchorEl={anchorRef.current}
        open={dateVisible}
        onClose={onDialogCancel}
        // onClose={onDialogConfirm}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        css={{
          ".MuiPaper-root.MuiPopover-paper": {
            marginTop: 8,
          },
        }}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            if (dialogStep > 0 || selection) {
              onDialogConfirm();
            }
          }
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            //maxHeight: 500, //! UNCOMMENT TO GET SCROLLABLE CALENDAR
            position: "relative",
          }}
        >
          <Collapse in={dialogStep === 0}>
            <InfiniteCalendar
              selectable
              selection={selection}
              monthDate={monthDate}
              onMonthChange={(newMonthDate) => setMonthDate(newMonthDate)}
              onSelectionChange={(selection) => setSelection(selection)}
              events={parseEventsGroupingAncestors(deadlines || [], { fromParentId: props.grape.id })?.events}
              // events={props.deadlines?.map((g) => ({
              //   id: g.id,
              //   title: g.title,
              //   start: g.date2,
              //   end: g.date,
              //   type: g.status,
              // }))}
              onEventClick={(item) => {
                const time = new Date(item.start || item.end);
                if (time.getHours() !== 0 || time.getMinutes() !== 0) {
                  setTime(time);
                }

                titleTextRef.current = item.title;
                setRekey((x) => x + 1);
                setDialogStep(1);
                setType(item.type);
                setSelection({ startDate: new Date(item.start), endDate: new Date(item.end) });
                setDialogEditId(item.id);
                setDateVisible(true);
              }}
            />
          </Collapse>

          <Collapse in={dialogStep === 1}>{renderDialogConfirmationStep()}</Collapse>
        </div>

        <Grid container justify="flex-end" style={{ marginBottom: 10, padding: "0 12px" }}>
          <Button
            children={selection || dialogStep ? (dialogStep === 0 ? "annulla" : "indietro") : "chiudi"}
            style={{ color: theme.palette.text.secondary }}
            onClick={onDialogCancel}
          />
          <div css={{ flex: 1 }} />
          {dialogEditId && (
            <Button
              css={{ fontWeight: "bold", color: "#de2000" }}
              children={"elimina"}
              onClick={() => {
                const toDeleteGrape = deadlines?.find((g) => g.id === dialogEditId);
                if (!toDeleteGrape) return;
                showConfirmDialog(`Vuoi eliminare questa "${getDateTypeTitle(toDeleteGrape.status)}"?`, "", () => {
                  if (!toDeleteGrape) {
                    MLDialog.showSnackbar("err deleting deadline");
                  } else {
                    Grapes.doDeleteGrape(client, toDeleteGrape, [
                      { query: GRAPE_DESCENDENT_DEADLINES, variables: { grapeId: props.grape.id } },
                    ]);
                    onDialogCancel();
                  }
                });
              }}
            />
          )}
          {selection || dialogStep ? (
            <Button
              css={{ fontWeight: "bold" }}
              children={dialogStep === 0 ? "aggiungi" : "conferma"}
              onClick={onDialogConfirm}
            />
          ) : null}
        </Grid>
      </Popover>
    </React.Fragment>
  );

  function renderDialogConfirmationStep() {
    if (!selection) return null;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div css={{ margin: 24, marginBottom: 32 }}>
          {dialogEditId ? (
            <React.Fragment>
              <KeyboardDatePicker
                value={selection.startDate}
                // onChange={(d: any) => setSelection((s) => ({ ...s, startDate: d }))}
                onChange={(d: any) => {
                  const date = moment(d);
                  const newSelection = { ...selection };
                  if (date.isAfter(selection.endDate)) {
                    newSelection.startDate = newSelection.endDate!;
                    newSelection.endDate = date.toDate();
                  } else {
                    newSelection.startDate = date.toDate();
                  }
                  setSelection(newSelection);
                }}
              />
              {" → "}
              <KeyboardDatePicker
                value={selection.endDate}
                // onChange={(d: any) => setSelection((s) => ({ ...s, endDate: d } as any))}
                onChange={(d: any) => {
                  const date = moment(d);
                  const newSelection = { ...selection };
                  if (date.isBefore(selection.startDate)) {
                    newSelection.endDate = newSelection.startDate;
                    newSelection.startDate = date.toDate();
                  } else {
                    newSelection.endDate = date.toDate();
                  }
                  setSelection(newSelection);
                }}
              />
            </React.Fragment>
          ) : (
            <Typography variant="h6">
              {moment(selection.startDate).format("DD MMM YYYY")}
              {selection.endDate &&
                !moment(selection.startDate).isSame(selection.endDate, "date") &&
                " → " + moment(selection.endDate).format("DD MMM YYYY")}
            </Typography>
          )}

          <div css={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: 24 }}>
            <FormControl>
              <InputLabel children="Type" />
              <Select value={type} onChange={(e) => setType(e.target.value as number)}>
                {dateTypes.map((type) => (
                  <MenuItem
                    key={type.value}
                    value={type.value}
                    children={type.title}
                    style={{ textTransform: "capitalize" }}
                  />
                ))}
              </Select>
            </FormControl>

            <div css={{ flex: 1, margin: "0 16px" }}>
              <TextField
                label="Event title"
                fullWidth
                key={rekey}
                defaultValue={titleTextRef.current}
                onChange={(e) => (titleTextRef.current = e.target.value)}
                ref={(r: any) => (titleRef.current = r)}
              />
            </div>

            {/* <DescriptionInput
              id="date-text"
              key={rekey}
              ref={(r: any) => (titleRef.current = r)}
              text={titleTextRef.current}
              placeholder="Title..."
              onChange={(text: string) => (titleTextRef.current = text)}
              style={{
                flex: 1,
                // flex: 0,
                alignSelf: 'stretch',
                fontSize: 18,
              }}
              /> */}

            <KeyboardTimePicker
              id="time-picker"
              label="Time"
              ampm={false}
              value={time}
              css={{ width: 120 }}
              onChange={(date) => setTime(date)}
              KeyboardButtonProps={{ "aria-label": "change time" }}
            />
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
};

export default GrapeDateItem;

function pushRecursiveDeadlineGrapes(grape: IGrape, list: IGrape[]) {
  if (grape.date) list.push(grape);
  for (let i = 0; i < grape.children?.length || 0; i++) {
    pushRecursiveDeadlineGrapes(grape.children[i], list);
  }
  return list;
}
