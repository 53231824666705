import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

const InitialLoading = (props: { error?: any }) => {
  const [vis, setVis] = useState(false);

  useEffect(() => setVis(true), []);

  return (
    <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
      {/* Loading.. */}
      <img
        src={require("../assets/gif/tenor.gif")}
        style={{
          opacity: vis ? 1 : 0,
          transition: "opacity 500ms",
          transitionDelay: "500ms",
        }}
      />

      {props.error ? (
        <div
          css={{ color: "#aaa" }}
          children={props.error?.response?.data?.message || props.error?.message || props.error}
        />
      ) : null}
    </Grid>
  );
};

export default InitialLoading;
