/** @jsxImportSource @emotion/react */

import { useApolloClient } from "@apollo/react-hooks";
import { Folder } from "@material-ui/icons";
import "moment/locale/it";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useGrapesApp } from "../../App";
import { showInputDialog } from "../../utils";
import UserPreferences from "../../utils/UserPreferences";
import MLDialog from "../MLDialog";

interface FolderItem<T> {
  id: string;
  isFolder: boolean;
  name: string;
  list: T[];
}

function ClientSortableList<T>(props: {
  groupId: string;
  list: T[];
  getItemId: (item: T) => string;
  //---
  className?: string;
  renderItem: (item: T, index: number) => any;
  renderEmptyItem?: any;
  renderTrailElement?: any;
  disabled?: boolean;
  distance?: number;
  isCombineEnabled?: boolean;
  //---
  customDragDrop?: (onDragEnd: (res: DropResult) => void, children: any) => any;
}): JSX.Element {
  const getLocalStorageId = () => "csl-indexes-" + props.groupId;

  const grapesApp = useGrapesApp();
  const client = useApolloClient();

  //? indexes
  const initIndexes = useCallback(() => {
    try {
      let valuestr = UserPreferences.getPreference(grapesApp, getLocalStorageId());
      if (!valuestr) throw "not stored yet";
      let values = JSON.parse(valuestr);
      return values;
    } catch (ex) {}
    return {};
  }, []);

  const [indexes, setIndexes] = useState<{ [key: string]: number }>(initIndexes);

  //? folders
  // use ClientSortableTreee instead
  const [folders, setFolders] = useState<{ [key: string]: string[] }>({});
  const folderedGrapes = deriveFoldersByGrapesId(folders);

  const getOrderedListByIndexes = (list: T[]) => {
    const foundFolders: { [folderName: string]: FolderItem<T> } = {};

    const ordered = list.sort((a: T, b: T) => {
      const ida = props.getItemId(a);
      const idb = props.getItemId(b);
      let ia = (indexes[ida] >= 0 ? indexes[ida] : list.indexOf(a) || 0) as number;
      let ib = (indexes[idb] >= 0 ? indexes[idb] : list.indexOf(b) || 0) as number;
      return ia - ib;
    });

    const rlist: (FolderItem<T> | T)[] = [];
    for (let c = 0; c < ordered.length; c++) {
      const item = ordered[c];

      const id = props.getItemId(ordered[c]);
      if (folderedGrapes[id]) {
        const folderName = folderedGrapes[id];
        if (!foundFolders[folderName])
          foundFolders[folderName] = { isFolder: true, id: "fid:" + props.getItemId(item), list: [], name: folderName };
        foundFolders[folderName].list.push(item);
      } else {
        rlist.push(item);
      }
    }

    return rlist;
  };

  // const getOrderedListByIndexes = (list: T[]) => {
  //   return list.sort((a: T, b: T) => {
  //     const ida = props.getItemId(a);
  //     const idb = props.getItemId(b);
  //     let ia = (indexes[ida] >= 0 ? indexes[ida] : list.indexOf(a) || 0) as number;
  //     let ib = (indexes[idb] >= 0 ? indexes[idb] : list.indexOf(b) || 0) as number;
  //     return ia - ib;
  //   });
  // };

  const [list, setList] = useState(() => {
    return getOrderedListByIndexes(props.list || []);
  });

  useEffect(() => {
    setList(getOrderedListByIndexes(props.list || []));
  }, [props.list]);

  const onDragEnd = (res: DropResult) => {
    if (!res.destination) return;

    let draggingItem: T | undefined;

    const flatlist: T[] = [];
    for (let c = 0; c < list.length; c++) {
      const isFolder = (list[c] as any).isFolder;
      if (isFolder) {
        const folder = list[c] as FolderItem<T>;
        for (let n = 0; n < folder.list.length; n++) {
          flatlist.push(folder.list[n]);
        }
      } else {
        flatlist.push(list[c] as T);
      }
    }

    const newList = flatlist.filter((item) => {
      if (props.getItemId(item) === res.draggableId) {
        draggingItem = item;
        return false;
      }
      return true;
    });

    const newVal: any = {};
    const destinationIndex = res.destination?.index || 0;

    if (draggingItem) {
      newList.splice(res.destination?.index, 0, draggingItem);
    } else {
      newVal[res.draggableId] = destinationIndex;
    }

    newList.forEach((item, index) => {
      const shiftIndex = !draggingItem && index >= destinationIndex;
      newVal[props.getItemId(item)] = index + (shiftIndex ? 1 : 0);
    });

    setList(newList);
    console.log(props.groupId, newList);

    //? save to localStorage
    UserPreferences.savePreference(grapesApp, getLocalStorageId(), JSON.stringify(newVal));
    setIndexes(newVal);
  };

  const droppable = (
    <Droppable droppableId={props.groupId} isCombineEnabled={!!props.isCombineEnabled}>
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className={props.className}>
          {list.length == 0 ? props.renderEmptyItem : null}

          {list.map((item, index) => {
            const isFolder = (item as any).isFolder;

            const draggableId = isFolder ? (item as FolderItem<T>).id : props.getItemId(item as T);

            return (
              <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={props.disabled}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    css={{ outline: "none", position: "relative", paddingTop: props.distance }}
                  >
                    {isFolder ? renderFolder(item as FolderItem<T>, index) : props.renderItem(item as T, index)}
                  </div>
                )}
              </Draggable>
            );
          })}

          {provided.placeholder}

          {props.renderTrailElement}
        </div>
      )}
    </Droppable>
  );

  if (props.customDragDrop) {
    return props.customDragDrop(onDragEnd, droppable);
  }

  return <DragDropContext onDragEnd={onDragEnd} children={droppable} />;

  function renderFolder<T>(item: FolderItem<T>, index: number) {
    return <div css={{ backgroundColor: "cyan" }}>{item.name}</div>;
  }
}

export default ClientSortableList;

function deriveFoldersByGrapesId(folders: { [key: string]: string[] }) {
  const map: { [grapId: string]: string } = {};
  for (let folderName in folders) {
    for (let c = 0; c < folders[folderName].length; c++) {
      map[folders[folderName][c]] = folderName;
    }
  }
  return map;
}
