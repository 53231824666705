import { useEffect, useRef } from "react";
import io from "socket.io-client";
import { useGrapesApp } from "../App";
import MLDialog from "../components/MLDialog";

const mainUrl = process.env.REACT_APP_BASE_URL as string;

let socket: ReturnType<typeof io>;

export default function useSocket(eventName: string, cb: any) {
  const { user } = useGrapesApp();
  const userId = user?.id;

  const isDisconnectedRef = useRef(false);
  const timeout = useRef<any>();

  const disconnectEvent = () => {
    timeout.current = setTimeout(() => {
      isDisconnectedRef.current = true;
      MLDialog.showSnackbar("Network is slow! Message will disappear when everything seems to be working fine", {
        variant: "error",
        persist: true,
        key: "disconnect_snackbar",
      });
    }, 15 * 1000); // 15 seconds
  };
  const connectEvent = () => {
    clearTimeout(timeout.current);
    if (isDisconnectedRef.current) {
      // window.location.reload();
      MLDialog.hideSnackbar("disconnect_snackbar");
    }
  };

  useEffect(() => {
    if (userId && !socket) {
      socket = io(mainUrl, { path: "/socket", auth: { id: userId } });

      socket.on("connect", connectEvent);
      socket.on("disconnect", disconnectEvent);
    }

    return () => {
      socket?.off("connect", connectEvent);
      socket?.off("disconnect", disconnectEvent);
    };
  }, [userId]);

  useEffect(() => {
    if (eventName && socket && cb) {
      socket.on(eventName, cb);
    }
    return () => {
      socket?.off(eventName, cb);
    };
  }, [eventName, cb]);

  return socket;
}
