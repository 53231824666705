import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { SetStateAction } from "jotai";
import moment from "moment";
import { useEffect, useState } from "react";
import { cleanHTMLTags } from "../../../../utils";
import Grapes, { IGrapeEdit } from "../../../../modules/Grapes";
import { IGrape } from "../../../grape_gql_interface";
import MLDialog from "../../../MLDialog";
import { SmartInputField } from "../../../SmartInputField";
import { __DEV__ } from "../../../../utils/init";
import { getStringColors } from "../../../GrapeLabels";

export const SprintEditorModal = (props: {
  sprint: IGrape | undefined;
  onClose: () => void;
  onEditSave: (changes: IGrapeEdit) => Promise<any>;
}) => {
  const [loading, setLoading] = useState(false);

  const [renderSprint, _setRenderSprint] = useState<IGrape | undefined>(props.sprint);

  const setRenderSprint = (callback: SetStateAction<IGrape | undefined>) => {
    _setRenderSprint(callback);
  };

  useEffect(() => {
    // if (props.sprint && props.sprint.id !== renderSprint?.id) setRenderSprint(props.sprint);
    if (props.sprint) {
      const changed = JSON.stringify(renderSprint) !== JSON.stringify(props.sprint);
      if (changed) setRenderSprint(props.sprint);
    }
  }, [props.sprint]);

  const related = [...(renderSprint?.children || []), ...(renderSprint?.related_to || [])];
  const tasks = [
    //main
    renderSprint?.date ? grapeToTask(renderSprint!, "task") : undefined,

    //subtasks
    ...related.map((c) => (c.date || c.date0 ? grapeToTask(c, "task") : undefined)),

    //
  ].filter(Boolean) as Task[];

  const renderDateInput = (key: "date" | "date0", label: string) => (
    <Grid container direction="row" spacing={2} justify="center">
      <Grid item>
        <Typography variant="h6" children={label} css={{ padding: 8 }} />
      </Grid>

      <Grid item css={{ marginTop: 10 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            css={{ input: { color: renderSprint?.[key] ? "#ccc !important" : undefined } }}
            value={renderSprint?.[key] || moment()}
            onChange={(d: any) => {
              const date = moment(d).toISOString();
              setRenderSprint((s) => ({ ...s!, [key]: date }));
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      open={!!props.sprint}
      onClose={() => {
        const changed = JSON.stringify(renderSprint) !== JSON.stringify(props.sprint);
        if (!changed) {
          props.onClose();
        }
      }}
      maxWidth="lg"
    >
      {renderSprint ? (
        <div css={{ pointerEvents: loading ? "none" : undefined }}>
          <DialogTitle>
            <SmartInputField
              placeholder="Nome dello sprint..."
              value={cleanHTMLTags(renderSprint!.title || "")}
              onChange={(title) => setRenderSprint((s) => ({ ...s!, title }))}
            />
          </DialogTitle>

          {__DEV__ ? (
            <DialogContent>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  {renderSprint?.date ? null : renderDateInput("date", "Deadline")}

                  {tasks.length ? (
                    <div css={{ minHeight: 200, minWidth: 200 }}>
                      <Gantt
                        viewMode={ViewMode.Day}
                        onDateChange={(task, c) => console.log("change", task, c)}
                        onDelete={(task) => console.log("delete", task)}
                        onProgressChange={(task, c) => console.log("progress", task, c)}
                        onDoubleClick={(task) => console.log("double", task)}
                        tasks={tasks}
                      />
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </DialogContent>
          ) : (
            <DialogContent>
              {renderDateInput("date0", "Inizio")}
              {renderDateInput("date", "Deadline")}
            </DialogContent>
          )}

          <DialogActions>
            <Button children="ANNULLA" onClick={() => props.onClose()} />
            <Button
              children={loading ? <CircularProgress /> : "SALVA"}
              css={{ fontWeight: "bold !important" }}
              onClick={() => {
                if (!(renderSprint?.title || "").trim()) {
                  MLDialog.showSnackbar("Inserisci un titolo", { variant: "error" });
                  return;
                }

                let changes: IGrapeEdit = {
                  title: renderSprint!.title,
                  date: renderSprint!.date,
                  date0: renderSprint!.date0,
                  //TODO wip, edit more
                };

                setLoading(true);
                props.onEditSave(changes).finally(() => setLoading(false));
              }}
            />
          </DialogActions>
        </div>
      ) : (
        <div />
      )}
    </Dialog>
  );
};

type TaskType = "task" | "milestone" | "project";

export function grapeToTask(
  grape: IGrape,
  type: TaskType,
  options?: { color: string }
): Task & { progressColor: string } {
  const dateStart = moment(grape.date0 || grape.date).startOf("day");
  let dateEnd = moment(grape.date);
  if (dateEnd.isSameOrBefore(dateStart)) dateEnd = dateStart.clone().add(1, "day");

  return {
    id: grape.id,
    type: type,
    name: cleanHTMLTags(grape.title || ""),
    start: dateStart.toDate(),
    end: dateEnd.toDate(),
    isDisabled: false,
    progress: 1,
    progressColor: options?.color || getStringColors(grape.title || "").background,
    // dependencies: [],
  };
}
