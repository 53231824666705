import { Grid } from "@material-ui/core";
import gql from "graphql-tag";
import React from "react";
import Contextable from "./Contextable";
import { CSSProperties } from "react";
import { IUser } from "./User";
import { openPreferredSizeWindow, showConfirmDialog } from "../utils";
import { QUERY_REMOVE_FILE } from "./GrapeDetail/core/gql_interface";
import { useApolloClient } from "@apollo/react-hooks";
import MLDialog from "./MLDialog";

export interface IGrapeFile {
  id: string;
  mimetype: string;
  created_at: string;
  updated_at: string;
  title: string;
  url: string;
  thumbnail: string;
  owner: IUser;
}

const GrapeFile = (props: { grapeFile: IGrapeFile; onDeleted?: () => void; style?: CSSProperties }) => {
  const client = useApolloClient();

  // console.log(51, props.grapeFile);

  return (
    <Contextable
      menuItems={[
        // {
        //   key: "20",
        //   caption: "Crea Task",
        //   onClick: () => {
        //     Grapes.addGrape(
        //       client,
        //       grape.id,
        //       `<img
        //       width="50" height="50"
        //       style="object-fit: cover"
        //       src="${file.thumbnail}"
        //       data-file-id="${file.id}"
        //       data-file-url="${file.url}"
        //       onClick="window.open('${file.url}')"
        //     />`
        //     );
        //   },
        // },
        {
          key: "file-download",
          caption: "Scarica",
          onClick: () => {
            window.open(props.grapeFile.url);
          },
        },
        {
          key: "file-delete",
          caption: "Elimina",
          color: "#de2000",
          onClick: () => {
            showConfirmDialog(
              `Vuoi rimuovere "${props.grapeFile.title}" ?`,
              "Una volta rimosso non potrai più recuperarlo",
              () => {
                client
                  .mutate({
                    mutation: QUERY_REMOVE_FILE,
                    variables: { fileId: props.grapeFile.id },
                  })
                  .then(() => {
                    props.onDeleted?.();
                    MLDialog.showSnackbar("File eliminato", { variant: "success" });
                  })
                  .catch((error: any) => {
                    MLDialog.showSnackbar(`Error ${error}`, { variant: "error" });
                  });
              }
            );
          },
        },
      ]}
    >
      <GrapeFileThumbnail
        {...props.grapeFile}
        style={props.style}
        onClick={() => openFileInPage(props.grapeFile.url)}
      />
    </Contextable>
  );
};

export default GrapeFile;

export const openFileInPage = (url: string, mimetype?: string) => {
  const isImage = mimetype?.startsWith("image");

  if (isImage) {
    var img = new Image();
    img.onload = function () {
      openPreferredSizeWindow(url, img.naturalWidth, img.naturalHeight);
    };
    img.src = url;
  } else {
    openPreferredSizeWindow(url, 1600, 1200);
  }
};

export const GrapeFileThumbnail = (props: {
  mimetype: string;
  thumbnail?: string;
  title: React.ReactNode | string;
  url?: string;
  style?: CSSProperties;
  onClick: () => void;
}) => {
  const isImage = (props.thumbnail || props.url) && props.mimetype.startsWith("image");

  return (
    <Grid
      container
      item
      style={{
        width: 50,
        height: 50,
        border: "1px solid #eee",
        position: "relative",
        margin: 2,
        textAlign: "center",
        cursor: "pointer",
        overflow: "hidden",
        background: "#eee",
        backgroundSize: "cover",
        backgroundImage: isImage ? `url("${props.thumbnail || props.url}")` : undefined,
        ...(props.style || {}),
      }}
      justify="center"
      alignItems="center"
      // onClick={() => window.open(props.url)}
      onClick={props.onClick}
    >
      {/* {isImage ? null : <span children={props.mimetype} />} */}
      {isImage ? null : (
        <span
          // children={props.mimetype.split('/').join('\n') + '\n' + props.title}
          children={props.title}
          style={{ fontSize: 8, pointerEvents: "none" }}
        />
      )}
    </Grid>
  );
};
