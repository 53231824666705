/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { __DEV__ } from "../../../utils/init";
import { IGrape } from "../../grape_gql_interface";
import DebugIcon from "@material-ui/icons/Widgets";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import { defaultGrapeListType } from "../../GrapeDetail/core/grapeTypes";
import { isArray } from "util";
import { GrapeAppDebugEnabled } from "../AppDebug";

const GrapeDebugComponent = (props: { grape: IGrape }) => {
  const [visible, setVisible] = useState(false);
  const [grapeDetail, setGrapeDetail] = useState<IGrape>();

  return (
    <React.Fragment>
      {visible ? (
        <div
          css={{
            position: "absolute",
            bottom: 0,
            // top: 0,
            left: 0,
            // right: 0,
            height: "100%",
            maxHeight: 700,
            width: "100%",
            maxWidth: 600,
            zIndex: 100,
            overflow: "scroll",
            border: "1px solid black",
            backgroundColor: "white",
            padding: 10,
          }}
        >
          <GrapeDebugItem grape={props.grape} depth={0} onClick={(g) => setGrapeDetail(g)} />

          {grapeDetail ? (
            <div
              css={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
              onClick={() => setGrapeDetail(undefined)}
            >
              <div
                css={{
                  position: "relative",
                  border: "1px solid black",
                  backgroundColor: "white",
                  padding: 10,
                  margin: 20,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  css={{ position: "absolute", top: 0, right: 0, fontSize: 20, padding: 4 }}
                  className="hoverable"
                  onClick={() => setGrapeDetail(undefined)}
                  children={<CloseIcon />}
                />
                <PropValue title="title" value={props.grape.title} bigger />
                <PropValue title="description" value={props.grape.description} />
                <PropValue title="type" value={props.grape.type || defaultGrapeListType} />
                <PropValue title="labels" value={props.grape.labels || []} />
                <PropValue
                  title={"childrens: " + (props.grape.children?.length || 0)}
                  value={props.grape.children?.map((g) => g.title) || 0}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div
        css={{
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 100,
          padding: 4,
          background: "orange",
          cursor: "pointer",
          borderTopRightRadius: 12,
          transform: visible ? "translate(0, 0)" : "translate(4px, -4px)",
          transition: "all 200ms",
        }}
        onClick={() => setVisible(!visible)}
        children={<DebugIcon style={{ color: "white", fontSize: 20 }} />}
      />
    </React.Fragment>
  );
};

export const GrapeDebug = GrapeAppDebugEnabled && __DEV__ ? GrapeDebugComponent : () => null;

const GrapeDebugItem = (props: { grape: IGrape; depth: number; onClick: (g: IGrape) => void }) => {
  const [expanded, setExpanded] = useState(true);
  const canToggleExpand = props.depth > 0;

  // const depthColor="rgba(200, 150, 0, " + (0.05 + props.depth / 10) + ")"
  const depthColor = ["#eee", "#0a0", "#ad0", "#dd0", "#f80", "#f00", "#f00", "#f00", "#f00"][props.depth];

  return (
    <div>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          // borderBottom: "1px solid #eee",
          ":hover": { backgroundColor: "#fafafa", cursor: "pointer" },
        }}
        onClick={() => props.onClick(props.grape)}
        style={
          {
            //   backgroundColor: props.depth % 2 === 0 ? "#f8f8f8" : "none",
          }
        }
      >
        <div
          css={{
            fontSize: 10,
            color: "#888",

            width: 24 * (1 + props.depth),
            borderBottom: "3px solid white",
            borderColor: depthColor,
            position: "relative",
          }}
          className={canToggleExpand ? "hoverable" : ""}
          onClick={() => canToggleExpand && setExpanded(!expanded)}
        >
          <span children={props.depth + ":" + (props.grape.children?.length || "")} />

          <div css={{ position: "absolute", top: 0, right: 0 }}>
            {canToggleExpand && props.grape.children?.length ? (
              <ChevronLeftIcon
                style={{
                  fontSize: 16,
                  transition: "transform 200ms",
                  transform: expanded ? "rotate(90deg)" : "rotate(270deg)",
                }}
              />
            ) : null}
          </div>
        </div>

        <div css={{ flex: 1, overflow: "hidden" }}>
          <div children={props.grape.title} css={{ whiteSpace: "nowrap" }} />
        </div>
        <MoreHorizIcon className="hoverable" css={{ fontSize: 20, marginLeft: 20, opacity: 0.4 }} />
      </div>

      {expanded
        ? props.grape.children?.map((g) => (
            <GrapeDebugItem key={g.id} grape={g} depth={props.depth + 1} onClick={() => props.onClick(g)} />
          ))
        : null}
    </div>
  );
};

const PropValue = (props: { title: string; value: any; bigger?: boolean }) => {
  return (
    <div css={{ borderBottom: "1px solid #ddd", padding: "10px 0" }}>
      <div css={{ fontSize: 14, fontWeight: "normal", marginBottom: 4 }} children={props.title} />
      <div
        css={{ fontSize: props.bigger ? 20 : 14, fontWeight: "bold" }}
        children={isArray(props.value) ? "[ " + props.value.join(", ") + " ]" : props.value}
      />
    </div>
  );
};
