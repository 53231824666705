/** @jsxImportSource @emotion/react */
import { Checkbox, useTheme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { useRef, useState } from "react";
import { Item, Menu, Submenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
// import { Popover, Menu, MenuItem } from '@material-ui/core';

export type InnerContextableRef = { open: (e: React.MouseEvent) => void };

export type MenuItem = IContextMenuItem | undefined | null | false;
export interface IContextable {
  children?: React.ReactElement | React.HTMLAttributes<HTMLElement>;
  className?: string;
  menuItems: MenuItem[] | (() => MenuItem[]);
  withLongTouch?: boolean;
  bothMouseButtons?: boolean;
  headerComponent?: any;
  footerComponent?: any;
  disabled?: boolean;
  disableOnSelectCapture?: boolean;
  onContextMenu?: () => void;
  style?: CSSProperties;
  innerRef?: (ref: InnerContextableRef) => void;
}

export interface IContextMenuItem {
  key?: string;
  caption?: any;
  checked?: boolean;
  onClick?: (params?: { checked?: boolean }) => void;
  color?: string;
  hoverable?: boolean;
  subMenuItems?: IContextMenuItem[];
}

const Contextable = (props: IContextable) => {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  const menuRef = useRef(null);
  const touchTimeout = useRef<NodeJS.Timeout>();
  const disableOnSelectCaptureSelectingRef = useRef(false);

  const handleContextMenu = (e: React.MouseEvent) => {
    if (disableOnSelectCaptureSelectingRef.current || props.disabled) {
      // keep default behaviour
      return;
    }

    e?.preventDefault?.();
    menuRef && menuRef.current && (menuRef.current! as Menu).show(e as any, {});
    setContextMenuOpen(true);
    props.onContextMenu?.();
  };

  if (props.innerRef) props.innerRef({ open: handleContextMenu });

  return (
    <React.Fragment>
      <div
        onClick={props.bothMouseButtons ? handleContextMenu : undefined}
        onContextMenu={handleContextMenu}
        className={props.className}
        css={{ transition: "opacity 200ms", opacity: contextMenuOpen ? 0.4 : 1 }}
        onTouchStart={(e: React.TouchEvent) => {
          if (!props.withLongTouch) return;

          if (e?.touches[0]?.pageX > 0) {
            e.preventDefault();
            e.stopPropagation();

            const touch = e.touches?.[0];

            const evt: any = {
              // Simulate left click
              button: 0,
              type: "click",
              // Additional key to tell the difference between
              // a regular click and a fastclick
              fastclick: true,
              // From touch positions
              clientX: touch?.clientX,
              clientY: touch?.clientY,
              pageX: touch?.pageX,
              pageY: touch?.pageY,
              screenX: touch?.screenX,
              screenY: touch?.screenY,
              stopPropagation: () => false,
            };

            touchTimeout.current = setTimeout(() => handleContextMenu(evt), 500);
          }
        }}
        onTouchEnd={() => touchTimeout.current && clearTimeout(touchTimeout.current!)}
        onTouchMove={() => touchTimeout.current && clearTimeout(touchTimeout.current!)}
        children={props.children}
        onBlurCapture={
          props.disableOnSelectCapture ? () => (disableOnSelectCaptureSelectingRef.current = false) : undefined
        }
        onSelectCapture={
          props.disableOnSelectCapture
            ? () => {
                const selection = window.getSelection() as Selection & { baseOffset: number; extentOffset: number };
                if (!selection) return;

                const start = selection.baseOffset || selection.anchorOffset;
                const end = selection.extentOffset || selection.focusOffset;

                disableOnSelectCaptureSelectingRef.current = start !== end;
              }
            : undefined
        }
      />

      {!props.disabled ? (
        <Menu
          id="a"
          ref={menuRef}
          onHidden={() => setContextMenuOpen(false)}
          css={{
            // zIndex: 2
            zIndex: 1500,
          }}
        >
          {props.headerComponent}
          {(typeof props.menuItems === "function" ? props.menuItems() : props.menuItems).map((item, index) => (
            <ContextableMenuItem item={item} key={String(index)} />
          ))}
          {props.footerComponent}
        </Menu>
      ) : null}
    </React.Fragment>
  );
};

export default Contextable;

const ContextableMenuItem = (props: { item: IContextMenuItem | undefined | null | false; key: string }) => {
  const { item, key } = props;
  const theme = useTheme();

  if (!item) return null;

  return item.subMenuItems ? (
    <Submenu key={key} label={item.caption}>
      {item.subMenuItems &&
        item.subMenuItems.map((item, index) => <ContextableMenuItem item={item} key={String(index)} />)}
    </Submenu>
  ) : (
    <Item
      key={key}
      // onClick={(e) => {
      //   const shouldNotClose = item.onClick?.();
      //   if (!shouldNotClose) {
      //     // menuRef?.current && (menuRef.current! as Menu).hide(e as any);
      //   }
      // }}
      onClick={() => item.onClick?.({ checked: !item.checked })}
      css={{
        " > .react-contexify__item__content": {
          display: "flex",
          flexDirection: "row",
          padding: "8px 12px",
          color: item.color,
          alignItems: "center",
        },
        ":hover > .react-contexify__item__content": {
          // color: item.hoverable === false ? 'initial !important' : undefined,
          color: (item.color || "initial") + " !important",
          // background: (item.hoverable !== false ? item.color || theme.palette.primary.main : 'none') + ' !important',
          background: (item.hoverable !== false ? theme.palette.action.selected : "none") + " !important",
        },
      }}
    >
      {item.checked !== undefined ? <Checkbox style={{ padding: 0, marginRight: 8 }} checked={item.checked} /> : null}
      {item.caption}
    </Item>
  );
};
