/** @jsxImportSource @emotion/react */
import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, Card, IconButton, Tooltip } from "@material-ui/core";
import { ExecutionResult, gql } from "apollo-boost";
import ApolloClient from "apollo-client";
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions";
import ChipInput from "material-ui-chip-input";
import React, { useState } from "react";
import { GET_USER_GRAPE_ANCHESTORS } from "../components/GrapeCollaboratorsThumbs";
import {
  APPEND_GRAPE,
  CREATE_GRAPE,
  EDIT_GRAPE,
  GET_GRAPE,
  GrapeLink,
  GrapeLinkInput,
  IGrape,
  IUserGrapePermission,
  SET_REMINDER,
} from "../components/grape_gql_interface";
import MLDialog from "../components/MLDialog";

import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import { getStringColors, GrapeLabel } from "../components/GrapeLabels";
import MabiDatePicker from "../components/MabiDatePicker";
import { GET_ALL_GRAPES, GET_MY_GRAPES } from "../repositories/queries_mutations";
import { showColorPickerModal, showInputDialog } from "../utils";
import { StoryPointsInfo } from "./utilities/StoryPointsInfo";
import { IMyGrape } from "../components/MyGrapeDetail";
import { __DEV__ } from "../utils/init";

export interface IGrapeEdit {
  title?: string;
  description?: string;
  parent?: string;
  type?: string;
  state?: string;
  labels?: string[];
  date?: string;
  date0?: string;
  date1?: string;
  date2?: string;
  progress?: number;
  priority?: number;
  main_order?: number;
  cross_order?: number;
  storypoints?: number;
  status?: number;
  settings?: any;
  theme?: any;
  links_to?: GrapeLinkInput[];
}

export interface IGrapeAppend {
  grapeId: string;
  parentId: string;
  main_order: number;
  cross_order?: number;
  status?: number;
}

export default class Grapes {
  static addGrape = (
    client: ApolloClient<any>,
    parentId: string,
    title?: string,
    inputVariables?: IGrapeEdit,
    refetchQueries?: ((result: ExecutionResult) => RefetchQueryDescription) | RefetchQueryDescription
  ) => {
    return client
      .mutate({
        mutation: CREATE_GRAPE,
        variables: {
          input: { parent: parentId, title: title, ...(inputVariables || {}) },
        },
        awaitRefetchQueries: true,
        refetchQueries: refetchQueries || [
          parentId ? { query: GET_GRAPE, variables: { id: parentId } } : { query: GET_ALL_GRAPES },
        ],
      })
      .then((resp: any) => {
        if (__DEV__) console.log("finish: ", resp, inputVariables);
        return resp;
      });
    // .then((resp: any) => focusGrape(resp.data.createGrape.id));
  };

  static editGrape = (
    client: ApolloClient<any>,
    grapeId: string,
    inputVariables?: IGrapeEdit,
    refetchQueries?: ((result: ExecutionResult) => RefetchQueryDescription) | RefetchQueryDescription
  ) => {
    return client.mutate({
      mutation: EDIT_GRAPE,
      variables: { input: { id: grapeId, ...(inputVariables || {}) } },
      refetchQueries: refetchQueries || [{ query: GET_MY_GRAPES }],
    });
  };

  static mirrorGrape = ({ client, parent, grape }: { client: ApolloClient<any>; parent: IGrape; grape: IGrape }) => {
    const params = {
      type: "mirror",
      title: grape.title,
      description: grape.description,
      labels: grape.labels,
      settings: { "mirror-grape": grape.id },
    };

    Grapes.addGrape(client, parent!.id, grape!.title, params);
  };

  static appendGrape = (
    client: ApolloClient<any>,
    inputVariables: IGrapeAppend,
    refetchQueries?: ((result: ExecutionResult) => RefetchQueryDescription) | RefetchQueryDescription
  ) => {
    return client.mutate({
      mutation: APPEND_GRAPE,
      variables: inputVariables,
      refetchQueries: refetchQueries || [{ query: GET_MY_GRAPES }],
    });
  };

  static setMyDay = (client: ApolloClient<any>, grape: IGrape, visible: boolean) => {
    client.mutate({
      mutation: visible ? ADD_GRAPE_TO_MY_DAY : REMOVE_GRAPE_FROM_MY_DAY,
      variables: { grapeId: grape.id },
      refetchQueries: [{ query: GET_MY_GRAPES }],
    });
  };

  static archiveGrape = (client: ApolloClient<any>, grape: IGrape) => {
    client
      .mutate({
        mutation: ARCHIVE_GRAPE,
        variables: { grapeId: grape.id },
        refetchQueries: [{ query: GET_ALL_GRAPES }, { query: GET_MY_GRAPES }],
      })
      .catch((e) => console.log(JSON.stringify(e)));
  };

  static unarchiveGrape = (client: ApolloClient<any>, grape: IGrape) => {
    client
      .mutate({
        mutation: UNARCHIVE_GRAPE,
        variables: { grapeId: grape.id },
        refetchQueries: [{ query: GET_ALL_GRAPES }, { query: GET_MY_GRAPES }],
      })
      .catch((e) => console.log(JSON.stringify(e)));
  };

  //onShareGrape
  static requestInviteToGrape = (client: ApolloClient<any>, grape: IGrape, collaborators?: IUserGrapePermission[]) => {
    setTimeout(() => {
      const email = window.prompt("Inserisci l'email dell'utente che vuoi invitare");
      if (email && email.trim().length) {
        if (collaborators?.find((c) => c?.user?.email?.toLocaleLowerCase() === email.trim().toLocaleLowerCase())) {
          MLDialog.showSnackbar(email + " ha già accesso a questo elemento");
          return;
        }

        var confirmDialog = true;
        if (grape.parent) {
          confirmDialog = window.confirm(
            "Stai invitato un utente su un sotto-livello, non potrà vedere i livelli superiori. Vuoi continuare?"
          );
        }

        client
          .mutate({
            mutation: INVITE_USER_TO_GRAPE,
            variables: { grapeId: grape.id, email },
            refetchQueries: [{ query: GET_USER_GRAPE_ANCHESTORS, variables: { grapeId: grape.id } }],
          })
          .catch((e) => console.log(JSON.stringify(e)));
      }
    });
  };

  static doDeleteGrape = (
    client: ApolloClient<any>,
    grape: { id: string; parent: string | undefined },
    refetchQueries?: any
  ) => {
    return client.mutate({
      mutation: DELETE_GRAPE,
      variables: { id: grape.id },
      refetchQueries: refetchQueries || [
        !grape.parent ? { query: GET_ALL_GRAPES } : { query: GET_GRAPE, variables: { id: grape.id } },
      ], //!wip parent id
    });
  };

  static startFileUpload = (client: ApolloClient<any>, grapeId: string, file: File, title?: string) => {
    return client.mutate({
      mutation: gql`
        mutation uploadFile($grapeId: ID!, $file: Upload!, $title: String!) {
          uploadFile(grapeId: $grapeId, file: $file, title: $title) {
            id
            files {
              id
              title
              url
              thumbnail
            }
          }
        }
      `,
      variables: { grapeId: grapeId, file, title: title || "" },
      refetchQueries: [{ query: GET_GRAPE, variables: { id: grapeId } }],
    });
  };

  static openStoryPointsEditor = (grape: IGrape, onChange: (attr: string, value: any) => void) => {
    showInputDialog("Story points", <StoryPointsInfo />, "" + (grape.storypoints || 0), (res) => {
      // const res = window.prompt("Story points", "" + (grape.storypoints || 0));
      if (res === null || res === undefined) return;
      onChange("storypoints", parseInt("" + res) || 0);
    });
  };

  static openLabelEditor = (
    grape: IGrape,
    onChange: (attr: string, value: any) => void,
    labelColors: any,
    setLabelColor?: (label: string, color: string) => void
  ) => {
    const inputId: string = "input-dialog-" + new Date().getTime();
    const curLabels = { current: grape?.labels || [] };

    setTimeout(() => (document.getElementById(inputId) as any)?.focus?.(), 100);

    const onConfirmLabel = () => {
      if (curLabels.current.join("_") !== grape?.labels?.join("_")) {
        onChange("labels", curLabels.current ?? []);
      }
      MLDialog.hideModal();
    };

    MLDialog.showModal(
      "Etichetta",
      <GrapeLabelsEditor
        inputId={inputId}
        labels={(grape.labels || []).filter((l) => l !== "mirror")}
        onChange={(list) => (curLabels.current = list)}
        onEnterPressed={onConfirmLabel}
        labelColors={labelColors}
        onEditColorRequest={
          setLabelColor
            ? (label, color) =>
                showColorPickerModal('"' + label + '" colore', color, (newColor) => setLabelColor(label, newColor))
            : undefined
        }
      />,
      {
        positiveText: "Ok",
        onPositiveClick: onConfirmLabel,
        negativeText: "Annulla",
      }
    );
  };

  static getSetting = <T extends Object>(grape: IGrape, setting: string, defaultValue?: T) =>
    Grapes.getGrapeConfig("settings", grape, setting, defaultValue);

  static setSetting = (
    client: ApolloClient<any>,
    grape: IGrape,
    setting: string,
    value: string | number | boolean,
    refetchQueries?: any
  ) => Grapes.setGrapeConfig("settings", client, grape, setting, value, refetchQueries);

  static getThemeConfig = <T extends Object>(grape: IGrape, setting: string, defaultValue?: T) =>
    Grapes.getGrapeConfig("theme", grape, setting, defaultValue);

  static setThemeConfig = (
    client: ApolloClient<any>,
    grape: IGrape,
    setting: string,
    value: string | number | boolean,
    refetchQueries?: any
  ) => {
    console.log("🟨", !!client, grape.id, setting, value);
    Grapes.setGrapeConfig("theme", client, grape, setting, value, refetchQueries);
  };

  private static getGrapeConfig = <T extends Object>(
    config: string,
    grape: IGrape,
    setting: string,
    defaultValue?: T
  ) => {
    return (((grape || {}) as any)[config] || {})[setting] ?? defaultValue;
  };

  private static setGrapeConfig = (
    config: "theme" | "settings",
    client: ApolloClient<any>,
    grape: IGrape,
    setting: string,
    value: string | number | boolean,
    refetchQueries?: any
  ) => {
    const configs: any = grape[config] || {};
    configs[setting] = value;
    Grapes.editGrape(client, grape.id, { [config]: configs }, refetchQueries);
  };

  // planner

  static openModalPlanner = (
    client: ApolloClient<any>,
    grape: IGrape | IMyGrape,
    props: { myGrape?: string; editMode: "myDay" | "deadline" }
  ) => {
    const isMyGrape = (grape as any).headless_my_day;
    // const grapeDate = (props.myGrape ? grape.date1 : undefined) || grape.date || (isMyGrape && grape.created_at) || undefined;
    const grapeDate =
      (props.editMode == "deadline" ? grape.date : grape.date1) ||
      (props.editMode == "myDay" && grape.created_at) ||
      undefined;

    const grapeDateStart = grape.date0 ? new Date(grape.date0) : undefined;

    const isMobile = window.innerWidth <= 600;

    const PlanComponent = () => {
      const [date, setDate] = useState(grapeDate ? new Date(grapeDate) : undefined);
      const [dateStart, setDateStart] = useState<Date | undefined>(grapeDateStart);
      // const [reminder, setReminder] = useState();

      const saveChanges = () => {
        const dateValueString = date?.toISOString() || "";
        const dataToChange: IGrapeEdit = {};

        switch (props.editMode) {
          case "myDay":
            dataToChange.date1 = dateValueString;
            dataToChange.date2 = dateStart?.toISOString() || "";
            break;
          case "deadline":
            dataToChange.date = dateValueString;
            dataToChange.date0 = dateStart?.toISOString() || "";
            break;
        }

        // change data
        Grapes.editGrape(client, grape.id, dataToChange);

        // wants in my day
        if (props.editMode == "myDay" && !!props.myGrape !== !!dateValueString) {
          Grapes.setMyDay(client, grape, !!dateValueString);
        }
      };

      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Tooltip title="Mandami una notifica">
            <div
              css={{ position: "absolute", top: 12, right: 12, padding: 8 }}
              className="hoverable"
              children={<i className="material-icons" children="notifications" />}
              onClick={() => Grapes.setReminder(client, grape)}
            />
          </Tooltip>

          <div>
            <Card
              css={
                isMobile
                  ? {
                      padding: 0,
                      margin: "0 -24px 0 -30px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      boxShadow: "none",
                    }
                  : { padding: 4 }
              }
            >
              <div
                css={
                  date
                    ? {}
                    : {
                        "button.rdrDay .rdrSelected": { color: "transparent !important" },
                        "button.rdrDay .rdrSelected ~ .rdrDayNumber span": { color: "initial !important" },
                      }
                }
                // css={
                //   date
                //     ? {}
                //     : {
                //         "button.rdrDay .rdrSelected": { color: "transparent !important" },
                //         "button.rdrDay .rdrSelected ~ .rdrDayNumber span": { color: "initial !important" },
                //       }
                // }
              >
                <MabiDatePicker
                  date={date ? date : moment().toDate()}
                  dateStart={dateStart}
                  rangePicker={["myDay", "deadline"].includes(props.editMode)}
                  onChange={(v, dateStart) => {
                    setDate(v);
                    setDateStart(dateStart);
                  }}
                />
              </div>
            </Card>

            {date && (
              <div
                css={{
                  textAlign: "center",
                  opacity: date ? 1 : 0,
                  marginTop: 16,
                  marginBottom: 24,
                  textTransform: "capitalize",
                }}
                // children={"Pianifica per il " + (date === null ? "_______" : moment(date).format("ddd D MMM YYYY"))}
                children={
                  moment(date).format("ddd D MMM YYYY") +
                  (dateStart ? " → " + moment(dateStart).format("ddd D MMM YYYY") : "")
                }
              />
            )}

            <div css={{ display: "flex", flexDirection: "row" }}>
              {isMobile ? (
                <Button
                  // variant="contained"
                  children="Chiudi"
                  onClick={() => MLDialog.hideModal()}
                  css={{ margin: 0, marginRight: 8, marginTop: 16 }}
                />
              ) : undefined}

              {date != null && props.editMode === "deadline" ? (
                <Button
                  children="Ripristina"
                  color="secondary"
                  css={{ margin: 0, marginTop: 16 }}
                  onClick={() => {
                    setDate(undefined);
                    setDateStart(undefined);
                  }}
                />
              ) : undefined}

              {grape.date1 && props.editMode === "myDay" ? (
                <Button
                  children="Rimuovi dalla mia giornata"
                  color="secondary"
                  css={{ margin: 0, marginTop: 16 }}
                  onClick={() => {
                    Grapes.editGrape(client, grape.id, { date1: "" });
                    Grapes.setMyDay(client, grape, false);
                    MLDialog.hideModal();
                  }}
                />
              ) : undefined}

              <div css={{ width: 16, flexGrow: 1 }} />

              <Button
                // variant="outlined"
                children="Salva"
                onClick={() => {
                  saveChanges();
                  MLDialog.hideModal();
                }}
                css={{ margin: 0, marginTop: 16, color: "#00aa00" }}
              />
            </div>

            {/* <h3 children="Promemoria" />
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Ora precisa"
              value={time}
              onChange={(value) => {
                _time = value;
                setTime(time);
              }}
            /> */}
          </div>
        </MuiPickersUtilsProvider>
      );
    };

    MLDialog.showModal(props.editMode == "myDay" ? "🌞 La mia Giornata" : "Deadline", <PlanComponent />, {
      positiveText: "",
      fullscreen: isMobile,
      // onPositiveClick: () => {
      //   Grapes.setMyDay(client, grape, true);
      //   Grapes.editGrape(client, grape.id, { date1: _date.toISOString() });
      // },
    });
  };

  static setReminder = (client: ApolloClient<any>, grape: IGrape) => {
    var date: MaterialUiPickersDate = new Date(new Date().getTime());
    var time: MaterialUiPickersDate = new Date(date);

    const Promemoriaz = () => {
      const [_date, _setDate] = useState(date);
      const [_time, _setTime] = useState(time);

      const setDate = (d: any) => {
        date = d;
        _setDate(d);
      };

      const setTime = (t: any) => {
        time = t;
        _setTime(t);
      };

      return (
        <div
          style={{
            width: 300,
            maxWidth: "80vw",
            //minHeight: 270
          }}
        >
          <Box display="flex" flexDirection="row">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Data di scadenza"
                format="dd/MM/yyyy"
                value={_date}
                onChange={setDate}
              />
              <div style={{ width: 8 }} />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Ora precisa"
                value={_time}
                onChange={setTime}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <div style={{ height: 24 }} />
          {/* <span dangerouslySetInnerHTML={{ __html: grape?.title }} /> */}
        </div>
      );
    };

    MLDialog.showModal("Imposta Promemoria", <Promemoriaz />, {
      positiveText: "Imposta",
      onPositiveClick: () => {
        const finalDate: Date = new Date(date as Date);
        finalDate.setHours((time as Date).getHours(), (time as Date).getMinutes(), 0);
        client.mutate({
          mutation: SET_REMINDER,
          variables: { input: { grapeId: grape.id, scheduled_for: finalDate.toUTCString() } },
          // refetchQueries: [{ query: GET_MY_GRAPES }],
        });

        // if (email && email.trim().length) {
        //   client
        //     .mutate({
        //       mutation: INVITE_USER_TO_GRAPE,
        //       variables: { grapeId: grape.id, email },
        //       refetchQueries: [{ query: GET_USER_GRAPE_ANCHESTORS, variables: { grapeId: grape.id } }],
        //     })
        //     .catch((e) => console.log(JSON.stringify(e)));
        // }
      },
      //negativeText: 'Annulla',
      //onNegativeClick: () => {},
      // oppositeText: isEditing ? 'Rimuovi' : undefined,
      // onOppositeClick: isEditing ? onDeleteLabel : undefined,
    });
  };
}

export const ADD_GRAPE_TO_MY_DAY = gql`
  mutation addGrapeToMyDay($grapeId: ID!) {
    addToMyDay(grapeId: $grapeId)
  }
`;

export const REMOVE_GRAPE_FROM_MY_DAY = gql`
  mutation removeGrapeFromMyDay($grapeId: ID!) {
    removeFromMyDay(grapeId: $grapeId)
  }
`;

export const INVITE_USER_TO_GRAPE = gql`
  mutation inviteUser($grapeId: ID!, $email: String!) {
    inviteUser(grapeId: $grapeId, email: $email) {
      id
    }
  }
`;

export const ARCHIVE_GRAPE = gql`
  mutation archiveRootGrape($grapeId: ID!) {
    archiveRootGrape(grapeId: $grapeId) {
      id
    }
  }
`;

export const UNARCHIVE_GRAPE = gql`
  mutation unarchiveRootGrape($grapeId: ID!) {
    unarchiveRootGrape(grapeId: $grapeId) {
      id
    }
  }
`;

export const DELETE_GRAPE = gql`
  mutation deleteGrape($id: ID!) {
    deleteGrape(id: $id) {
      id
    }
  }
`;

const GrapeLabelsEditor = (props: {
  inputId?: string;
  labels?: string[];
  onChange?: (labels: string[]) => void;
  onEnterPressed?: () => void;
  onEditColorRequest?: (label: string, color: string) => void;
  labelColors?: any;
}) => {
  const [labels, setLabels] = useState(props.labels || []);

  return (
    <ChipInput
      InputProps={{
        id: props.inputId,
        //   onKeyPress: (ev) => {
        //     if (ev.key === "Enter") {
        //       ev.preventDefault();
        //       onConfirmLabel();
        //     }
        //   },
      }}
      value={labels}
      chipRenderer={(chip) => {
        return (
          <GrapeLabel
            labelColors={props.labelColors}
            key={chip.value}
            title={chip.value}
            css={{ height: 22, verticalAlign: "center" }}
            leading={
              props.onEditColorRequest
                ? (colors) => (
                    <IconButton
                      css={{ padding: 0, marginRight: 8 }}
                      onClick={() => props.onEditColorRequest!(chip.value, colors.background)}
                      children={
                        <i
                          css={{ fontSize: 20, color: colors.foreground }}
                          children="edit"
                          className="material-icons"
                        />
                      }
                    />
                  )
                : undefined
            }
            trail={(colors) => (
              <IconButton
                css={{ padding: 0, marginLeft: 8 }}
                onClick={chip.handleDelete}
                children={
                  <i css={{ fontSize: 20, color: colors.foreground }} children="close" className="material-icons" />
                }
              />
            )}
          />
        );
      }}
      onAdd={(chip) => {
        if (!labels.includes(chip.value)) {
          const newLabels = labels.slice();
          newLabels.push(chip);
          setLabels(newLabels);
          props.onChange?.(newLabels);
        }
      }}
      onDelete={(chip) => {
        const newLabels = labels.filter((l) => l !== chip);
        setLabels(newLabels);
        props.onChange?.(newLabels);
      }}
      onKeyDown={(e) => {
        const innerText = (e.nativeEvent?.target as any)?.value || "";
        if (e.nativeEvent.key === "Enter" && innerText === "") {
          props.onEnterPressed?.();
        }
      }}
    />
  );
};
