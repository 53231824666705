import { gql } from "apollo-boost";

export const GET_GRAPE_ACTIVITIES = gql`
  query activitiesForGrapeId($grapeId: ID!, $options: OptionsActivitiesForGrape) {
    activitiesForGrapeId(grapeId: $grapeId, options: $options) {
      id
      text
      created_at
    }
  }
`;
