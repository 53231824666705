/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { Box, Button, Grid, Grow, LinearProgress } from "@material-ui/core";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { IMyGrape, MyGrapeType } from ".";
import { myGrapeConfigs } from "../../App";
import { GET_MY_GRAPES } from "../../repositories/queries_mutations";
import { AppGrapeDetail } from "../App/AppGrapeDetail";
import ClientSortableList from "../ClientSortable/ClientSortableList";
import Grape from "../Grape";
import { IGrape } from "../grape_gql_interface";
import ignoreErrorMutation from "./ignoreErrorMutation";
import { StickyLabel } from "./StickyLabel";

export default (props: {
  myGrapeList: IMyGrape[];
  selectedGrape?: IGrape;
  selectedMyGrape?: MyGrapeType;
  setSelectedGrape: (grape: IGrape) => void;
  loading?: boolean;
  trailing?: React.ReactNode;
}) => {
  const { myGrapeList, selectedGrape, setSelectedGrape, selectedMyGrape, loading } = props;

  const client = useApolloClient();
  const [segmentedButtons, setSegmentedButtons] = useState<{ title: string; onClick: any }[]>();

  const myTodoList: IMyGrape[] = myGrapeList.filter((x) => x.status === 1);

  // MyDay Expiring AssignedToMe
  const clearMyTodoList = (action: "restore" | "hide", fromDate?: Moment) => {
    const capitalized = selectedMyGrape && selectedMyGrape?.charAt(0).toUpperCase() + selectedMyGrape.slice(1);

    const mutation = ignoreErrorMutation(action, capitalized);
    client
      .mutate({
        refetchQueries: [{ query: GET_MY_GRAPES }],
        awaitRefetchQueries: true,
        mutation: mutation,
        variables: { fromDate },
      })
      .then(() => console.log("done"));
  };

  return (
    <Box display="flex" flexDirection="row">
      <Box
        flexGrow="1"
        display="flex"
        flexDirection="column"
        style={{
          borderRight: "1px solid #ccc",
          width: 300,
        }}
      >
        {/* HEADER */}
        {myGrapeConfigs[selectedMyGrape!]?.withCheckboxes && (
          <Grid
            container
            direction="row"
            style={{ borderBottom: "1px solid #eee", padding: 4, marginBottom: 4 }}
            spacing={1}
            onMouseLeave={() => setTimeout(() => setSegmentedButtons(undefined), 300)}
          >
            {segmentedButtons?.length ? (
              segmentedButtons.map((b, i) => (
                <Grid
                  key={b.title}
                  item
                  xs={Math.max(2, Math.floor(12 / segmentedButtons.length)) as any}
                  css={{ display: "flex" }}
                >
                  <Grow in appear timeout={1000 - i * 250}>
                    <Button
                      style={{ flex: 1, fontSize: 10, padding: "5px 10px" }}
                      variant="outlined"
                      children={b.title}
                      onClick={() => {
                        setSegmentedButtons(undefined);
                        b.onClick();
                      }}
                    />
                  </Grow>
                </Grid>
              ))
            ) : (
              <React.Fragment>
                <Grid item xs={6}>
                  <Grow in timeout={750}>
                    <Button
                      disabled={!myTodoList.length}
                      style={{ fontSize: 10, padding: "5px 10px" }}
                      variant="outlined"
                      // children={`nascondi ${myTodoList.length || ""} task completat${
                      //   myTodoList.length === 1 ? "o" : "i"
                      // }`}
                      onClick={() => clearMyTodoList("hide")}
                      children={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `nascondi task completat${myTodoList.length === 1 ? "o" : "i"}: <b>${
                              myTodoList.length || 0
                            }</b>/${myGrapeList.length}`,
                          }}
                        />
                      }
                    />
                  </Grow>
                </Grid>
                <Grid item xs={6}>
                  <Grow in timeout={500}>
                    <Button
                      style={{ fontSize: 10, padding: "5px 10px" }}
                      variant="outlined"
                      children="ripristina task nascosti"
                      // onClick={() => clearMyTodoList('restore')}
                      onClick={() =>
                        setSegmentedButtons([
                          {
                            title: "tutti",
                            onClick: () => clearMyTodoList("restore"),
                          },
                          {
                            title: "ultima settimana",
                            onClick: () => clearMyTodoList("restore", moment().subtract(4, "days").startOf("week")),
                          },
                          {
                            title: "oggi",
                            onClick: () => clearMyTodoList("restore", moment().startOf("day")),
                          },
                        ])
                      }
                    />
                  </Grow>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        )}

        {/* LIST */}
        <Box flexGrow={1} className="no-sb" style={{ overflowY: "scroll", padding: 8, paddingTop: 3 }}>
          <StickyLabel children={String(selectedMyGrape)} />
          {!myGrapeList?.length ? (
            <div
              style={{
                padding: 10,
                textAlign: "left",
                width: "100%",
                color: "#aaa",
                boxShadow: "border-box",
                WebkitBoxSizing: "border-box",
              }}
              children={loading ? <LinearProgress /> : "Lista vuota.."}
            />
          ) : (
            <ClientSortableList
              key={selectedMyGrape}
              disabled={selectedMyGrape === "assignedToMe"}
              groupId={"my-grape-" + selectedMyGrape}
              distance={8}
              list={myGrapeList}
              getItemId={(item) => item.id}
              renderItem={(item: IMyGrape, index: number) => {
                const list: any[] = item?.ancestors || [];
                // const list: any[] = ((item && (item as any).genealogicalTree) || {}).ancestors || [];

                return (
                  <Grape
                    key={item.id}
                    myGrape={selectedMyGrape}
                    grape={item}
                    // style={{ margin: 4 }}
                    anchestors={list || []}
                    shouldFetchAnchestors
                    className="grape-list-item"
                    groupType={myGrapeConfigs[selectedMyGrape!]?.withCheckboxes ? "todo" : "list"}
                    selected={selectedGrape && selectedGrape.id === item.id}
                    renderBananaDot={selectedMyGrape !== "archived" && item.type === "kanban"}
                    renderSubGrapes={false}
                    renderCaption={() => (
                      <React.Fragment>
                        {/* {list?.[0]?.title && (index === 0 || item.rootParent !== myGrapeList[index - 1]?.rootParent) ? ( */}
                        {list?.[0]?.title ? (
                          <div
                            style={{
                              cursor: "pointer",
                              padding: 8,
                              fontWeight: "bold",
                              // borderTop: "3px solid #eee",
                            }}
                            dangerouslySetInnerHTML={{ __html: list[0]?.title }}
                            // children={list[0] && list[0].title}
                            onClick={(e: any) => {
                              if (e.stopPropagation) e.stopPropagation();
                              setSelectedGrape(list[0]);
                            }}
                          />
                        ) : null}

                        {/* same parent group 
                          {index === 0 ||
                          item?.ancestors?.[item.ancestors.length - 1]?.id !==
                            myGrapeList[index - 1]?.ancestors?.[myGrapeList[index - 1].ancestors.length - 1]?.id ? (
                            <AnchestorsBreadcrumbs
                              anchestors={list.slice(1)}
                              onClick={(item, index) => {
                                //! prevent to open single grape as detail - both chat and kanban item
                                if (list[index + 1 - 1]?.type === "chat" || list[index + 1 - 1]?.type === "kanban") {
                                  setSelectedGrape(list[index + 1 - 1]); //! + 1 - 1 for <- slice(1)
                                } else {
                                  setSelectedGrape(item);
                                }
                              }}
                            />
                          ) : null} */}
                      </React.Fragment>
                    )}
                    onRequestOpen={(g) => {
                      // if (item.id === selectedGrape) return;
                      let itemToSelect = g || item;
                      if (list[list.length - 2]?.type === "kanban") {
                        //! prevent to open single grape as detail - kanban item
                        // itemToSelect = list[list.length - 2]; // TODO - prevent opening kanban
                      }
                      setSelectedGrape(itemToSelect);
                    }}
                  />
                );
              }}
              // renderTrailElement={
              //   selectedMyGrape == "myDay" && (
              //     <div
              //       ref={inputEl}
              //       contentEditable={true}
              //       suppressContentEditableWarning={true}
              //       data-placeholder="+ add"
              //       className="add-btn-hoverable"
              //       onKeyDown={(e: React.KeyboardEvent) => {
              //         if (e.which === 13 && !e.shiftKey) {
              //           // PRESS ONLY ENTER

              //           e.preventDefault();
              //           const el: any = inputEl.current;
              //           if (!el || !el.innerHTML || !el.innerHTML.length) return;
              //           addMyGrape(el.innerHTML);
              //           el.innerHTML = "";
              //           return true;
              //         }
              //       }}
              //     />
              //   )
              // }
            />
          )}
          {props.trailing}
        </Box>
      </Box>

      <AppGrapeDetail />
    </Box>
  );
};
