import { useTheme } from "@material-ui/core";
import AlignRightIcon from "@material-ui/icons/FormatAlignRight";
import { useSafeGrapeDetailContextSelector } from "../GrapeDetail/GrapeDetail/hooks/GrapeDetailContext";
import { IGrape } from "../grape_gql_interface";

export const GrapeGanttButtonItem = (props: { outlined?: boolean }) => {
  const theme = useTheme();
  const subPath = useSafeGrapeDetailContextSelector("subPath");
  const getGrapeSemiPath = useSafeGrapeDetailContextSelector("getGrapeSemiPath");
  const setSubPath = useSafeGrapeDetailContextSelector("setSubPath");
  const onGrapeSubpathChange = useSafeGrapeDetailContextSelector("onGrapeSubpathChange");

  const showGantt = subPath === "gantt";
  const iconSize = 20;

  return (
    <div
      className={"hoverable" + (props.outlined ? " outlined" : "")}
      css={{
        marginLeft: 8,
        display: "flex",
        alignItems: "center",
        padding: "0 6px",
      }}
      onClick={() => {
        // props.onSubPathChange?.(backlog ? "backlog" : "");
        const subpath = showGantt ? "" : "gantt";
        setSubPath(subpath);
        onGrapeSubpathChange?.(getGrapeSemiPath(subpath));
      }}
    >
      <div
        style={{
          opacity: 0.8,
          // color,
          // marginLeft: props.iconOnly ? 6 : -3,
          // marginRight: 6,
          marginLeft: 3,
          marginRight: 3,
          transform: "translateY(1px)",
        }}
      >
        {/* <AlignRightIcon css={{ fontSize: iconSize }} /> */}
        <GanttIcon size={iconSize} color={showGantt ? theme.palette.primary.main : undefined} />
      </div>
      {/* <span children="NOTE" style={{ letterSpacing: 1, fontSize: 12 }} /> */}
    </div>
  );
};

export const GanttIcon = ({ size, color }: { size: number; color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="mdi-chart-gantt"
    height={size + "px"}
    width={size + "px"}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M2,5H10V2H12V22H10V18H6V15H10V13H4V10H10V8H2V5M14,5H17V8H14V5M14,10H19V13H14V10M14,15H22V18H14V15Z"
    />
  </svg>
);
