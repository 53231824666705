/** @jsxImportSource @emotion/react */
import { useEffect, useMemo } from "react";
import { Notifier, useNotifier } from "react-use-notifier";
import { useGrapesApp } from "../../../App";
import useSocket from "../../../hooks/useSocket";
import { IGrape } from "../../grape_gql_interface";
import { IUser } from "../../User";

export type MouseMoveSocketState = "join" | "move" | "focus" | "afk" | "leave";

export interface IGrapesMouseMoveSocketEvent {
  grapeId: string;
  focusGrapeId?: string;
  user: IUser;
  x: number;
  y: number;
  subPath: string;
  state: MouseMoveSocketState;
  _milliseconds: number;
  milliseconds: number;
}

export interface GrapeSocketController {
  activeUserIds: Notifier<string[]>;
  selectedGrapeIds: Notifier<{ [grapeId: string]: string[] }>;
  pointers: Notifier<IGrapesMouseMoveSocketEvent[]>;
  onMouseMove: (event: { x: number; y: number }) => void;
  onLeave: () => void;
  onFocus: (grape: IGrape | undefined) => void;
  showPointers: Notifier<boolean>;
}

export const usersSocketController = (grapeId: string, subPath: string): GrapeSocketController => {
  const pointers = useNotifier<IGrapesMouseMoveSocketEvent[]>([]);
  const activeUserIds = useNotifier<string[]>([]);
  const selectedGrapeIds = useNotifier<{ [grapeId: string]: string[] }>({});
  const showPointers = useNotifier<boolean>(true);

  const grapesApp = useGrapesApp();
  const leanUser = useMemo(() => {
    //@ts-ignore
    const { user_preferences, __typename, ...leanUser } = grapesApp.user;

    return leanUser;
  }, [grapesApp.user]);

  const socket = useSocket("mouseMove", (data: IGrapesMouseMoveSocketEvent) => {
    // console.log("--> ", data);
    const milliseconds = new Date().getTime();
    if (data.grapeId == grapeId) {
      onEvent({ ...data, milliseconds });
    }
  });

  useEffect(() => {
    const evt = { grapeId: grapeId, user: leanUser };

    setTimeout(() => socket.emit("mouseMove", { ...evt, state: "join", x: 200, y: 200 }));

    return () => {
      socket.emit("mouseMove", { ...evt, state: "leave" });
    };
  }, []);

  const onUserBlur = (userId: string) => {
    for (let i = 0; i < selectedGrapeIds.value.keys?.length; i++) {
      if (selectedGrapeIds.value[selectedGrapeIds.value.keys[i]]?.includes(userId)) {
        selectedGrapeIds.value = {
          ...selectedGrapeIds.value,
          [selectedGrapeIds.value.keys[i]]: selectedGrapeIds.value[userId].filter((id) => id !== userId),
        };
      }
    }
  };

  const onEvent = (event: IGrapesMouseMoveSocketEvent) => {
    if (event.state === "focus") {
      onUserBlur(event.user.id);
      if (!(selectedGrapeIds.value[event.focusGrapeId!] || []).includes(event.user.id)) {
        selectedGrapeIds.value = {
          [event.focusGrapeId!]: [...(selectedGrapeIds.value[event.grapeId] || []), event.user.id],
        };
      }
    } else if (event.state === "leave") {
      onUserBlur(event.user.id);
    }

    //----

    let list: IGrapesMouseMoveSocketEvent[] = [];
    let found = false;
    for (let i = 0; i < pointers.value.length; i++) {
      if (pointers.value[i].user.id === event.user.id) {
        found = true;
        list.push({ ...pointers.value[i], ...event });
      } else {
        list.push(pointers.value[i]);
      }
    }

    if (!found) {
      list.push(event);
    }
    pointers.value = list.filter((u) => u.state !== "leave");

    updateActiveUsersIfChanged();
  };

  const updateActiveUsersIfChanged = () => {
    const curActiveUserIds = pointers.value.filter((u) => u.state !== "leave").map((u) => u.user.id);
    if (curActiveUserIds.length !== activeUserIds.value.length) {
      activeUserIds.value = curActiveUserIds;
      return;
    }
    for (let i = 0; i < curActiveUserIds.length; i++) {
      if (!activeUserIds.value.includes(curActiveUserIds[i])) {
        activeUserIds.value = curActiveUserIds;
        return;
      }
    }
  };

  const onMouseMove = (position: { x: number; y: number }) => {
    dispatchEvent({
      state: "move",
      x: position.x,
      y: position.y,
    });
  };

  const onLeave = () => {
    dispatchEvent({
      state: "leave",
    });
  };

  const onFocus = (grape: IGrape | undefined) => {
    dispatchEvent({
      state: "focus",
      focusGrapeId: grape?.id || null,
    });
  };

  const dispatchEvent = (params: {
    state: MouseMoveSocketState;
    x?: number;
    y?: number;
    focusGrapeId?: string | null;
  }) => {
    const sendEvent = {
      grapeId: grapeId,
      user: leanUser,
      _milliseconds: new Date().getTime(),
      subPath: subPath,
      ...params,
    };

    socket.emit("mouseMove", sendEvent);

    //! debug
    // if (__DEV__) onEvent(sendEvent as any);
  };

  return { activeUserIds, selectedGrapeIds, pointers, onMouseMove, onLeave, onFocus, showPointers };
};
