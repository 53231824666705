/** @jsxImportSource @emotion/react */
import { Box } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { IGrape } from "../grape_gql_interface";
import { defaultGrapeListType, grapeListTypes } from "./core/grapeTypes";
import {
  useGrapeDetailContextSelector,
  useSafeGrapeDetailContextSelector,
} from "./GrapeDetail/hooks/GrapeDetailContext";
import { GrapeUserPointers } from "./GrapeDetailPointers/GrapeUserPointer";

const DetailScaffold = (props: { grape: IGrape; header: React.ReactNode; children: any }) => {
  const socketController = useSafeGrapeDetailContextSelector("socketController");

  const updateEveryMilliseconds = 5 * 1000;

  const itemRef = useRef<any>();
  const fix = useRef({ x: 500, y: 500, tx: 0, ty: 0 });

  // const rect = useRect(itemRef, { updateEveryMilliseconds });

  // const scrollHeight = useRef(0);
  // const [_, __] = useState(0);
  // const forceUpdate = () => __((x) => x + 1);
  // const computeScrollHeight = () => {
  //   const newScrollHeight: number = itemRef.current?.scrollHeight || 0;
  //   if (newScrollHeight !== scrollHeight.current) {
  //     scrollHeight.current = newScrollHeight;
  //     forceUpdate();
  //   }
  // };

  const computeRect = () => {
    const scrollLeft = horizontalScroller?.current?.scrollLeft || 0;

    const rect = itemRef.current?.getBoundingClientRect();

    if (!rect) {
      return;
    }

    const tx = rect.left + scrollLeft;
    const ty = rect.top;

    if (tx !== fix.current.tx || rect.ty !== fix.current.ty) {
      fix.current.tx = tx;
      fix.current.ty = ty;
      onMouseMove(fix.current.x, fix.current.y);
    }
  };

  const horizontalScroller = useRef<any>();
  useEffect(() => {
    horizontalScroller.current = document.querySelector("#horizontal-scroller");

    // monitor changes
    setTimeout(computeRect, 50);
    let interval = setInterval(computeRect, updateEveryMilliseconds);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const onMouseMove = (x: number, y: number) => {
    const scrollLeft = horizontalScroller?.current?.scrollLeft || 0;

    fix.current.x = x;
    fix.current.y = y;

    socketController.onMouseMove({
      // x: e.clientX - (rect?.left || 0) + scrollLeft,
      // y: e.clientY - (rect?.top || 0) + itemRef.current?.scrollTop,
      x: fix.current.x - fix.current.tx + scrollLeft,
      y: fix.current.y - fix.current.ty + itemRef.current?.scrollTop,
    });
  };

  /*
  <Box display="flex" flexDirection="row">

    <Box
      flexGrow="1"
      display="flex"
      flexDirection="column"
      style={{
        borderRight: "1px solid #ccc",
        width: weekViewEnabled ? undefined : 300,
      }}
    >
    */

  const headerScrollsWithContent = grapeListTypes[props.grape.type || defaultGrapeListType]?.headerScrollsWithContent;

  return (
    <React.Fragment>
      {headerScrollsWithContent ? null : props.header}
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
        }}
        className="droppable-container"
        onMouseMove={(e) => onMouseMove(e.clientX, e.clientY)}
        onMouseLeave={(e) => socketController.onLeave()}
      >
        <div ref={itemRef} css={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          {headerScrollsWithContent ? props.header : null}
          {props.children}
          <GrapeUserPointers />
        </div>
      </div>
    </React.Fragment>
  );

  // return (
  //   <React.Fragment>
  //     {props.renderHeader()}
  //     <Box flexGrow={1} className="droppable-container" style={{ position: "relative" }}>
  //       {props.children}
  //     </Box>
  //   </React.Fragment>
  // );
};

export default DetailScaffold;
