import { useQuery } from "@apollo/react-hooks";
import { LinearProgress } from "@material-ui/core";
import ApolloClient from "apollo-client";
import React, { useState } from "react";
import { retrieveAnchestorsFromCache } from "./gqlGrapesUtils";
import Grapes, { IGrapeAppend, IGrapeEdit } from "./Grapes";
import { RootGrapesList } from "../pages/Main/ListGrapes";
import { GET_ALL_GRAPES } from "../repositories/queries_mutations";
import { escapeHTML, showConfirmDialog } from "../utils";
import { getBoardGrapeStructure } from "../components/GrapeDetail/GrapeBoard";
import { hideGrapesFromChildren } from "../components/GrapeDetail/core/grapeTypes";
import { GET_GRAPE, IGrape } from "../components/grape_gql_interface";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MLDialog from "../components/MLDialog";
import Grape from "../components/Grape";
import { ItemSelector } from "../components/input/ItemSelector";
import { gql } from "apollo-boost";

export function showMoveGrapeDialog(client: ApolloClient<any>, grape: IGrape) {
  const gTitle = escapeHTML(grape.title);
  let postScrollTimeout: any;
  let selected: IGrape | undefined;
  let anchestors: IGrape[] = retrieveAnchestorsFromCache(client, grape);
  MLDialog.showModal(
    "Sposta [" + gTitle + "]",
    <div
      id="move-grape-scaffold"
      // className="no-sb"
      css={{ overflowX: "scroll" }}
    >
      <GrapeListSelector
        isRootList
        history={anchestors}
        onChange={(g) => {
          selected = g;

          if (postScrollTimeout != null) clearTimeout(postScrollTimeout);
          postScrollTimeout = setTimeout(() => {
            const modal: any = document.querySelector("#move-grape-scaffold");
            modal?.scrollTo({ left: 999999999999999, behavior: "smooth" });
          }, 100);
        }}
      />
    </div>,
    {
      positiveText: "SPOSTA",
      onPositiveClick: () => {
        if (!selected) {
          MLDialog.showSnackbar("Seleziona il grappolo in cui vuoi spostare [" + gTitle + "]");
          return;
        }

        if (grape.id === selected.id) {
          MLDialog.showSnackbar("Non puoi spostare [" + gTitle + "] all'interno di se stesso");
          return;
        }

        function finalizeMoveConfirmation(additionalEdits?: { cross_order?: number; status?: number }) {
          const sTitle = escapeHTML(selected!.title);
          showConfirmDialog("", "Vuoi spostare [" + gTitle + "] all'interno di [" + sTitle + "]?", () => {
            // Grapes.editGrape(client, grape.id, {
            //   ...(additionalEdits || {}),
            //   parent: selected!.id,
            // });
            Grapes.appendGrape(client, {
              grapeId: grape.id,
              main_order: grape.main_order,
              ...(additionalEdits || {}),
              parentId: selected!.id,
            });
          });
        }

        if (selected.type === "board") {
          const selection = { columnId: 0 };
          MLDialog.showModal(
            "Seleziona colonna della board",
            <MoveGrapeChooseBoardColumn grape={selected!} onChange={(colId) => (selection.columnId = colId)} />,
            {
              onPositiveClick: () => {
                finalizeMoveConfirmation({ cross_order: selection.columnId });
              },
            }
          );
        } else {
          finalizeMoveConfirmation();
        }
      },
    }
  );
}

export function showPickerGrapeDialog(
  title: string,
  onPositiveClick: (grape?: IGrape) => void,
  params?: { client: ApolloClient<any>; fromGrape?: IGrape }
) {
  let anchestors: IGrape[] = [];
  if (params?.fromGrape) {
    anchestors = retrieveAnchestorsFromCache(params.client, params.fromGrape);
  }

  let postScrollTimeout: any;
  let selected: IGrape | undefined;
  MLDialog.showModal(
    title,
    <div
      id="move-grape-scaffold"
      // className="no-sb"
      css={{ overflowX: "scroll" }}
    >
      <GrapeListSelector
        isRootList
        history={anchestors}
        onChange={(g) => {
          selected = g;

          if (postScrollTimeout != null) clearTimeout(postScrollTimeout);
          postScrollTimeout = setTimeout(() => {
            const modal: any = document.querySelector("#move-grape-scaffold");
            modal?.scrollTo({ left: 999999999999999, behavior: "smooth" });
          }, 100);
        }}
      />
    </div>,
    {
      positiveText: "CONFERMA",
      onPositiveClick: () => {
        onPositiveClick(selected);
      },
    }
  );
}

export const GrapeListSelector = (props: {
  isRootList: boolean;
  fetchGrape?: IGrape;
  onChange: (g: IGrape | undefined) => void;
  history?: IGrape[];
}) => {
  //TODO wip eddy (errore, qualcosa non popolato)
  const { loading, data, error } = useQuery(
    props.fetchGrape ? (props.fetchGrape?.type === "sprint" ? GET_GRAPE_RELETED : GET_GRAPE) : GET_ALL_GRAPES,
    {
      variables: props.fetchGrape ? { id: props.fetchGrape.id } : undefined,
    }
  );

  const allChildren: IGrape[] =
    (props.fetchGrape
      ? props.fetchGrape?.type === "sprint"
        ? data?.allGrapes[0]?.related_to
        : data?.allGrapes?.[0]?.children
      : data?.allGrapes) || [];

  const subList = allChildren.filter((g) => !hideGrapesFromChildren.includes(g.type));
  const allSprints = allChildren.filter((g) => g.type === "sprint");

  const [selected, setSelected] = useState<IGrape | undefined>(() => {
    if (props.history && props.history.length > 0) {
      const g2 = props.history[0];
      return subList.find((g) => g.id === g2.id);
    }
    return undefined;
  });

  const onSelected = (g: IGrape | undefined) => {
    setSelected(g);
    props.onChange(g);
  };

  const desiredWidth = props.isRootList ? 240 : 200;

  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      <div
        className="no-sb"
        css={{
          minWidth: desiredWidth,
          width: desiredWidth,
          maxHeight: Math.min(500, window.innerHeight * 0.7),
          overflowY: "scroll",
        }}
      >
        {/* HEADER */}
        {props.fetchGrape?.id ? (
          <div style={{ fontWeight: "bold" }} children={escapeHTML(props.fetchGrape.title)} />
        ) : null}
        {allSprints?.length ? (
          <ItemSelector
            css={{ margin: "12px 0" }}
            placeholder="Sprint"
            getId={(g) => g.id}
            onChanged={(id, g) => onSelected(g)}
            list={allSprints}
            selectedId={selected?.type === "sprint" ? selected?.id : undefined}
            renderItem={(g) => <div children={escapeHTML(g.title)} />}
          />
        ) : null}

        {/* BODY */}
        {!data || loading ? (
          <LinearProgress />
        ) : subList.length == 0 ? (
          <div css={{ padding: 12 }}>
            <i children="Nessun elemento" />
          </div>
        ) : props.isRootList ? (
          <RootGrapesList
            readOnly
            onDeleted={() => {}}
            onRequestOpen={(g) => onSelected(g)}
            rootGrapes={subList}
            selectedGrape={selected}
          />
        ) : (
          <GrapesList grape={props.fetchGrape!} children={subList} selected={selected} onSelected={onSelected} />
        )}
      </div>

      {selected ? (
        <GrapeListSelector
          key={selected.id}
          isRootList={false}
          fetchGrape={selected}
          history={props.history?.slice(1)}
          onChange={(g) => props.onChange(g)}
        />
      ) : null}
    </div>
  );
};

const MoveGrapeChooseBoardColumn = (props: { grape: IGrape; onChange: (colId: number) => void }) => {
  const [selectedId, setSelectedId] = useState<number>(0);
  const structure = getBoardGrapeStructure(props.grape);
  return (
    <div>
      {structure.columns.map((col) => (
        <div
          key={col.id}
          css={{
            padding: 10,
            borderTop: "1px solid #eee",
            borderLeft: "5px solid " + (selectedId === col.id ? "orange" : "transparent"),
          }}
          className="hoverable"
          onClick={() => {
            props.onChange(col.id);
            setSelectedId(col.id);
          }}
        >
          {col.name}
        </div>
      ))}
    </div>
  );
};

const GrapesList = (props: {
  grape: IGrape;
  children: IGrape[];
  selected?: IGrape;
  onSelected: (g: IGrape) => void;
}) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const [groupedChildren] = useState(() => {
    if (props.grape?.type !== "board") {
      return undefined;
    }

    const structure = getBoardGrapeStructure(props.grape);
    const defaultColumn = structure.columns.find((c) => !c.hidden) ?? structure.columns[0];
    const childrenGroupedByColumn: { [columnId: string]: IGrape[] } = {};
    for (let i in props.children) {
      const child = props.children[i];
      if (!structure.columns.find((c) => c.id === child.cross_order)) {
        childrenGroupedByColumn[defaultColumn.id] ??= [];
        childrenGroupedByColumn[defaultColumn.id].push(child);
      } else {
        childrenGroupedByColumn[child.cross_order] ??= [];
        childrenGroupedByColumn[child.cross_order].push(child);
      }
    }
    return childrenGroupedByColumn;
  });

  const renderGrapeItem = (g: IGrape) => (
    <div
      key={g.id}
      className="hoverable"
      children={escapeHTML(g.title)}
      onClick={() => props.onSelected(g)}
      css={{
        fontSize: 14,
        fontWeight: 500,
        padding: 10,
        maxHeight: 80,
        borderBottom: "1px solid #ccc",
        overflow: "hidden",
        background: props.selected?.id == g.id ? "#efefef" : "#ffffff",
      }}
    />
  );

  return (
    <React.Fragment>
      {groupedChildren?.[0]
        ? getBoardGrapeStructure(props.grape).columns.map((col) => {
            const isExpanded = expanded.includes(String(col.id));
            return (
              <React.Fragment key={col.id}>
                <div
                  className="hoverable"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    position: "sticky",
                    top: 0,
                    background: "#efefef",
                  }}
                  onClick={() =>
                    setExpanded((list) => {
                      const newList = list.filter((id) => id !== String(col.id));
                      if (!isExpanded) {
                        newList.push(String(col.id));
                      }
                      return newList;
                    })
                  }
                >
                  <ExpandMoreIcon style={{ transform: `rotate(${isExpanded ? 180 : 0}deg)` }} />{" "}
                  <span children={col.name} />
                </div>
                {isExpanded ? groupedChildren[col.id]?.map((g) => renderGrapeItem(g)) : null}
              </React.Fragment>
            );
          })
        : props.children.map(renderGrapeItem)}
    </React.Fragment>
  );
};

const GET_GRAPE_RELETED = gql`
  query getGrape($id: ID) {
    allGrapes(id: $id) {
      id
      title
      description
      type
      links_to {
        type
        grape {
          id
          title
        }
      }
      related_to {
        id
        title
      }
      children {
        id
        title
        type
        description
        links_to {
          type
          grape {
            id
            title
          }
        }
      }
    }
  }
`;
