/** @jsxImportSource @emotion/react */
// from material ui icons
import { Tooltip, useTheme } from "@material-ui/core";
import PointersIconOn from "@material-ui/icons/TvTwoTone";
import PointersIconOff from "@material-ui/icons/TvOff";

export const GrapeUserPointersToggle = (props: { enabled: boolean; onChanged: (value: boolean) => void }) => {
  const PointersIcon = props.enabled ? PointersIconOn : PointersIconOff;
  const theme = useTheme();
  return (
    <Tooltip title={props.enabled ? "Puntatori visibili" : "Puntatori nascosti"}>
      <div
        css={{ marginLeft: 4, marginTop: 2, padding: "0 6px" }}
        className="hoverable outlined"
        onClick={() => props.onChanged(!props.enabled)}
      >
        <PointersIcon style={{ fontSize: 14, color: theme.palette.primary.main }} />
      </div>
    </Tooltip>
  );
};
