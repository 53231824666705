import { useApolloClient } from "@apollo/react-hooks";
import { Box, LinearProgress } from "@material-ui/core";
import { GrapeDetailProvider } from ".";
import Grapes from "../../../modules/Grapes";
import { GrapeDebug } from "../../Debug/GrapeDebug";
import { IGrape } from "../../grape_gql_interface";
import MLDialog from "../../MLDialog";
import { GrapeTabsLayout } from "../components/GrapeTabsLayout";
import { showFilesUploadDialog } from "../core/file_upload_utils";
import { defaultGrapeListType, grapeListTypes, hideGrapesFromChildren } from "../core/grapeTypes";
import { GrapeDetailBody } from "./GrapeDetail";
import { useSafeGrapeDetailContextSelector } from "./hooks/GrapeDetailContext";

export const GrapeDetailRoot = (props: {}) => {
  const client = useApolloClient();

  const grape = useSafeGrapeDetailContextSelector("grape");
  const subPath = useSafeGrapeDetailContextSelector("subPath");
  const isGrapeReady = useSafeGrapeDetailContextSelector("isGrapeReady");
  const collaborators = useSafeGrapeDetailContextSelector("collaborators");
  const allAnchestors = useSafeGrapeDetailContextSelector("allAnchestors");
  const selectedGrape = useSafeGrapeDetailContextSelector("selectedGrape");
  const selectedGrapeParams = useSafeGrapeDetailContextSelector("selectedGrapeParams");
  const urlGrapes = useSafeGrapeDetailContextSelector("urlGrapes");
  // actions
  const setSelectedGrape = useSafeGrapeDetailContextSelector("setSelectedGrape");
  const setSelectedTabGrapeId = useSafeGrapeDetailContextSelector("setSelectedTabGrapeId");
  const onRequestClose = useSafeGrapeDetailContextSelector("onRequestClose");
  const onGrapeSubpathChange = useSafeGrapeDetailContextSelector("onGrapeSubpathChange");
  const getGrapeSemiPath = useSafeGrapeDetailContextSelector("getGrapeSemiPath");

  const shouldRenderTabLayout = false; // grape.type === "tabs" && !props.params?.isMainTabsGrape;
  // const shouldRenderTabLayout = grape.type === "tabs";

  const onPasteGrapeSurface = (event: React.ClipboardEvent) => {
    if (grapeListTypes[grape.type].preventPasteEvent) {
      return;
    }

    let items = (event.clipboardData || (event as any).originalEvent.clipboardData).items;
    const filesToUpload: File[] = [];
    for (let index in items) {
      let item = items[index];
      if (item.kind === "file") {
        const file: File | null = item.getAsFile();
        if (file != null) {
          filesToUpload.push(file);
        } else {
          MLDialog.showSnackbar("Invalid file");
          console.log("❌", file, item);
        }
      }
    }
    if (filesToUpload.length) showFilesUploadDialog(client, grape, filesToUpload);
  };

  return (
    // <StickyContainer style={{ flex: 1, display: "flex" }}>
    <Box
      flex={1}
      display="flex"
      flexDirection={shouldRenderTabLayout ? "column-reverse" : "row"}
      alignItems="flex-stretch"
      style={{ position: "relative" }}
    >
      {!isGrapeReady ? <LinearProgress style={{ position: "absolute", top: 0, left: 0, right: 0 }} /> : null}

      {/* 
        <div css={{ position: "absolute", bottom: 0, left: 0, right: 0, zIndex: 10 }}>
          <div css={{ padding: 8, borderTop: "1px solid #ccc", background: "white" }} children="Apri chat" />
        </div> */}

      {shouldRenderTabLayout ? (
        <GrapeTabsLayout
          grape={grape}
          // advancedMenu={<GrapeAdvancedMenu />}
          tabs={(grape?.children || []).filter((g: IGrape) => !hideGrapesFromChildren.includes(g.type))}
          selectedGrape={selectedGrape}
          setSelectedTabGrapeId={setSelectedTabGrapeId}
          onAddPressed={() => {
            const title = window.prompt("Inserisci il nome della nuova Tab");
            if (!title) return;
            Grapes.addGrape(client, grape.id, title).then((res) => {
              const newGrapeId = res?.data?.createGrape?.id;
              setSelectedTabGrapeId(newGrapeId);
            });
          }}
        />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          style={{
            position: "relative",
            minWidth: subPath === "gantt" ? 900 : 200,
            borderRight: isGrapeReady ? "1px solid #ccc" : undefined,
            width: grapeListTypes[grape.type || defaultGrapeListType]?.styles?.width || 340,
            maxWidth: grapeListTypes[grape.type || defaultGrapeListType]?.styles?.maxWidth || undefined,
          }}
          onPaste={onPasteGrapeSurface}
        >
          <GrapeDetailBody />

          {/* is not ready yet */}
          {!isGrapeReady && (
            <div
              onClick={(e) => e.stopPropagation()}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 1,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                zIndex: 100,
              }}
              children={<LinearProgress />}
            />
          )}

          <GrapeDebug grape={grape} />
        </Box>
      )}

      <Box display="flex" flexGrow={1} alignItems="stretch">
        {selectedGrape ? ( // || grape?.type === "tabs"
          <GrapeDetailProvider
            key={selectedGrape.id}
            anchestors={[...allAnchestors, grape]}
            grape={selectedGrape}
            params={selectedGrapeParams}
            collaborators={collaborators}
            onRequestClose={onRequestClose}
            initialUrlGrapes={urlGrapes || []}
            onDeleted={() => setSelectedGrape(undefined)}
            onGrapeSubpathChange={(path) => onGrapeSubpathChange?.(getGrapeSemiPath() + path)}
          />
        ) : null}
      </Box>
    </Box>
    // </StickyContainer>
  );
};
