/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import React, { useState } from "react";
import { readGrapeFragment, writeFragment } from "../modules/dndHandler";
import Grapes, { IGrapeEdit } from "../modules/Grapes";
import { showPickerGrapeDialog } from "../modules/moveGrape";
import { __DEV__ } from "../utils/init";
import Contextable from "./Contextable";
import { onPasteEventParseFormattedText } from "./DescriptionInput";
import Grape from "./Grape";
import { getCloseGrapePriority } from "./GrapePriority";
import { IGrape } from "./grape_gql_interface";
import { LabelSelector } from "./LabelSelector";
import MLDialog from "./MLDialog";

export const TMP_GRAPE_PREFIX = "tmp-grape-";
export const GRAPE_FILE_INLINE = "grape-file-inline";

const AddTextField = (props: {
  parentGrape?: IGrape;
  labels?: string[];
  addToGrape: (title: string, variables: IGrapeEdit) => Promise<any>;
  renderEmptyItem?: any;
  withLabelSelector?: boolean;
  className?: string;
}) => {
  const [label, setLabel] = useState("");
  const [fileToUpload, setFileToUpload] = useState<File[]>([]);
  const [grapesQueue, setGrapesQueue] = useState<IGrape[]>([]);
  const [focus, setFocus] = useState(false);
  const [fileQueue, setFileQueue] = useState<File[]>([]);
  const client = useApolloClient();

  const onGrapeMirror = () => {
    showPickerGrapeDialog(
      "Di quale grappolo vuoi fare il mirroring?",
      (g) => {
        if (!g) return;
        Grapes.mirrorGrape({ client, parent: props.parentGrape!, grape: g });
      },
      {
        client: client,
        fromGrape: props.parentGrape,
      }
    );
  };

  return (
    <React.Fragment>
      {grapesQueue.length == 0 ? props.renderEmptyItem : null}

      {grapesQueue.map((g, i) => (
        <Grape
          key={g.id}
          grape={g}
          groupType={props.parentGrape?.type || "list"}
          anchestors={[]}
          style={{
            opacity: 0.5,
            borderBottom: "none",
            // boxShadow: "0 1px 4px rgba(0, 0, 0, 0.12)",
            boxShadow: "rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px",
            borderRadius: 3,
            margin: "0 2px",
            // margin: 4,
            marginTop: 8,
            // marginBottom: 0,
            pointerEvents: "none",
            width: "calc(100% - 4px)",
            ...((g as any).error
              ? {
                  opacity: 1,
                  backgroundColor: "rgba(255, 0, 0, 0.1)",
                  border: "2px solid red",
                }
              : {}),
          }}
        />
      ))}

      <Contextable
        menuItems={[
          {
            key: "mirror",
            caption: "Crea un mirror",
            onClick: () => onGrapeMirror(),
          },
        ]}
      >
        <div
          className={props.className}
          css={{
            position: "relative",
            display: "block",
            ":hover .grape-label-selector": {
              opacity: "1 !important",
            },
            ":active .grape-label-selector": {
              opacity: "1 !important",
            },
          }}
        >
          {fileToUpload?.map((file, index) => (
            <div
              key={file.name + "_" + index}
              css={{
                float: "left",
                width: 24,
                height: 24,
                border: "1px solid #ccc",
                margin: 5,
                borderRadius: 3,
                backgroundImage: `url(${URL.createObjectURL(file)})`,
                backgroundSize: "cover",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                " i": { opacity: 0, color: "red" },
                ":hover": { " i": { opacity: 1 } },
              }}
              onClick={() => setFileToUpload((files) => files?.filter((f, i) => i !== index))}
              children={<i className="material-icons" children="close" />}
            />
          ))}

          {props.withLabelSelector && (props.labels || []).length ? (
            <LabelSelector
              className="grape-label-selector"
              css={{
                float: "right",
                marginRight: 8,
                marginTop: 4,
                opacity: focus || label ? 1 : 0,
                transititon: "opacity 500ms",
              }}
              inputStyle={{ maxWidth: 30, display: "inline-block" }}
              selected={label}
              onChanged={setLabel}
              labels={props.labels || []}
            />
          ) : null}

          <div
            onDrop={(e) => {
              e.preventDefault();
              console.log("drop -->", e);
            }}
            onFocus={() => setFocus(() => true)}
            onBlur={() => setFocus(() => false)}
            contentEditable={true}
            suppressContentEditableWarning={true}
            className="add-btn-hoverable"
            data-placeholder="+ Aggiungi"
            style={{ height: "auto", whiteSpace: "pre-line" }}
            onPaste={(event) => {
              onPasteEventParseFormattedText(event);

              //? upload files
              let items = (event.clipboardData || (event as any).originalEvent.clipboardData).items;
              const filesToUpload: File[] = [];
              for (let index in items) {
                let item = items[index];
                if (item.kind === "file") {
                  const file: File | null = item.getAsFile();
                  if (file != null) {
                    filesToUpload.push(file);
                  } else {
                    MLDialog.showSnackbar("Invalid file");
                    console.log("❌", file, item);
                  }
                }
              }

              if (filesToUpload.length) {
                setFileToUpload(filesToUpload);
                event.preventDefault();
                event.stopPropagation();
              }
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              // console.log(e.which, e.key);

              if (e.which === 13 && !e.shiftKey) {
                // PRESS ONLY ENTER
                e.preventDefault();
                let title: string = (e.currentTarget.innerHTML || "").trim();
                if (!title || !title.length) return;
                e.currentTarget.innerHTML = "";
                const preventLabelReset = true; // e.ctrlKey;

                const variables: IGrapeEdit = {};
                if (label?.length) variables["labels"] = [label.toLowerCase()];

                // get labels between []
                title.match(/\[[\s\S]*?\]/g)?.forEach((match) => {
                  let label = match.replace("[", "").replace("]", "").trim().toLowerCase();
                  label = label.replace(/&amp;/g, "&");
                  if (__DEV__) console.log("label:", label);
                  if (!variables["labels"]) variables["labels"] = [];
                  if (!variables["labels"].includes(label)) variables["labels"].push(label);
                });
                title = title.replace(/\[[\s\S]*?\]/g, "");

                // get storypoints from {}
                title.match(/\{.*?\}/g)?.forEach((match) => {
                  const storyPoints = match.replace("{", "").replace("}", "").trim().toLowerCase();
                  try {
                    const nStoryPoints = parseInt(storyPoints);
                    if (__DEV__) console.log("sp:", nStoryPoints);
                    variables["storypoints"] = nStoryPoints;
                    title = title.replace(/\{.*?\}/g, "");
                  } catch (e) {}
                });

                // get priority from ()
                const betweenRoundBrackets = title.match(/\(([^)]+)\)/) || [];
                for (let i = 0; i < betweenRoundBrackets.length; i++) {
                  const match = betweenRoundBrackets[i];
                  const priority = match.replace("(", "").replace(")", "").trim().toLowerCase();
                  if (!priority.includes(" ")) {
                    try {
                      const intValue = parseInt(priority);
                      if (!isNaN(intValue)) {
                        const nPriority = getCloseGrapePriority(intValue);
                        if (__DEV__) console.log("prioroty:", nPriority);
                        variables["priority"] = nPriority;
                        title = title.replace(match, "");
                        break;
                      }
                    } catch (e) {}
                  }
                }

                if (props.parentGrape && title === "mirror-grape") {
                  onGrapeMirror();
                  return;
                }

                setTimeout(() => {
                  //create tmp grape
                  const g = {
                    id: temproraryGrapId(),
                    parent: props.parentGrape?.id,
                    title,
                    labels: [],
                    ...variables,
                  } as IGrape;

                  setGrapesQueue((q) => [...q, g]);
                  if (!preventLabelReset) setLabel("");

                  props
                    .addToGrape(title, variables)
                    .then((resp) => {
                      setGrapesQueue((q) => q.filter((x) => x.id !== g.id));

                      let newGrapeId = resp?.data?.createGrape?.id;
                      if (!newGrapeId && resp?.length === 24) newGrapeId = resp;

                      if (fileToUpload?.length && newGrapeId) {
                        setFileQueue((files) => [...files, ...fileToUpload]);
                        setFileToUpload([]);
                        fileToUpload.forEach((file) =>
                          Grapes.startFileUpload(client, newGrapeId, file, GRAPE_FILE_INLINE)
                            .then((r) => {
                              console.log("🟩 uploaded ", r);
                              setFileQueue((files) => files.filter((f) => f !== file));
                            })
                            .catch((e) => {
                              MLDialog.showSnackbar("❌ err uploading file");
                              console.log("❌ ", e);
                            })
                        );
                      }
                    })
                    .catch((e) => {
                      console.log("error", e);
                      setGrapesQueue((q) => q.map((x) => (x.id !== g.id ? x : { ...g, error: e })));
                    });
                });
                return true;
              }
            }}
          />
        </div>
      </Contextable>
    </React.Fragment>
  );

  function tryWritingToFragment(title: string, variables: IGrapeEdit) {
    if (!props.parentGrape) return;

    try {
      const parent = readGrapeFragment(client, props.parentGrape?.id);

      const tmpGrape = {
        id: (TMP_GRAPE_PREFIX + Math.random() + new Date().getTime()).substring(0, 24),
        parent: props.parentGrape.id,
        title,
        labels: [],
        main_order: ((parent.children || [])[parent.children?.length || 0]?.main_order || 500) + 100,
        ...variables,
        // __typename: "Grape",
      } as IGrape;

      writeFragment(client, props.parentGrape.id, {
        ...parent,
        // children: parent.children.slice(5),
        children: [...parent.children, tmpGrape],
      });
    } catch (e) {
      console.log("❌ err writing fragment add-text-field", e);
    }
  }
};

export default AddTextField;

export const temproraryGrapId = () => {
  return (TMP_GRAPE_PREFIX + Math.random() + "_" + new Date().getTime()).substring(0, 24);
};

export const isTemporaryGrapeId = (id: string) => {
  return id.startsWith(TMP_GRAPE_PREFIX);
};
