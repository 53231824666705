import React from "react";
import InfoIcon from "@material-ui/icons/Info";

export const StoryPointsInfo = (props: {}) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <React.Fragment>
      <div
        className="hoverable"
        style={{ padding: 8, margin: "0 -8px", display: "flex", flexDirection: "row", alignItems: "center" }}
        onClick={() => setVisible((v) => !v)}
      >
        <InfoIcon />
        <span style={{ marginLeft: 10 }} children="Come si calcolano" />
      </div>

      {visible ? (
        <div
          style={{ whiteSpace: "pre-line", border: "1px solid #ccc", padding: 20, margin: 10 }}
          children={`Story Points - Fibonacci
        
        Story Point - Cos’è? 
        Unità di misura, slegata dal tempo e dal budget.
        
        1-2-3-4-5
        
        Scala Fibonacci
        1-1-2-3-5-8-13-21-34-55-89-144
        
        Fibonacci Storypoints
        1-2-3-5-8-13-20-40-60-100
        
        1 - Fix velocissimo
        2 - Cambio testo/intervento semplice
        3 - Aggiungere immagine non complessa
        5 - Piccola feature - Componente semplice
        8 - Piccola feature - Componente semplice-medio
        13 - Piccola feature - Componente medio
        20 - Feature - Pagina intera semplice / Componente difficile
        40 - Pagina complessa/Più pagine semplici
        60 - Sezione intera semplice/media
        100 - Sezione intera media/complessa`}
        />
      ) : null}
    </React.Fragment>
  );
};
