/** @jsxImportSource @emotion/react */
import { fade, getLuminance, IconButton, Input, MenuItem, Select } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import EditIcon from "@material-ui/icons/Edit";
import React, { useRef } from "react";
import { useGrapeDetailContextSelector } from "./GrapeDetail/GrapeDetail/hooks/GrapeDetailContext";
import { getContrastYIQ, getStringColors } from "./GrapeLabels";
import ColorPicker from "material-ui-color-picker";
import { clearCurrentInputFocus, firstValidColor } from "../utils";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";

export const LabelSelector = (props: {
  labels: string[];
  className?: string;
  selected?: string;
  placeholder?: string;
  renderItem?: (label: string) => React.ReactNode;
  onChanged: (label: string) => void;
  onAddNew?: (label: string) => void;
  onEditPressed?: (label: string, color: string) => void;
  itemStyles?: CSSProperties;
  inputStyle?: CSSProperties;
  style?: CSSProperties;
}) => {
  const labelColors = useGrapeDetailContextSelector("labelColors");
  const value = (props.selected || "").toUpperCase();
  const setLabel = (newLabel: string) => {
    if (newLabel !== value) props.onChanged(newLabel);
  };

  const options: string[] = [];
  props.labels?.map((x) => {
    const xUpperCase = x.toUpperCase();
    if (!options.includes(xUpperCase)) {
      options.push(xUpperCase);
    }
  });
  options.sort();

  const getLabelStringColors = (label: string) => {
    let background: string = firstValidColor(labelColors[label.toUpperCase()]) || getStringColors(label).background;
    const foreground = getContrastYIQ(background, undefined, "white");
    return { background, foreground };
  };

  const stringColors = getLabelStringColors(props.selected || "");
  const foreground = props.placeholder && !props.selected ? "#000000" : stringColors.foreground;
  const background = props.placeholder && !props.selected ? "#ffffff" : stringColors.background;

  const itemStyles: any = {
    textAlign: "center",
    fontSize: 12,
    fontFamily: "monospace",
    fontWeight: "bold",
    ...(props.itemStyles || {}),
  };

  const IconComp = (props: any) => {
    return (
      <div
        css={{
          position: "relative",
          cursor: "pointer",
          ":hover": { background: "white", "svg:last-of-type": { fill: "#aa0000 !important" } },
        }}
        tabIndex={0}
      >
        <ArrowDropDownIcon
          css={{ pointerEvents: "none", position: "absolute", left: -20, top: 0, bottom: 0, margin: "auto" }}
        />
        <CloseIcon css={{ fontSize: 18, margin: "2px 4px 0 4px" }} onClick={() => setLabel("")} />
      </div>
    );
  };

  return (
    <div
      className={props.className}
      css={{
        position: "relative",
        ...((props.style as any) || {}),
        ...((value || "") === "" && !props.placeholder
          ? {}
          : {
              ".MuiInput-input": {
                paddingRight: 18,
                backgroundColor: "transparent",
                display: "flex",
                "::after": { content: `'${props.placeholder ? (value ? "" : props.placeholder) : value || ""}'` },
                "> div": { display: value && props.placeholder ? "" : "none" },
                ...((props.inputStyle as any) || {}),
              },
              "> div": {
                background,
                color: foreground,
                borderRadius: 4,
                paddingLeft: 6,
                height: 20,
                ":hover": { background: fade(background, 0.7) + " !important" },
                border: getLuminance(background) > 0.98 ? "1px solid #dddddd" : undefined,
              },
              svg: { fill: foreground },
            }),
      }}
    >
      <Select
        variant="standard"
        value={value || ""}
        label={value || ""}
        onChange={(e: any) => {
          const value = e.target.value;
          if (value === "add-new") {
            const newLabel = prompt("Nuova etichetta");
            if (props.onAddNew && (newLabel || "").trim().length) props.onAddNew!(newLabel!);
            // if (newLabel?.length) setLabel(newLabel);
            return;
          }
          setLabel(value);
        }}
        IconComponent={props.selected ? IconComp : undefined}
        // MenuProps={{ disablePortal: true }}
        css={{
          ":hover": { opacity: 0.7 },
          fieldset: { display: "none" },
          "::before, ::after": {
            opacity: 0,
          },
          ".MuiInput-input": {
            ...itemStyles,
            "> button": { display: "none" },
          },
        }}
      >
        <MenuItem value={""} style={{ opacity: 0.5 }} children={props.placeholder || "label"} />

        {options.map((key: string) => {
          const { foreground, background } = getLabelStringColors(key);
          return (
            <MenuItem
              key={key}
              value={key}
              css={{
                textTransform: "uppercase",
                color: foreground,
                background,
                ":hover": {
                  background: fade(background, 0.7),
                },
                ...itemStyles,
              }}
            >
              <div children={props.renderItem?.(key) ?? key} css={{ flex: 1, textAlign: "left" }} />

              {props.onEditPressed ? (
                <IconButton
                  css={{
                    padding: 4,
                    margin: 0,
                    marginLeft: 4,
                    // marginRight: 4, // ":hover": { opacity: 0.5 }
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.onEditPressed!(key, background);
                  }}
                >
                  {/* <ColorPicker ref={(ref) => (inputRef.current = ref)} defaultValue={background} onChange={(c) => {}} /> */}

                  {/* <EditIcon css={{ fontSize: 14, color: foreground }} /> */}
                  <EditIcon css={{ fontSize: 14, color: foreground }} />
                </IconButton>
              ) : null}
            </MenuItem>
          );
        })}

        {props.onAddNew ? <MenuItem children={"+ Aggiungi"} value="add-new" /> : null}
      </Select>
    </div>
  );
};
