/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { Button, Grid } from "@material-ui/core";
import { gql } from "apollo-boost";
import ApolloClient from "apollo-client";
import { showConfirmDialog, showInputDialog } from "../../../utils";
import { GRAPE_FILE_INLINE } from "../../AddTextField";
import { GET_GRAPE, IGrape } from "../../grape_gql_interface";
import MLDialog from "../../MLDialog";

/// ---------------
// ?  file upload
export const showFilesUploadDialog = (
  client: ApolloClient<any>,
  grape: IGrape,
  files: File[],
  params?: { addLoadingTask: () => any; disposeLoadingTask: (task: any) => void }
) => {
  //! multiple files
  if (files.length > 1) {
    showConfirmDialog(
      "Attenzione",
      <div
        dangerouslySetInnerHTML={{
          __html: "Vuoi caricare " + files.length + " file all'interno di:<br/><br/>" + grape.title,
        }}
      />,
      () => files.forEach((f) => startFileUpload(client, grape, f, undefined, params))
    );
    return;
  }

  //! single file - ask for title
  showInputDialog(
    "Caricamento",
    <Grid container direction="column" justify="center">
      {grape.type !== "chat" ? (
        <Button
          variant="contained"
          color="primary"
          children="Imposta come miniatura"
          onClick={() => {
            startFileUpload(client, grape, files[0], GRAPE_FILE_INLINE);
            MLDialog.hideModal();
          }}
        />
      ) : null}

      <Grid container item xs>
        <img style={{ maxWidth: 300, maxHeight: "50vh", marginBottom: 10 }} src={URL.createObjectURL(files[0])} />
      </Grid>
      <Grid container item xs children={files[0].name} style={{ margin: "12px 0" }} />
    </Grid>,
    "Aggiungi un messaggio..",
    (title) => startFileUpload(client, grape, files[0], title, params)
  );
};

const startFileUpload = (
  client: ApolloClient<any>,
  grape: IGrape,
  fileRes: File,
  title?: string,
  params?: { addLoadingTask: () => any; disposeLoadingTask: (task: any) => void }
) => {
  const fileLoadingTask = params?.addLoadingTask?.();
  const onEndUpload = () => params?.disposeLoadingTask?.(fileLoadingTask);

  const uploadFunction = (file: File) => {
    client
      .mutate({
        mutation: gql`
          mutation uploadFile($grapeId: ID!, $file: Upload!, $title: String!) {
            uploadFile(grapeId: $grapeId, file: $file, title: $title) {
              id
              files {
                id
                title
                url
                thumbnail
              }
            }
          }
        `,
        variables: { grapeId: grape.id, file, title: title || "" },
        refetchQueries: [{ query: GET_GRAPE, variables: { id: grape.id } }],
      })
      .then(onEndUpload)
      .catch((e: any) => {
        console.log("❌", e);
        MLDialog.showSnackbar("Non riesco a caricare questo file");
        onEndUpload();
      });
  };

  uploadFunction(fileRes);
  //? resize image
  // resizeImageFn(fileRes)
  //   .then((f) => {
  //     f.name = fileRes.name;
  //     uploadFunction(f);
  //   })
  //   .catch((e) => uploadFunction(fileRes));
};
