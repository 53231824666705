import { getStringColors } from "../components/GrapeLabels";
import { IUser } from "../components/User";
import UserPreferences from "../utils/UserPreferences";

const defaultCursorColors = [
  "#29BB9C",
  "#39CA74",
  "#3A99D8",
  "#9A5CB4",
  "#35495D",
  "#239F85",
  "#F491CB",
  "#F0C330",
  "#E47E30",
  "#E44D42",
  "#BE3A31",
];

const mabiloftColors: { [key: string]: string } = {
  "tiziano@mabiloft.com": "#1DCE00",
  "eddy@mabiloft.com": "#55BB99",
  "simone@mabiloft.com": "#0027FF",
  "veronica@mabiloft.com": "#BB00DA",
  "beatrice@mabiloft.com": "#C99EFF",
  "daniele@mabiloft.com": "#1B203C",
  "francesco@mabiloft.com": "#E85322",
  "giulio@mabiloft.com": "#FF32B9",
  "michael@mabiloft.com": "#0F4C81",
  "matteo@mabiloft.com": "#11A09A",
  "martina@mabiloft.com": "#EFD700",
  // "monitor@mabiloft.com": "#ffffff",
};

export const getUserColor = (user: IUser): string => {
  const defaultColor = mabiloftColors[user.email] || getStringColors(user.email).background;
  // defaultCursorColors[Math.floor(Math.random() * defaultCursorColors.length)];
  return UserPreferences.getPublicPreference(user, "cursor_color") || defaultColor;
};
