import "gantt-task-react/dist/index.css";
import { useState } from "react";
import Contextable from "../../Contextable";
import DetailScaffold from "../DetailScaffold";
import { GrapeDetailToolbar } from "../GrapeDetail/GrapeDetailToolbar";
import { useSafeGrapeDetailContextSelector } from "../GrapeDetail/hooks/GrapeDetailContext";
import { GrapesGantt } from "./components/GrapesGantt";
import FilterIcon from "@material-ui/icons/FilterList";

export const GrapeDetailGantt = (props: {}) => {
  const grape = useSafeGrapeDetailContextSelector("grape");

  const childrenFilter = useSafeGrapeDetailContextSelector("childrenFilter");
  const setChildrenFilter = useSafeGrapeDetailContextSelector("setChildrenFilter");

  const isFilterActive = !!(childrenFilter?.onlyGanttized || childrenFilter?.onlyUncompleted);

  return (
    <DetailScaffold
      grape={grape}
      header={
        <GrapeDetailToolbar
          trailing={
            <Contextable
              bothMouseButtons
              menuItems={[
                {
                  caption: "Solo le ganttizzate",
                  checked: !!childrenFilter?.onlyGanttized,
                  onClick: () => setChildrenFilter((f) => ({ ...f, onlyGanttized: !f?.onlyGanttized })),
                },
                {
                  caption: "Solo da completare",
                  checked: !!childrenFilter?.onlyUncompleted,
                  onClick: () => setChildrenFilter((f) => ({ ...f, onlyUncompleted: !f?.onlyUncompleted })),
                },
              ]}
            >
              <div css={{ position: "relative" }}>
                <FilterIcon
                  className="hoverable"
                  css={{
                    marginLeft: 4,
                    fontSize: 20,
                    padding: 2,
                    transform: "translateY(2px)",
                  }}
                />

                {isFilterActive && (
                  <div
                    css={{
                      position: "absolute",
                      top: 5,
                      right: 0,
                      width: 7,
                      height: 7,
                      borderRadius: 4,
                      backgroundColor: "red",
                    }}
                  />
                )}
              </div>
            </Contextable>
          }
          actions={
            [] // actions
          }
        />
      }
    >
      <GrapesGantt />
    </DetailScaffold>
  );
};
