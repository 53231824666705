import { useApolloClient, useQuery } from "@apollo/react-hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { GrapeDetailOpenParams, IGrapeDetail } from "..";
import { useGrapesApp } from "../../../../App";
import useSocket from "../../../../hooks/useSocket";
import Grapes from "../../../../modules/Grapes";
import { getAllFoundLabels, mergeObjects } from "../../../../utils";
import { useSafeWindowPollingQuery } from "../../../../utils/safeWindowQuery";
import UserPreferences from "../../../../utils/UserPreferences";
import { useRootGrapeContextSelector } from "../../../App/RootGrapeProvider";
import {
  GET_ANCESTORS,
  GET_GRAPE,
  GrapeChildrenFilter,
  IGrape,
  IUserGrapePermission,
} from "../../../grape_gql_interface";
import { IUser } from "../../../User";
import { usersSocketController } from "../../GrapeDetailPointers/usersSocketController";
import { useGrapeDescentDeadlines } from "./GrapeDescentDeatlines";
import { useGrapeDetailContextActions } from "./GrapeDetailContextActions";
import { useEditGrape } from "./useEditGrape";
const uuid = require("uuid");

export type GrapeModalState = { grape: IGrape; params?: GrapeDetailOpenParams; visible?: boolean };

function useGrapeDetailController(props: IGrapeDetail) {
  const client = useApolloClient();
  const grapesApp = useGrapesApp();
  const [grapeFromProps] = useState<IGrape>(props.grape || {});
  const isValidGrapeId = grapeFromProps?.id?.length === 24;
  const deadlines = useGrapeDescentDeadlines(props.grape);

  const {
    data: getGrape,
    refetch: refetchGetGrape,
    // } = useSafeWindowPollingQuery(oldKanban ? GET_GRAPE_OLD_KANBAN : GET_GRAPE, {
  } = useSafeWindowPollingQuery(GET_GRAPE, {
    variables: { id: grapeFromProps?.id },
    skip: !isValidGrapeId,
    // pollInterval: grapesPollInterval, //TODO improve pollInterval to delay while editing or doing something
  });

  //? core
  const [grape, _editGrape, optimistic] = useEditGrape(initGrapeFromProps(grapeFromProps, getGrape?.allGrapes?.[0]));
  const isGrapeReady = !(isValidGrapeId && !grape?.created_at);

  //? state
  const [urlGrapes, _setUrlGrapes] = useState(
    (props.initialUrlGrapes?.length > 1 && props.initialUrlGrapes.slice(1)) || []
  );
  const [selectedGrape, _setSelectedGrape] = useState<any>(urlGrapes?.length && { id: urlGrapes[0].split("_")[0] });
  const [subPath, _setSubPath] = useState("" + (props.initialUrlGrapes?.[0]?.split("_")?.[1] ?? ""));
  const [selectedGrapeParams, setSelectedGrapeParams] = useState<any>(undefined);
  const [modalGrape, _setModalGrape] = useState<GrapeModalState>();
  const socketController = usersSocketController(props.grape.id, subPath);

  //? anchestors
  const { data: dataAnchestors, loading: loadingAnchestors } = useQuery(GET_ANCESTORS, {
    variables: { grapeId: grape.id },
    skip: !isValidGrapeId || !grape.parent,
  });
  const allAnchestors: IGrape[] =
    dataAnchestors?.getAncestors || [...(props.params?.anchestors || []), ...props.anchestors] || [];
  const missingAnchestors = allAnchestors.filter((g, i) => {
    //? skip kanban columns
    if (i > 0 && allAnchestors[i - 1]?.type === "kanban") return false;
    if (allAnchestors[i]?.type === "progress") return false;
    //? check if is missing
    return !(props.anchestors || []).filter((x) => x.id === g.id).length;
  });

  //? configs
  const labelColors = Grapes.getThemeConfig(grape, "label-colors", {}) || {};
  const inheritedLabelColors: any = useSafeGrapeDetailContextSelector("labelColors") || {};
  const allFoundLabels = getAllFoundLabels({ ...grape, labels: [] });
  const isRootGrape = !(allAnchestors || []).length;

  //? filters
  const [childrenFilter, _setChildrenFilter] = useState<GrapeChildrenFilter | undefined>(defaultFilter(grape, subPath));

  const collaborators: IUserGrapePermission[] = aggregateCollaborators([
    ...(props.collaborators || []),
    ...(grapesApp?.user ? [{ user: grapesApp.user }] : []),
    ...(grape?.owner ? [{ user: grape.owner }] : []),
    ...(grape?.assignees?.map((user: IUser) => ({ user, assigned: true })) || []),
    ...(grape?.users?.map((user: IUserGrapePermission) => user) || []),
  ]);

  useSocket("updatedGrape", (data: IGrape) => {
    if (data.id === grape.id) {
      // optimistic(data);
      optimistic(data);
      // refetchGetGrape().then((e) => {
      //   // console.log("🟩 refetched");
      // }); //TODO fetch for parent
    } else {
      // for (let c = 0; c < grape.children?.length; c++) {
      //   if (data.id === grape.children[c].id) {
      //     optimistic(data);
      //     break;
      //   }
      // }
    }
  });

  //!---------- ACTIONS ----------

  const getGrapeSemiPath = (newSemiPath?: string) => {
    let path = grape.id;
    if (newSemiPath ?? subPath) path += "_" + (newSemiPath ?? subPath);
    return path + "/";
  };

  const setLabelColor = (label: string, color: string) => {
    Grapes.setThemeConfig(client, grape, "label-colors", {
      ...labelColors,
      [label.toUpperCase()]: color,
    });
  };

  //! middlewhare --> url dispatch
  const setSelectedGrape = (subGrape: IGrape | undefined, params?: GrapeDetailOpenParams) => {
    if (grape.title.toLocaleLowerCase().includes("certiblok")) {
      console.log("DETAIL -->", subGrape?.title);
    }

    if (subGrape) {
      props.onGrapeSubpathChange?.(getGrapeSemiPath() + subGrape.id);
    } else {
      props.onGrapeSubpathChange?.(getGrapeSemiPath());
    }
    setSelectedGrapeParams(params || undefined);
    _setSelectedGrape(subGrape);
    _setUrlGrapes(subGrape?.id ? [subGrape.id] : []);
    socketController.onFocus(subGrape);
  };

  const setModalGrape = (state: GrapeModalState) => {
    if (grape.title.toLocaleLowerCase().includes("certiblok")) {
      console.log("MODAL -->", state.grape?.title);
    }
    _setModalGrape(state);
  };

  const actions = useGrapeDetailContextActions({
    grape,
    props,
    modalGrape,
    selectedGrape,
    socketController,
    setModalGrape,
    setSelectedGrape,
    setSelectedGrapeParams,
  });

  //!---

  return {
    grape,
    myGrape: props.myGrape,
    collaborators,
    allFoundLabels,
    allAnchestors,
    labelColors: { ...inheritedLabelColors, ...labelColors },
    deadlines,
    missingAnchestors,
    socketController,

    //? config
    isGrapeReady,
    isRootGrape,
    isValidGrapeId,
    userNotAllowed: getGrape?.allGrapes && getGrape.allGrapes.length === 0,

    //? state
    selectedGrape,
    subPath,
    urlGrapes,
    modalGrape,
    selectedGrapeParams,
    childrenFilter,

    //? actions
    ...actions,
    getGrapeSemiPath,
    setLabelColor,
    editGrape: _editGrape,
    setChildrenFilter: _setChildrenFilter,
    setSubPath: _setSubPath,

    //? internals
    _props: props,
  };
}

export type GrapeDetailContextType = ReturnType<typeof useGrapeDetailController>;

const GrapeDetailContext = createContext<GrapeDetailContextType | undefined>(undefined);

export const GrapeDetailContextProvider: React.FC<{
  props: IGrapeDetail;
  children: React.ReactNode;
}> = ({ children, props }) => {
  const _registerDetailGrape = useRootGrapeContextSelector("_registerDetailGrape");
  // const parent = useContext(GrapeDetailContext);
  const grapeDetailController = useGrapeDetailController(props);
  useEffect(() => {
    const uniqueId = props.grape.id + "_" + uuid.v4();
    _registerDetailGrape(uniqueId, grapeDetailController);
    return () => _registerDetailGrape(uniqueId, undefined);
  }, []);
  return <GrapeDetailContext.Provider key={props.grape.id} value={grapeDetailController} children={children} />;
};

export function useGrapeDetailContextSelector<T extends keyof GrapeDetailContextType>(
  field: T
): GrapeDetailContextType[T] {
  return useContextSelector(GrapeDetailContext, (v) => v![field]);
}
export function useSafeGrapeDetailContextSelector<T extends keyof GrapeDetailContextType>(
  field: T
): GrapeDetailContextType[T] {
  return useContextSelector(GrapeDetailContext, (v) => v?.[field]) as any;
}

export function useGrapeDetailContextUnoptimized() {
  const context = useContextSelector(GrapeDetailContext, (v) => v);

  if (context === undefined) {
    throw new Error("Trying to use context outside of provider");
  }

  return context;
}

function aggregateCollaborators(list: IUserGrapePermission[]) {
  const aggregated: IUserGrapePermission[] = [];
  for (let c = 0; c < list.length; c++) {
    let found = false;
    for (let u = 0; u < aggregated.length; u++) {
      if (list[c].user.id === aggregated[u].user.id) {
        list[c].assigned = list[c].assigned || aggregated[u].assigned;
        list[c].permission = list[c].permission || aggregated[u].permission;
        found = true;
      }
    }
    if (!found) aggregated.push(list[c]);
  }
  return aggregated;
}

function initGrapeFromProps(fromProps: IGrape, next: IGrape | undefined) {
  const grape = mergeObjects({ children: [] }, fromProps, next);
  if (grape.type === "sprint") {
    grape.children = [...(grape.children || []), ...(grape.related_to || [])];
  }
  return grape;
}

function defaultFilter(grape: IGrape, subPath: string): GrapeChildrenFilter | undefined {
  // if (subPath === "gantt") {
  //   return { onlyGanttized: true };
  // }
  return undefined;
}
