/** @jsxImportSource @emotion/react */
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { LinearProgress, Typography, useTheme } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CancelOutlined";
import MessageFillIcon from "@material-ui/icons/ChatBubble";
import MessageOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import MessageTextIcon from "@material-ui/icons/Message";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { useGrapesApp } from "../../../App";
import useSocket from "../../../hooks/useSocket";
import { showConfirmDialog } from "../../../utils";
import Contextable, { IContextMenuItem } from "../../Contextable";
import { IGrape } from "../../grape_gql_interface";
import { SmartInputField, SmartInputFieldRef } from "../../SmartInputField";
import { GrapeActivities } from "../GrapeActivities/GrapeActivities";
import { useSafeGrapeDetailContextSelector } from "../GrapeDetail/hooks/GrapeDetailContext";
import {
  CREATE_GRAPE_COMMENT,
  DELETE_GRAPE_COMMENT,
  EDIT_GRAPE_COMMENT,
  GET_COMMENTS,
  GET_COUNT_COMMENTS,
  IGrapeComment,
  READ_GRAPE_COMMENTS,
} from "./gql_interface_grape_comments";
import { GrapeCommentCit, GrapeUserComment } from "./GrapeUserComment";

export const GrapeComments = (props: { grape: IGrape }) => {
  const theme = useTheme();
  const client = useApolloClient();
  const { user } = useGrapesApp();

  const scrollToBottom = useSafeGrapeDetailContextSelector("scrollToBottom");

  const inputRef = useRef<SmartInputFieldRef>();
  const [commentAction, setCommentAction] = useState<{ comment: IGrapeComment; action: "edit" | "reply" }>();

  const grapeComments = useQuery(GET_COMMENTS, { variables: { grapeId: props.grape.id } });
  const listGrapeComments = grapeComments?.data?.getCommentsOfGrape || [];

  const countComments = useQuery(GET_COUNT_COMMENTS, { variables: { grapeId: props.grape.id } });
  const messagesCount = countComments?.data?.getCountOfCommentsToReadOfGrape?.total || 0;
  const messagesUnreadCount = countComments?.data?.getCountOfCommentsToReadOfGrape?.unread || 0;

  useSocket("commentsUpdated", (grapeUpdated: any) => {
    if (grapeUpdated.id === props.grape.id) {
      grapeComments.refetch();
      countComments.refetch();
    }
  });

  const unreadMessagesColor = theme.palette.primary.main;

  const onMessageBadgeClick = useCallback(() => {
    if (messagesUnreadCount > 0) {
      client.mutate({
        mutation: READ_GRAPE_COMMENTS,
        variables: {
          grapeId: props.grape.id,
        },
        refetchQueries: [
          { query: GET_COMMENTS, variables: { grapeId: props.grape.id } },
          { query: GET_COUNT_COMMENTS, variables: { grapeId: props.grape.id } },
        ],
      });
    } else if (messagesCount > 0) {
      scrollToBottom();
    }
  }, [props.grape.id, messagesCount, messagesUnreadCount]);

  return (
    <React.Fragment>
      <GrapeActivities grape={props.grape} />

      <div
        css={{
          position: "sticky",
          // bottom: 0,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
        }}
      >
        <div
          css={{
            padding: "6px 12px", // 12
            background: "white",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            //
            border: "1px solid #eee",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none",
            // backgroundColor: "#fafafa",
            // marginRight: 16,
          }}
        >
          <div
            css={{
              padding: "0px 4px",
              borderRadius: 6,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              boxShadow: messagesCount > 0 && messagesUnreadCount <= 0 ? "inset 0 0 1px 1px #ccc" : undefined,
              backgroundColor: messagesUnreadCount > 0 ? unreadMessagesColor : undefined,
              ":hover":
                messagesCount > 0
                  ? {
                      cursor: "pointer",
                      opacity: 0.5,
                    }
                  : undefined,
            }}
            onClick={onMessageBadgeClick}
          >
            <div css={{ margin: "4px 0 4px 4px" }}>
              {messagesUnreadCount > 0 ? (
                <div css={{ width: 24, height: 24, position: "relative" }}>
                  <MessageFillIcon css={{ color: "white" }} />
                  <div
                    css={{
                      position: "absolute",
                      top: -3,
                      right: -3,
                      backgroundColor: "white",
                      width: 8,
                      height: 8,
                      border: "3px solid " + unreadMessagesColor,
                      borderRadius: 10,
                    }}
                  />
                </div>
              ) : messagesCount > 0 ? (
                <MessageTextIcon css={{ color: theme.palette.primary.main }} />
              ) : (
                <MessageOutlineIcon css={{ color: "rgba(0, 0, 0, 0.6)" }} />
              )}
            </div>

            {messagesCount > 0 ? (
              <Typography
                variant="caption"
                children={messagesUnreadCount || messagesCount}
                style={{
                  margin: "0 4px",
                  lineHeight: 1,
                  color: messagesUnreadCount > 0 ? "white" : undefined,
                }}
              />
            ) : undefined}
          </div>

          <div css={{ display: "flex", flexDirection: "column" }}>
            {commentAction ? (
              <div css={{ display: "flex", flexDirection: "row", alignItems: "start", paddingLeft: 12 }}>
                <GrapeCommentCit
                  title={
                    commentAction.action === "reply"
                      ? commentAction.comment.owner
                        ? `${commentAction.comment.owner.name} ${commentAction.comment.owner.surname}`
                        : commentAction.comment.owner_id
                      : "Modifica commento"
                  }
                  content={commentAction.comment.message}
                />

                <CloseOutlinedIcon
                  css={{ cursor: "pointer", width: 24, height: 24, marginLeft: 12, color: theme.palette.primary.main }}
                  onClick={() => {
                    setCommentAction(undefined);
                    inputRef.current?.clearText();
                  }}
                />
              </div>
            ) : null}

            <SmartInputField
              //@ts-ignore
              ref={inputRef}
              // value={text}
              // onChange={setText}
              placeholder={"Scrivi un commento..."}
              css={{ minWidth: 200 }}
              onKeyPressed={(e) => {
                if (e.key === "Escape") {
                  setCommentAction(undefined);
                  inputRef.current?.clearText();
                }
              }}
              onSubmit={(message) => {
                const refetchQueries = [
                  { query: GET_COMMENTS, variables: { grapeId: props.grape.id } },
                  { query: GET_COUNT_COMMENTS, variables: { grapeId: props.grape.id } },
                ];

                if (commentAction?.action === "edit") {
                  client.mutate({
                    mutation: EDIT_GRAPE_COMMENT,
                    variables: {
                      input: {
                        id: commentAction.comment.id,
                        message,
                      },
                    },
                    refetchQueries,
                  });
                  setCommentAction(undefined);
                  return;
                }

                //? send new message
                client.mutate({
                  mutation: CREATE_GRAPE_COMMENT,
                  variables: {
                    input: {
                      grape_id: props.grape.id,
                      message,
                      reference_comment_id: commentAction?.action === "reply" ? commentAction.comment.id : undefined,
                    },
                  },
                  refetchQueries,
                });
                setCommentAction(undefined);
              }}
            />
          </div>
        </div>
      </div>

      {grapeComments.loading ? (
        <LinearProgress />
      ) : (
        <div>
          {listGrapeComments.map((comment: IGrapeComment, index: number) => (
            <Contextable key={comment.id} menuItems={getModalActions(comment)}>
              <GrapeUserComment
                comment={comment}
                // condensed={index > 0 && isCommentCondensed(comment, listGrapeComments[index + 1])}
                // condensed={index > 0 && isCommentCondensed(comment, listGrapeComments[index - 1])}
                condensed={isCommentCondensed(comment, listGrapeComments[index - 1], index)}
              />
            </Contextable>
          ))}
        </div>
      )}
    </React.Fragment>
  );

  function isCommentCondensed(comment: IGrapeComment, upperComment: IGrapeComment | undefined, index: number) {
    if (!upperComment) {
      return false;
    }
    if (comment.owner_id !== upperComment.owner_id) {
      return false;
    }
    if (comment.reference_comment_id) {
      return false;
    }
    return moment(upperComment.created_at).diff(moment(comment.created_at), "seconds") < 60;
  }

  function getModalActions(comment: IGrapeComment): IContextMenuItem[] {
    const isMyComment = user.id === comment.owner_id;
    let actions: IContextMenuItem[] = [
      {
        caption: "Rispondi",
        onClick: () => {
          setCommentAction({ comment, action: "reply" });
          inputRef.current?.requestFocus();
        },
      },
    ];

    if (isMyComment) {
      actions = [
        {
          caption: "Modifica",
          onClick: () => {
            setCommentAction({ comment, action: "edit" });
            inputRef.current?.setText(comment.message);
            inputRef.current?.requestFocus();
          },
        },
        ...actions,
      ];
      actions.push({
        caption: "Elimina",
        color: "#de2000",
        onClick: () =>
          showConfirmDialog(
            "Sei sicuro di voler eliminare questo commento?",
            "Una volta rimosso non potrai più recuperarlo",
            () => {
              console.log("comment:", comment.id, "grape:", props.grape.id);
              client.mutate({
                mutation: DELETE_GRAPE_COMMENT,
                variables: { id: comment.id },
                refetchQueries: [
                  { query: GET_COMMENTS, variables: { grapeId: props.grape.id } },
                  { query: GET_COUNT_COMMENTS, variables: { grapeId: props.grape.id } },
                ],
              });
            }
          ),
      });
    }

    return actions;
  }
};
