import gql from "graphql-tag";

export const USER_FRAGMENT = gql`
  fragment user on User {
    id
    name
    surname
    email
    thumb_profile
  }
`;

export const GRAPE_FILE_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment grapeFile on GrapeFile {
    id
    mimetype
    created_at
    updated_at
    title
    url
    thumbnail
    owner {
      ...user
    }
  }
`;

export const MY_GRAPE_FRAGMENT = gql`
  ${USER_FRAGMENT}
  ${GRAPE_FILE_FRAGMENT}
  fragment myGrape on MyGrape {
    id
    title
    description
    type
    main_order
    status
    parent
    date
    date0
    date1
    date2
    progress
    storypoints
    priority
    settings
    theme
    ancestors {
      id
      title
      type
    }
    assignees {
      ...user
    }
    files {
      ...grapeFile
    }
    labels
    updated_at
    created_at
    owner {
      ...user
    }
    children {
      id
      title
      description
      type
      created_at
      updated_at
      main_order
      cross_order
      status
      parent
      date
      date0
      date1
      date2
      labels
      progress
      priority
      owner {
        ...user
      }
    }
  }
`;
