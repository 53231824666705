import React from "react";
import { makeStyles, Tooltip, withStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

export default CustomTooltip;
