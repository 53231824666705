/** @jsxImportSource @emotion/react */
/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { Tooltip, useTheme } from "@material-ui/core";
import Grapes from "../../../modules/Grapes";
import { PromiseItem } from "../../../modules/utilities/PromisesDispatcherTool";
import { isCompletionGrapeByTitle, isWipGrapeByTitle, showConfirmDialog } from "../../../utils";
import { IGrape, IUserGrapePermission } from "../../grape_gql_interface";
import { migrateFromBoardToKanban } from "../core/migrateGrapeUtils";
import DetailScaffold from "../DetailScaffold";
import { GrapeComments } from "../GrapeComments/GrapeComments";
import { GrapeDetailOpenParams } from "../GrapeDetail";
import { GrapeDetailToolbar } from "../GrapeDetail/GrapeDetailToolbar";
import { IRenderGrapesColumnParams } from "../GrapeDetail/hooks/GrapeDetailRenderColumn";
import { BoardBody, deserializeBoardStructure, serializeBoardStructure } from "./BoardBody";
import { BoardStructure } from "./BoardBody_bk";
import { BoardSprintManager } from "./BoardSprintManager";

export type RenderGrapesColumnFunction = (child: IGrape, children: IGrape[], params?: IRenderGrapesColumnParams) => any;

export interface IBoardGrape {
  editGrape: (grapeId: string, prop: string, value: any, props?: any) => void;
  renderGrapesColumn: RenderGrapesColumnFunction;
  subPath?: string;
  allLabels?: string[];
  onSubPathChange?: (subpath: string) => void;
  grape: IGrape;
  onRequestOpen?: (item: IGrape, params?: GrapeDetailOpenParams) => void;
  collaborators?: IUserGrapePermission[];
  childrens: IGrape[];
  anchestors: IGrape[];
}

export const getStatusChangeBoard = (grape: IGrape, columnId: number): number => {
  const structure = deserializeBoardStructure(Grapes.getSetting(grape, "board-structure"));
  for (let c = 0; c < structure.columns.length; c++) {
    if (structure.columns[c].id === columnId) {
      // return structure.columns[c].isCompletionColumn ? 1 : 0;
      if (structure.columns[c].isCompletionColumn || isCompletionGrapeByTitle(structure.columns[c].name)) {
        return 1;
      }
      if (structure.columns[c].isWipColumn || isWipGrapeByTitle(structure.columns[c].name)) {
        return 2;
      }
    }
  }
  return 0;
};

export const getBoardGrapeStructure = (grape: IGrape) => {
  return deserializeBoardStructure(Grapes.getSetting(grape, "board-structure"));
};

export const BoardGrape = (props: IBoardGrape) => {
  const theme = useTheme();
  const client = useApolloClient();
  const structure = getBoardGrapeStructure(props.grape);

  const showBacklog = props.subPath === "backlog";
  const setShowBacklog = (backlog: boolean) => props.onSubPathChange?.(backlog ? "backlog" : "");

  return (
    <DetailScaffold
      grape={props.grape}
      header={
        <GrapeDetailToolbar
          actions={
            showBacklog || structure.columns.filter((c) => c.hidden).length
              ? [
                  {
                    color: theme.palette.primary.main,
                    // color: showBacklog ? undefined : theme.palette.primary.main,
                    tooltip: showBacklog ? "Hide backlog" : "Backlog",
                    icon: showBacklog ? "grid_off" : "grid_on",
                    onClick: () => setShowBacklog(!showBacklog),
                  },
                ]
              : undefined
          }
          trailing={<BoardSprintManager grape={props.grape} />}
          // leading={<BoardSprintManager grape={props.grape} />}
        />
      }
    >
      {/* {__DEV__ || ["tiziano@mabiloft.com", "eddy@mabiloft.com"].includes(grapesApp.user.email) ? (
        <MigrationTool grape={props.grape} />
      ) : null} */}

      {/* <BoardToolbar structure={structure} subPath={props.subPath} onSubPathChange={props.onSubPathChange} /> */}

      <BoardBody
        boardProps={props}
        structure={structure}
        onStructureChanged={(struct) => {
          Grapes.setSetting(client, props.grape, "board-structure", serializeBoardStructure(struct));
        }}
      />

      <GrapeComments grape={props.grape} />
    </DetailScaffold>
  );
};

const BoardToolbar = (props: {
  structure: BoardStructure;
  subPath?: string;
  onSubPathChange?: IBoardGrape["onSubPathChange"];
}) => {
  const theme = useTheme();
  const showBacklog = props.subPath === "backlog";
  const setShowBacklog = (backlog: boolean) => props.onSubPathChange?.(backlog ? "backlog" : "");

  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      {showBacklog || props.structure.columns.filter((c) => c.hidden).length ? (
        <div
          className="hoverable" // outlined"
          onClick={() => setShowBacklog(!showBacklog)}
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "inherit",
            color: theme.palette.primary.main,
          }}
        >
          <i className="material-icons" children={showBacklog ? "grid_off" : "grid_on"} />
          <span children={showBacklog ? "Hide backlog" : "Backlog"} />
        </div>
      ) : null}
    </div>
  );
};

const MigrationTool = (props: { grape: IGrape }) => {
  const client = useApolloClient();

  return (
    <div
      css={{
        margin: "5px 15px",
        padding: 5,
        cursor: "pointer",
        background: "#aaff22",
        width: 100,
        fontWeight: "bold",
        letterSpacing: 1,
        textAlign: "center",
        ":hover": { opacity: 0.7 },
      }}
      children="MIGRAPES"
      onClick={() => {
        for (let c = 0; c < props.grape.children.length; c++) {
          for (let i = 0; i < props.grape.children[c].children.length; i++) {
            if (props.grape.children[c].children[i].type === "progress") {
              alert(
                'Impossibile migrare alla nuova kanban board. Per favore cancella tutti i "progress" grapes prima.'
              );
              return;
            }
          }
        }

        showConfirmDialog("Vuoi migrare alla nuova kanban?", "L'operazione non è facile da invertire, confermi?", () =>
          migrateFromBoardToKanban(props.grape, getBoardGrapeStructure(props.grape))
        );
      }}
    />
  );
};
