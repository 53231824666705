/** @jsxImportSource @emotion/react */
export const StickyLabel = ({ children, top }: { children: string; top?: number }) => (
  <div
    children={children}
    css={{
      fontWeight: "bold",
      fontSize: 18,
      padding: "4px 8px",
      margin: "4px 0",
      paddingTop: 16,
      position: "sticky",
      top: top ?? -6,
      zIndex: 1,
      background: "white",
      textTransform: "capitalize",
    }}
  />
);
