import { useAtomValue } from "jotai";
import { MAGIC_ATOM } from "../../stores/magic";

import GrapeLabels from "../GrapeLabels";
import { IGrape } from "../grape_gql_interface";

type UrgencyStatusProps = {
  grape: IGrape;
  urgency: number;
};
const UrgencyStatus: React.FC<UrgencyStatusProps> = ({ grape, urgency }) => {
  const isEnabledMagic = useAtomValue(MAGIC_ATOM);

  if (urgency === 0 || grape.status === 1 || !isEnabledMagic) return null;

  return (
    <GrapeLabels
      leading={[
        {
          title: urgency.toString(),
          tooltip: "Urgency",
          color: {
            background: "#ea3300",
            foreground: "#ffffff",
          },
        },
      ]}
    />
  );
};
export default UrgencyStatus;
