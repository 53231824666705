/** @jsxImportSource @emotion/react */
import { MenuItem, Select } from "@material-ui/core";
import { __DEV__ } from "../../utils/init";
import { defaultGrapeListType, grapeListTypes } from "../GrapeDetail/core/grapeTypes";
import { showKanbanMigrateDialog } from "../GrapeDetail/core/migrateGrapeUtils";
import { useGrapeDetailContextSelector } from "../GrapeDetail/GrapeDetail/hooks/GrapeDetailContext";
import MLDialog from "../MLDialog";

export const GrapeTypeSelector = (props: { outlined?: boolean }) => {
  const grape = useGrapeDetailContextSelector("grape");
  const editGrape = useGrapeDetailContextSelector("editGrape");
  const allAnchestors = useGrapeDetailContextSelector("allAnchestors");

  const parentType = allAnchestors[allAnchestors.length - 1]?.type || defaultGrapeListType;
  const parentIsProgress = parentType === "progress";
  const grandparentIsKanban = allAnchestors[allAnchestors.length - 2]?.type === "kanban";

  let grapeTypeOptions: string[] = [];
  if (grape.type === "progress" || grandparentIsKanban || parentIsProgress) {
    grapeTypeOptions.push("progress");
  }
  if (grape.type === "progress" || parentType === "board") {
    grapeTypeOptions.push("progress");
  }
  grapeTypeOptions.push(...Object.keys(grapeListTypes).filter((key) => grapeListTypes[key].selectable));

  //? remove duplicates
  grapeTypeOptions = [...(new Set(grapeTypeOptions) as any)];

  return (
    <Select
      variant={props.outlined ? "outlined" : "standard"} // filled | standard | outlined
      className={"hoverable" + (props.outlined ? " outlined" : "")}
      value={
        (grapeTypeOptions.includes(grape.type) && grape.type) ||
        (grape.type === "board" && "kanban") ||
        (grape.type === "kanban" && "board") ||
        defaultGrapeListType
      }
      onChange={(e) => {
        const newType = e.target.value as string;
        if (grape.type === newType) return;

        // if ((__DEV__ && newType === "kanban") || grape.type === "kanban") {
        //   showKanbanMigrateDialog(grape, newType);
        //   return;
        // }

        if (parentIsProgress && newType === "progress") {
          MLDialog.showModal(
            `Questo grappolo si trova già all'interno di un "${newType}"`,
            "Portalo allo stesso livello per trasformarlo in un progress"
          );
          return;
        }
        editGrape(grape.id, "type", e.target.value);
      }}
      // MenuProps={{ disablePortal: true }}
      css={{
        height: 20,
        marginTop: 1,
        fieldset: { display: "none" },
        "::before": {
          opacity: 0,
        },
        ".MuiSelect-root.MuiSelect-select": {
          padding: "0 4px",
          paddingRight: 8,
          // padding: "3px 8px",
          fontSize: 0,
        },
        ".MuiSvgIcon-root.MuiSelect-icon": {
          // color: 'initial',
          right: -2,
        },
        ".MuiSelect-iconOutlined": {
          right: 0,
          color: "black",
        },
      }}
    >
      {grapeTypeOptions.map((key: string) => (
        <MenuItem key={key} value={key} style={{ textTransform: "capitalize" }}>
          {/* {key}  */}
          <div css={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <i
              className="material-icons"
              children={grapeListTypes[key].icon}
              css={{ fontSize: 20, marginRight: 8, marginTop: 1 }}
            />
            {grapeListTypes[key].title || key}
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};
