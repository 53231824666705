import { useSafeWindowPollingQuery } from "../../../../utils/safeWindowQuery";
import { IGrape } from "../../../grape_gql_interface";
import { IMyGrape } from "../../../MyGrapeDetail";
import { GRAPE_DESCENDENT_DEADLINES } from "../../core/gql_interface";

/// DESCENT DEADLINES
export const useGrapeDescentDeadlines = (grape: IGrape) => {
  const { data: dataDeadlines } = useSafeWindowPollingQuery(GRAPE_DESCENDENT_DEADLINES, {
    variables: { grapeId: grape?.id },
    skip: !grape?.id,
    // pollInterval: grapesPollInterval,
  });

  const descentDeadlines = (dataDeadlines?.allDescentDeadlines || []) as IMyGrape[];
  const grapeDeadlines = pushRecursiveDeadlineGrapes(grape, []);
  const deadlines = [...descentDeadlines, ...grapeDeadlines];
  // console.log(99, deadlines);
  return deadlines;
};

function pushRecursiveDeadlineGrapes(grape: IGrape, list: IGrape[]) {
  if (grape.date) list.push(grape);
  for (let i = 0; i < grape.children?.length || 0; i++) {
    pushRecursiveDeadlineGrapes(grape.children[i], list);
  }
  return list;
}
