import { IUser } from "../User";
import ExportCSVPlugin from "./export_csv_plugin";
import PresenzePlugin from "./presenze_plugin";
import { GrapesPlugin } from "./types";

const userPlugins: { [key: string]: { title: string; plugin: typeof GrapesPlugin }[] } = {
  "tiziano@mabiloft.com": [
    { title: PresenzePlugin.title, plugin: PresenzePlugin },
    { title: ExportCSVPlugin.title, plugin: ExportCSVPlugin },
  ],
  "eddy@mabiloft.com": [
    // { title: PresenzePlugin.title, plugin: PresenzePlugin },
    { title: ExportCSVPlugin.title, plugin: ExportCSVPlugin },
  ],
  "veronica@mabiloft.com": [
    { title: ExportCSVPlugin.title, plugin: ExportCSVPlugin },
  ],
};

export const getUserPlugins = (user: IUser) => {
  if (user && userPlugins[user.email]) {
    return userPlugins[user.email];
  }
};
