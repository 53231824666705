/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { Box, fade, Tooltip, useTheme } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment, { Moment } from "moment";
import { useRef } from "react";
import { IBoardGrape } from ".";
import { showConfirmDialog, showInputDialog } from "../../../utils";
import Contextable from "../../Contextable";
import CustomTooltip from "../../CustomTooltip";
import DescriptionInput from "../../DescriptionInput";
import { UPDATE_TIMEOUT_MILLIS } from "../../Grape";
import { GET_GRAPE } from "../../grape_gql_interface";
import { HIDE_COMPLETED_GRAPES, RESTORE_COMPLETED_GRAPES } from "../core/gql_interface";
import { BoardColumn, boardGrapeSpecs, BoardStructure } from "./BoardBody";

export const BoardHeaders = (props: {
  boardProps: IBoardGrape;
  columns: BoardColumn[];
  structure: BoardStructure;
  onStructureChanged: (struct: BoardStructure) => void;
}) => {
  const { columns } = props;
  const theme = useTheme();
  const client = useApolloClient();
  const contextables = useRef<any>({});
  const textDebounces = useRef<any>({});

  const structureRef = useRef(props.structure);
  const updateStructure = (struct: BoardStructure) => props.onStructureChanged((structureRef.current = struct));

  if (!columns?.length) return null;

  const openBoardColumnSettings = (e: any, index: number) => {
    contextables.current[index]?.open(e);
  };

  const addBoardColumn = (index: number) => {
    showInputDialog("Nuova colonna", "Nome della nuova colonna", undefined, (name) => {
      if (!name) return;

      let newColumns: BoardColumn[] = [];
      for (let i = 0; i <= columns.length; i++) {
        if (i == index) {
          newColumns.push({
            id: new Date().getTime(),
            name: name,
            order: i,
          });
        }
        if (columns[i]) newColumns.push(columns[i]);
      }
      newColumns = newColumns.map((c, order) => ({ ...c, ["order"]: order }));

      updateStructure({
        ...structureRef.current,
        columns: newColumns,
      });
    });
  };

  function moveColumnTo(column: BoardColumn, toRight: boolean) {
    let newColumns = columns.map((c, i) => ({ ...c, order: c.id == column.id ? i + (toRight ? 1.1 : -1.1) : i }));
    newColumns.sort((a, b) => a.order - b.order);
    newColumns = newColumns.map((c, order) => ({ ...c, ["order"]: order }));
    updateStructure({
      ...structureRef.current,
      columns: newColumns,
    });
  }

  const onColumnPropChanged = (column: BoardColumn, prop: keyof BoardColumn, value: any) => {
    const struct = {
      ...structureRef.current,
      columns: structureRef.current.columns.map((c) => {
        if (c.id == column.id) {
          return { ...c, ...{ [prop]: value } };
        }
        return c;
      }),
    };
    updateStructure(struct);
  };

  const onDeleteColumn = (column: BoardColumn) => {
    if (props.columns.length == 0) return;
    const struct = { ...structureRef.current };
    struct.columns = struct.columns.filter((c) => c.id != column.id);
    updateStructure(struct);
  };

  function debounceColumnNameChange(column: BoardColumn, name: string) {
    const columnKey = `${column.id}`;
    if (textDebounces.current[columnKey]) clearTimeout(textDebounces.current[columnKey]);
    textDebounces.current[columnKey] = setTimeout(() => {
      onColumnPropChanged(column, "name", name);
    }, UPDATE_TIMEOUT_MILLIS);
  }

  return (
    <div
      css={{
        left: 0,
        right: 0,
        top: 0,
        position: "sticky",
        paddingRight: boardGrapeSpecs.horizontalPadding,
        // backgroundColor: "white",
        zIndex: 2,
      }}
    >
      <div css={{ display: "flex" }}>
        {/* <div css={{ position: "relative", flex: 1, display: "flex", flexDirection: "row" }}> */}
        {columns.map((column, index: number) => {
          const expandChildrens = column.expandChildrens ?? true;
          const isHidden = column.hidden || false;
          const todoModeEnabled = !!column.todoModeEnabled || false;
          const noBananaDot = column.noBananaDot || false;
          const totStorypoints = column.storypoints || 0;

          return (
            <Box
              flex={1}
              key={column.id}
              display="flex"
              flexDirection="column"
              style={{
                padding: boardGrapeSpecs.columnPadding / 2,
                paddingTop: boardGrapeSpecs.columnPadding + boardGrapeSpecs.columnPadding / 2,
                paddingBottom: 0,
                backgroundColor: "white",
                width: boardGrapeSpecs.columnWidth,
                whiteSpace: "initial",
                position: "relative",
              }}
            >
              <div
                css={{
                  flex: 1,
                  // boxShadow: "0 0 1px blue",
                  backgroundColor: isHidden ? fade(theme.palette.primary.main, 0.1) : "rgba(0, 0, 0,0.026)",
                  padding: "4px 0",
                  borderTopLeftRadius: boardGrapeSpecs.borderRadius,
                  borderTopRightRadius: boardGrapeSpecs.borderRadius,

                  ".kanban-add-column-btn": {
                    top: 13,
                    display: "inline-block",
                    width: 24,
                    height: 24,
                    // borderRadius: 12,
                    borderRadius: 5,
                    opacity: 0,
                    transition: "opacity 250ms",
                    pointerEvents: "none",
                    textAlign: "center",
                    lineHeight: "20px",
                    fontSize: "20px",
                    cursor: "pointer",
                    border: "1px solid #ccc",
                    backgroundColor: "white",
                    position: "absolute",
                    zIndex: 1,
                    "&.to-left": { left: -13 },
                    "&.to-right": { right: -13 },
                    "::before": { content: "'+'" },
                    ":hover": { backgroundColor: "#eee" },
                  },
                  ".kanban-column-settings, .kanban-column-storypoints": {
                    top: 13,
                    display: "flex",
                    position: "absolute",
                    right: 20,
                    width: 24,
                    height: 24,
                    borderRadius: 16,
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid transparent",
                    margin: "auto",
                    cursor: "pointer",
                    // backgroundColor: "white",
                    // border: "1px solid #ccc",
                    pointerEvents: "none",
                    opacity: 0,
                    transition: "opacity 250ms",
                    ":hover": { backgroundColor: "#eee" },
                  },
                  ".kanban-column-storypoints": {
                    fontSize: 11,
                    fontWeight: "bold",
                    right: "auto",
                    left: 20,
                    backgroundColor: "white",
                    borderColor: "#ccc",
                    opacity: 1,
                    pointerEvents: "all",
                    color: "#ee9900",
                    // color: 'white',
                    // border: 'none',
                    // backgroundColor: '#ee9900',
                  },

                  ":hover": {
                    ".kanban-add-column-btn, .kanban-column-settings": {
                      opacity: "1 !important" as any,
                      pointerEvents: "initial !important" as any,
                    },
                  },
                }}
              >
                <div className="kanban-add-column-btn to-left" onClick={() => addBoardColumn(index)} />
                <div className="kanban-add-column-btn to-right" onClick={() => addBoardColumn(index + 1)} />

                {totStorypoints > 0 ? (
                  <Tooltip title="Column story points">
                    <div className="kanban-column-storypoints" children={totStorypoints} />
                  </Tooltip>
                ) : null}

                <div className="kanban-column-settings" onClick={(e) => openBoardColumnSettings(e, index)}>
                  <MoreVertIcon fontSize="small" css={{ opacity: 0.8 }} />
                </div>

                <Contextable
                  innerRef={(r) => (contextables.current[index] = r)}
                  menuItems={[
                    columns.length > 1 && {
                      key: "move",
                      hoverable: false,
                      caption: (
                        <div
                          css={{ flex: 1, display: "flex", flexDirection: "column", borderBottom: "1px solid #ccc" }}
                        >
                          <h6 children="Move" style={{ textAlign: "center", margin: 0, marginBottom: 4 }} />
                          <div
                            css={{
                              display: "flex",
                              flex: 1,
                              height: 30,
                              flexDirection: "row",
                              "> div": { paddingTop: 4 },
                            }}
                          >
                            <div
                              css={{ flex: 0.5, opacity: index > 0 ? 1 : 0 }}
                              className="hoverable"
                              children="← left"
                              onClick={() => index > 0 && moveColumnTo(column, false)}
                            />
                            <div
                              css={{
                                flex: 0.5,
                                textAlign: "right",
                                opacity: index < columns.length - 1 ? 1 : 0,
                              }}
                              className="hoverable"
                              children="right →"
                              onClick={() => index < columns.length - 1 && moveColumnTo(column, true)}
                            />
                          </div>
                        </div>
                      ),
                    },
                    // { key: 'add-before', caption: 'Enter 1 on the left', onClick: () => addKanbanColumn(prevIndex) },
                    // { key: 'add-after', caption: 'Enter 1 on the right', onClick: () => addKanbanColumn(nextIndex) },
                    {
                      key: "todo-mode",
                      checked: todoModeEnabled,
                      caption: "Abilita todo list", //"Todo mode",
                      onClick: (params) => onColumnPropChanged(column, "todoModeEnabled", params?.checked),
                    },
                    {
                      key: "expand-childrens",
                      checked: expandChildrens,
                      caption: "Mostra anteprima lista",
                      onClick: (params) => onColumnPropChanged(column, "expandChildrens", params?.checked),
                    },
                    {
                      key: "description",
                      caption: (column.description?.trim()?.length ? "Modifica" : "Aggiungi") + " descrizione",
                      onClick: () => {
                        const newDescription = prompt("Descrizione", column.description);
                        if (newDescription !== undefined && newDescription !== null) {
                          onColumnPropChanged(column, "description", newDescription);
                        }
                      },
                    },
                    todoModeEnabled && {
                      key: "hide-tasks",
                      caption: "Nascondi task completati",
                      onClick: () => {
                        console.log({ parentId: props.boardProps.grape.id, cross_order: column.id });
                        client.mutate({
                          mutation: HIDE_COMPLETED_GRAPES,
                          variables: { parentId: props.boardProps.grape.id, cross_order: column.id },
                        });
                      },
                    },
                    todoModeEnabled && {
                      key: "resume-tasks",
                      caption: "Ripristina task completati",
                      subMenuItems: [
                        {
                          key: "resume-tasks-today",
                          caption: "Oggi",
                          onClick: () => restoreCompletedTasks(column, moment().subtract(1, "days").endOf("day")),
                        },
                        {
                          key: "resume-tasks-week",
                          caption: "Ultima settimana",
                          onClick: () => restoreCompletedTasks(column, moment().subtract(4, "days").startOf("week")),
                        },
                        {
                          key: "resume-tasks-all",
                          caption: "Tutti",
                          onClick: () => {
                            showConfirmDialog(
                              "Vuoi ripristinare tutti i task completati?",
                              "",
                              //calback
                              () => restoreCompletedTasks(column)
                            );
                          },
                        },
                      ],
                    },
                    {
                      key: "banana-dot",
                      caption: (noBananaDot ? "Abilita" : "Disabilita") + " invecchiamento 🍌",
                      onClick: () => onColumnPropChanged(column, "noBananaDot", !noBananaDot),
                    },
                    columns.length > 1 && {
                      key: "hide-show",
                      caption: isHidden ? "Mostra sempre questa colonna" : "Nascondi colonna nel backlog",
                      onClick: () => onColumnPropChanged(column, "hidden", !isHidden),
                    },

                    columns.length > 1 && {
                      key: "delete",
                      caption: "Elimina colonna",
                      color: "#de2000",
                      onClick: () => {
                        showConfirmDialog(`Vuoi eliminare la colonna "${column.name || " "}"?`, undefined, () => {
                          onDeleteColumn(column);
                        });
                      },
                    },
                  ]}
                >
                  <CustomTooltip
                    arrow
                    title={column.description || " "}
                    disableFocusListener={!column.description}
                    disableHoverListener={!column.description}
                    disableTouchListener={!column.description}
                    style={{ backgroundColor: "red" }}
                    css={
                      column.description
                        ? {
                            "::before": {
                              content: '""',
                              width: 6,
                              height: 6,
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 3,
                              display: "inline-block",
                              marginRight: 5,
                              marginBottom: 2,
                            },
                          }
                        : undefined
                    }
                  >
                    <DescriptionInput
                      id={"gid-title-" + column.id}
                      text={column.name}
                      placeholder="titolo..."
                      onChange={(text) => debounceColumnNameChange(column, text)}
                      style={{
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                        overflow: "hidden",
                        // fontSize: 18,
                        fontSize: 15,
                        marginTop: 5,
                        marginBottom: 5,
                        textAlign: "center",
                        fontWeight: "bold",
                        color: isHidden ? theme.palette.primary.main : undefined,
                      }}
                    />
                  </CustomTooltip>
                </Contextable>
              </div>
            </Box>
          );
        })}
        {/* </div> */}
      </div>
    </div>
  );

  function restoreCompletedTasks(column: BoardColumn, fromDate?: Moment) {
    client
      .mutate({
        mutation: RESTORE_COMPLETED_GRAPES,
        variables: { parentId: props.boardProps.grape.id, fromDate, cross_order: column.id },
        refetchQueries: [{ query: GET_GRAPE, variables: { id: props.boardProps.grape.id } }],
        awaitRefetchQueries: true,
      })
      .then(() => console.log("done"));
  }
};
