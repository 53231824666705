/** @jsxImportSource @emotion/react */
import { ApolloProvider, useApolloClient, useQuery } from "@apollo/react-hooks";
import { Button, Grid, TextField, ThemeProvider, Typography, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ApolloClient from "apollo-client";
import gql from "graphql-tag";
import moment from "moment";
import "moment/locale/it";
import { default as React, lazy, useContext, useEffect } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppGrapeDetail } from "./components/App/AppGrapeDetail";
import { MyGrapeButton } from "./components/App/components/MyGrapeButton";
import { RootGrapeContext, useRootGrapeController } from "./components/App/RootGrapeProvider";
import AppBarSmall from "./components/AppBarSmall";
import ApplicationError from "./components/ApplicationError";
import { AppControllerDebug } from "./components/Debug/AppDebug";
import { GrapesChatAppProvider } from "./components/GrapeDetail/ChatGrapesApp";
import { IGrape } from "./components/grape_gql_interface";
import InitialLoading from "./components/InitialLoading";
import MLDialog, { MLDialogProvider } from "./components/MLDialog";
import { MyGrapeDetail } from "./components/MyGrapeDetail";
import { SearchBox } from "./components/SearchBox";
import CircularSuspense from "./components/Suspense/CircularSuspense";
import { UnauthScaffold } from "./components/UnauthScaffold";
import { IUser } from "./components/User";
import useSocket from "./hooks/useSocket";
import appTheme from "./modules/theme";
import { RootGrapesList } from "./pages/Main/ListGrapes";
import { ResetPassword } from "./pages/ResetPassword";
import { GET_ME_SINGLE, RESET_PASSWORD, UPDATE_USER } from "./repositories/queries_mutations";
import { showInputDialog } from "./utils";
import { apolloClient, messaging, token } from "./utils/init";

const ChatView = lazy(() => import("./pages/Chat"));
const MyActivities = lazy(() => import("./pages/MyActivities"));

moment.locale("it");

export interface IGrapesApp {
  user: IUser;
  client: ApolloClient<any>;
}

//? grapes app context
const GrapesAppContext = React.createContext<IGrapesApp>({ user: {} as any, client: {} as any });
const { Provider: GrapesAppProvider, Consumer: GrapesAppConsumer } = GrapesAppContext;
// export const useGrapesApp = useContext(GrapesAppContext);
export const useGrapesApp = () => useContext(GrapesAppContext);
export { GrapesAppConsumer };

export const myGrapeConfigs: any = {
  myDay: { withCheckboxes: true },
  myWeek: { withCheckboxes: false },
  expiring: { withCheckboxes: true },
  assignedToMe: { withCheckboxes: true },
  archived: {},
};

const GrapesAppRoute = (props: {}) => {
  const rootGrapeController = useRootGrapeController();
  const {
    myGrapes,
    grapeDetailOnly,
    getDeletedGrapes,
    setRootSelectedGrape,
    selectedGrape,
    selectedMyGrape,
    loadingRootGrapes,
    rootGrapes,
    appError,
    // actions
    createNewGrape,
    createNewFolder,
  } = rootGrapeController;

  if (loadingRootGrapes) return <InitialLoading error={appError} />;
  if (!rootGrapes) return <ApplicationError error={appError} />;

  return (
    <RootGrapeContext.Provider value={rootGrapeController}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        onPaste={(e) => {
          console.log(e, 333333333);
        }}
      >
        <Box
          id="horizontal-scroller"
          display="flex"
          alignItems="flex-stretch"
          height="100%"
          className="no-sb"
          style={{ overflowX: "scroll" }}
        >
          {!grapeDetailOnly && (
            <Box
              display="flex"
              flexDirection="column"
              style={{
                borderRight: "1px solid #ccc",
                width: 250,
                minWidth: 250,
                maxWidth: 250,
              }}
            >
              <Box flexGrow="1" display="flex" flexDirection="column" className="droppable-container">
                <Box flexGrow="1" display="flex" flexDirection="column">
                  <SearchBox
                    onSelected={(grape) => {
                      setRootSelectedGrape(undefined, grape);
                    }}
                  />

                  <React.Fragment>
                    <MyGrapeButton
                      id="myDay"
                      title="🌞 La mia Giornata"
                      countCriteria={(x) =>
                        x.status === 0 && moment(x.date1 || x.date || x.created_at).isSameOrBefore(moment())
                      }
                    />
                    <MyGrapeButton id="assignedToMe" title="🏅 Assegnati a me" countCriteria={(x) => x.status === 0} />

                    <MyGrapeButton id="planner" title="📅 Planner" />
                    {/* <MyGrapeButton id="expiring" title="⏲ In scadenza" /> */}
                  </React.Fragment>

                  <div style={{ borderTop: "2px solid#ccc" }} />

                  <Box
                    flexGrow="1"
                    // className="droppable-container"
                    // css={{ backgroundColor: 'red', '> div': { backgroundColor: 'orange' } }}
                  >
                    <RootGrapesList
                      // ClientSortableTree
                      selectedGrape={selectedGrape}
                      rootGrapes={rootGrapes.allGrapes as IGrape[]}
                      onDeleted={(g) => {
                        if (selectedGrape && selectedGrape.id === g.id) setRootSelectedGrape(undefined, undefined);
                      }}
                      onRequestOpen={(g) => {
                        // if (item.id === selectedGrape) return;
                        setRootSelectedGrape(undefined, g, false);
                      }}
                    />
                  </Box>

                  {myGrapes?.archived?.length || getDeletedGrapes?.length ? (
                    <React.Fragment>
                      <div style={{ borderTop: "2px solid#ccc" }} />
                      <MyGrapeButton
                        id="archived"
                        title={"🔒 Archiviati" + (getDeletedGrapes.length ? " e eliminati" : "")}
                        countCriteria={(x) => x.status === 0}
                      />
                    </React.Fragment>
                  ) : null}
                </Box>

                <AppControllerDebug />
              </Box>

              {/* <JumpingFace /> */}

              <AppBarSmall
                actions={[
                  // utility to spread up main_order of rootGrapes
                  // {
                  //   title: "Poke",
                  //   muicon: "compare_arrows",
                  //   subactions: [
                  //     { muicon: "", title: "Giulio", onClick: () => poke("giulio@mabiloft.com") },
                  //     { muicon: "", title: "Michael", onClick: () => poke("michael@mabiloft.com") },
                  //     { muicon: "", title: "Daniele", onClick: () => poke("daniele@mabiloft.com") },
                  //     { muicon: "", title: "Veronica", onClick: () => poke("veronica@mabiloft.com") },
                  //     { muicon: "", title: "Beatrice", onClick: () => poke("beatrice@mabiloft.com") },
                  //     { muicon: "", title: "Francesco", onClick: () => poke("francesco@mabiloft.com") },
                  //     { muicon: "", title: "Simone", onClick: () => poke("simone@mabiloft.com") },
                  //     { muicon: "", title: "Eddy", onClick: () => poke("eddy@mabiloft.com") },
                  //     { muicon: "", title: "Tiziano", onClick: () => poke("tiziano@mabiloft.com") },
                  //   ],
                  // },

                  /*{
                      title: 'Spread',
                      muicon: 'publish',
                      onClick: () => {
                        console.log(55, data.allGrapes.map((x: any) => x.main_order).join('_'));
                        //data.allGrapes?.forEach((grape: IGrape, index: number) => {
                        //  if (!grape) return;
                        //  client.mutate({
                        //    mutation: EDIT_GRAPE,
                        //    variables: {
                        //      input: {
                        //        id: grape.id,
                        //        main_order: 50000 + (index * 5000),
                        //      },
                        //    },
                        //  });
                        //});
                      },
                    },*/

                  // {
                  //   title: "Refresh",
                  //   muicon: "refresh",
                  //   onClick: () => {
                  //     if (!resettingStore) {
                  //       resettingStore = true;
                  //       client.resetStore().then(_ => {
                  //         resettingStore = false;
                  //       });
                  //     }
                  //   }
                  // },

                  // { title: "Crea nuovo grappolo", muicon: "add", onClick: () => createNewGrape() },
                  {
                    title: "Crea nuovo grappolo",
                    muicon: "add",
                    subactions: [
                      { title: "📂 Crea una cartella", onClick: () => createNewFolder() },
                      { title: "📄 Nuovo grappolo", onClick: () => createNewGrape() },
                    ],
                  },
                  // { title: 'Chat threads', muicon: 'message', onClick: () => (document.location.pathname = '/chat') },
                ]}
              />
            </Box>
          )}

          <Box display="flex" flexGrow={1} alignItems="stretch" style={{ position: "relative" }}>
            {selectedMyGrape ? (
              <MyGrapeDetail key={moment().format("DD-MM-yyyy")} />
            ) : selectedGrape ? (
              <AppGrapeDetail />
            ) : null}

            {/* {renderSearchResults()} */}
          </Box>
        </Box>
      </Box>{" "}
    </RootGrapeContext.Provider>
  );
};

const AppRouter = (props: any) => {
  const { data: dataMeSingle, error, refetch: refetchUser } = useQuery(GET_ME_SINGLE, { skip: !token });
  const client = useApolloClient();
  const theme = useTheme();

  useSocket("userUpdated", () => {
    refetchUser();
  });

  const doLogin = () => {
    const input = {
      email: ((document.querySelector("#login-email") as any) || {}).value,
      password: ((document.querySelector("#login-password") as any) || {}).value,
    };

    if (!input.email || !input.password) {
      alert("empty fields");
      return;
    }

    client
      .mutate({
        mutation: gql`
          mutation login($input: InputLogin!) {
            uniqueLogin(input: $input)
          }
        `,
        variables: { input },
      })
      .then((resp: any) => {
        if (resp && resp.data && resp.data.uniqueLogin) {
          const token = resp.data.uniqueLogin;
          localStorage.setItem("app-token", token);
          window.location.href = "";
        } else {
          alert("login error");
        }
      })
      .catch((err: any) => alert(err));
  };

  if (!token || (error && error.message && error.message.includes("invalid JWT"))) {
    return (
      <Router>
        <Switch>
          <Route
            path="/reset-password"
            children={
              <CircularSuspense>
                <ResetPassword />
              </CircularSuspense>
            }
          />
          <Route>
            <UnauthScaffold>
              <Grid item xs>
                <h3 children="Login" />
              </Grid>

              <Grid item xs>
                <TextField id="login-email" type="email" label="Email" fullWidth />
              </Grid>

              <Grid item xs>
                <TextField
                  id="login-password"
                  label="Password"
                  fullWidth
                  style={{ marginTop: 10, marginBottom: 20 }}
                  type="password"
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      doLogin();
                    }
                  }}
                />
              </Grid>
              <Typography
                variant="caption"
                color="primary"
                children="Hai dimenticato la password?"
                css={{
                  marginBottom: 10,
                  ":hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}
                onClick={() => {
                  showInputDialog(
                    "Password dimenticata?",
                    "Inserisci qui sotto la email",
                    "email@mail.com",
                    (result) => {
                      if (result) {
                        const email = result.trim().toLowerCase().replace(" ", "");
                        // check if email is valid
                        if (!email.includes("@") || !email.includes(".")) {
                          MLDialog.showSnackbar("Email non valida", { variant: "error" });
                          return;
                        }

                        client
                          .mutate({ mutation: RESET_PASSWORD, variables: { email } })
                          .then(() => {
                            MLDialog.showSnackbar("Email inviata con successo", { variant: "success" });
                          })
                          .catch((err) => {
                            console.log("RESET", err);

                            MLDialog.showSnackbar("Qualcosa è andato storto", { variant: "error" });
                          });
                      }
                    }
                  );
                }}
              />
              <Button children="Login" variant="contained" color="primary" disableElevation onClick={() => doLogin()} />
            </UnauthScaffold>
          </Route>
        </Switch>
      </Router>
    );
  }

  return (
    <GrapesAppProvider value={{ user: dataMeSingle?.meSingle, client }}>
      <GrapesChatAppProvider>
        <Router>
          <Switch>
            <Route
              path="/my-activities"
              children={
                <CircularSuspense>
                  <MyActivities />
                </CircularSuspense>
              }
            />
            <Route
              path="/chat/:grapeId?/:threadId?"
              children={
                <CircularSuspense>
                  <ChatView />
                </CircularSuspense>
              }
            />
            <Route component={GrapesAppRoute} />
          </Switch>
        </Router>
      </GrapesChatAppProvider>
    </GrapesAppProvider>
  );
};

// main app container
const App: React.FC = () => {
  useEffect(() => {
    messaging
      ?.getToken()
      .then((currentToken: any) => {
        console.log("FCM_TOKEN", currentToken);
        if (currentToken) {
          if (token) {
            apolloClient
              .mutate({ mutation: UPDATE_USER, variables: { input: { fcm_token: currentToken } } })
              .then((response) => console.log(response))
              .catch((error) => console.log(error));
          }
          //? sendTokenToServer(currentToken);
          //? updateUIForPushEnabled(currentToken);
        } else {
          // Show permission request.
          console.log("No Instance ID token available. Request permission to generate one.");
          // Show permission UI.
          //? updateUIForPushPermissionRequired();
          //? setTokenSentToServer(false);
        }
      })
      .catch((err: any) => {
        console.log("An error occurred while retrieving token. ", err);
        //? showToken('Error retrieving Instance ID token. ', err);
        //? setTokenSentToServer(false);
      });
  }, [messaging]);

  return (
    <ThemeProvider theme={appTheme}>
      <ApolloProvider client={apolloClient}>
        <MLDialogProvider>
          <AppRouter />
        </MLDialogProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
