import { Box, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FullscreenIcon from "@material-ui/icons/ListAlt";
import ShareIcon from "@material-ui/icons/Share";
import React, { useState } from "react";
import { chatDisabled, IGrapeDetailAction } from ".";
import { copyToClipboard, isEmptyHTMLString } from "../../../utils";
import { __DEV__ } from "../../../utils/init";
import Contextable from "../../Contextable";
import { ContextableGrape } from "../../ContextableGrape";
import DescriptionInput from "../../DescriptionInput";
import { GrapeDetailActions } from "../../GrapeDetailItems/GrapeDetailActions";
import GrapeFilesItem from "../../GrapeDetailItems/GrapeFilesItem";
import { GrapeGanttButtonItem } from "../../GrapeDetailItems/GrapeGanttButtonItem";
import GrapeItemButton from "../../GrapeDetailItems/GrapeItemButton";
import { GrapeTypeSelector } from "../../GrapeDetailItems/GrapeTypeSelector";
import { GrapeUniqueId } from "../../GrapeDetailItems/GrapeUniqueId";
import GrapeFile, { IGrapeFile } from "../../GrapeFile";
import GrapeLabels, { ILabel } from "../../GrapeLabels";
import MLDialog from "../../MLDialog";
import { UserChip } from "../../User";
import { GrapeChatButton } from "../ChatGrapesApp";
import { GrapeDetailFilterBar } from "../components/GrapeDetailFilterBar";
import { grapeListTypes } from "../core/grapeTypes";
import { useGrapeDetailContextSelector, useSafeGrapeDetailContextSelector } from "./hooks/GrapeDetailContext";

export const GrapeDetailToolbar = (props: {
  actions?: IGrapeDetailAction[];
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
}) => {
  const grape = useGrapeDetailContextSelector("grape");
  const isRootGrape = useGrapeDetailContextSelector("isRootGrape");
  const isValidGrapeId = useGrapeDetailContextSelector("isValidGrapeId");
  const onRequestOpen = useGrapeDetailContextSelector("onRequestOpen");

  if (grapeListTypes[grape?.type]?.simpleHeader) {
    return (
      <Box
        // display="flex"
        // flexDirection="column"
        // flexShrink={1}
        style={{
          borderBottom: "1px solid #eee",
          padding: 10,
          position: "relative",
          // maxWidth: grape.type === "kanban" ? 300 : "100%",
          maxWidth: "100%",
        }}
      >
        <GrapeDetailHeader />
      </Box>
    );
  }

  const stickyBoxStyle: React.CSSProperties = {
    position: "sticky",
    left: 0,
    // maxWidth: 400,
  };

  const renderAssignees = () => {
    const assignees = grape.assignees || [];
    const maxUsers = 3;
    return (
      <Box display="flex" flexDirection="row">
        {/* {assignees.slice(0, Math.min(maxUsers, assignees.length)).map((user, index) => ( */}
        {assignees.map((user, index) => (
          <Contextable
            key={user.id}
            menuItems={[{ key: "deassign", caption: "Rimuovi assegnazione", onClick: () => {} }]}
            css={{ marginLeft: index > 0 ? -10 : 0 }}
          >
            <UserChip collapsed user={user} />
          </Contextable>
        ))}
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Box
        // display="flex"
        // flexDirection="column"
        // flexShrink={1}
        style={{
          borderBottom: "1px solid #eee",
          position: "relative",
          // maxWidth: grape.type === "kanban" ? 300 : "100%",
          maxWidth: "100%",
        }}
      >
        <Box style={{ ...stickyBoxStyle, maxWidth: 400, padding: 10 }}>
          <GrapeDetailHeader />

          {!isRootGrape && grape?.files?.length ? (
            <React.Fragment>
              <Grid
                container
                children={grape.files.map((f) => (
                  <GrapeFileThumbnail key={f.id} file={f} />
                ))}
              />
            </React.Fragment>
          ) : null}

          <Box
            display="flex"
            style={{ marginTop: 8, marginLeft: -8 }}
            css={{
              button: { padding: "1px 6px !important", letterSpacing: 1, fontSize: 12 },
            }}
          >
            {grapeListTypes[grape?.type]?.disableTypeSelector ? null : <GrapeTypeSelector />}
            {/* <GrapeDetailDocumentItem grape={grape} onRequestOpen={onRequestOpen} /> */}
            <GrapeGanttButtonItem />
            <GrapeDetailActions actions={props.actions} />
            <Box
              flexGrow={1}
              //css={{ maxWidth: 250 }}
            />
            <GrapeFilesItem iconOnly grape={grape} onRequestOpen={onRequestOpen} />
            {/* <GrapeDateItem iconOnly grape={grape} />  */}

            {/* {isRootGrape || collaborators.length !== (props.collaborators || []).length ? (
              <GrapeCollaboratorsThumbs
                iconOnly
                collaborators={collaborators}
                grape={grape}
                shouldFetchAnchestors={isValidGrapeId}
                onCollaboratorClick={(c) => console.log(c)}
                onInviteCollaboratorClick={() => Grapes.requestInviteToGrape(client, grape, collaborators)}
              />
            ) : (
              renderAssignees()
            )} */}
            {renderAssignees()}
            {/* descendent chats */}
            {!chatDisabled && isValidGrapeId && <GrapeChatButton grape={grape} />}
          </Box>
        </Box>
      </Box>

      {/* FILTER BAR */}
      <Box style={stickyBoxStyle}>
        <GrapeDetailFilterBar leading={props.leading} trailing={props.trailing} />
      </Box>
    </React.Fragment>
  );
};

const GrapeDetailHeader = (props: {}) => {
  const grape = useGrapeDetailContextSelector("grape");
  const isGrapeReady = useGrapeDetailContextSelector("isGrapeReady");
  const isValidGrapeId = useGrapeDetailContextSelector("isValidGrapeId");
  // actions
  const editGrape = useGrapeDetailContextSelector("editGrape");
  const onRequestCloseParent = useGrapeDetailContextSelector("onRequestCloseParent");
  const onModalExpand = useGrapeDetailContextSelector("onModalExpand");

  const [showMore, setShowMore] = useState<boolean | "hidden">(true);
  const hasDescription = !isEmptyHTMLString(grape?.description);

  const renderGrapeDescription = (collapsed?: boolean) => {
    return (
      <div
        css={[
          {
            marginTop: 10,
            marginBottom: 10,
            position: "relative",
            // visibility: showMore || grape?.description?.trim()?.length ? "visible" : "invisible",
            transition: "all 250ms",
          },
          showMore !== "hidden" && (showMore || hasDescription)
            ? {
                // maxHeight: "100vh",
                opacity: 1,
              }
            : {
                pointerEvents: "none",
                minHeight: "8px !important",
                maxHeight: "0 !important",
                opacity: 0,
                margin: 0,
              },
          collapsed
            ? {
                // maxHeight: showMore ? 'initial' : 64,
                overflowY: showMore === true ? "visible" : "hidden",
                maxHeight: 72,
                minHeight: 32,
                marginLeft: -6,
                marginRight: -6,
                zIndex: 1,
                " .input-description": {
                  transition: "border 200ms",
                  border: collapsed ? `1px solid ${showMore === true ? "#ccc" : "rgba(0, 0, 0, 0)"}` : undefined,

                  "::-webkit-scrollbar-thumb": {
                    background: "linear-gradient(white, #ccc 64px)",
                  },
                  ":focus": {
                    "::-webkit-scrollbar-thumb": {
                      background: "#ccc",
                    },
                  },
                },
              }
            : undefined,
        ]}
      >
        {/* <MDEditor
          value={grape.description}
          onChange={(v) => {
            console.log("edit", v);
          }}
        />
        <MDEditor.Markdown source={grape.description} style={{ whiteSpace: "pre-wrap" }} />

        <div children="--old--" css={{ margin: 20, background: "yellow" }} /> */}

        <DescriptionInput
          id={"gid-desc-" + grape.id}
          text={grape.description}
          placeholder="descrizione..."
          className={"input-description sm-sb " + (showMore === true ? "show-more" : "")}
          onChange={(text: string) => editGrape(grape.id, "description", text, { withDelay: true })}
          // onFocus={() => setShowMore(true)}
          // onBlur={() => setShowMore(false)}
          style={
            collapsed
              ? {
                  boxSizing: "border-box",
                  padding: 6,
                  maxHeight: "55vh",
                  overflowY: "scroll",
                  background: "white",
                }
              : undefined
          }
        />
      </div>
    );
  };

  const renderShareGrapeButton = () =>
    isValidGrapeId && (
      <div
        className="hoverable"
        style={{
          cursor: "pointer",
          opacity: 0.8,
          padding: "0 4px",
          transform: "translateY(-1px)",
          display: "inline-block",
        }}
        onClick={() => {
          //! wip - trovo l'url in maniera approssimativa, da migliorare e centralizzare
          copyToClipboard(window.location.href.split(grape.id)[0] + grape.id);
          MLDialog.showSnackbar("Link copied to clipboard");
        }}
        children={<ShareIcon style={{ width: 20 }} />}
      />
    );

  const renderCloseDetailButton = () =>
    isValidGrapeId &&
    onRequestCloseParent && (
      <div
        style={{
          cursor: "pointer",
          opacity: 0.8,
          transform: "translate(2px, -1px)",
          display: "inline-block",
          marginLeft: 2,
        }}
        onClick={onRequestCloseParent}
        // children={<CloseIcon style={{ width: 20 }} />}
        children={<CloseIcon style={{ width: 28 }} />}
      />
    );

  const renderExpandModalButton = () =>
    isValidGrapeId &&
    onModalExpand && (
      <div
        style={{
          cursor: "pointer",
          opacity: 0.8,
          transform: "translate(2px, -1px)",
          display: "inline-block",
          marginLeft: 2,
        }}
        onClick={onModalExpand}
        // children={<FullscreenIcon style={{ width: 20 }} />}
        children={<FullscreenIcon style={{ width: 28 }} />}
      />
    );

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={{
        minHeight: 21,
        position: "relative",
        maxWidth: ["kanban", "board"].includes(grape.type) ? 300 : "100%",
      }}
    >
      <Box
        flexGrow={1} // overflow="hidden"
        style={{ maxWidth: "100%" }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              float: "right",
              // height: 30,
              paddingLeft: 8,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* {renderShareGrapeButton()} */}
            {renderExpandModalButton()}
            {renderCloseDetailButton()}
          </div>

          {grape.type !== "detail" ? (
            <div style={{ float: "left", height: 23 }}>
              <GrapeAdvancedMenu />
            </div>
          ) : null}

          <DescriptionInput
            id={"gid-title-" + grape.id}
            text={grape.title}
            placeholder={!isGrapeReady ? "Loading.." : "titolo..."}
            onChange={(text: string) => editGrape(grape.id, "title", text, { withDelay: true })}
            style={{
              fontSize: 18,
              float: "none",
              // maxWidth: 220
            }}
          />
        </div>

        {/* RENDER GRAPE LABELS */}
        <GrapeDetailLabels />

        {!grapeListTypes[grape?.type]?.simpleHeader
          ? renderGrapeDescription(false && (true || ["kanban", "board"].includes(grape.type)))
          : null}
      </Box>
    </Box>
  );
};

const GrapeDetailLabels = (props: {}) => {
  const leading: ILabel[] = [];

  const grape = useSafeGrapeDetailContextSelector("grape");

  if (grape?.virtualStorypoints || grape.storypoints) {
    leading.push({
      title: "" + (grape?.virtualStorypoints || grape.storypoints),
      tooltip: "Total story points",
      color: {
        foreground: grape?.virtualStorypoints ? "#ee9900" : "#333333",
        background: "#ffffff",
      },
    });
  }

  (grape?.links_to || [])
    // .filter((link) => link.grape.type === "sprint")
    .forEach((link) => {
      leading.push({
        title: link.grape?.title,
        tooltip: "sprint: " + link.grape?.title,
        color: { background: "#ffffff", foreground: "#ff0077" },
      });
    });

  if (__DEV__ && (grape.type === "kanban" || grape.type === "board")) {
    leading.push({
      title: grape.type[0],
      tooltip: grape.type,
      color: {
        foreground: "#ffffff",
        background: grape.type === "board" ? "#009955" : "#ee9900",
      },
    });
  }

  return (
    <div style={{ marginTop: 5 }}>
      <GrapeUniqueId grape={grape} css={{ verticalAlign: "top", marginRight: 5 }} />
      {leading?.length || grape?.labels?.length ? <GrapeLabels leading={leading} labels={grape?.labels || []} /> : null}
    </div>
  );
};

const GrapeAdvancedMenu = (props: {}) => {
  const grape = useGrapeDetailContextSelector("grape");
  const myGrape = useGrapeDetailContextSelector("myGrape");
  const collaborators = useGrapeDetailContextSelector("collaborators");
  const allAnchestors = useGrapeDetailContextSelector("allAnchestors");
  const editGrape = useGrapeDetailContextSelector("editGrape");

  return (
    <ContextableGrape
      fromAdvancedMenu
      myGrape={myGrape}
      anchestors={allAnchestors}
      collaborators={collaborators}
      onChange={(attr, value, options) => editGrape(grape.id, attr, value, options)}
      grape={grape}
      bothMouseButtons
      children={
        <GrapeItemButton
          iconOnly
          icon="more_vert"
          style={{
            margin: 0,
            paddingLeft: 0,
            marginLeft: -8,
          }}
        />
      }
    />
  );
};

const GrapeFileThumbnail = (props: { file: IGrapeFile }) => (
  <GrapeFile
    key={props.file.id}
    grapeFile={props.file}
    onDeleted={() => {
      //   console.log("deleted", props.file)
    }}
  />
);
