/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useState, useRef } from "react";
import { QueryResult } from "@apollo/react-common";
import { Query } from "@apollo/react-components";
import { IUser, UserChip } from "./User";
import { gql } from "apollo-boost";
import Grape from "./Grape";
import {
  IGrape,
  IUserGrapePermission,
  GET_GRAPE,
  ASSIGN_GRAPE_TO_USER,
  DEASSIGN_GRAPE_FROM_USER,
  REMOVE_USER_FROM_GRAPE,
} from "./grape_gql_interface";
import { useApolloClient } from "@apollo/react-hooks";
import { useTheme, Popover, Tooltip } from "@material-ui/core";
import { useGrapesApp } from "../App";
import Contextable from "./Contextable";
import MLDialog from "./MLDialog";
import Grapes from "../modules/Grapes";
import GrapeItemButton from "./GrapeDetailItems/GrapeItemButton";
import { GET_MY_GRAPES } from "../repositories/queries_mutations";
import { getUserColor } from "../modules/UserUtils";
import { USER_FRAGMENT } from "../repositories/fragments";

export const GET_USER_GRAPE_ANCHESTORS = gql`
  ${USER_FRAGMENT}
  query getUsersFromGrape($grapeId: ID!) {
    getUsersFromGrape(grapeId: $grapeId) {
      user {
        ...user
      }
      permission
    }
  }
`;

const GrapeCollaboratorsThumbs = (props: {
  iconOnly?: boolean;
  onInviteCollaboratorClick?: () => void;
  collaborators: IUserGrapePermission[];
  grape: IGrape;
  selectedUserIds?: string[];
  shouldFetchAnchestors?: boolean;
  onCollaboratorClick?: (user: IUserGrapePermission) => void;
  onUpdatedCollaborators?: (list: IUserGrapePermission[]) => void;
  onAssignedTo?: (userId: string, deassign?: boolean) => void;
  maxWidth?: number | string;
  children?: (collaboratos?: IUserGrapePermission[]) => JSX.Element;
  activeUserIds?: string[];
}) => {
  const client = useApolloClient();
  const theme = useTheme();
  const grapesApp = useGrapesApp();
  const imgSize = 22;

  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef<any>();

  const onAssignTo = (userId: string, deassign?: boolean) => {
    client
      .mutate({
        mutation: deassign ? DEASSIGN_GRAPE_FROM_USER : ASSIGN_GRAPE_TO_USER,
        variables: { grapeId: props.grape.id, userId },
        refetchQueries: [{ query: GET_MY_GRAPES }, { query: GET_GRAPE, variables: { id: props.grape.id } }],
      })
      .catch((e) => {
        console.log(e);
        MLDialog.showSnackbar("Something went wrong");
      });
  };

  const onRemoveUserFromGrape = (user: IUser) => {
    if (window.confirm(`Vuoi rimuovere "${user?.name} ${user?.surname}" dai partecipanti?`)) {
      client
        .mutate({
          mutation: REMOVE_USER_FROM_GRAPE,
          variables: {
            userId: user.id,
            grapeId: props.grape.id,
          },
          refetchQueries: [{ query: GET_GRAPE, variables: { id: props.grape.id } }],
        })
        .catch((e) => {
          console.log(e);
          MLDialog.showSnackbar("Something went wrong");
        });
    }
  };

  return (
    <Query
      query={GET_USER_GRAPE_ANCHESTORS}
      variables={{ grapeId: props.grape.id }}
      skip={!props.shouldFetchAnchestors}
    >
      {({ data }: QueryResult) => {
        let allCollaborators: IUserGrapePermission[];

        if (data?.getUsersFromGrape?.length) {
          allCollaborators = [...(props.collaborators || []), ...data.getUsersFromGrape];
        } else {
          allCollaborators = props.collaborators || [];
        }

        const uniqueCollaborators: IUserGrapePermission[] = [];

        allCollaborators.forEach((gp, index) => {
          const assigned = !!(props.grape?.assignees || []).find((x) => x.id == gp.user.id);
          for (var c = 0; c < uniqueCollaborators.length; c++) {
            if (uniqueCollaborators[c]?.user?.id === gp?.user?.id) {
              uniqueCollaborators[c] = {
                ...gp,
                ...uniqueCollaborators[c],
                assigned,
                user: {
                  ...(gp?.user || {}),
                  ...(uniqueCollaborators[c]?.user || {}),
                },
              };
              return;
            }
          }
          uniqueCollaborators.push({ ...gp, assigned });
        });

        if (allCollaborators?.length !== props.collaborators?.length) {
          props.onUpdatedCollaborators?.(allCollaborators);
        }

        const renderCollaborators = () => (
          <div
            css={{
              whiteSpace: "initial",
              maxWidth: props.maxWidth || 240,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {uniqueCollaborators.map((uPermission: any, index: number) => {
              const isOwner = uPermission.permission === "owner";
              const isDeletable = !isOwner;
              const isAssigned = !!uPermission?.assigned;
              const isActive = props.activeUserIds?.includes(uPermission?.user?.id);
              const isSelected = (props.selectedUserIds || []).includes(uPermission?.user?.id);
              return (
                <Contextable
                  key={uPermission?.user?.id ?? index}
                  menuItems={[
                    {
                      key: "delete",
                      caption: `Rimuovi ${uPermission.user?.name} ${uPermission.user?.surname}`,
                      onClick: () => onRemoveUserFromGrape(uPermission.user),
                    },
                  ]}
                >
                  <UserChip
                    collapsed
                    size={imgSize}
                    user={uPermission?.user}
                    deletable={isDeletable}
                    avatarStyle={{ margin: 5 }}
                    className={isOwner ? "owner" : undefined}
                    tooltip={uPermission.permission ? uPermission.permission + " - " : ""}
                    assigned={isAssigned}
                    active={isActive}
                    onClick={() => {
                      if (props.onCollaboratorClick) {
                        props.onCollaboratorClick(uPermission);
                      } else {
                        onAssignTo(uPermission?.user.id, isAssigned);
                      }
                    }}
                    css={{
                      cursor: "pointer",
                      verticalAlign: "top",
                      display: "inline-block",
                      transition: "transform 400ms",
                      ".avatar::before": {
                        content: "''",
                        position: "absolute",
                        left: -2,
                        top: -2,
                        right: -2,
                        bottom: -2,
                        borderRadius: "50%",
                        transition: "opacity 200ms",
                        // border: "2.5px solid " + (isSelected ? theme.palette.primary.main : "#444"),
                        // opacity: isSelected ? 1 : 0,
                        border: "2.5px solid " + getUserColor(uPermission.user),
                        opacity: isActive || isSelected ? 1 : 0,
                      },
                      ":hover": {
                        transform: "rotate(-10deg)",
                        ".avatar::before": {
                          opacity: 1,
                        },
                      },
                      ".avatar::after": isSelected
                        ? {
                            content: "''",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            right: -0,
                            bottom: -0,
                            borderRadius: "50%",
                            border: "2.5px solid white",
                          }
                        : {},
                    }}
                  />
                </Contextable>
              );
            })}

            {props.onInviteCollaboratorClick && (
              <Tooltip title="Invite collaborator">
                <div
                  className="hoverable"
                  onClick={props.onInviteCollaboratorClick}
                  css={{
                    margin: 5,
                    width: imgSize,
                    height: imgSize,
                    borderRadius: (imgSize + 2) / 2,
                    display: "inline-block",
                  }}
                >
                  <div
                    css={{ display: "flex", alignItems: "center" }}
                    children={<i css={{ fontSize: imgSize }} className="material-icons" children="add" />}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        );

        if (props.children) {
          return props.children(uniqueCollaborators);
        } else if (!props.iconOnly) {
          return renderCollaborators();
        } else {
          return (
            <div
              css={{ position: "relative", display: "flex", alignItems: "center" }}
              ref={(r) => (popoverRef.current = r)}
            >
              <GrapeItemButton
                icon={uniqueCollaborators?.length === 1 ? "person" : "people_alt"}
                onClick={() => setPopoverOpen(true)}
                label={uniqueCollaborators?.length > 1 ? String(uniqueCollaborators.length) : ""}
              />

              <Popover
                open={popoverOpen}
                children={renderCollaborators()}
                onClose={() => setPopoverOpen(false)}
                anchorEl={popoverRef?.current}
                // anchorOrigin={{
                // vertical: "bottom",
                // horizontal: "center",
                // }}
              />
            </div>
          );
        }
      }}
    </Query>
  );
};

export default GrapeCollaboratorsThumbs;

export function getUniqueCollaborators() {}
