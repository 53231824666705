/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React, { useState, useRef, useEffect } from "react";
import { Calendar, DateRange } from "react-date-range";
import { Popover, useTheme, Grid, Button, Box, Select, MenuItem } from "@material-ui/core";
import moment from "moment";
//@ts-ignore
import * as rdrLocales from "react-date-range/dist/locale";
import { isSameDay } from "date-fns";

export default (props: {
  date: Date;
  rangePicker?: boolean;
  dateStart?: Date;
  onChange: (date: Date, dateStart?: Date) => void;
}) => {
  const theme = useTheme();
  // const [date, setDate] = useState(props.date);

  // const DatePickerComp = Calendar as any;
  const DatePickerComp = (props.rangePicker ? DateRange : Calendar) as any;
  const hoverColor = "orange";

  return (
    <div
      css={{
        minHeight: 350,
        "button.rdrDay > span": {
          borderRadius: 0,
          "&.rdrDayStartPreview.rdrDayEndPreview": {
            opacity: 0,
            color: hoverColor + " !important",
            ":hover": { opacity: 1 },
          },
        },
        "button.rdrDay .rdrDayNumber::after": { borderRadius: "0 !important" },
        "button.rdrDayToday .rdrDayNumber span::after": {
          background: "transparent !important",
          top: -1,
          bottom: "-1px !important",
          height: "auto !important",
          border: "1px solid " + hoverColor,
          borderRadius: 0,
          transform: "none",
          left: 1,
          right: 1,
          width: "auto",
        },
        // "button.rdrDayToday .rdrSelected ~ .rdrDayNumber span::after": {},
      }}
    >
      <DatePickerComp
        date={props.date}
        //? range picker
        ranges={[{ startDate: props.dateStart || props.date, endDate: props.date }]}
        // onChange={(ranges: { startDate: Date; endDate: Date }) => {
        onChange={(data: any) => {
          if (props.rangePicker) {
            const range: { startDate: Date; endDate: Date } = data.range1;
            props.onChange(range.endDate, isSameDay(range.startDate, range.endDate) ? undefined : range.startDate);
          } else {
            props.onChange(data);
          }
        }}
        // !---
        moveRangeOnFirstSelection={false}
        retainEndDateOnFirstSelection={false}
        locale={rdrLocales.it}
        color={theme.palette.secondary.main}
        rangeColors={[theme.palette.secondary.main]}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
        showSelectionPreview={false}
      />
    </div>
  );
};
