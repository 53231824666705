/** @jsxImportSource @emotion/react */
import { Box, Checkbox } from "@material-ui/core";
import moment, { Moment } from "moment";
import { IGrape } from "../grape_gql_interface";
import { InfiniteCalendar, IEvent } from "../CalendarZ";
import { useQuery } from "@apollo/react-hooks";
import { useState } from "react";
import { getStringColors } from "../GrapeLabels";
import { MY_GRAPE_FRAGMENT } from "../../repositories/fragments";
import gql from "graphql-tag";
import { useSafeWindowPollingQuery } from "../../utils/safeWindowQuery";

var TurndownService = require("turndown").default;

export function parseEventsGroupingAncestors(list: IGrape[], params?: { fromParentId?: string; skip?: String[] }) {
  const turndownService = new TurndownService();

  const ancestors: IGrape[] = [];

  const events: IEvent[] = list
    ?.map((g) => g as IGrape & { ancestors: IGrape[] })
    .filter((g) => !(params?.skip || []).includes((g.ancestors?.[0] || g).id))
    .map((g) => {
      let rootAncestor: IGrape | undefined = g.ancestors?.[0] || g;

      let titleList = g.ancestors || [];

      if (params?.fromParentId) {
        const foundIndex = titleList.findIndex((item) => item.id === params.fromParentId);
        if (foundIndex >= 0) {
          rootAncestor = titleList[foundIndex + 1] || g;
          titleList = titleList.slice(foundIndex + 1) || [];
        }
      }

      let parsedTitle = [...titleList, g]
        .reverse()
        .map((a) => a.title)
        .join(" / ");
      parsedTitle = turndownService.turndown(parsedTitle);

      if (rootAncestor && !ancestors.find((item) => item.id === rootAncestor?.id)) {
        ancestors.push(rootAncestor);
      }

      const colors = rootAncestor ? getStringColors(rootAncestor.title) : undefined;
      return {
        id: g.id,
        // title: (rootAncestor ? rootAncestor.title + " - " : "") + g.title,
        title: parsedTitle,
        start: g.date0 || g.date,
        end: g.date,
        type: g.status,
        colors: colors,
      };
    });

  return { events, ancestors };
}

export default (props: {}) => {
  const [monthDate, setMonthDate] = useState(() => new Date());

  const [hiddenItems, setHiddenItems] = useState<IGrape[]>([]);

  // const { data: dataDescendentDeadlines } = useQuery(GRAPE_DESCENDENT_DEADLINES, {
  //   // variables: { grapeId: grapeFromProps?.id },
  //   variables: {},
  //   skip: toSkipMainQuery,
  //   pollInterval,
  // });

  const { data: dataPlanner } = useSafeWindowPollingQuery(PLANNER_WITH_DATES, {
    variables: { start: moment(monthDate).startOf("month"), end: moment(monthDate).endOf("month") },
    pollInterval: 10000,
  });

  console.log("💜", "data planner", dataPlanner?.getPlannerWithDates);
  console.log(moment(monthDate).startOf("month").toISOString() + "\n" + moment(monthDate).endOf("month").toISOString());

  // const { events, ancestors } = parseEventsGroupingAncestors(props.expiring, { skip: hiddenItems.map((g) => g.id) });
  const { events, ancestors } = parseEventsGroupingAncestors(dataPlanner?.getPlannerWithDates, {
    skip: hiddenItems.map((g) => g.id),
  });

  return (
    <Box flex={1} display="flex" flexDirection="row">
      <Box flexGrow={1}>
        <InfiniteCalendar
          css={{
            width: "calc(100% - 40px)",
            height: "calc(100% - 40px)",
            maxWidth: 1000,
            maxHeight: 1000,
            margin: 20,
            positin: "relative",
            "> table": { width: "100%", height: "100%" },
          }}
          monthDate={monthDate}
          onMonthChange={(newMonthDate) => setMonthDate(newMonthDate)}
          events={events}
        />
      </Box>

      {/* RIGHT BAR */}
      <div css={{ margin: 20, marginLeft: 0, minWidth: 140, maxWidth: 280, height: 500 }}>
        {[...ancestors, ...hiddenItems]
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((root, i: number) => {
            return (
              <Box
                key={root.id}
                display="flex"
                flexDirection="row"
                alignItems="center"
                css={
                  {
                    // padding: "4px 0",
                    // borderTop: i > 0 ? "1px solid #eee" : undefined
                  }
                }
              >
                <Checkbox
                  checked={hiddenItems.filter((g) => g.id == root.id).length == 0}
                  onChange={(e) => {
                    const isNowChecked = e.target.checked;
                    setHiddenItems((_list) => {
                      const list = _list.filter((g) => g.id !== root.id);
                      if (!isNowChecked) list.push(root);
                      return list;
                    });
                  }}
                  style={{ color: getStringColors(root.title).background }}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: root.title }}
                  css={{
                    // marginLeft: 15,
                    fontsize: 14,
                    fontWeight: 500,
                  }}
                />
              </Box>
            );
          })}
      </div>
    </Box>
  );
};

export const PLANNER_WITH_DATES = gql`
  ${MY_GRAPE_FRAGMENT}
  query getPlannerWithDates($start: Date!, $end: Date!) {
    getPlannerWithDates(startDate: $start, endDate: $end) {
      ...myGrape
    }
  }
`;
