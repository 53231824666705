/** @jsxImportSource @emotion/react */
import { getLuminance, IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { firstValidColor } from "../utils";
import { useSafeGrapeDetailContextSelector } from "./GrapeDetail/GrapeDetail/hooks/GrapeDetailContext";

export function getStringColors(str: string) {
  const background = "#" + intToRGB(str ? hashCode(str.toLowerCase()) : 0);
  const foreground = getContrastYIQ(background, undefined, "white");
  return { background, foreground };
}

export interface ILabelColor {
  foreground: string;
  background: string;
}

export interface ILabel {
  title: string;
  tooltip?: string;
  color?: ILabelColor;
  onClick?: (e?: any) => void;
}

const GrapeLabels = (props: {
  leading?: ILabel[];
  labels?: string[];
  onLabelClick?: (l: string, index: number, event: any) => void;
  disableTooltip?: boolean;
}) => {
  // const theme = useTheme();

  if (!props.labels?.length && !props.leading) return null;

  // const hexColor = '#' + intToRGB(hashCode(props.labels[0].toLowerCase()));
  // const color = getContrastYIQ(hexColor, undefined, 'white');

  // return (
  //   <React.Fragment
  //     children={props.labels.map((x, i) => (
  //       <div
  //         key={i}
  //         children={x}
  //         className="grape-label"
  //         style={{
  //           padding: '2px 4px',
  //           marginRight: 4,
  //           display: 'inline-block',
  //           borderRadius: 4,
  //           background: '#eee',
  //           fontFamily: 'monospace',
  //           fontWeight: 'bold',
  //         }}
  //       />
  //     ))}
  //   />
  // );

  return (
    <React.Fragment>
      {props.leading?.map((item, i) => (
        <GrapeLabel key={`l-${i}`} {...item} disableTooltip={props.disableTooltip} />
      ))}

      {props.labels?.map((l, i) => (
        <GrapeLabel
          key={`i-${i}`}
          title={l}
          onClick={(e) => props.onLabelClick?.(l, i, e)}
          disableTooltip={props.disableTooltip}
        />
      ))}
    </React.Fragment>
  );
};

export function GrapeLabel(
  props: ILabel & {
    className?: string;
    onClick?: (e: any) => void;
    leading?: (colors: ILabelColor) => React.ReactNode;
    trail?: (colors: ILabelColor) => React.ReactNode;
    labelColors?: any;
    disableTooltip?: boolean;
    onEditRequest?: (label: string, color: string) => void;
  }
) {
  const title = (props.title || "").toUpperCase();
  const labelColors = useSafeGrapeDetailContextSelector("labelColors") || props.labelColors || {};
  let background =
    firstValidColor(props.color?.background, labelColors[title]) || `#${intToRGB(hashCode(title.toLowerCase()))}`;

  const foreground =
    props.color?.foreground || (background ? getContrastYIQ(background, undefined, "#ffffff") : "#000000");

  let tooltip = props.tooltip;

  const isBlocked = ["❌", "BLOCKED"].includes(title);
  if (isBlocked) {
    background = "#ededed";
    tooltip = "Blocked";
  }

  const isMirror = ["🔗", "MIRROR"].includes(title);
  if (isMirror) {
    background = "#fff";
    tooltip = "Mirror";
  }

  const comp = (
    <div
      className={"grape-label " + (props.className || "")}
      css={{
        textTransform: "uppercase",
        padding: "2px 4px",
        marginRight: 4,
        display: "inline-block",
        borderRadius: 4,
        // background: props.labels[1] ? props.labels[1] : 'rgba(240, 90, 0, 0.2)', // 'rgba(0, 0, 0, 0.1)',
        background: background,
        fontFamily: "monospace",
        fontWeight: "bold",
        boxShadow: background && getLuminance(background) > 0.9 ? "inset 0 0 2px black" : undefined,
        // color: props.labels[1] ? 'white' : undefined,
        // color: '#' + intToRGB(hashCode(props.labels[0])),
        color: foreground,
        // color: 'white',
        maxWidth: 140,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      onClick={props?.onClick}
    >
      {props.leading && props.leading({ foreground, background })}

      {isMirror || isBlocked ? (
        <span children={isBlocked ? "❌" : isMirror ? "🔗" : ""} css={{ fontSize: 9 }} />
      ) : (
        <span children={title} />
      )}

      {props.trail && props.trail({ foreground, background })}
    </div>
  );

  if (props.disableTooltip || !tooltip) {
    return comp;
  }

  return <Tooltip title={tooltip} children={comp} />;
}

export function getContrastYIQ(hexcolor: string, dark?: string, light?: string) {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  // return yiq >= 128 ? dark || 'black' : light || 'white';
  // return yiq >= 188 ? dark || "black" : light || "white";
  return yiq >= 170 ? dark || "black" : light || "white";
}

function hashCode(str: string) {
  //forcing colors
  if (str === "test") return 20109999999;
  if (str === "ui") return 2111997;
  if (str === "app") return 27071994;

  // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: number) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
}

export default GrapeLabels;
