/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { Tooltip } from "@material-ui/core";
import { CSSProperties } from "react";
import React, { MouseEventHandler } from "react";

const GrapeItemButton = (props: {
  onClick?: (e: any) => void;
  outlined?: boolean;
  icon: string;
  label?: string;
  color?: string;
  tooltip?: string;
  iconOnly?: boolean;
  className?: string;
  style?: CSSProperties;
}) => {
  const comp = (
    <div
      className={(props.className || "") + ("hoverable" + (props.outlined ? " outlined" : ""))}
      css={{
        marginLeft: 8,
        display: "flex",
        alignItems: "center",
        padding: "0 6px",
      }}
      onClick={props.onClick}
      style={props.style}
    >
      <i
        className="material-icons"
        children={props.icon}
        style={{
          fontSize: 16,
          opacity: 0.8,
          color: props.color,
          margin: "5px 3px",
        }}
      />
      <span children={props.label} style={{ color: props.color, letterSpacing: 1, fontSize: 12 }} />
    </div>
  );

  if (!props.tooltip) return comp;

  return <Tooltip title={props.tooltip} children={comp} />;
};

export default GrapeItemButton;
