/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import { Box, fade, Tooltip, useTheme } from "@material-ui/core";
import moment, { Moment } from "moment";
import { CSSProperties } from "react";
import { getStringColors } from "./GrapeLabels";

function getMonthYearDateString(date: Moment) {
  // const mmmm = date.format('MMMM').split('').join('\n');
  const mmmm = date.format("MMMM").split("");
  const yyyy = date.format("YYYY").split("");

  var str = "";

  for (var c = 0; c < mmmm.length; c++) {
    str += mmmm[c];
    if (c < yyyy.length) str += " " + yyyy[c];
    str += "\n";
  }

  return str;
}

const weekDays: { dddd: string; ddd: string }[] = [...new Array(7)].map((_, day) => ({
  ddd: moment().weekday(day).format("ddd"),
  dddd: moment().weekday(day).format("dddd"),
}));

export type DateSelection = { startDate: Date; endDate?: Date };

export type IEvent = {
  id: string | number;
  title: string;
  start: string;
  end: string;
  type: number;
  colors?: { foreground: string; background: string };
};

//? shared props - defs
const monthBarWidth = 28;
const borderColor = "#ccc";
const minCellWidth = 70;
const cellHeight = 18;
const showMonthAtRight = false;
const scrollable = false;

interface IInfiniteCalendar {
  selectable?: boolean;
  selection?: DateSelection;
  onSelectionChange?: (sel: DateSelection) => void;
  oneDayOnly?: boolean;
  events?: IEvent[];
  onEventClick?: (evt: IEvent) => void;
  monthDate: Date;
  onMonthChange?: (date: Date) => void;
  className?: string;
}

export const InfiniteCalendar = (props: IInfiniteCalendar) => {
  // const theme = useTheme();
  // const today = moment();
  // const initialDate = moment().startOf('month').startOf('week');

  return (
    <div
      css={{
        position: "relative",
        display: "inline-block",
        overflowY: "scroll",
        paddingRight: showMonthAtRight ? monthBarWidth : 0,
        margin: 20,
        maxHeight: "calc(100% - 40px)",
        //   maxHeight: '100%',
        border: "1px solid " + borderColor,
        // backgroundColor: '#f8f8f8',
        backgroundColor: "#fff",
      }}
      className={(props.className || "") + " no-sb"}
    >
      {/* {initialDate.format('DD-MMMM-yyyy')} */}

      <table
        cellPadding={0}
        cellSpacing={0}
        css={{
          position: "relative",
          backgroundColor: "white",
          "thead th": {
            position: "sticky",
            top: "0",
            backgroundColor: "white",
          },
          "tbody td": { padding: 0 },
        }}
      >
        <thead css={{ position: "relative", zIndex: 1 }}>
          <tr>
            <th colSpan={7}>
              <div css={{ display: "flex", flexDirection: "column", boxShadow: "0 1px 1px rgba(0,0,0,0.18)" }}>
                <div css={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <i
                    className="material-icons hoverable"
                    children="chevron_left"
                    css={{ padding: 8, userSelect: "none" }}
                    onClick={() => props.onMonthChange?.(moment(props.monthDate).subtract(1, "month").toDate())}
                  />
                  <div
                    css={{ flex: 1, textAlign: "center", textTransform: "capitalize" }}
                    children={moment(props.monthDate).format("MMMM YYYY")}
                  />
                  <i
                    className="material-icons hoverable"
                    children="chevron_right"
                    css={{ padding: 8, userSelect: "none" }}
                    onClick={() => props.onMonthChange?.(moment(props.monthDate).add(1, "month").toDate())}
                  />
                </div>
                <div css={{ display: "flex", flexDirection: "row" }}>
                  {weekDays.map((day, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          flex: 1,
                          padding: 3,
                          textAlign: "center",
                          // fontWeight: 'normal',
                          fontFamily: "monospace",
                          textTransform: "uppercase",
                          // color: '#555',
                          // width: 33,
                          // fontSize: 16,
                          // borderBottom: '1px solid ' + borderColor,
                        }}
                        children={day.ddd}
                      />
                    );
                  })}
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {renderMonth(props.monthDate)}
          {/* {[...new Array(6 * 7)].map((_, week) => {
            const weekDate = initialDate.clone().add(week * 7, 'days');
            return <CalendarWeek key={week} weekDate={weekDate} {...props} />;
          })} */}
        </tbody>
      </table>
    </div>
  );

  function renderMonth(renderMonthDate: string | Date) {
    const monthDate = moment(renderMonthDate);
    const initialDate = moment(renderMonthDate).startOf("month").startOf("week");
    const lastDate = moment(renderMonthDate).endOf("month").endOf("week");
    const toRenderWeeks = (lastDate.diff(initialDate, "days") + 1) / 7;

    // console.log('render', toRenderWeeks, 'weeks');

    return [...new Array(toRenderWeeks)].map((_, week) => {
      const weekDate = initialDate.clone().add(week * 7, "days");
      // console.log(week, weekDate.format('DD MMM YYYY'));
      return <CalendarWeek key={week} weekDate={weekDate} {...props} />;
    });
  }
};

interface ICalendarWeek extends IInfiniteCalendar {
  weekDate: Moment;
}

const CalendarWeek = (props: ICalendarWeek) => {
  const theme = useTheme();
  const weekOrders: any = {};
  const selectionBorderRadius = 8;
  const selectionBorderWidth = 2;
  const today = moment();

  const getCleanTitle = (src: string) => {
    let str = src || "";

    // remove html tags
    // str = str.replace(/<\/?[^>]+(>|$)/g, "");

    // remove emojis
    str = str.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, "");

    return str;
  };

  const renderMonthLabel = (date: Moment) => (
    <div
      // children={date.format('MMMM').split('').join('\n')}
      children={getMonthYearDateString(date)}
      css={{
        paddingLeft: 4,
        width: monthBarWidth - 4,
        textAlign: "left",
        borderTop: "1px solid " + borderColor,
        whiteSpace: "pre-line",
        textTransform: "uppercase",
        position: "absolute",
        right: -monthBarWidth,
        // fontWeight: 'bold',
        fontSize: 12,
        lineHeight: "13px",
        paddingTop: 5,
        fontFamily: "monospace",
        color: "#888",
        // transform: 'translate(0px, -7px)',
        transform: "translate(0px, -1px)",
      }}
    />
  );

  return (
    <tr css={{ verticalAlign: "top" }}>
      {weekDays.map((day, index) => {
        const date = props.weekDate.clone().add(index, "days");
        const intDate = date.date();

        const isToday = today.isSame(date, "date");
        const isCurrentMonth = date.isSame(props.monthDate, "month");

        const isSelected =
          props.selection &&
          date.isSameOrAfter(props.selection.startDate, "date") &&
          date.isSameOrBefore(props.selection.endDate, "date");

        const allEvents = props.events?.filter(
          (e) => date.isSameOrAfter(e.start, "date") && date.isSameOrBefore(e.end, "date")
        );
        //?.sort((e1, e2) => ((weekOrders[e1.id] || 0) > (weekOrders[e2.id] || 0) ? 1 : 0));

        return (
          <td
            key={index}
            css={{
              position: "relative",
              paddingBottom: 2,
              // zIndex: 7 - index,
              zIndex: 0,

              // borderLeft: intDate === 1 && index > 0 ? "1px solid " + borderColor : undefined,
              // borderTop: "1px solid " + (intDate <= 7 ? borderColor : "transparent"),
              borderTop: "1px solid #e4e4e4",
              backgroundColor: isCurrentMonth ? "transparent" : "#f5f5f5",

              // "::before": {
              //   content: "''",
              //   position: "absolute",
              //   left: 0,
              //   top: 0,
              //   bottom: 0,
              //   zIndex: -1,
              //   opacity: 0.2,
              //   borderLeft: "1px solid " + (index > 0 ? borderColor : "transparent"),
              //   pointerEvents: "none",
              // },

              "::after": isSelected
                ? {
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 2,
                    bottom: 2,
                    content: '""',
                    pointerEvents: "none",
                    zIndex: 1,
                    backgroundColor: fade(theme.palette.primary.main, 0.24),
                    border: selectionBorderWidth + "px solid " + theme.palette.primary.main,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    // borderLeftWidth: isSelected && date.isSame(props.selection!.startDate, "date") ? 2 : 0,
                    // borderRightWidth: isSelected && date.isSame(props.selection!.endDate, "date") ? 2 : 0,
                    ...(date.isSame(props.selection!.startDate, "date")
                      ? {
                          left: 2,
                          borderLeftWidth: selectionBorderWidth,
                          borderTopLeftRadius: selectionBorderRadius,
                          borderBottomLeftRadius: selectionBorderRadius,
                        }
                      : {}),
                    ...(date.isSame(props.selection!.endDate, "date")
                      ? {
                          right: 2,
                          borderRightWidth: selectionBorderWidth,
                          borderTopRightRadius: selectionBorderRadius,
                          borderBottomRightRadius: selectionBorderRadius,
                        }
                      : {}),
                  }
                : {},
            }}
          >
            {intDate === 1 && showMonthAtRight ? renderMonthLabel(date) : null}

            <div css={{ display: "flex", flexDirection: "column" }}>
              <div
                className={props.selectable ? "hoverable" : undefined}
                css={{
                  position: "relative",
                  fontWeight: isToday ? "bold" : undefined,
                  // color: isToday ? theme.palette.primary.main : undefined,
                  padding: 6,
                  // padding: 4,
                  margin: 2,
                  textAlign: "center",
                  borderRadius: 4,
                  //!  border: "1px solid " + (isSelected ? theme.palette.primary.main : "transparent"),
                  opacity: isCurrentMonth ? undefined : 0.4,
                }}
                onClick={() => {
                  const newSelection = {} as DateSelection;

                  if (
                    // props.selection &&
                    props.selection?.endDate &&
                    moment(props.selection.startDate).isSame(props.selection.endDate, "date")
                  ) {
                    if (date.isAfter(props.selection.startDate)) {
                      newSelection.endDate = date.toDate();
                      newSelection.startDate = props.selection.startDate;
                    } else {
                      newSelection.startDate = date.toDate();
                      newSelection.endDate = props.selection.startDate;
                    }
                  }

                  if (!newSelection.startDate) newSelection.startDate = date.toDate();
                  if (!newSelection.endDate) newSelection.endDate = date.toDate();

                  props.onSelectionChange?.(newSelection);
                }}
              >
                {intDate}

                {intDate === 1 && scrollable && !showMonthAtRight && (
                  <div
                    css={{
                      position: "absolute",
                      top: -2,
                      left: 0,
                      right: 0,
                      textAlign: "center",
                      fontSize: 10,
                      overflow: "hidden",
                      letterSpacing: "1.8px",
                      // opacity: 0.7,
                      textTransform: "uppercase",
                    }}
                    children={date.format("MMMM")}
                    // children={date.format('MMMM').substr(0, 8)}
                  />
                )}
              </div>

              <div
                css={{
                  display: "grid",
                  gridAutoRows: cellHeight + "px",
                  gridRowGap: 2,
                  minWidth: minCellWidth,
                  paddingBottom: 4,
                }}
              >
                {allEvents?.map((e, i) => {
                  const firstOfWeek = !(weekOrders[e.id] >= 0);
                  if (!firstOfWeek) {
                    return null;
                  }

                  weekOrders[e.id] = i;

                  //! find free index
                  const occupiedIndexes = allEvents
                    .map((x) => (x.id !== e.id && weekOrders[x.id] >= 0 ? weekOrders[x.id] : -1))
                    // .filter((x) => x.id !== e.id)
                    // .map((x) => (weekOrders[x.id] >= 0 ? weekOrders[x.id] : -1))
                    .sort((a, b) => a - b);

                  for (var j = 0; j < occupiedIndexes.length; j++) {
                    if (weekOrders[e.id] === occupiedIndexes[j]) {
                      weekOrders[e.id] = occupiedIndexes[j] + 1;
                    }
                  }

                  // const daysExtentOfWeek = 1 + Math.min(6 - index, -date.diff(e.end, 'days'));
                  const daysExtentOfWeek = -date.diff(e.end, "days");
                  const colors = e.colors || getStringColors(e.title || "");

                  let cellSpan = 1 + Math.min(6 - index, daysExtentOfWeek);
                  let cellWidthFix = 0;

                  const labelStyle: any = {
                    left: 0,
                    backgroundColor: colors.background,
                  };
                  if (date.isSame(e.start, "date")) {
                    labelStyle.borderTopLeftRadius = 4;
                    labelStyle.borderBottomLeftRadius = 4;
                    cellWidthFix -= 2;
                    labelStyle.left += 2;
                  }
                  if (daysExtentOfWeek <= 6 - index) {
                    labelStyle.borderTopRightRadius = 4;
                    labelStyle.borderBottomRightRadius = 4;
                    cellWidthFix -= 2;
                  }

                  // 0.28 multiplier just fixes large rows
                  labelStyle.width = `calc(${100 * cellSpan}% + ${cellSpan * 0.28}px + ${cellWidthFix}px)`;

                  return (
                    <div
                      key={e.id}
                      css={{
                        position: "relative",
                        gridRow: 1 + weekOrders[e.id],
                        height: cellHeight,
                      }}
                    >
                      <Tooltip
                        // title={dateTypes.find((d) => d.value === e.type)?.title || "date"}
                        title={e.title || getDateTypeTitle(e.type) || "date"}
                      >
                        <div
                          onClick={props.onEventClick ? () => props.onEventClick!(e) : undefined}
                          // children={daysExtentOfWeek + '_' + (6 - index) + '_' + e.title}
                          children={getCleanTitle(e.title)}
                          style={labelStyle}
                          css={{
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            // textTransform: 'uppercase',
                            overflow: "hidden",
                            cursor: props.onEventClick ? "pointer" : undefined,
                            color: colors.foreground,
                            // fontSize: 11,
                            padding: "1px 3px",
                            boxSizing: "border-box",
                            fontFamily: "monospace",
                            fontWeight: "bold",
                            ":hover": props.onEventClick ? { opacity: 0.7 } : undefined,
                          }}
                        />
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const dateTypes = [
  { value: 0, title: "date" },
  { value: 1, title: "deadline" },
  { value: 2, title: "event" },
  { value: 3, title: "track" },
];

export const getDateTypeTitle = (type: number) => dateTypes.find((d) => d.value === type)?.title || "date";
export const getDateTypeValueTitle = (title: string) => dateTypes.find((d) => d.title === title)?.value || 0;
