import React from "react";
import { IGrapeDetailAction } from "../GrapeDetail/GrapeDetail";
import GrapeItemButton from "./GrapeItemButton";

export const GrapeDetailActions = (props: { actions?: IGrapeDetailAction[] }) => {
  return (
    <React.Fragment
      children={props.actions?.map((action, i) => (
        <GrapeItemButton
          key={action.icon + "_" + i}
          icon={action.icon}
          tooltip={action.tooltip}
          color={action.color}
          label={action.label}
          onClick={action.onClick}
        />
      ))}
    />
  );
};
