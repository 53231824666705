export interface GrapeListType {
  icon: string;
  title?: string;
  selectable?: boolean;
  renderInput?: boolean;
  headerScrollsWithContent?: boolean;
  noBananaDotsChildrens?: boolean;
  disableTypeSelector?: boolean;
  preventPasteEvent?: boolean;
  styles?: React.CSSProperties;
  simpleHeader?: boolean;
}

export const grapeListTypes = {
  list: {
    icon: "reorder",
    selectable: true,
    renderInput: true,
    headerScrollsWithContent: true,
    noBananaDotsChildrens: true,
  },
  progress: {
    icon: "settings_ethernet",
    selectable: false,
    renderInput: true,
    headerScrollsWithContent: true,
  },
  todo: {
    icon: "playlist_add_check",
    selectable: true,
    renderInput: true,
    headerScrollsWithContent: true,
  },
  kanban: {
    icon: "calendar_view_week",
    title: "Kanban",
    selectable: true,
    renderInput: true,
    headerScrollsWithContent: false,
    // headerScrollsWithContent: true,
    styles: { width: "auto" },
  },
  board: {
    icon: "view_column",
    title: "Board (deprecated)",
    selectable: true,
    renderInput: false,
    headerScrollsWithContent: false,
    // headerScrollsWithContent: true,
    styles: { width: "auto" },
  },
  root: {
    icon: "text_rotate_horizontal",
    selectable: false,
    renderInput: false,
    headerScrollsWithContent: true,
    styles: { width: 800, maxWidth: "100vw" },
  },
  detail: {
    icon: "text_rotate_vertical",
    selectable: false,
    renderInput: false,
    headerScrollsWithContent: false,
    simpleHeader: true,
    preventPasteEvent: true,
    styles: { width: 500 },
  },
  chat: {
    icon: "chat",
    renderInput: false,
    headerScrollsWithContent: false,
  },
  // tabs: {
  //   icon: "summarize",
  //   selectable: true,
  // },
  // gantt: {
  //   icon: "summarize",
  //   selectable: true,
  // },
  canvas: {
    icon: "draw",
    selectable: false,
    renderInput: false,
    headerScrollsWithContent: false,
    simpleHeader: true,
  },
  mirror: {
    icon: "screen-share",
    selectable: false,
    disableTypeSelector: true,
  },
} as { [key: string]: GrapeListType };

export type GrapeListTypeKey = keyof typeof grapeListTypes;

export const defaultGrapeListType = "list";

export const hideGrapesFromChildren = ["chat", "deadline", "detail", "sprint", "column"];
