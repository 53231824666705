import React from "react";
import { Grid } from "@material-ui/core";
import { IGrape } from "./grape_gql_interface";
import { CSSProperties } from "react";

const AnchestorsBreadcrumbs = (props: {
  anchestors: IGrape[];
  onClick?: (grape: IGrape, index: number) => void;
  style?: CSSProperties;
}) => (
  <Grid container direction="row" style={props.style}>
    {props.anchestors.map((item, index) => (
      <div
        key={item.id}
        style={{ padding: "0 8px" }}
        onClick={(e: any) => {
          if (e.stopPropagation && props.onClick) e.stopPropagation();
          props.onClick?.(item, index);
        }}
      >
        <span children=" ‣ " />
        <span dangerouslySetInnerHTML={{ __html: item.title }} className="btn-anchor" />
      </div>
    ))}
  </Grid>
);

export default AnchestorsBreadcrumbs;
