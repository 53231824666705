/** @jsxImportSource @emotion/react */
import { useApolloClient } from "@apollo/react-hooks";
import { Box, Dialog } from "@material-ui/core";
import { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { onDragEndHandler } from "../../../modules/dndHandler";
import AnchestorsBreadcrumbs from "../../AnchestorsBreadcrumbs";
import { IGrape, IUserGrapePermission } from "../../grape_gql_interface";
import { MyGrapeType } from "../../MyGrapeDetail";
import VelocityDialog from "../VelocityDialog";
import { GrapeDetailRoot } from "./GrapeDetailRoot";
import { GrapeDetailContextProvider, useSafeGrapeDetailContextSelector } from "./hooks/GrapeDetailContext";

export const grapesPollInterval = 10000;
export const chatDisabled = true; // search query: is chat enabled disabled isChatEnabled isChatDisabled

export interface IGrapeDetailAction {
  icon: string;
  label?: string;
  color?: string;
  tooltip?: string;
  onClick?: () => boolean | void;
}

export interface IGrapeDetail {
  onModalExpand?: () => void;
  anchestors: IGrape[];
  grape: IGrape;
  myGrape?: MyGrapeType;
  collaborators?: IUserGrapePermission[];
  initialUrlGrapes: string[];
  onGrapeSubpathChange?: (path: string) => void;
  params?: GrapeDetailOpenParams;
  onDeleted?: () => void;
  selected?: boolean;
  onRequestClose?: () => void;
}

export interface GrapeDetailOpenParams {
  anchestors?: IGrape[];
  openAside?: boolean;
  isMainTabsGrape?: boolean;
}

export const getGrapeDraggableId = (params: { item: IGrape; parent: IGrape }) => {
  const { item, parent } = params;
  return item.id + "_" + parent.id + "_" + parent.status + "_" + parent.type;
};

const GrapeDetail = (props: IGrapeDetail) => {
  const grape = useSafeGrapeDetailContextSelector("grape");
  const userNotAllowed = useSafeGrapeDetailContextSelector("userNotAllowed");
  const collaborators = useSafeGrapeDetailContextSelector("collaborators");
  const missingAnchestors = useSafeGrapeDetailContextSelector("missingAnchestors");
  const allAnchestors = useSafeGrapeDetailContextSelector("allAnchestors");
  const selectedGrapeParams = useSafeGrapeDetailContextSelector("selectedGrapeParams");
  const urlGrapes = useSafeGrapeDetailContextSelector("urlGrapes");
  const modalGrape = useSafeGrapeDetailContextSelector("modalGrape");
  // actions
  const setSelectedGrape = useSafeGrapeDetailContextSelector("setSelectedGrape");
  const onRequestClose = useSafeGrapeDetailContextSelector("onRequestClose");
  const closeModal = useSafeGrapeDetailContextSelector("closeModal");
  const onRequestOpenAside = useSafeGrapeDetailContextSelector("onRequestOpenAside");

  // - #

  const client = useApolloClient();
  const [velocityGrapeId, setVelocityGrapeId] = useState<string | undefined>();

  //! trying to request not allowed grape
  if (userNotAllowed) return null;

  return (
    <div css={{ display: "flex", flexDirection: "column" }} className={"grape-detail-" + props.grape.id}>
      {missingAnchestors.length ? (
        <AnchestorsBreadcrumbs
          onClick={(g) => setSelectedGrape(g)}
          anchestors={missingAnchestors}
          style={{ margin: "0 4px", padding: "4px 0", borderBottom: "1px solid #ccc" }}
        />
      ) : null}

      <GrapeDetailRoot />

      <VelocityDialog
        grape={grape}
        grapeId={velocityGrapeId}
        onClose={() => {
          setVelocityGrapeId(undefined);
        }}
      />

      {renderModalGrape()}
    </div>
  );

  function renderModalGrape() {
    if (!modalGrape?.grape) return null;
    // if (!selectedGrape) return null;

    return (
      <Dialog
        open={!!modalGrape.visible}
        // open={modalGrape}
        // fullWidth
        onClose={closeModal}
        id="modal-horizontal-scroller"
        css={{
          // maxWidth: 650,
          ".MuiDialog-paperWidthSm": { maxWidth: "94vw" },
          ".MuiBackdrop-root": { background: "rgba(0, 0, 0, 0.24)" },
        }}
        // fullScreen
        // style={{width:300}}
      >
        <DragDropContext onDragEnd={(res) => onDragEndHandler(client, res)}>
          <Box display="flex" style={{ height: 740, maxHeight: "94vh" }}>
            <GrapeDetailProvider
              key={modalGrape.grape.id}
              onModalExpand={() => {
                closeModal();
                onRequestOpenAside(modalGrape!.grape!, modalGrape?.params, true);
              }}
              anchestors={[...allAnchestors, grape]}
              grape={modalGrape.grape}
              params={selectedGrapeParams}
              collaborators={collaborators}
              onRequestClose={onRequestClose}
              initialUrlGrapes={urlGrapes || []}
              onDeleted={() => setSelectedGrape(undefined)}
            />

            {/* {renderGrapeDetail(modalGrape)} */}
          </Box>
        </DragDropContext>
      </Dialog>
    );
  }
};

export const GrapeDetailProvider = (props: IGrapeDetail) => {
  return (
    <GrapeDetailContextProvider props={props}>
      <GrapeDetail key={props.grape?.id} {...props} />
    </GrapeDetailContextProvider>
  );
};
